import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#726B83',
    backgroundColor: 'transparent',
    fontSize: 14,
    lineHeight: 1.1,
    borderRadius: 12,
    padding: 0,
    boxShadow: 'none',
    textAlign: 'left',
    '&:hover': {
      color: '#4300FF',
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}));

function ButtonText(props) {
  const { children, ...passThroughProps } = props
  const classes = useStyles();

  return (
    <Button disableRipple { ...passThroughProps } className={ classes.root }>
      {children}
    </Button>
  )
}
export default ButtonText
