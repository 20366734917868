import React from 'react';
import {
  Box,
  Grid,
  Avatar,
  Typography
} from '@material-ui/core';

// import {giveWorkforceName} from '../../utility/workforce'
import {startCreateGroup} from '../../components/modals/CreateGroup'
import GroupElement from '../../components/groups/GroupElement'
import ButtonBrick from '../../components/general/buttons/ButtonBrick'
// import ProfilePreview from '../../components/profile/ProfilePreview'
import AddRoundedIcon from '@material-ui/icons/AddRounded';



// Main function
function People(props) {
  const { parent } = props
  let { fullTeams, fullCommunities} = parent.state

  return (
    <>
      {/* Intro */}
      <Box mb={2}>
        <Grid container direction="row">
          <Box width={60}>
            <Avatar
              style={{
                width: 90, height: 90,
                border: '3px solid #141217',
                borderRadius: 12,
                boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.1)'
              }}
              src={"https://drive.google.com/uc?id=1KA4eVY-xeftOHAWrh8qlV5jcgemIaiPI"}
            />
          </Box>
          <Box pl={6} style={{ width: 'calc(100% - 90px)' }}>
            <Typography variant="h2">
              <i>Novo</i>
            </Typography>
            <Typography variant="subtitle1" style={{ color: '#4300FF' }}>
              We're building SAAS for space – aka SAASS
            </Typography>
          </Box>
        </Grid>
      </Box>
      {/* Groups */}
      <Box my={3}>


        {/* Teams */}
        <Box p={2} my={2} style={{ background: '#EFEFF1', borderRadius: 12 }}>
          <Typography variant="h5">
            Teams
          </Typography>

          <Box my={2} />

          {fullTeams.length ? (
            fullTeams.map((team) => {
              if(!team.parent) {
                return (
                  <Box my={1}>
                    <GroupElement parent={parent} group={team} type="team" />
                  </Box>
                )
              } else return null
            })
          ) : "No teams"}

          <ButtonBrick startIcon={<AddRoundedIcon style={{ fontSize: 15 }} />}
            onClick={() => startCreateGroup(parent, 'team')}
          >
            Add a team
          </ButtonBrick>
        </Box>

        {/* Communities */}
        <Box p={2} my={2} style={{ background: '#EFEFF1', borderRadius: 12 }}>
          <Typography variant="h5">
            Communities
          </Typography>

          <Box my={2} />

          {fullCommunities.length ? (
            fullCommunities.map((community) => {
              return (
                <Box my={1}>
                  <GroupElement parent={parent} group={community} type="community" />
                </Box>
              )
            })
          ) : "No communities"}

          <ButtonBrick  startIcon={<AddRoundedIcon style={{ fontSize: 15 }} />}
            onClick={() => startCreateGroup(parent, 'community')}
          >
            Add a community
          </ButtonBrick>
        </Box>

        <Box my={10}/>
      </Box>
    </>
  )
};
export default People;
