import React from "react";
import { v4 as uuidv4 } from 'uuid';
import {
  Typography,
  Button,
  IconButton,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl
  } from '@material-ui/core';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';

import ButtonGrey from '../general/buttons/ButtonGrey';
import ChipLight from '../general/chips/ChipLight';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {sendInviteLink} from '../../api/sendgrid'
import {createInviteLink} from '../../api/workforce'

export function toggleInviteDiv(parent) {
  parent.setState({inviteDiv: !parent.state.inviteDiv})
  if(!parent.state.inviteLink) {
    let inviteLink = uuidv4();
    let fullLink = {
      code: inviteLink,
      created: Date.now
    }

    createInviteLink(parent.state.workforce._id, parent.state.token, fullLink, parent.state.user.permission_token)
    .then((response) => {
      parent.setState({inviteLink: inviteLink})
    })

  }
}


function Invite(props) {
  const [open, setOpen] = React.useState(false);
  const {parent} = props

  const handleClickOpen = () => {
    toggleInviteDiv(parent)
    setOpen(true);
  };

  const handleClose = (deleteEmails) => {
    parent.setState({inviteLinkCopied: false})
    setOpen(false);
    if(deleteEmails) {
      parent.setState({inviteEmails: []})
    }
  };

  function giveLinkText() {
    return `https://app.joinhelm.com/joinWorkforce/${parent.state.workforce._id}/${parent.state.inviteLink}`
  }

  function linkIsReady() {
    if(parent.state.inviteLink) {
      return true
    } else return false
  }

  function enterEmail() {
    parent.state.inviteEmails.push(parent.state.inviteEmailField)
    parent.setState({inviteEmailField: ''})
  }

  function removeEmail(email) {
    console.log('delete')
    console.log(email)
    let index = parent.state.inviteEmails.indexOf(email)
    if(index !== -1) {
      parent.state.inviteEmails.splice(index, 1)
    }
    parent.forceUpdate()
  }

  async function sendEmails() {
    let emails = parent.state.inviteEmails
    let field = parent.state.inviteEmailField
    let link = giveLinkText()

    // account for them not clicking enter
    if(!emails.length && field && field.length) {
      emails.push(field)
    }

    if(emails.length) {
      await emails.forEach((email, i) => {
        sendInviteLink(email, link, parent.state.workforce ? parent.state.workforce.name : '')
      })
      handleClose(true)
    }
  }

  //TODO
  if(parent && parent.state.workforce) {
  // if(false) {
    return (
      <div>
        <Button variant="contained" color="primary" onClick={handleClickOpen} startIcon={ <PersonAddIcon />}>
          Add teammates
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
          <DialogTitle>
            <Typography variant="h4">Invite People to {parent.state.workforce.name}</Typography>
          </DialogTitle>
          <DialogContent>
            <Box mr={1}>
              <Typography variant="subtitle2">
                Invite via email or copy an invite link
              </Typography>
            </Box>
            <Box my={3} />

            <Grid container direction="row" spacing={2} alignItems="center">
              <Grid item xs={11}>
                <FormControl fullWidth >
                  <TextField
                    autoFocus
                    label="Email"
                    type="email"
                    placeholder="example@email.com"
                    fullWidth
                    value={parent.state.inviteEmailField}
                    onChange={(event) => parent.setState({inviteEmailField: event.target.value})}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <IconButton variant="filled" color="primary" size="small">
                  <AddRoundedIcon onClick={() => enterEmail()} />
                </IconButton>
              </Grid>
            </Grid>

            <Box my={3} />

            {parent.state.inviteEmails.map((e, i) => {
              return (
                <ChipLight
                  icon={ <SendIcon fontSize="small" /> }
                  label={e}
                  onDelete={() => removeEmail(e)}
                  deleteIcon={<CloseIcon />}
                />
              )
            })}
            <Box mt={3}>

            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container direction="row-reverse" justify="space-between">
              <Box>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button variant="contained" onClick={() => sendEmails()} color="primary">
                  Send
                </Button>
              </Box>
              {linkIsReady() ? (
                <Box mx={1} my={1}>
                  <CopyToClipboard text={giveLinkText()}
                    onCopy={() => parent.setState({ inviteLinkCopied : true})}>
                    <ButtonGrey
                      disableElevation
                      variant="contained"
                      size="small"
                      startIcon={ <FileCopyRoundedIcon fontSize="small" /> }>
                        {parent.state.inviteLinkCopied ? 'Copied!' : 'Copy link'}
                    </ButtonGrey>
                  </CopyToClipboard>

                </Box>
              ) : null}
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    )
  } else return null
};

export default Invite;
