


export const mentorMatchGoals = [
  {
    id: 'g1',
    name: 'Learn the ropes'
  },
  {
    id: 'g2',
    name: 'Plan my career path'
  },
  {
    id: 'g3',
    name: 'Master my domain of work'
  },
  {
    id: 'g4',
    name: 'Find a role model'
  },
  {
    id: 'g5',
    name: 'Navigate new challenges'
  },
]

export const adjectives = [
  'adaptable','adventurous','ambitious','calm','careful',
  'compassionate','conscientious',
  'courteous','creative','decisive','determined','diligent','diplomatic',
  'dynamic','easygoing','emotional','energetic','enthusiastic',
  'frank','generous',
  'helpful','humorous','imaginative',
  'independent','intellectual','intuitive','inventive','kind',
  'modest','optimistic','passionate','patient','persistent',
  'pioneering', 'polite','practical',
  'pro-active','rational','reliable','reserved','resourceful',
  'self-confident','self-disciplined','sensitive','sincere',
  'sociable','straightforward','thoughtful','tidy',
  'versatile','witty'
]

export const defaultDomains = ["Sales", "Product", "Operations", "Development",
  "Marketing", "Legal", "HR", "Finance", "Design", "Engineering"]

export const motivators = ["Mission", "Lifestyle", "Teammates", "Culture", "Challenge", "Mastery", "Autonomy"]
export const levels = ["Novice", "Competent", "Proficient", "Advanced", "Guru"]

export const contactSituations = [
  {
    text: 'In general',
    value: 'primary'
  },
  {
    text: 'To set a mtg',
    value: 'meetings'
  },
  {
    text: 'If it\'s urgent',
    value: 'urgent'
  },
  {
    text: 'If it\'s casual',
    value: 'casual'
  },
]

export function contactValueToText(key) {
  let val = ''
  contactSituations.forEach((item, i) => {
    if(item.value === key) {
      val = item.text
    }
  });
  return val
}

export const contactMethods = [
  {
    name: 'Slack'
  },
  {
    name: 'Video Call'
  },
  {
    name: 'Text'
  },
  {
    name: 'Phone Call'
  },
  {
    name: 'Email',
    email: true
  },
  {
    name: 'Calendly',
    link: true
  },
  {
    name: 'Teams'
  },
  {
    name: 'In-person'
  }
]

const giveContactMethodsObj = () => {
  let res = {}
  contactMethods.forEach((cm, i) => {
    res[cm.name] = cm
  });
  console.log(res)
  return res
}

export const contactMethodsObj = giveContactMethodsObj()

export const defaultContactPreferences = {
  primary: {
    name: 'Slack'
  },
  meetings: {
    name: 'Calendly'
  },
  urgent: {
    name: 'Phone Call'
  },
  casual: {
    name: 'Text'
  }
}

export const templateFAQs = [
  'Describe how you receive feedback best',
  'Describe how you prefer to be recognized',
  'Describe how you work with others',
  'Define your ideal workday',
  'Describe what you need to work at your best'
]

export const collabs = [
  {
    title: 'New hire mentoring'
  },
  {
    title: 'Career mentoring'
  },
  {
    title: 'Just chatting'
  },
  {
    title: 'Work reviews'
  },
  {
    title: 'Grab lunch'
  },
  {
    title: 'Coffee chats'
  },
  {
    title: 'Brainstorming'
  },
]
