import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: '#141217',
    borderRadius: 12,
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#312E3B',
    },
  },
}));

function ButtonBlack(props) {
  const { children, ...passThroughProps } = props
  const classes = useStyles();

  return (
    <Button { ...passThroughProps } variant="contained" className={ classes.root }>
      {children}
    </Button>
  )
}
export default ButtonBlack
