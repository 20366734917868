export const sendInviteLink = async (email, link, workforceName) => {
  if(email) {
    try {
      const response = await fetch(`https://joinhelm.com/api/sendInviteLink?email=${email}&link=${link}&wfn=${workforceName}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
      });

      const responseData = await response.json();

      return responseData
    } catch (error) {
      console.log(error)
    }
  }
};
