import React from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import ProfilePreview from '../profile/ProfilePreview'
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

function userTeammates(parent) {
  let userTeams = parent.state.userTeams
  console.log(userTeams)
  if(userTeams && userTeams.length) {
    return (
      <>
        {userTeams.map((team) => {
          let leader = false
          if(team.leads && team.leads.length) {
            team.users.forEach((user, i) => {
              if(!leader && team.leads.includes(user._id)) {
                leader = user
              }
            });
          }

          return (
            <Box p={2} mb={1.5} style={{ border: '3px solid #EFEFF1', borderRadius: 12 }}>
              <Typography variant="h6" gutterBottom>{team.name}</Typography>

              {team.users.map((profile) => {
                if(profile._id === leader._id) {
                  return (
                    <Box pb={1}>
                      <Typography variant="overline" gutterBottom>
                        Lead
                      </Typography>
                      <ProfilePreview
                        parent={parent} user={profile}
                        style={{ border: '2px solid #EFEFF1' }}
                      />
                    </Box>
                  )
                } else return null
              })}
              {team.users.map((profile) => {
                if(profile._id !== parent.state.user._id) {
                  if(profile._id === leader._id) {
                    return null
                  } else return (
                    <ProfilePreview
                    parent={parent} user={profile} />
                  )
                } else return null
              })}
            </Box>
          )
        })}
      </>
    )
  } else return 'You are not part of any teams'
}



function DemoPhoebeIntros(props) {
  let {parent} = props

  const close = () => {
    parent.setState({ demoPhoebeIntros: false })
  }


  // TOMMY (part 1/2): the number in the case statements below is the step the tour is on
  // previously it was step 1 = teammates, 2 = mentor match, 3 = communites
  // create new cases and change the step numbers accordingly to modify the tour
  // only other thing that needs accounted for is the next() function above ^
  const giveDialog = () => {
    return (
      <>
        <DialogTitle style={{ background: '#FFF' }}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="overline" style={{ color: '#726B83' }}>
              INTROS
            </Typography>
            <IconButton size="small" onClick={() => close()}>
              <CloseRoundedIcon style={{ color: '#726B83' }} />
            </IconButton>
          </Grid>
          <Box>
            <Typography variant="h4" gutterBottom>
              Welcome aboard! <br/>
              <span style={{ color: '#4300FF' }}>Let's meet your teammates.</span>
            </Typography>
            <Typography variant="body1" style={{ color: '#726B83' }}>
              <b>Helm will make intros in Slack.</b> Take a second to view their profiles to learn more about who they are.
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent style={{ background: '#FFF' }}>


          {userTeammates(parent)}

        </DialogContent>

        <DialogActions>
          <ButtonPurple onClick={() => close()}>
            Done
          </ButtonPurple>
        </DialogActions>
      </>
    )
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={true} onClose={() => close()}>
        {giveDialog()}
      </Dialog>
    </>
  )
}

export default DemoPhoebeIntros;
