import React from 'react';

const axios = require('axios');
const encodeUrl = require('encodeurl')

export const SLACK_REDIRECT_URI = `https://joinhelm.com/api/mo/slack_user_oauth`
export const PERSONAL_SLACK_LINK =`https://slack.com/oauth/v2/authorize?client_id=339604598439.1680535122228&user_scope=identity.basic,identity.avatar&redirect_uri=${SLACK_REDIRECT_URI}`
export const WORKFORCE_SLACK_LINK = "https://slack.com/oauth/v2/authorize?client_id=339604598439.1680535122228&scope=chat:write,im:write,users.profile:read,users:read,team:read,channels:read,groups:read&user_scope=identity.avatar,identity.basic"

const SLACK_API = 'https://slack.com/api'

export function addToSlackButton() {
  return (
    <a href="https://slack.com/oauth/v2/authorize?client_id=339604598439.3490680230657&scope=channels:manage,chat:write,commands,groups:write,im:write,mpim:write,team:read,users:read,groups:read,channels:read&user_scope=identity.avatar,identity.basic"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
  )
}

export function getSlackTeamProfile(slackToken) {
  return axios.get(`${SLACK_API}/team.info?token=${slackToken}`)
  .then(response => {
      // console.log(response)
      return response
  }).catch(error => {
      console.log(error);
      return null
  });
}

export function getSlackChannels(slackToken) {
  return axios.get(`${SLACK_API}/conversations.list?token=${slackToken}&exclude_archived=true&types=public_channel,private_channel`)
  .then(response => {
      // console.log(response)
      return response
  }).catch(error => {
      console.log(error);
      return null
  });
}

export function getSlackUsers(slackToken, slackCursor, existingUsers) {
  console.log('ran')

  // handle pagination
  let cursor = ``
  if(slackCursor) {
    cursor = `&cursor=${encodeUrl(slackCursor)}`
  }

  // handle stacking users
  if(!existingUsers) {
    existingUsers = []
  }

  return axios.get(`${SLACK_API}/users.list?token=${slackToken}&limit=200${cursor}`)
  .then(res => {
    let response = res.data
    let allUsers = existingUsers.concat(response.members)

    // if there are more users to retrieve, call again and stack users
    console.log(response)
    console.log(response.response_metadata)
    if(response.response_metadata && response.response_metadata.next_cursor && response.response_metadata.next_cursor.length) {
      return getSlackUsers(slackToken, response.response_metadata.next_cursor, allUsers)
    } else { // otherwise return users
      response.members = allUsers
      return response
    }
  }).catch(error => {
      console.log(error);
      return null
  });
}

export function getSlackUserChannel(user, slackToken) {
  return axios.get(`${SLACK_API}/conversations.open?users=${user}&token=${slackToken}`)
  .then(response => {
    // console.log(response)
      if(response.data.ok) {
        return response.data.channel.id
      } else return 'error'
  }).catch(error => {
      console.log(error);
      return null
  });
}

export async function sendSlackInviteDM(slackToken, workforceId, inviteLink, workforceName, invitedBy, token, slackId) {
  try {
    const response = await fetch(`https://joinhelm.com/api/slack/invite`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        slackToken: slackToken,
        workforceId: workforceId,
        inviteLink: inviteLink,
        workforceName: workforceName,
        invitedBy: invitedBy,
        slackId: slackId
      })
    });

    const responseData = await response.json();

    return responseData
  } catch (error) {
    console.log(error)
  }
}

export function OLDsendSlackInviteDM(slackToken, channel, workforceId, inviteLink, workforceName, invitedBy) {
  let blocks = [
		{
			"type": "header",
			"text": {
				"type": "plain_text",
				"text": `:wave: Hello! ${invitedBy} invited you to join ${workforceName} on Helm.`,
				"emoji": true
			}
		},
		{
			"type": "section",
			"text": {
				"type": "mrkdwn",
				"text": "*I'm here to manage your living work profile* while you work... \n\n>*Why it matters:* \n>:handshake: *Stay connected* while working from anywhere \n>:bulb: *Find clarity* in the story behind your work \n>:dart: *Keep growing* by showing off what you can do"
			}
		},
		{
			"type": "actions",
			"elements": [
				{
					"type": "button",
					"text": {
						"type": "plain_text",
						"text": "Get started",
						"emoji": true
					},
					"style": "primary",
          "value": `${JSON.stringify({
            ignore: true
          })}`,
					"action_id": "actionId-0",
          "url": `https://app.joinhelm.com/joinWorkforce/${workforceId}/${inviteLink}`
				}
			]
		}
	]
  let encodedBlocks = encodeUrl(JSON.stringify(blocks))

  return axios.get(`${SLACK_API}/chat.postMessage?channel=${channel}&blocks=${encodedBlocks}&token=${slackToken}`)
  .then(response => {
      // console.log(response)
      return response
  }).catch(error => {
      console.log(error);
      return null
  });
}
