import React, {Component} from 'react';
import queryString from "query-string"
import Loading from "../components/general/Loading";
import Cookies from 'universal-cookie';
import { Redirect } from "react-router-dom"


class UserFork extends Component {

  constructor() {
    super()
    this.state = {
      redirect: false
    }
  }

  componentDidMount() {
    const cookies = new Cookies();
    const values = queryString.parse(this.props.location.search)
    if(values.independent) {
      cookies.set('independentFork', true, { path: '/' });
    } else if(values.team) {
      cookies.set('teamFork', true, { path: '/' });
    }
    this.setState({redirect: true})
  }

  render() {

    if (this.state.redirect) {
      return <Redirect to={`/`} />
    }

    return (
      <>
        <Loading />
      </>
    )
  }
}
export default UserFork;
