import React from 'react'
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function BlackChip(props) {
  let { children, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#141217',
      color: '#FFF',
      height: 28,
      fontWeight: 500,
      padding: '0 5px 0 5px'
    }
  }));

  const classes = useStyles();

  return (
    <Chip { ...passThroughProps } className={ classes.root }>
      {children}
    </Chip>
  )
}
export default BlackChip
