import React from "react";
import { CircularProgress, Grid, Box } from '@material-ui/core';

const Loading = () => (
  <Grid container direction="row" justify="center">
    <Box my={10}>
      <CircularProgress />
    </Box>
  </Grid>
);

export default Loading;
