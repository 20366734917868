import React from 'react'
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function PurpleSquareChip(props) {
  let { children, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'rgba(67,0,255,0.1)',
      color: '#4300FF',
      borderRadius: 8,
      height: 26,
      fontSize: 15,
      fontWeight: 700,
      fontFamily: 'lato',
      letterSpacing: 0.3,
      padding: 1,
      '&:hover': {
        backgroundColor: 'rgba(67,0,255,0.2)'
      }
    }
  }));

  const classes = useStyles();

  return (
    <Chip { ...passThroughProps } className={ classes.root }>
      {children}
    </Chip>
  )
}
export default PurpleSquareChip
