import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {giveFirstName, giveLastName, giveTitle,
      givePurposeStatement, givePronouns,
      capitalizeFirstLetter, giveOnboardingInfo
    } from '../../utility/profile'
import {giveWorkforceName, giveWorkforceLogo} from '../../utility/workforce'
import {imageSrc} from '../../utility/images'
import {contactValueToText} from '../../utility/constants'
import {office_profiles} from '../../utility/demo/office-profiles'
import {toggleProfileDetails} from './UpdateProfile'
import {onboardingDiv} from '../../components/profile/onboarding'
import {handleSearch} from './SearchResults'
// import {addToSlackButton} from '../../api/slack'

import CapabilityBlock from '../../components/profile/CapabilityBlock'
import Invite from '../../components/profile/Invite'
import FAQBlock from '../../components/profile/FAQBlock'
import ProfilePreview from '../../components/profile/ProfilePreview'
// import MentorPreview from '../../components/profile/MentorPreview'
import PurpleSquareChip from '../../components/general/chips/PurpleSquareChip'
import YellowSquareChip from '../../components/general/chips/YellowSquareChip'
import BlueSquareChip from '../../components/general/chips/BlueSquareChip'
import GreenSquareChip from '../../components/general/chips/GreenSquareChip'
import ButtonWhite from '../../components/general/buttons/ButtonWhite'
import ButtonBlack from '../../components/general/buttons/ButtonBlack'
import ButtonText from '../../components/general/buttons/ButtonText'
import ExtraSmallAvatar from '../../components/general/avatars/ExtraSmallAvatar'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import AlternateEmailRoundedIcon from '@material-ui/icons/AlternateEmailRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {
  Box,
  Grid,
  Link,
  Avatar,
  Typography,
  Divider,
  LinearProgress,
  IconButton,
  Button
} from '@material-ui/core';
import domain from '../../assets/icons/domain-purple.png'
import collab from '../../assets/icons/collabs-green.png'
import attributes from '../../assets/icons/attributes-blue.png'
import interests from '../../assets/icons/interests-yellow.png'
import slackLogo from '../../assets/slack-logo-icon.png'
import workforceFiller from '../../assets/workforce-filler.png'
import { makeStyles, withStyles } from '@material-ui/core/styles';


const ProfileStrength = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:'rgba(20,18,23,0.1)' ,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#00D9AE',
  },
}))(LinearProgress);



const useStyles = makeStyles((theme) => ({
  boxA: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - 300px)`,
    },
  },
  boxB: {
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 300,
      display: 'inline',
    },
  },
  bioBox: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 140px)',
      paddingLeft: 30,
    },
  },
  box1A: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 200,
      paddingRight: 30,
    },
  },
  box1B: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 200px)',
    },
  },
  box2A: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 300,
    },
  },
  box2B: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 300px)',
      paddingleft: 30,
    },
  },
  emptyStyle: {
    borderRadius: 12,
    width: '100%',
    background: 'rgba(0,194,255,0.1)',
    color: 'rgba(0,194,255,1)',
    '&:hover': {
      background: 'rgba(0,194,255,0.15)',
      cursor: 'pointer'
    },
  },
  emptyRole: {
    borderRadius: 12,
    width: '100%',
    background: 'rgba(67,0,255,0.1)',
    color: 'rgba(67,0,255,1)',
    '&:hover': {
      background: 'rgba(67,0,255,0.15)',
      cursor: 'pointer'
    },
  },
  emptyCollabs: {
    borderRadius: 12,
    width: '100%',
    background: 'rgba(0,217,174,0.2)',
    color: '#009779',
    '&:hover': {
      background: 'rgba(0,217,174,0.25)',
      cursor: 'pointer'
    },
  },
  emptyInterests: {
    borderRadius: 12,
    width: '100%',
    background: 'rgba(255,217,0,0.4)',
    color: '#8B7600',
    '&:hover': {
      background: 'rgba(255,217,0,0.4)',
      cursor: 'pointer'
    },
  },
  avatar: {
    border: '2px solid #FFF',
    maxHeight: 23,
    maxWidth: 23,
    fontSize: 12,
  },
  levelDot: {
    height: 12,
    width: 12,
    borderRadius: 6,
    background: '#4300FF',
    opacity: '100%',
    '&:hover': {
      opacity: '60%',
      cursor: 'pointer'
    },
  },
  basicsEditButton: {
    width: 30,
    height: 30,
    color: '#141217',
    background: '#EFEFF1',
    '&:hover': {
      background: '#E6E4EC',
      boxShadow: '0 0 5px 1px rgba(0,0,0,0.06)'
    },
  },
  editButtonHide: {
    visibility: 'hidden',
    height: 22,
  },
  editButtonShow: {
    visibility: 'visible',
    height: 22,
    color: '#141217',
    backgroundColor: '#EFEFF1',
    border: '1px solid #E6E4EC',
    padding: '0 10px 0 10px',
    borderRadius: 12,
    fontSize: 12,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#E6E4EC',
    },
  },
  hoverDividerShow:{
    background: '#EFEFF1',
    width: 5,
    marginBottom: 10,
    borderRadius: 20,
  },
  hoverDividerHide:{
    background: 'transparent',
    width: 5,
    marginBottom: 10,
    borderRadius: 20,
    animationName: 'fadeIn',
  },
  shareButton: {
    height: 22,
    color: '#141217',
    backgroundColor: '#EFEFF1',
    border: '1px solid #E6E4EC',
    padding: '0 10px 0 10px',
    borderRadius: 12,
    fontSize: 12,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#E6E4EC',
    },
  },
  connectBar:{
    background: 'rgba(0,255,204,0.3)',
    width: 5,
    borderRadius: 20,
  },
  tourBox: {
    position: 'fixed',
    bottom: 0,
  },
  tourBar: {
    width: '100%',
    marginLeft: -25,
    backgroundColor: '#4300FF',
    color: '#FFF',
    boxShadow: '0 0 5px 10px rgba(0,0,0,0.06)',
    borderRadius: 12
  },
}));


function giveImpact(user, classes) {
  if(user.impact) {
    return (
      <>
        <Typography variant="body1" gutterBottom style={{ color: '#8053FF' }}>
          {user.impact}
        </Typography>
      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}

const purposeEmojis = {
  'Mission': '🚀',
  'Lifestyle': '🏖️',
  'Teammates': '👥',
  'Culture': '🍩',
  'Mastery': '📚',
  'Autonomy': '🧭',
  'Challenge': '🏆'
}
function listPurposes(user) {
  if(user.purposes) {
    return user.purposes.map((purpose, i) => {
      let emoji = purposeEmojis[purpose] ? purposeEmojis[purpose] : ''
      return (
        <>
          <GreenSquareChip
            label={`${emoji} ${purpose}`}
          />
          <Box m={0.5} />
        </>
      )
    })
  } else return null
}

function clickShareProfile(parent) {
  parent.setState({gLinkCopied: true})
}

export function listTeammates(parent, user) {
  let {workforce} = parent.state
  let profiles = parent.state.profiles
  let slackUsers = parent.state.slackUsers

  let existingSlackIds = []
  profiles.forEach((profile, i) => {
    if(profile.slack_id) {
      existingSlackIds.push(profile.slack_id)
    }
  });
  console.log(existingSlackIds)
  console.log(slackUsers)

  let allProfiles = profiles.concat(slackUsers)

  console.log(allProfiles)

  if(allProfiles && allProfiles.length > 1) {
    return allProfiles.map((profile, i) => {
      let isSlack = profile.id ? true : false
      if(!isSlack || !existingSlackIds.includes(profile.id)) {
        return (
          <div key={i}  >
            <ProfilePreview style={{ border: `3px solid ${profile._id === user._id ? '#FFF' : 'transparent'}` }}
              parent={parent} user={profile} isSlackUser={isSlack} />
            <Box my={0.5}/>
          </div>
        )
      } else return null
    })
  } else if(user.independent) {
    // return 'No teammates. Upgrade to the team plan to see coworkers profiles'
    return (
      <>
        <div>
          <ProfilePreview style={{ border: `3px solid ${parent.state.user && parent.state.user._id === user._id ? '#FFF' : 'transparent'}` }}
           parent={parent} user={parent.state.user} />
          <Box my={0.5}/>
        </div>
        {office_profiles.map((profile, i) => {
          return (
            <div key={i}>
              <ProfilePreview style={{ border: `3px solid ${profile._id === user._id ? '#FFF' : 'transparent'}` }}
              parent={parent} user={profile} />
              <Box my={0.5}/>
            </div>
          )
        })}
      </>
    )
  } else {
    return (
      <>
        {workforce && workforce.slack && workforce.slack.access_token ? (
          <p>No teammates. Invite them within Slack</p>
        ) : (
          <p>No teammates. Invite them!</p>
        )}
      </>
    )
  }
}

export function giveProfileActions(parent, outsider) {
  if(parent.state.tab === 'profile' && !outsider) {
    return (
      <Box p={2} mb={2} style={{ border: '3px solid #EFEFF1', borderRadius: 20 }}>
        <Box p={2} style={{ color: '#FFF', borderRadius: 12, background: 'linear-gradient(95.82deg, #4300FF -6.73%, #00D9AE 86.16%)' }}>
          <Typography variant="subtitle2" gutterBottom>
            Share your profile
          </Typography>
          <Box m={1} />
          <CopyToClipboard text={`https://app.joinhelm.com/profile/${parent.state.user._id}`}
            onCopy={() => clickShareProfile(parent)}>
            <ButtonWhite startIcon={<FileCopyRoundedIcon />} style={{ width: '100%', justifyContent: 'left' }}>
              {parent.state.profileLinkCopied ? 'Link copied!' : 'Copy profile link'}
            </ButtonWhite>
          </CopyToClipboard>
        </Box>

        <Box m={1} />

        <Box p={2} style={{ borderRadius: 12, background: '#EFEFF1' }}>
          <Typography variant="subtitle2" gutterBottom>
            Profile strength: <span style={{ color: '#009779' }}>{parent.state.profileProgress.percent}%</span>
          </Typography>

          <ProfileStrength
            value={parent.state.profileProgress.percent} variant="determinate"
          />

          {parent.state.profileProgress.prompt ? (
            <>
              <Box py={1}>
                <Divider style={{ background: 'rgba(20,18,23,0.1)' }} />
              </Box>

              <ButtonText onClick={() => toggleProfileDetails(parent, true, parent.state.profileProgress.prompt.tab)}
              startIcon={<ArrowForwardRoundedIcon style={{ fontSize: 14 }} />}>
                {parent.state.profileProgress.prompt.text}
              </ButtonText>
            </>
          ) : null}
        </Box>
        {parent.state.user.independent ? (
          <>
            <Box my={1}/>
            <ButtonBlack onClick={() => parent.setState({ tab: 'workforceOnboarding' })}
            startIcon={<ArrowForwardRoundedIcon />} style={{ width: '100%', justifyContent: 'left' }}>
              Upgrade to team plan
            </ButtonBlack>
          </>
        ) : null}
        <Invite parent={parent} />
      </Box>
    )
  } else return null
}

function giveCapabilities(parent, user) {
  let domains = user.domains
  let filtered = []
  if(domains) {
    domains.forEach((domain, i) => {
      if(domain.capabilities && domain.capabilities.length) {
        filtered.push(domain)
      }
    });
  }
  console.log(filtered)
  if(filtered.length) {
    return (
      <>
        {domains.map((domain) => {
          if(domain.capabilities && domain.capabilities.length) {
            return <CapabilityBlock domain={domain} parent={parent} />
          } else return null
        })}
      </>
    )
  }
}

function openEmail(email) {
  window.open(`mailto:${email}`)
}

function giveLinkButton(user, key) {
  let name = user.contact_preferences[key].name
  let link = user.contact_preferences[key].link

  let hasEmail = name === 'Email' && link
  let hasLink = link && link.length

  if(!hasEmail && link && link.substring(0,8) !== 'https://') {
    link = 'https://' + link
  }

  if(hasEmail) {
    return (
      <Link onClick={() => openEmail(link)}
       style={{ textDecoration: 'underline', color: '#4300FF', cursor: 'pointer' }}>
         <Typography variant="subtitle2">
           {user.contact_preferences[key].name}
         </Typography>
      </Link>
    )
  } else if(hasLink) {
    return (
      <Link href={link} target="_blank"
       style={{ textDecoration: 'underline', color: '#4300FF', cursor: 'pointer' }}>
        <Typography variant="subtitle2">
          {user.contact_preferences[key].name}
        </Typography>
      </Link>
    )
  } else {
    return (
      <Typography variant="subtitle2">
        {user.contact_preferences[key].name}
      </Typography>
    )
  }
}

function editProfileButton(parent, element, classes) {
  if(parent.state.tab === 'profile' && !parent.state.isDemo) {
    return (
      <div>
        <Button
          className={parent.state[`show${element}`] ? classes.editButtonShow : classes.editButtonHide}
          onClick={() => toggleProfileDetails(parent, true, element)}
          startIcon={<EditRoundedIcon style={{ fontSize: 12 }} />}
        >
          Edit {element}
        </Button>
      </div>
    )
  } else return null
}

function toggleEditButton(parent, element, value) {
  if(parent.state.tab === 'profile') {
    parent.setState({ [`show${element}`]: value })
  }
}


// Main function
function Profile(props) {
  let {parent, teammate, outsider} = props
  let {workforce} = parent.state
  const classes = useStyles()

  teammate = parent.state.officeCharId ? office_profiles[parent.state.officeCharId] : teammate

  let user = teammate ? teammate : parent.state.user
  let pronouns = givePronouns(user)
  let purposeStatement = givePurposeStatement(user)
  let purposes = listPurposes(user)
  let onboardingInfo = user.onboarding ? giveOnboardingInfo(user, parent) : {steps_needed: [], complete: true}
  console.log(teammate)

  return (
    <>
      {/* Intro */}
      <Box width="100%">
        <Grid
          onMouseEnter={() => toggleEditButton(parent, 'basics', true)}
          onMouseLeave={() => toggleEditButton(parent, 'basics', false)}
          container direction="row" className={classes.editBox}
        >
          <Box width={140}>
            <Avatar
              style={{
                width: 140, height: 140,
                border: '6px solid #141217',
                borderRadius: 20,
                boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.1)'
              }}
              src={imageSrc(user, false, true)}
            />
          </Box>
          <Box className={classes.bioBox}>
            <Grid container direction="row">
              <Box style={{ width: 'calc(100% - 100px)' }}>
                <Typography variant="h2" style={{ fontFamily: 'Vollkorn' }}>
                  <i>{giveFirstName(user)} {giveLastName(user)}</i>
                </Typography>
              </Box>
              <Box width={100}>
                {parent.state.tab === 'profile' && !parent.state.isDemo ? (
                  <IconButton className={classes.basicsEditButton} onClick={() => toggleProfileDetails(parent, true, 'basics')}>
                    <EditRoundedIcon style={{ fontSize: 18 }} />
                  </IconButton>
                ) : null}

                {parent.state.user ? (
                  <CopyToClipboard text={`https://app.joinhelm.com/profile/${parent.state.user._id}`}
                    onCopy={() => clickShareProfile(parent)}>
                    <Button className={classes.shareButton} startIcon={<FileCopyRoundedIcon style={{ fontSize: 14 }} />}>
                      {parent.state.profileLinkCopied ? 'Link copied!' : 'Copy link'}
                    </Button>
                  </CopyToClipboard>
                ) : null}

              </Box>
            </Grid>
            <Typography variant="subtitle1" style={{ color: '#4300FF', fontWeight: 600, fontFamily: 'Lato' }}>
              {giveTitle(user)}<span style={{ color: '#726B83', fontWeight: 400 }}> {pronouns.length ? `${giveTitle(user).length ? '| ': ''}${pronouns}` : ''} </span>
            </Typography>
            <Box my={2.5} />
            <Grid container direction="row" alignItems="center">
              {workforce ? (
                <Box mr={3} mb={0.5}>
                  <Grid container direction="row" alignItems="center">
                    <ExtraSmallAvatar style={{ borderColor: '#EFEFF1' }} src={giveWorkforceLogo(workforce)} />
                    <Box pl={1}>
                      <Typography variant="body2" style={{ color: '#726B83' }}>
                        {giveWorkforceName(workforce, user)}
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
              ) : (
                <>
                  {user.workforce_name ? (
                    <Box mr={3} mb={0.5}>
                      <Grid container direction="row" alignItems="center">
                        <ExtraSmallAvatar style={{ borderColor: '#EFEFF1' }} src={workforceFiller} />
                        <Box pl={1}>
                          <Typography variant="body2" style={{ color: '#726B83' }}>
                            {giveWorkforceName(workforce, user)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  ) : null}
                </>
              )}

              <Box mr={3} mb={0.5}>
                <Grid container direction="row" alignItems="center">
                  <ExtraSmallAvatar style={{ borderColor: '#EFEFF1' }} src="https://drive.google.com/uc?id=1KA4eVY-xeftOHAWrh8qlV5jcgemIaiPI" />
                  <Box pl={1}>
                    <Typography variant="subtitle2" style={{ color: '#141217' }}>
                      Novo
                    </Typography>
                  </Box>
                </Grid>
              </Box>

              {user.location && user.location.formatted ? (
                <Box mr={3} mb={0.5}>
                  <Grid container direction="row" alignItems="center">
                    <LocationOnRoundedIcon fontSize="small" style={{ color: '#726B83' }} />
                    <Box pl={1}>
                      <Typography variant="body2" style={{ color: '#726B83' }}>
                        {user.location.formatted}
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
              ) : null}

              {/*<Box mr={3} mb={0.5}>
                <Grid container direction="row" alignItems="center">
                  <WatchLaterRoundedIcon fontSize="small" style={{ color: '#726B83' }} />
                  <Box pl={1}>
                    <Typography variant="body2" style={{ color: '#726B83' }}>
                      12:32 PM / EST
                    </Typography>
                  </Box>
                </Grid>
              </Box>*/}

            </Grid>
          </Box>

        </Grid>
      </Box>

      <Box m={3} />

      <Box py={3} width="100%">


        {/* CONTACT */}
        {onboardingInfo.steps_needed.includes('contact') ? null : (
          <Grid container direction="row"
            onMouseEnter={() => toggleEditButton(parent, 'contact preferences', true)}
            onMouseLeave={() => toggleEditButton(parent, 'contact preferences', false)}
          >
            <Divider
              className={parent.state[`showcontact preferences`] ? classes.hoverDividerShow : classes.hoverDividerHide}
              orientation="vertical" flexItem style={{ marginBottom: 0 }}
            />
            <Box pl={2} pr={3} style={{ width: 'calc(100% - 5px)' }}>
              <Grid container direction="row" alignItems="center" justify="space-between">
                <Box>
                  <Grid container direction="row" alignItems="center">
                    <AlternateEmailRoundedIcon style={{ fontSize: 14 }} />
                    <Box m={0.5} />
                    <Typography variant="subtitle2" style={{ color: '#141217' }}>
                      Contact: <span style={{ fontWeight: 500, color: '#726B83' }}> how to reach me...</span>
                    </Typography>
                  </Grid>
                </Box>



                {editProfileButton(parent, 'contact preferences', classes)}
              </Grid>
              <Box m={1} />
              {user.contact_preferences ? (
                <Grid container direction="row" spacing={1}>
                  {Object.keys(user.contact_preferences).map((key) => {
                    return (
                      <Grid item xs={6} sm={6} md={3}>
                        <Box p={1} style={{ width: '100%', background: '#EFEFF1', borderRadius: 8 }}>
                          <Typography variant="caption" style={{ color: '#726B83' }}>
                            {contactValueToText(key)}...
                          </Typography>
                          {user.contact_preferences[key].name === 'Slack' ? (
                            <Typography variant="subtitle2" startIcon={<img alt="slack" src={slackLogo} style={{ width: 14 }} />}>
                              {user.contact_preferences[key].name}
                            </Typography>
                          ) : (
                            <>
                              {giveLinkButton(user, key)}
                            </>
                          )}
                        </Box>
                      </Grid>
                    )
                  })}
                </Grid>
              ) : (
                <>
                  {teammate ? null : (
                    <Box p={2} className={classes.emptyStyle}
                      onClick={() => toggleProfileDetails(parent, true, 'contact preferences')}
                    >
                      <Grid container direction="row" alignItems="center" justify="space-between">
                        <Box style={{ width: 'calc(100% - 20px)', color: '#0093C0' }}>
                          <Typography variant="body1">
                            Set your <b>contanct preferences</b>
                          </Typography>
                        </Box>
                        <ChevronRightRoundedIcon fontSize="small" style={{ color: '#00C2FF' }} />
                      </Grid>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Grid>
        )}

        <Box m={2} />

        {/* COLLABS */}
        {(!teammate && !outsider) || (user.collabs && user.collabs.length) ? (
          <>
            {onboardingInfo.steps_needed.includes('collabs') ? null : (
            <Grid container direction="row"
              onMouseEnter={() => toggleEditButton(parent, 'collabs', true)}
              onMouseLeave={() => toggleEditButton(parent, 'collabs', false)}
            >
              <Divider
                className={parent.state[`showcollabs`] ? classes.hoverDividerShow : classes.hoverDividerHide}
                orientation="vertical" flexItem
              />
              <Box pl={2} pr={3} style={{ width: 'calc(100% - 5px)' }}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Box>
                    <Grid container direction="row" alignItems="center">
                      <img src={collab} style={{ height: 14 }} alt="Collabs" />
                      <Box m={0.5} />
                      <Typography variant="subtitle2" style={{ color: '#009779' }}>
                        Collabs: <span style={{ fontWeight: 500, color: '#726B83' }}>I'm open to...</span>
                      </Typography>
                    </Grid>
                  </Box>
                  {editProfileButton(parent, 'collabs', classes)}
                </Grid>
                <Box m={0.75} />
                <Grid container direction="row">
                  <>
                  {user.collabs && user.collabs.length ? (
                    <>
                      {user.collabs.map((collab) => {
                        return (
                          <Box mr={0.5} mb={0.5}>
                            <GreenSquareChip onClick={() => handleSearch(parent, collab.title, parent.state.profiles)}
                            label={collab.title} />
                          </Box>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {teammate ? null : (
                        <Box p={2} className={classes.emptyCollabs}
                          onClick={() => toggleProfileDetails(parent, true, 'collabs')}
                        >
                          <Grid container direction="row" alignItems="center" justify="space-between">
                            <Box style={{ width: 'calc(100% - 20px)' }}>
                              <Typography variant="body1">
                                Outline your <b>collabs</b>
                              </Typography>
                            </Box>
                            <ChevronRightRoundedIcon fontSize="small" />
                          </Grid>
                        </Box>
                      )}
                    </>
                  )}
                </>
                </Grid>
                <Box m={2} />
              </Box>
            </Grid>
          )}
          </>
        ) : null}

        <Box m={1} />

        {/* DOMAINS */}
        {(!teammate && !outsider) || (user.domains && user.domains.length) ? (
          <>
            {onboardingInfo.steps_needed.includes('domains') ? null : (
              <Grid container direction="row"
                onMouseEnter={() => toggleEditButton(parent, 'domains', true)}
                onMouseLeave={() => toggleEditButton(parent, 'domains', false)}
              >
                <Divider
                  className={parent.state[`showdomains`] ? classes.hoverDividerShow : classes.hoverDividerHide}
                  orientation="vertical" flexItem
                />
                <Box pl={2} pr={3} style={{ width: 'calc(100% - 5px)' }}>
                  <Grid container direction="row" alignItems="center" justify="space-between">
                    <Box>
                      <Grid container direction="row" alignItems="center">
                        <img src={domain} style={{ height: 14 }} alt="Domain" />
                        <Box m={0.5} />
                        <Typography variant="subtitle2" style={{ color: '#4300FF' }}>
                          Domains: <span style={{ fontWeight: 500, color: '#726B83' }}>I work within...</span>
                        </Typography>
                      </Grid>
                    </Box>
                    {editProfileButton(parent, 'domains', classes)}
                  </Grid>
                  <Box m={0.75} />
                  <Grid container direction="row">

                    {user.domains && user.domains.length ? (
                      <>
                        {user.domains.map((domain) => {
                          return (
                            <Box mr={0.5} mb={0.5}>
                              <PurpleSquareChip onClick={() => handleSearch(parent, domain.title, parent.state.profiles)}
                              label={domain.title} />
                            </Box>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        {teammate ? null : (
                          <Box p={2} className={classes.emptyRole}
                            onClick={() => toggleProfileDetails(parent, true, 'domains')}
                          >
                            <Grid container direction="row" alignItems="center" justify="space-between">
                              <Box style={{ width: 'calc(100% - 20px)', color: '#4300FF' }}>
                                <Typography variant="body1">
                                  Outline the <b>domain of your work</b>
                                </Typography>
                              </Box>
                              <ChevronRightRoundedIcon fontSize="small" style={{ color: '#4300FF' }} />
                            </Grid>
                          </Box>
                        )}
                      </>
                  )}
                  </Grid>
                  <Box m={2} />
                </Box>
              </Grid>
            )}
          </>
        ) : null}

        <Box m={1} />

        {/* ATTRIBUTES */}
        {(!teammate && !outsider) || (user.adjectives && user.adjectives.length) ? (
          <>
            {onboardingInfo.steps_needed.includes('character') ? null : (
              <Grid container direction="row"
                onMouseEnter={() => toggleEditButton(parent, 'character', true)}
                onMouseLeave={() => toggleEditButton(parent, 'character', false)}
              >
                <Divider
                  className={parent.state[`showcharacter`] ? classes.hoverDividerShow : classes.hoverDividerHide}
                  orientation="vertical" flexItem
                />
                <Box pl={2} pr={3} style={{ width: 'calc(100% - 5px)' }}>
                  <Grid container direction="row" alignItems="center" justify="space-between">
                    <Box>
                      <Grid container direction="row" alignItems="center">
                        <img src={attributes} style={{ height: 14 }} alt="Attributes" />
                        <Box m={0.5} />
                        <Typography variant="subtitle2" style={{ color: '#0093C0' }}>
                          Attributes: <span style={{ fontWeight: 500, color: '#726B83' }}>my persona is...</span>
                        </Typography>
                      </Grid>
                    </Box>
                    {editProfileButton(parent, 'character', classes)}
                  </Grid>

                  <Box m={0.75} />
                  {user.adjectives && user.adjectives.length ? (
                    <>
                      <Grid container direction="row">
                        {user.adjectives.map((adj) => {
                          return (
                            <Box mr={0.5} mb={0.5}>
                              <BlueSquareChip label={capitalizeFirstLetter(adj)} />
                            </Box>
                          )
                        })}
                      </Grid>
                    </>
                  ) : (
                    <>
                      {teammate ? null : (
                        <Box p={2} className={classes.emptyStyle}
                          onClick={() => toggleProfileDetails(parent, true, 'attributes')}
                        >
                          <Grid container direction="row" alignItems="center" justify="space-between">
                            <Box style={{ width: 'calc(100% - 20px)', color: '#0093C0' }}>
                              <Typography variant="body1">
                                Outline your <b>five attributes</b>
                              </Typography>
                            </Box>
                            <ChevronRightRoundedIcon fontSize="small" style={{ color: '#00C2FF' }} />
                          </Grid>
                        </Box>
                      )}
                    </>
                  )}

                  <Box m={2} />
                </Box>
              </Grid>
            )}
          </>
        ) : null}

        <Box m={1} />

        {/* INTERESTS */}
        {(!teammate && !outsider) || (user.interests && user.interests.length) ? (
          <>
          {onboardingInfo.steps_needed.includes('interests') ? null : (
            <Grid container direction="row"
              onMouseEnter={() => toggleEditButton(parent, 'interests', true)}
              onMouseLeave={() => toggleEditButton(parent, 'interests', false)}
            >
              <Divider
                className={parent.state[`showinterests`] ? classes.hoverDividerShow : classes.hoverDividerHide}
                orientation="vertical" flexItem
              />
              <Box pl={2} pr={3} style={{ width: 'calc(100% - 5px)' }}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Box>
                    <Grid container direction="row" alignItems="center">
                      <img src={interests} style={{ height: 14 }} alt="Interests" />
                      <Box m={0.5} />
                      <Typography variant="subtitle2" style={{ color: '#8B7600' }}>
                        Interests: <span style={{ fontWeight: 500, color: '#726B83' }}>let's talk about...</span>
                      </Typography>
                    </Grid>
                  </Box>
                  {editProfileButton(parent, 'interests', classes)}
                </Grid>

                <Box m={0.75} />
                <Grid container direction="row">
                  {user.interests && user.interests.length ? (
                    <>
                      {user.interests.map((interest) => {
                        return (
                          <Box mr={0.5} mb={0.5}>
                            <YellowSquareChip onClick={() => handleSearch(parent, interest.title, parent.state.profiles)}
                            label={interest.title} />
                          </Box>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {teammate ? null : (
                        <Box p={2} className={classes.emptyInterests}
                          onClick={() => toggleProfileDetails(parent, true, 'interests')}
                        >
                          <Grid container direction="row" alignItems="center" justify="space-between">
                            <Box style={{ width: 'calc(100% - 20px)' }}>
                              <Typography variant="body1">
                                Outline your <b>interests</b>
                              </Typography>
                            </Box>
                            <ChevronRightRoundedIcon fontSize="small" />
                          </Grid>
                        </Box>
                      )}
                    </>
                  )}
                </Grid>
                <Box m={2} />
              </Box>
            </Grid>
          )}
          </>
        ) : null}
      </Box>

      {/* PURPOSE */}
      {!onboardingInfo.complete ? null : (
        <>
          <Box p={3} style={{ background: 'rgba(0,255,204,0.20)', borderRadius: 12 }}
            onMouseEnter={() => toggleEditButton(parent, 'purpose', true)}
            onMouseLeave={() => toggleEditButton(parent, 'purpose', false)}
          >
            <Grid container direction="row" alignItems="center" justify="space-between">
              <Box>
                <Typography variant="subtitle2" gutterBottom style={{ color: '#009779'}}>
                  Purpose: <span style={{ fontWeight: 500, color: '#726B83' }}>what excites me...</span>
                </Typography>
              </Box>
              {editProfileButton(parent, 'purpose', classes)}
            </Grid>


            { purposeStatement ? (
              <>
                <Typography variant="h6" gutterBottom style={{ color: '#006954' }}>
                  {purposeStatement}
                </Typography>
              </>
            ):(
              <>
                {teammate ? null : (
                  <Box p={2} className={classes.emptyRole}
                    onClick={() => toggleProfileDetails(parent, true, 'purpose')}
                  >
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Box style={{ width: 'calc(100% - 20px)', color: '#4300FF' }}>
                        <Typography variant="body1">
                          Set your <b>purpose statement</b>
                        </Typography>
                      </Box>
                      <ChevronRightRoundedIcon fontSize="small" style={{ color: '#4300FF' }} />
                    </Grid>
                  </Box>
                )}
              </>
            )}

            <Box my={1}/>

            { purposes && purposes.length ? (
              <>
                <Grid container direction="row">
                  {purposes}
                </Grid>
              </>
            ):(
              <>
                {teammate ? null : (
                  <Box p={2} className={classes.emptyRole}
                    onClick={() => toggleProfileDetails(parent, true, 'purpose')}
                  >
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Box style={{ width: 'calc(100% - 20px)', color: '#4300FF' }}>
                        <Typography variant="body1">
                          List your <b>motivators</b>
                        </Typography>
                      </Box>
                      <ChevronRightRoundedIcon fontSize="small" style={{ color: '#4300FF' }} />
                    </Grid>
                  </Box>
                )}
              </>
            )}

          </Box>
          <Box my={2}/>
        </>
      )}

      {/* Role */}
      {!onboardingInfo.complete ? null : (
        <>
          <Box p={3} style={{ background: 'rgba(67,0,255,0.10)', borderRadius: 12 }}
            onMouseEnter={() => toggleEditButton(parent, 'role', true)}
            onMouseLeave={() => toggleEditButton(parent, 'role', false)}
          >
            <Grid container direction="row" alignItems="center" justify="space-between">
              <Box>
                <Typography variant="subtitle2" style={{ color: '#4300FF' }}>
                  Role: <span style={{ fontWeight: 500, color: '#726B83' }}>what I do...</span>
                </Typography>
              </Box>
              {editProfileButton(parent, 'role', classes)}
            </Grid>
            <Box my={1} />
            <Typography variant="h4" style={{ color: '#4300FF' }}>
              {giveTitle(user)}
            </Typography>
            {user.impact ? (
              giveImpact(user, classes)
            ) : (
              <Box p={2} className={classes.emptyRole}
                onClick={() => toggleProfileDetails(parent, true, 'role')}
              >
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Box style={{ width: 'calc(100% - 20px)', color: '#4300FF' }}>
                    <Typography variant="body1">
                      Give your <b>impact statement</b>
                    </Typography>
                  </Box>
                  <ChevronRightRoundedIcon fontSize="small" style={{ color: '#4300FF' }} />
                </Grid>
              </Box>
            )}
            <Box m={2} />

            {giveCapabilities(parent, user)}
          </Box>
          <Box my={2}/>
        </>
      )}

      {/* Work style */}
      {!onboardingInfo.complete ? null : (
        <>
          <Box p={3} style={{ background: 'rgba(0,194,255,0.20)', borderRadius: 12 }}
            onMouseEnter={() => toggleEditButton(parent, 'FAQs', true)}
            onMouseLeave={() => toggleEditButton(parent, 'FAQs', false)}
          >
            <Grid container direction="row" alignItems="center" justify="space-between">
              <Box>
                <Typography variant="subtitle2" gutterBottom style={{ color: '#0093C0' }}>
                  Work style:  <span style={{ fontWeight: 500, color: '#726B83' }}>how I work...</span>
                </Typography>
              </Box>
              {editProfileButton(parent, 'FAQs', classes)}
            </Grid>
            {user.faqs && user.faqs.length ? (
              <>
                  <Box my={2} />
                  {user.faqs.map((q) => {
                    return <FAQBlock q={q} />
                  })}
              </>
            ) : (
              <>
                {teammate ? null : (
                  <Box p={2} className={classes.emptyStyle}
                    onClick={() => toggleProfileDetails(parent, true, 'FAQs')}
                  >
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Box style={{ width: 'calc(100% - 20px)', color: '#0093C0' }}>
                        <Typography variant="body1">
                          Answer some <b>style FAQs</b>
                        </Typography>
                      </Box>
                      <ChevronRightRoundedIcon fontSize="small" style={{ color: '#00C2FF' }} />
                    </Grid>
                  </Box>
                )}
              </>
            )}
          </Box>
        </>
      )}

      {!outsider && user.onboarding ? onboardingDiv(parent, user, onboardingInfo, classes) : null}

      <Box my={10}/>
      {/* Tour Bar */}
      {parent.state.tourShowcase_1 ? (
        <Box py={2} className={classes.tourBox}>

          <Grid container direction="row" justify="center">
            <Box px={2} py={2} className={classes.tourBar}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="h6">
                    Let's explore your profile.
                  </Typography>
                  <Typography variant="body2">
                    A showcase of what you do and who you are. The profile basics can be set up in under 5 minutes
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                      <Typography variant="caption" style={{ color: 'rgba(255,255,255,0.7)' }}>
                        Step 1 of 4
                      </Typography>
                      <Box m={1} />
                      <ButtonWhite onClick={() => parent.setState({tourShowcase_1: false, tourNewHireIntro_2: true})}>
                        Next
                      </ButtonWhite>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      ): null}
    </>
  )
};

export default Profile;
