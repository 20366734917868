import React from "react";
import { Box, AppBar, Toolbar, Container,  Grid, Avatar, IconButton, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from "@auth0/auth0-react";
// import SearchBar from './SearchBar';
import {imageSrc} from '../../utility/images'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));




export default function SecondaryNav(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { logout } = useAuth0()
  let { profile } = props



  function logOut() {
    logout({ returnTo: 'https://joinhelm.com' })
  }

  return (
    <AppBar position="fixed" elevation="0" color="defult">
      <Container maxWidth="xl">
        <Toolbar>
          <Box mr="auto">
            <IconButton varient="text" onClick="" href="../">
              <ArrowBackIosRoundedIcon />
            </IconButton>
          </Box>
          <Grid container direction="row" justify="flex-end" alignItems="center">
            {/*<
            <Box mx={2}>
              <Button varient="contained" onClick="" href="../">
                Home
              </Button>
            </Box>
            <Box mx={2}>
              <Button varient="contained" onClick="" href="People">
                People
              </Button>
            </Box>
            <Box mx={2} >
              <Button varient="contained" onClick="" href="Settings">
                Settings
              </Button>
            </Box>
            <Box mx={2} />
            */}
            <Box>
              <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <Avatar alt="Name Here" src={imageSrc(profile)} className={classes.medium} />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <a href="/settings" style={{textDecoration: 'none'}}><MenuItem>Settings</MenuItem></a>
                <MenuItem onClick={logOut}>Log Out</MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
