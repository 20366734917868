import React from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  NativeSelect,
  FormControl,
  InputLabel
} from '@material-ui/core';
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import ButtonToggle from '../../components/general/buttons/ButtonToggle'
import DropDownInput from '../../components/general/DropDownInput'
import RoundAvatar from '../../components/general/avatars/RoundAvatar'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import HiSquareChip from '../../components/general/HiSquareChip'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import {getWorkforceDomains} from '../../utility/workforce'
import {capitalizeFirstLetter} from '../../utility/profile'
import {updateWorkforce} from '../../api/workforce'



export function startEditGroup(parent, group, type) {
  parent.setState({
    groupBeingEdited: group,
    editGroup: type,
    workforceDomains: getWorkforceDomains(parent.state.profiles, true),
    editGroupName: group.name,
    editGroupDomains: group.domains ? group.domains : [],
    editGroupChannel: group.slack_channel,
    editGroupLeads: group.leads ? group.leads : [],
    editGroupUsers: []
  })
  if(group.users) {
    let ids = []
    group.users.forEach((user, i) => {
      ids.push(user._id)
    });
    parent.setState({ editGroupUsers: ids })
  }
}

function addNewDomain(parent, givenVal) {
  let value = givenVal ? givenVal : parent.state.newDomain
  if(value) {
    parent.state.editGroupDomains.push(value)
    parent.setState({newDomain: ''})
    parent.forceUpdate()
  }
}
function removeDomain(parent, index) {
  parent.state.editGroupDomains.splice(index, 1)
  parent.forceUpdate()
}
function toggleDefaultDomain(parent, domainTitle) {
  let index = -1
  parent.state.editGroupDomains.forEach((domain, i) => {
    if(domain === domainTitle) {
      index = i
    }
  });
  console.log(index)
  if(index === -1) {
    parent.state.editGroupDomains.push(domainTitle)
  } else {
    parent.state.editGroupDomains.splice(index, 1)
  }
  parent.forceUpdate()
}
function hasDomain(parent, domainTitle) {
  let index = -1
  parent.state.editGroupDomains.forEach((domain, i) => {
    if(domain === domainTitle) {
      index = i
    }
  });

  if(index === -1) {
    return false
  } else {
    return true
  }
}

function toggleUser(parent, userId, adding, isLead) {

  let groupIndex = parent.state.editGroupUsers.indexOf(userId)
  let leadsIndex = parent.state.editGroupLeads.indexOf(userId)

  if(adding) {
    if(groupIndex === -1) {
      parent.state.editGroupUsers.push(userId)
    }

    if(!isLead && leadsIndex !== -1) {
      parent.state.editGroupLeads.splice(leadsIndex, 1)
    } else if(isLead && leadsIndex === -1) {
      parent.state.editGroupLeads.push(userId)
    }
  } else {
    if(groupIndex !== -1) {
      parent.state.editGroupUsers.splice(groupIndex, 1)
    }
    if(leadsIndex !== -1) {
      parent.state.editGroupLeads.splice(leadsIndex, 1)
    }
    parent.state.editGroupRemovedUsers.push(userId)
  }
  parent.setState({userSearch: ''})
  parent.forceUpdate()
}

// Main function
function EditGroup(props) {
  const { parent } = props
  let {groupBeingEdited, editGroup, editGroupName, resources_domains, editGroupDomains,
        newDomain, workforceDomains, editGroupError, publicChannels, editGroupChannel,
        profiles, userSearch, editGroupUsers, editGroupLeads, editGroupRemovedUsers} = parent.state

  let isTeam = editGroup === 'team'
  let teamOrCommunity = isTeam ? 'team' : 'community'

  const close = () => {
    parent.setState({ editGroup: false, editGroupName: '', editGroupDomains: [],
      newDomain: '', editGroupUsers: [], editGroupLeads: [], userSearch: '' })
  }

  const next = () => {
    if(!editGroupName) {
      parent.setState({ editGroupError: 'Provide a name' })
    } else {
      let group = {
        name: editGroupName,
        domains: editGroupDomains,
        slack_channel: editGroupChannel ? editGroupChannel : '',
        members: editGroupUsers
      }
      let updatedWorkforce = {}

      if(isTeam) {
        group.team_id = groupBeingEdited.team_id
        group.leads = editGroupLeads

        updatedWorkforce.teams = parent.state.workforce.teams ? parent.state.workforce.teams : []
        let index = -1
        updatedWorkforce.teams.forEach((team, i) => {
          if(team.team_id === group.team_id) {
            index = i
          }
        });

        if(index !== -1) {
          updatedWorkforce.teams.splice(index, 1, group)
        }

      } else {
        group.community_id = groupBeingEdited.community_id

        updatedWorkforce.communities = parent.state.workforce.communities ? parent.state.workforce.communities : []
        let index = -1
        updatedWorkforce.communities.forEach((c, i) => {
          if(c.community_id === group.community_id) {
            index = i
          }
        });

        if(index !== -1) {
          updatedWorkforce.communities.splice(index, 1, group)
        }
      }
      console.log(group)
      console.log(updatedWorkforce)
      if(isTeam) {
        updatedWorkforce.teamId = groupBeingEdited.team_id
      } else {
        updatedWorkforce.communityId = groupBeingEdited.community_id
      }
      updatedWorkforce.members = editGroupUsers // triggers user membership on back-end
      let finalRemovedMembers = editGroupRemovedUsers.filter(user => !editGroupUsers.includes(user)) // filter out on-team users
      updatedWorkforce.removedMembers = finalRemovedMembers // triggers removing user memberships
      updateWorkforce(parent.state.workforce._id, parent.state.token, updatedWorkforce, parent.state.user.permission_token)
      .then((res) => {
        console.log(res)
        if(!res.error) {
          parent.setState({refresh: true})
        }
      })
    }
  }


  return (
    <>
      <Dialog open={true} maxWidth="sm" fullWidth onClose={() => close(parent, false)}>
        <DialogTitle style={{ background: '#FFF' }}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="overline" style={{ color: '#726B83' }}>
              Edit {teamOrCommunity}
            </Typography>
            <IconButton size="small" onClick={() => close()}>
              <CloseRoundedIcon style={{ color: '#726B83' }} />
            </IconButton>
          </Grid>
          <Box>
            <Typography variant="h4" gutterBottom>
              Edit {groupBeingEdited.name} {teamOrCommunity}.
            </Typography>
          </Box>
          {editGroupError ? (
            <Typography variant="body1" style={{ color: 'red' }}>
              {editGroupError}
            </Typography>
          ) : null}
        </DialogTitle>
        <DialogContent style={{ background: '#FFF' }}>
          <Box p={2} mb={1.5} style={{ border: '3px solid #EFEFF1', borderRadius: 20 }}>
            <Typography variant="subtitle1" style={{ fontWeight: 900 }} gutterBottom>
              Name your {teamOrCommunity}
            </Typography>
            <TextField fullWidth required
              label={`${capitalizeFirstLetter(teamOrCommunity)} Name`}
              onChange={(event) => parent.setState({ editGroupName: event.target.value })}
              value={editGroupName}
            />

            <Box m={6} />

            <Box style={{ width: '100%' }}>
              <Typography variant="subtitle1" style={{ fontWeight: 900 }} gutterBottom>
                Link relevant tags to your {teamOrCommunity}
              </Typography>
                <div onKeyDown={(e) => e.keyCode === 13 ? addNewDomain(parent) : false}>
                  <ReactSearchAutocomplete
                    items={resources_domains}
                    fuseOptions={{ keys: ["text"] }}
                    resultStringKeyName="text"
                    onSearch={(val) => parent.setState({newDomain: val})}
                    onSelect={(val) => addNewDomain(parent, val.text)}
                    inputSearchString={newDomain}
                    placeholder="Development, Finance, Marketing ..."
                    styling={{
                      zIndex: 100
                    }}
                  />
                </div>
              <Box m={2} />

              <h3>Your workforce's domains</h3>
              <Grid container direction="row">
                {workforceDomains.map((domain, i) => {
                  return (
                    <Box mr={0.5} mb={0.5}>
                      <HiSquareChip clickable
                        onClick={() => toggleDefaultDomain(parent, domain.title)}
                        label={capitalizeFirstLetter(domain.title)}
                        style={{
                          background: hasDomain(parent, domain) ? 'rgba(67,0,255,0.1)' : '#EFEFF1',
                          color: hasDomain(parent, domain) ? '#4300FF' : '#726B83',
                        }}
                      />
                    </Box>
                  )
                })}
              </Grid>

              {/* Populate with chosen tags */}
              <Typography variant="subtitle2" style={{ color: '#726B83'}} gutterBottom>
                Selected tags
              </Typography>
              <Grid container direction="row">
                {editGroupDomains.map((domain, i) => {
                  return (
                    <Box mr={0.5} mb={0.5}>
                      <HiSquareChip styleVariant="purple"
                        label={domain}
                        deleteIcon={<HighlightOffRoundedIcon style={{ fontSize: 14 }} />}
                        onDelete={() => removeDomain(parent, i)}
                      />
                    </Box>
                  )}
                )}
              </Grid>
            </Box>

            <Box m={6} />

            <Box style={{ width: '100%' }}>
              <Typography variant="subtitle1" style={{ fontWeight: 900 }} gutterBottom>
                Connect a Slack channel
              </Typography>

              <FormControl fullWidth>
                <InputLabel htmlFor="select-Slack-channel">Channel</InputLabel>
                <NativeSelect
                  id="select-Slack-channel"
                  value={editGroupChannel ? editGroupChannel : 'None'}
                  onChange={(e) => parent.setState({ editGroupChannel: e.target.value })}
                  input={<DropDownInput />}
                >
                  <option aria-label="None" value="None">-</option>
                  {publicChannels.map((channel, i) => {
                    return <option key={i} value={channel.name}>#{channel.name}</option>
                  })}
                </NativeSelect>
              </FormControl>
            </Box>

            <Box m={6} />

            <Box style={{ width: '100%' }}>
              <Typography variant="subtitle1" style={{ fontWeight: 900 }} gutterBottom>
                Select your teammates
              </Typography>
              <div
                onKeyDown={(e) => e.keyCode === 13 ? addNewDomain(parent) : false}
              >
                <ReactSearchAutocomplete
                  items={profiles}
                  fuseOptions={{ keys: ["fname","lname"] }}
                  resultStringKeyName="full_name"
                  onSearch={(val) => parent.setState({userSearch: val})}
                  onSelect={(val) => toggleUser(parent, val._id, true)}
                  inputSearchString={userSearch}
                  placeholder="jane smith..."
                  styling={{
                    zIndex: 100
                  }}
                />
              </div>
              <Box m={2} />
              {/* Search results: people selected for the team */}
              <Box p={2} style={{ background: '#EFEFF1', borderRadius: 12  }}>
                <Typography variant="subtitle2" gutterBottom style={{ color: '#726B83' }}>
                  Selected members
                </Typography>
                {editGroupUsers.length ? (
                  <>
                    {editGroupUsers.map((userId) => {
                      let fullUser = {}
                      profiles.forEach((profile, i) => {
                        if(profile._id === userId) {
                          fullUser = profile
                        }
                      });

                      return (
                        <Box py={0.5}>
                          <Grid container direction="row" alignItems="center" justify="space-between">
                            <Box>
                              <Grid container direction="row" alignItems="center">
                                <RoundAvatar src=""/>
                                <Box m={1} />
                                <Typography variant="subtitle2" style={{ weight: 700, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'  }}>
                                   {fullUser.fname} {fullUser._id === parent.state.user._id ? (
                                     <span style={{ fontWeight: 500, color: '#726B83' }}> | you</span>
                                   ) : null}
                                </Typography>
                              </Grid>
                            </Box>
                            <Box>
                              <Grid container direction="row" alignItems="center">
                                {isTeam ? (
                                  <>
                                    <ButtonToggle
                                      style={editGroupLeads.includes(userId) ? { background: '#141217', color: '#FFF' } : {}}
                                      onClick={() => toggleUser(parent, userId, true, true)}
                                    >
                                      Lead
                                    </ButtonToggle>
                                    <Box m={0.5} />
                                    <ButtonToggle
                                      style={!editGroupLeads.includes(userId) ? { background: '#141217', color: '#FFF' } : {}}
                                      onClick={() => toggleUser(parent, userId, true)}
                                    >
                                      Member
                                    </ButtonToggle>
                                  </>
                                ) : null}
                                <Box ml={0.5}>
                                  <IconButton size="small"
                                  onClick={() => toggleUser(parent, userId, false)}
                                  >
                                    <CloseRoundedIcon fontSize="small"/>
                                  </IconButton>
                                </Box>
                              </Grid>
                            </Box>
                          </Grid>
                        </Box>
                      )
                    })}
                  </>
                ) : (
                  'No members selected'
                )}
              </Box>
            </Box>

          </Box>

        </DialogContent>
        <DialogActions>
          <ButtonPurple onClick={() => next()}>
            Done
          </ButtonPurple>
        </DialogActions>
      </Dialog>
    </>
  )
};
export default EditGroup;
