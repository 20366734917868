import React from 'react'
import { InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function DropDownInput(props) {
  let { children, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      background: theme.palette.background.paper,
    },
    input: {
      borderRadius: 8,
      position: 'relative',
      backgroundColor: theme.palette.background.default,
      border: '2px solid #E6E4EC',
      fontSize: 16,
      padding: '4px 20px 4px 6px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
        'lato'
      ].join(','),
      '&:focus': {
        borderRadius: 8,
        borderColor: '#4300FF',
        boxShadow: '0 0 0 0.2rem rgba(67,0,255,.25)',
        backgroundColor: theme.palette.background.paper,
      },
    },
  }));

  const classes = useStyles();

  return (
    <InputBase { ...passThroughProps } className={`${classes.root} ${classes.input}`}>
      {children}
    </InputBase>
  )
}
export default DropDownInput
