import React from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import ProfilePreview from '../profile/ProfilePreview'
import CommunityPreview from '../../components/groups/CommunityPreview'
import PurpleSquareChip from '../../components/general/chips/PurpleSquareChip'
import GreenSquareChip from '../../components/general/chips/GreenSquareChip'
import ButtonText from '../../components/general/buttons/ButtonText'
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import ButtonBlack from '../../components/general/buttons/ButtonBlack'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import domain from '../../assets/icons/domain-purple.png'
import collab from '../../assets/icons/collabs-green.png'
import socialLogo from '../../assets/HelmLogo-social.png'
import { updateUser } from '../../api/user';
import { handleTeamsAndCommunities } from '../../utility/init';

function userTeammates(parent) {
  let userTeams = parent.state.userTeams
  console.log(userTeams)
  if(userTeams && userTeams.length) {
    return (
      <>
        {userTeams.map((team) => {
          return (
            <Box p={2} mb={1.5} style={{ border: '3px solid #EFEFF1', borderRadius: 20 }}>
              <Typography variant="h6" gutterBottom>{team.name}</Typography>

              {team.users.map((profile) => {
                if(profile._id !== parent.state.user._id) {
                  return (
                    <ProfilePreview
                    parent={parent} user={profile} />
                  )
                } else return null
              })}
            </Box>
          )
        })}
      </>
    )
  } else return 'You are not part of any teams'
}

function update(parent, user) {
  updateUser(parent.state.user._id, parent.state.token, user).then((res) => {
    if (!res.error) {
      parent.setState({ user: res })
      let foundIndex = -1
      parent.state.profiles.forEach((p, i) => {
        if(p._id === res._id) {
          foundIndex = i
        }
      })
      if(foundIndex !== -1) {
        parent.state.profiles.splice(foundIndex, 1, res)
        console.log('spliced')
      }
      handleTeamsAndCommunities(parent, res)
      parent.forceUpdate()
    }
  })
}

function DemoConnect(props) {
  let {parent} = props
  let {demoConnect, selectedCommunities} = parent.state

  const close = () => {
    parent.setState({ demoConnect: false })
  }

  const next = () => {
    let step = demoConnect
    if(step === 6) {
      // last step
      let updatedUser = {
        communities: selectedCommunities
      }

      update(parent, updatedUser)

      close()
    } else {
      parent.setState({ demoConnect: step + 1 })
    }
  }

  // TOMMY (part 1/2): the number in the case statements below is the step the tour is on
  // previously it was step 1 = teammates, 2 = mentor match, 3 = communites
  // create new cases and change the step numbers accordingly to modify the tour
  // only other thing that needs accounted for is the next() function above ^
  const giveDialog = () => {
    let workforceName = parent.state.workforce ? parent.state.workforce.name : 'Dunder Mifflin'

    switch(parent.state.demoConnect) {
      case(1):
        return (
          <>
            <DialogTitle style={{ background: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="overline" style={{ color: '#726B83' }}>
                  TOUR
                </Typography>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Welcome aboard! <br/>
                  <i style={{ color: '#4300FF' }}>Let's meet your teammates.</i>
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>
                  We will let them know that you’ve joined! View their profile previews to learn more about who they are.
                </Typography>
              </Box>
            </DialogTitle>

            <DialogContent style={{ background: '#FFF' }}>


              {userTeammates(parent)}

            </DialogContent>

            <DialogActions>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box pl={2}>
                  <Typography variant="caption" style={{ color: '#726B83' }}>
                    Step 1 of 5
                  </Typography>
                </Box>
                <ButtonPurple onClick={() => next()}>
                  Next
                </ButtonPurple>
              </Grid>
            </DialogActions>
          </>
        )
      case(2):
        return (
          <>
            <DialogTitle style={{ background: '#141217', color: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box>
                  <Grid container direction="row" alignItems="center">
                    <Typography variant="overline" style={{ color: '#B1ADBA' }}>
                      TOUR
                    </Typography>
                    <Box m={0.5} />
                    <Typography variant="caption" style={{ color: '#00FFCC' }}>
                      / <span style={{ padding: 5 }} /> behind the scenes
                    </Typography>
                  </Grid>
                </Box>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Helm will intro new hires <br/>
                  <i style={{ color: '#00FFCC' }}>to their like-minded peers.</i>
                </Typography>
                <Typography variant="body1" style={{ color: '#B1ADBA' }}>
                  As soon as their profiles are made, Helm prompts peers with common ground to give them a warm welcome.
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent style={{ background: '#141217' }}>

              <Box p={2} mb={1.5} style={{ background: '#FFF', borderRadius: 12 }}>
                <Grid container direction="row">
                  <Box width={50}>
                    <img src={socialLogo} alt="Helm" style={{ width: 50, height: 50, border: '1px solid #D0CED6', borderRadius: 8 }} />
                  </Box>
                  <Box pl={2} style={{ width: 'calc(100% - 50px)' }}>
                    <Typography variant="subtitle2">
                      Helm
                    </Typography>
                    <Typography variant="body2" style={{ color: '#454052' }}>
                      Hey Dwight, we wanted to let you know that <b style={{ color: '#4300FF' }}>{parent.state.user.fname}</b> just joined {workforceName}. <b>You two share some overlap:</b>
                      <br /><br />
                      <span style={{ padding: 10 }}> •</span>You both work in <b>UI Design</b><br/>
                      <span style={{ padding: 10 }}> •</span>You’re both into <b><span role="img" aria-label="pan">🍳</span> cooking and <span role="img" aria-label="pan">⛷</span> skiing</b><br/>
                      <span style={{ padding: 10 }}> •</span>You’re both open to <b>coffee chats</b><br/>
                      <br/>
                      <b>Give {parent.state.user.fname} a warm welcome!</b>
                    </Typography>
                    <Box mt={2}>
                      <ButtonBlack size="small" style={{ borderRadius: 8 }}>
                        Say hi
                      </ButtonBlack>
                    </Box>
                  </Box>
                </Grid>
              </Box>

            </DialogContent>

            <DialogActions>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box pl={2}>
                  <Typography variant="caption" style={{ color: '#726B83' }}>
                    Step 2 of 5
                  </Typography>
                </Box>
                <ButtonPurple onClick={() => next()}>
                  Next
                </ButtonPurple>
              </Grid>
            </DialogActions>
          </>
        )
      case(3):
        return (
          <>
            <DialogTitle style={{ background: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="overline" style={{ color: '#726B83' }}>
                  TOUR
                </Typography>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Let's find you a mentor <br/>
                  <i style={{ color: '#4300FF' }}>to learn the ropes at {workforceName}.</i>
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>
                  Helm will do the work to find an ideal match. We’ll let you review the match before we make the connection.
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent style={{ background: '#FFF' }}>

              <Box p={2} mb={1.5} style={{ border: '3px solid #EFEFF1', borderRadius: 20 }}>
                <Typography variant="h6" gutterBottom>Search criteria</Typography>

                <Box>
                  <Grid container direction="row" alignItems="center">
                    <img src={collab} style={{ height: 14 }} alt="Collabs" />
                    <Box m={0.5} />
                    <Typography variant="subtitle2" style={{ color: '#009779' }}>
                      Collabs: <span style={{ fontWeight: 500, color: '#726B83' }}>people open to...</span>
                    </Typography>
                  </Grid>
                </Box>
                <Box m={0.75} />
                <Grid container direction="row">
                  <Box mr={0.5} mb={0.5}>
                    <GreenSquareChip
                    label="New hire mentoring" />
                  </Box>
                </Grid>

                <Box m={1} />

                <Box>
                  <Grid container direction="row" alignItems="center">
                    <img src={domain} style={{ height: 14 }} alt="Domain" />
                    <Box m={0.5} />
                    <Typography variant="subtitle2" style={{ color: '#4300FF' }}>
                      Domains: <span style={{ fontWeight: 500, color: '#726B83' }}>people working within...</span>
                    </Typography>
                  </Grid>
                </Box>
                <Box m={0.75} />
                <Grid container direction="row">
                  <Box mr={0.5} mb={0.5}>
                    <PurpleSquareChip
                    label="Sales" />
                  </Box>
                  <Box mr={0.5} mb={0.5}>
                    <PurpleSquareChip
                    label="Marketing" />
                  </Box>
                </Grid>

                <Box m={1} />

                <Typography variant="body2" style={{ color: '#726B83' }}>
                  Helm will prioritize people who share common ground with you (interests, background, etc.).
                </Typography>
              </Box>

            </DialogContent>

            <DialogActions>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box pl={2}>
                  <Typography variant="caption" style={{ color: '#726B83' }}>
                    Step 3 of 5
                  </Typography>
                </Box>
                <ButtonPurple onClick={() => next()}>
                  Next
                </ButtonPurple>
              </Grid>
            </DialogActions>
          </>
        )
      case(4):
        return (
          <>
            <DialogTitle style={{ background: '#141217', color: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box>
                  <Grid container direction="row" alignItems="center">
                    <Typography variant="overline" style={{ color: '#B1ADBA' }}>
                      TOUR
                    </Typography>
                    <Box m={0.5} />
                    <Typography variant="caption" style={{ color: '#00FFCC' }}>
                      / <span style={{ padding: 5 }} /> behind the scenes
                    </Typography>
                  </Grid>
                </Box>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Helm will search for a match <br/>
                  <i style={{ color: '#00FFCC' }}>and spark the conversation.</i>
                </Typography>
                <Typography variant="body1" style={{ color: '#B1ADBA' }}>
                  Our match-making algorithm finds ideal candidates to help new hires feel at home, right away.
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent style={{ background: '#141217' }}>

              <Box p={2} mb={1.5} style={{ background: '#FFF', borderRadius: 12 }}>
                <Grid container direction="row">
                  <Box width={50}>
                    <img src={socialLogo} alt="Helm" style={{ width: 50, height: 50, border: '1px solid #D0CED6', borderRadius: 8 }} />
                  </Box>
                  <Box pl={2} style={{ width: 'calc(100% - 50px)' }}>
                    <Typography variant="subtitle2">
                      Helm
                    </Typography>
                    <Typography variant="body2" style={{ color: '#454052' }}>
                      <span role="img" aria-label="wave">👋</span> Hey Matt, <b style={{ color: '#4300FF' }}>{parent.state.user.fname}</b> is a new hire on the product team at Dunder Mifflin. <b>{parent.state.user.fname}'s looking for a mentor during the onboarding process.</b> We thought you’d be a great fit:
                      <br /><br />
                      <span style={{ padding: 10 }}> •</span>You both work in <b>UI Design</b><br/>
                      <span style={{ padding: 10 }}> •</span>You’re both into <b><span role="img" aria-label="pan">🍳</span> cooking and <span role="img" aria-label="skiing">⛷</span> skiing</b><br/>
                      <span style={{ padding: 10 }}> •</span>You’re both open to <b>coffee chats</b><br/>
                      <br/>
                      <b>Are you interested in being {parent.state.user.fname}'s mentor?</b>
                    </Typography>
                    <Box mt={2}>
                      <Grid container direction="row">
                        <ButtonBlack size="small" style={{ borderRadius: 8 }}>
                          Yes
                        </ButtonBlack>
                        <Box m={0.5} />
                        <ButtonBlack size="small" style={{ borderRadius: 8, background: '#FFF', color: '#141217', border: '2px solid #726B83' }}>
                          No
                        </ButtonBlack>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Box>

            </DialogContent>

            <DialogActions>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box pl={2}>
                  <Typography variant="caption" style={{ color: '#726B83' }}>
                    Step 4 of 5
                  </Typography>
                </Box>
                <ButtonPurple onClick={() => next()}>
                  Next
                </ButtonPurple>
              </Grid>
            </DialogActions>
          </>
        )
      case(5):
        return (
          <>
            <DialogTitle style={{ background: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="overline" style={{ color: '#726B83' }}>
                  TOUR
                </Typography>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Join communities <br/>
                  <i style={{ color: '#4300FF' }}>that spark your interest.</i>
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>
                  Make friends with co-workers who have common backgrounds, lifestyles, and interests. This will help {workforceName} feel like home.
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent style={{ background: '#FFF' }}>

              <Box p={2} mb={1.5} style={{ border: '3px solid #EFEFF1', borderRadius: 20 }}>
                <Typography variant="h6" gutterBottom>Select a community</Typography>

                {/*<Typography variant="body2" gutterBottom>
                  Based on your interests:
                </Typography>*/}
                {parent.state.fullCommunities ? (
                  <>
                    {parent.state.fullCommunities.map((community) => {
                      return (
                        <Box my={1}>
                          <CommunityPreview community={community} parent={parent} clickAction="add" />
                        </Box>
                      )
                    })}
                  </>
                ) : null}
              </Box>

            </DialogContent>

            <DialogActions>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box pl={2}>
                  <Typography variant="caption" style={{ color: '#726B83' }}>
                    Step 5 of 5
                  </Typography>
                </Box>
                <Box>
                  <Grid container direction="row">
                    <ButtonText onClick={() => next()}
                      color="primary" variant="contained">
                      Skip
                    </ButtonText>
                    <ButtonPurple onClick={() => next()}>
                      Next
                    </ButtonPurple>
                  </Grid>
                </Box>
              </Grid>
            </DialogActions>
          </>
        )
      case(6):
        return (
          <>
            <DialogTitle style={{ background: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="overline" style={{ color: '#726B83' }}>
                  TOUR
                </Typography>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  <span role="img" aria-label="confetti">🎉</span> All done!<i style={{ color: '#4300FF' }}> Take a second to explore your People Wiki.</i>
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>
                  Search things like "forgot my password" or "party planning" to see the wiki in action.
                </Typography>
              </Box>
            </DialogTitle>

            <DialogActions>
              <ButtonPurple onClick={() => next()}>
                Done
              </ButtonPurple>
            </DialogActions>
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={true} onClose={() => close()}>
        {giveDialog()}
      </Dialog>
    </>
  )
}

export default DemoConnect;
