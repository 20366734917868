import React from "react";
import { Link } from "react-router-dom"
import clsx from 'clsx';
import {
  Box,
  AppBar,
  Grid,
  Menu,
  MenuItem,
  Container,
  IconButton,
  ButtonBase,
  Hidden,
  Divider,
  Typography,
  Drawer,
  Button,
  Tooltip
} from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import SmallAvatar from '../general/avatars/SmallAvatar';
import ButtonBrick from '../../components/general/buttons/ButtonBrick'
import HiButton from '../../components/general/HiButton'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ExtraSmallAvatar from '../general/avatars/ExtraSmallAvatar';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import logo from '../../assets/Helm-wordmark-wt.png'
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { useAuth0 } from "@auth0/auth0-react";
import {beginMentorMatch} from '../../components/modals/MentorMatch'
import {startCreateGroup} from '../../components/modals/CreateGroup'
import {clickIntoGroup} from '../../components/groups/GroupElement'

import {imageSrc} from '../../utility/images'
import {giveUsersCommunities} from '../../utility/user'
import {clickToHome} from '../../utility/tabs'
// import {userWikiSearch} from '../../api/search'
import {handleSearch} from '../../pages/tabs/SearchResults'
import { makeStyles, withStyles } from '@material-ui/core/styles';

const drawerWidth = 260;

const PurpleTooltip = withStyles((theme) => ({
  arrow: {
    color: '#4300FF',
  },
  tooltip: {
    backgroundColor: '#4300FF',
    color: '#FFF',
    fontSize: 18,
    fontFamily: 'Lato',
    fontWeight: 700,
    borderRadius: 4,
    padding: 12
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#FFF',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#141217',
    color: '#FFF',
    padding: '20px 30px 30px 30px',
  },
  content: {
    justifyContent: 'center',
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  boxSearch: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 850,
    },
  },
  drawerButton: {
    width: '100%',
    padding: '6px 10px 6px 10px',
    margin: 2,
    borderRadius: 8,
    background: 'none',
    '&:hover': {
      background: '#312E3B'
    },
    '&:focus': {
      background: '#4300FF'
    },
  },
  stupidButtonClass: {
    color: '#B1ADBA',
    backgroundColor: 'transparent',
    width: '100%',
    fontSize: 14,
    padding: '4px 8px 4px 8px',
    border: '1px solid rgba(67,0,255,1)',
    borderRadius: 8,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#4300FF',
      border: '1px solid #4300FF',
      color: '#FFF',
    },
  },
}));


// const searchWiki = async (parent, val) => {
//   if(val && val.length) {
//     parent.setState({wikiSearchText: val, overlay: 'search', noSearchResults: false})
//
//     const results = await userWikiSearch(val, parent.state.token, parent.state.user.permission_token)
//     if(results && results.length) {
//       parent.setState({ wikiSearchResults: results })
//     } else {
//       parent.setState({ wikiSearchResults: [], noSearchResults: true })
//     }
//   } else {
//     parent.setState({wikiSearchText: '', overlay: '', noSearchResults: false})
//   }
// }

function userTeams(parent, classes) {
  let userTeams = parent.state.userTeams
  // let numTeammates = 0
  // userTeams.forEach((team, i) => {
  //   numTeammates += team.users.length-1
  // });


  if(userTeams.length) {
    return (
      <>
        {userTeams.map((team) => {
          return (
            <ButtonBase className={classes.drawerButton}
              style={{ background: parent.state.selectedGroup === team ? '#4300FF' : '' }}
              onClick={() => clickIntoGroup(parent, team, team.team_id)}
            >
              <Grid container direction="row" alignItems="center">
                <Box
                  style={{
                    borderColor: parent.state.selectedGroup === team ? '#FFF' : '#585269',
                    borderStyle: 'solid',
                    borderWidth: 4,
                    borderRadius: 22,
                    width: 22,
                    height: 22
                  }}
                />
                <Box pl={2} style={{ width: 'calc(100% - 22px)' }}>
                  <Typography variant="body2" style={{ fontWeight: 600, textAlign: 'left' }}>
                    {team.name}
                  </Typography>
                </Box>
              </Grid>
            </ButtonBase>
          )
        })}
      </>
    )
  } else {
    return ('You are not on any teams')
  }
}

function usersCommunities(parent, classes) {
  let usersCommunities = giveUsersCommunities(parent.state.user._id, parent.state.fullCommunities, true)

  if(usersCommunities.length) {
    return (
      <>
        {usersCommunities ? (
          <>
            {usersCommunities.map((community) => {
              return (
                <ButtonBase className={classes.drawerButton}
                  style={{ background: parent.state.selectedGroup === community ? '#4300FF' : '' }}
                  onClick={() => clickIntoGroup(parent, community, community.community_id)}
                >
                  <Grid container direction="row" alignItems="center">
                    <Box
                      style={{
                        borderColor: parent.state.selectedGroup === community ? '#FFF' : '#585269',
                        borderStyle: 'solid',
                        borderWidth: 4,
                        borderRadius: 22,
                        width: 22,
                        height: 22
                      }}
                    />
                    <Box pl={2} style={{ width: 'calc(100% - 22px)' }}>
                      <Typography variant="body2" style={{ fontWeight: 600, textAlign: 'left' }}>
                        {community.name}
                      </Typography>
                    </Box>
                  </Grid>
                </ButtonBase>
              )
            })}
          </>
        ) : null}
      </>
    )
  } else {
    return ('You are not in any communities')
  }
}

export default function MainNav(props) {
  const classes = useStyles();

  let { user, parent, children, window, ...passThroughProps } = props
  let outsider = !(user && user._id)
  const [anchorEl, setAnchorEl] = React.useState(null);
  let {wikiSearchText, profiles} = parent.state

  const { logout } = useAuth0()
  function logOut() {
    logout({ returnTo: 'https://joinhelm.com' })
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleMobileToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [desktopOpen, setDesktopOpen] = React.useState(true);
  const handleDesktopToggle = () => {
    setDesktopOpen(!desktopOpen);
  };

  // Group add menus
  const [anchorTeam, setAnchorTeam] = React.useState(null);
  const [anchorCommunity, setAnchorCommunity] = React.useState(null);

  const handleClickAddTeam = (event) => {
    setAnchorTeam(event.currentTarget);
  };

  const handleCloseAddTeam = () => {
    setAnchorTeam(null);
  };

  const handleClickAddCommunity = (event) => {
    setAnchorCommunity(event.currentTarget);
  };

  const handleCloseAddCommunity = () => {
    setAnchorCommunity(null);
  };

  const drawer = (
    <div className={classes.drawer}>
      <Box py={3} mt={3}>
        <ButtonBase className={classes.drawerButton}
          style={{ background: parent.state.tab === 'profile' ? '#4300FF' : '' }}
          onClick={() => parent.setState({tab: 'profile', suggestionOverlay: false })}
        >
          <Grid container direction="row" alignItems="center">
            <ExtraSmallAvatar src={imageSrc(user)}
              style={{ borderColor: parent.state.tab === 'profile' ? '#FFF' : '#726B83' }}
            />
            <Box mx={1} />
            <Typography variant="body2" style={{ fontWeight: parent.state.tab === 'profile' ? 800 : 600 }}>
              Profile
            </Typography>
          </Grid>
        </ButtonBase>
        <ButtonBase className={classes.drawerButton}
          style={{ background: parent.state.tab === 'people' ? '#4300FF' : '' }}
          onClick={() => parent.setState({tab: 'people', suggestionOverlay: false })}
        >
          <Grid container direction="row" alignItems="center">
            <PeopleAltRoundedIcon
              style={{ fontSize: 22 }}
            />
            <Box mx={1} />
            <Typography variant="body2" style={{ fontWeight: parent.state.tab === 'people' ?  800 : 600 }}>
              Groups
            </Typography>
          </Grid>
        </ButtonBase>
        {/*
        <ButtonBase className={classes.drawerButton}
          style={{ background: parent.state.tab === 'connections' ? '#4300FF' : '' }}
          onClick={() => parent.setState({tab: 'connections'})}
        >
          <Grid container direction="row" alignItems="center">
            <FlareRoundedIcon
              style={{ fontSize: 22 }}
            />
            <Box mx={1} />
            <Typography variant="body2" style={{ fontWeight: parent.state.tab === 'connections' ? 800 : 600 }}>
              Connections
            </Typography>
          </Grid>
        </ButtonBase>
        */}
        <ButtonBase className={classes.drawerButton}
          style={{ background: parent.state.overlay ? '#4300FF' : '' }}
          onClick={() => parent.setState({tab: 'search' })}
        >
          <Grid container direction="row" alignItems="center">
            <SearchRoundedIcon
              style={{ fontSize: 22 }}
            />
            <Box mx={1} />
            <Typography variant="body2" style={{ fontWeight: parent.state.overlay ? 800 : 600 }}>
              Search
            </Typography>
          </Grid>
        </ButtonBase>
      </Box>
      <Divider style={{ backgroundColor: '#312E3B' }} />
      <Box py={3}>
        <Box p={2} mb={3} style={{ border: '2px solid #00FFCC', borderRadius: 8, color: '#FFF' }}>
          <Typography variant="subtitle2" style={{  fontWeight: 900 }}>
            My intros
          </Typography>
          <Typography variant="caption">
            Meet teammates and leaders
          </Typography>
          <Box m={1} />
          <HiButton colorVariant="green" sizeVariant="small" onClick={() => parent.setState({demoPhoebeIntros: true})}>
            See more
          </HiButton>
        </Box>

        {/* User mentor(s) list */}
        <Box my={1}>
          <Typography variant="caption" style={{ color: '#00FFCC', fontWeight: 600 }}>
            My mentor
          </Typography>
        </Box>
        <Box py={1}>
          <Box p={2} mb={2} style={{ background: '#312E3B', borderRadius: 8, color: '#726B83' }}>
            <Typography variant="subtitle2">
              No active mentor
            </Typography>
          </Box>
          {parent.state.tourClickFindMentor ? (
            <>
              <Box m={1} />
              <PurpleTooltip open={true} arrow title="👈 Next step: click here" placement="bottom">
                <Button className={classes.stupidButtonClass} onClick={() => parent.setState({ tourClickFindMentor: false, tourMentorMatch_3: true })} startIcon={<AddRoundedIcon style={{ fontSize: 15 }} />}>
                  Find a mentor
                </Button>
              </PurpleTooltip>
            </>
          ):(
            <>
              {!parent.state.user.independent ? (
                <>
                  <Box m={1} />
                  <ButtonBrick onClick={() => beginMentorMatch(parent)} startIcon={<AddRoundedIcon style={{ fontSize: 15 }} />}>
                    Find a mentor
                  </ButtonBrick>
                </>
              ) : null}
            </>
          )}

        </Box>

        {/* User teams list */}
        <Box my={1}>
          <Typography variant="caption" style={{ color: '#00FFCC', fontWeight: 600 }}>
            My teams
          </Typography>
        </Box>
        <Box py={1}>
          {parent.state.user ? userTeams(parent, classes) : 'Sign in to view more'}

          <Box m={1} />
          {/* Add team: open menu to create a team OR add existing team */}
          <ButtonBrick onClick={handleClickAddTeam} aria-controls="add-team-menu" aria-haspopup="true" startIcon={<AddRoundedIcon style={{ fontSize: 15 }} />}>
            Add teams
          </ButtonBrick>
          <Menu
            id="add-team-menu"
            anchorEl={anchorTeam}
            keepMounted
            open={Boolean(anchorTeam)}
            onClose={handleCloseAddTeam}
          >
            <MenuItem onClick={() => startCreateGroup(parent, 'team')}>Create a team</MenuItem>
            <MenuItem onClick={() => parent.setState({tab: 'people'})}>Browse all teams</MenuItem>
          </Menu>
        </Box>

        {/* User communities list */}
        <Box my={1}>
          <Typography variant="caption" style={{ color: '#00FFCC', fontWeight: 600 }}>
            My communities
          </Typography>
        </Box>
        <Box py={1}>
          {parent.state.user ? usersCommunities(parent, classes) : 'Sign in to view more'}

          <Box m={1} />
          {/* Add communties: open menu to create a team OR add existing team */}
          <ButtonBrick onClick={handleClickAddCommunity} aria-controls="add-community-menu" aria-haspopup="true" startIcon={<AddRoundedIcon style={{ fontSize: 15 }} />}>
            Add communities
          </ButtonBrick>
          <Menu
            id="add-community-menu"
            anchorEl={anchorCommunity}
            keepMounted
            open={Boolean(anchorCommunity)}
            onClose={handleCloseAddCommunity}
          >
            <MenuItem onClick={() => startCreateGroup(parent, 'community')}>Create a community</MenuItem>
            <MenuItem onClick={() => parent.setState({tab: 'people'})}>Browse all communities</MenuItem>
          </Menu>
        </Box>
      </Box>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div { ...passThroughProps  }>
      <AppBar position="fixed" elevation="0"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: desktopOpen,
        })}

      >
        <Box pt={2} px={3}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={2} md={1}>
              {/* Mobile */}
              <Hidden smUp>
                <IconButton
                  style={{ color: '#141217' }}
                  aria-label="open drawer"
                  onClick={handleMobileToggle}
                  edge="start"
                  className={clsx(classes.menuButton, mobileOpen && classes.hide)}
                >
                  <MenuRoundedIcon />
                </IconButton>
              </Hidden>
              {/* Desktop */}
              <Hidden xsDown>
                <IconButton
                  style={{ color: '#141217' }}
                  aria-label="open drawer"
                  onClick={handleDesktopToggle}
                  edge="open"
                  className={clsx(desktopOpen && classes.hide)}
                >
                  <MenuRoundedIcon />
                </IconButton>
              </Hidden>
            </Grid>

            <Grid item xs={8} md={10}>
              <Grid container direction="row" justify="center">
                {!outsider ? (
                  <Container style={{ zIndex: 'modal' }} className={classes.boxSearch}>
                    {/*<ReactSearchAutocomplete
                      items={[]}
                      resultStringKeyName="text"
                      onSearch={(val) => searchWiki(parent, val)}
                      inputSearchString={wikiSearchText}
                      placeholder="Fine someone..."
                      styling={{
                        zIndex: 100
                      }}
                    />*/}
                    <ReactSearchAutocomplete
                      items={profiles}
                      resultStringKeyName="text"
                      onSearch={(val) => handleSearch(parent, val, profiles)}
                      onFocus={() => parent.setState({suggestionOverlay: true})}
                      inputSearchString={wikiSearchText}
                      placeholder="Find people, teams, help ..."
                      autofocus
                      key='react-search'
                    />
                  </Container>
                ) : null}
              </Grid>
            </Grid>

            {/* Profile + menu */}
            {!outsider ? (
              <Grid item xs={2} md={1}>
                <Grid container direction="row" alignItems="center" justify="flex-end">
                  <Box>
                    <SmallAvatar src={imageSrc(user)}
                      style={{ borderColor: parent.state.tab === 'profile' ? '#4300FF' : '#141217' }}
                      onClick={handleMenuClick}
                    />
                  </Box>
                  <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => clickToHome(parent)}>My profile</MenuItem>
                      {user.independent ? null : (
                        <Link to="/settings" style={{textDecoration: 'none'}}>
                          <MenuItem>Settings</MenuItem>
                        </Link>
                      )}
                      <MenuItem onClick={logOut}>Log Out</MenuItem>
                    </Menu>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </AppBar>

      {/* SideNav */}
      <nav className={classes.drawer}>
        {/* Mobile */}
        <Hidden smUp>
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleMobileToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Box onClick={() => clickToHome()}>
                <img src={logo} alt="Helm" style={{ height: 40 }} />
              </Box>
              <Box>
                <IconButton
                  style={{ color: '#726B83' }}
                  size="small"
                  aria-label="close drawer"
                  onClick={handleMobileToggle}
                  edge="open"
                >
                  <MenuOpenRoundedIcon />
                </IconButton>
              </Box>
            </Grid>
            {drawer}
          </Drawer>
        </Hidden>
        {/* Desktop */}
        <Hidden xsDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            open={desktopOpen}
          >
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Box onClick={() => clickToHome()}>
                <img src={logo} alt="Helm" style={{ height: 40 }} />
              </Box>
              <Box>
                <IconButton
                  style={{ color: '#726B83' }}
                  size="small"
                  aria-label="close drawer"
                  onClick={handleDesktopToggle}
                  edge="open"
                >
                  <MenuOpenRoundedIcon />
                </IconButton>
              </Box>
            </Grid>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: desktopOpen,
        })}
      >
        <Grid container direction="row" justify="center">
          <Container style={{ maxWidth: 800 }}>
            {children}
          </Container>
        </Grid>
      </div>
    </div>
  );
}
