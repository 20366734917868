import React from 'react'
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import XXSmallAvatar from '../../components/general/avatars/XXSmallAvatar'
import { AvatarGroup } from '@material-ui/lab'
import {imageSrc} from '../../utility/images'
import {clickIntoGroup} from './GroupElement'

function TeamPreview(props) {
  const { team, parent, clickAction, disableClick, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      background: '#EFEFF1',
      borderRadius: 12,
      width: '100%',
      '&:hover': {
        background: '#E6E4EC',
        cursor: 'pointer'
      },
    },
    avatar: {
      border: '2px solid #FFF',
      maxHeight: 17,
      maxWidth: 17,
      fontSize: 12,
      color: '#726B83',
      background: 'transparent'
    },
  }));
  const classes = useStyles();

  const giveActionButton = () => {
    if(clickAction === 'add') {
      return <AddCircleRoundedIcon style={{ color: '#726B83' }}  />
    } else {
      return <ChevronRightRoundedIcon style={{ color: '#726B83' }} />
    }
  }

  const giveTeamMambers = (parent, team) => {
    if(team && team.users) {
      return team.users.map((user, i) => {
        return (
          <XXSmallAvatar src={imageSrc(user)} />
        )
      })
    }
  }

  const giveBody = () => {
    return (
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Box style={{ width: 'calc(100% - 30px)' }}>
          <Typography variant="subtitle1" style={{ letterSpacing: -0.1, fontWeight: 900, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'  }}>
            {team.name}
          </Typography>
          <AvatarGroup spacing={1} style={{ height: 22 }}
            classes={{ avatar: classes.avatar }}
          >
            {giveTeamMambers(parent, team)}
          </AvatarGroup>
        </Box>

        {giveActionButton()}
      </Grid>
    )
  }

  if(disableClick) {
    return (
      <Box py={2} px={2} className={classes.root} { ...passThroughProps }>
        {giveBody()}
      </Box>
    )
  } else {
    return (
      <Box py={2} px={2} className={classes.root} { ...passThroughProps }
        onClick={() => clickIntoGroup(parent, team, team.team_id)}>
        {giveBody()}
      </Box>
    )
  }
}
export default TeamPreview
