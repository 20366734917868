import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#141217',
    backgroundColor: '#FFF',
    borderRadius: 12,
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
  },
}));

function ButtonGrey(props) {
  const { children, ...passThroughProps } = props
  const classes = useStyles();

  return (
    <Button { ...passThroughProps } variant="contained" className={ classes.root }>
      {children}
    </Button>
  )
}
export default ButtonGrey
