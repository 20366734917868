import React, {Component} from 'react';
import { Redirect } from "react-router-dom"
import Loading from "../components/general/Loading";
import {independentInit} from '../utility/init'
import Profile from './tabs/Profile'
import {
  Box
} from '@material-ui/core';

class IndependentProfile extends Component {

  constructor(props) {
    super();
    this.state = {
      profiles: [],
      tab: 'profile',
      timeline: [],
      teammateTimeline: [],
      responsesByDay: [],
      emails: [],
      fullLink: {},
      slackUsers: [],
      onboardingMotivators: [],
      resources_domains: [],
      resources_tools: [],
      resources_interests: [],
      resources_interestCategories: [],
      addingDomains: []
    };
  }

  componentDidMount() {
    const { match } = this.props
    let id = match.params.userId
    independentInit(this, id)
  }

  render() {

    if(this.state.toApp) {
      return <Redirect to={`/`} />
    }

    return (
      <>
        {this.state.profile ? (
          <>
            <Box my={15} />
            <Profile parent={this} teammate={this.state.profile} outsider={true}/>
          </>
        ) : <Loading />}
      </>
    )
  }
}
export default IndependentProfile;
