
// {
//  demo: true,
//   _id: 'char0',
//   adjectives: [''],
//   collabs: [{
//     title: ''
//   }],
//   contact_preferences: {
//     primary: {
//       name: ''
//     },
//     meetings: {
//       name: ''
//     },
//     casual: {
//       name: ''
//     },
//     urgent: {
//       name: ''
//     }
//   },
//   created: new Date('March 24, 2005 00:00:00'),
//   domains: [
//     {
//       title: '',
//       capabilities: [
//         {
//           skill_level: 3,
//           title: '',
//           tools: ['']
//         }
//       ]
//     },
//   ],
//   faqs: [
//     {
//       question: '',
//       answer: ''
//     }
//   ],
//   fname: '',
//   independent: true,
//   impact: '',
//   interests: [
//     {
//       title: ''
//     }
//   ],
//   lname: '',
//   location: {
//     formatted: 'Scranton, Pennsylvania'
//   },
//   picture: '',
//   pronouns: '',
//   purpose_statement: '',
//   purposes: [''],
//   title: '',
//   workforce_name: 'Dunder Mifflin'
// }

export const office_profiles = [
  {
    demo: true,
    _id: 'char1',
    adjectives: ['Witty', 'Pioneering', 'Intellectual', 'Generous', 'Diplomatic'],
    collabs: [
      {
        title: 'Mentoring'
      },
      {
        title: 'Career advice'
      },
      {
        title: 'Work reviews'
      },
      {
        title: 'Just chatting'
      },
    ],
    communities: ['comm1'],
    contact_preferences: {
      primary: {
        name: 'In-person'
      },
      meetings: {
        name: 'In-person'
      },
      casual: {
        name: 'In-person'
      },
      urgent: {
        name: 'In-person'
      }
    },
    created: new Date('March 24, 2005 00:00:00'),
    domains: [
      {
        title: 'Operations',
        capabilities: [
          {
            skill_level: 5,
            title: 'Maintain sales and operational performance',
            tools: ['PowerPoint', 'Email surveillance', 'Gift baskets']
          },
          {
            skill_level: 3,
            title: 'Lead transitional activities during mergers',
            tools: ['Team building activities']
          },
          {
            skill_level: 5,
            title: 'Serve as corporate-level advocate for branch employees',
            tools: []
          }
        ]
      },
      {
        title: 'Directing',
        capabilities: [
          {
            skill_level: 4,
            title: 'Empower internal party-planning committee',
            tools: ['Email', 'Conference meetings', 'Performance reviews']
          },
          {
            skill_level: 4,
            title: 'Designed and led on-site employee training',
            tools: []
          }
        ]
      },
      {
        title: 'Coaching',
        capabilities: [
          {
            skill_level: 5,
            title: 'Subverting HR policies for the sake of morale',
            tools: []
          },
          {
            skill_level: 5,
            title: 'Represent the company at conferences and public events',
            tools: ['Public speaking', 'Home videos', 'Scholarships']
          }
        ]
      },
    ],
    faqs: [
      {
        question: 'Would I rather be feared or loved?',
        answer: 'I want people to be afraid of how much they love me.'
      },
      {
        question: 'How do you gain perspective',
        answer: 'You know, sometimes to get perspective, I like to think about a spaceman on a star, incredibly far away. And our problems don\'t matter to him because we\'re just a distant point of light.'
      }
    ],
    fname: 'Michael',
    independent: true,
    impact: 'Broad decision-making authority on branch operations; I place those responsabilities secondary to my desire to be friends with my employees.',
    interests: [
      {
        title: '🎥 Videography'
      },
      {
        title: '🥋 Martial arts'
      },
      {
        title: '🎙 Stand up'
      },
      {
        title: '🏀 Basketball'
      },
    ],
    lname: 'Scott',
    location: {
      formatted: 'Scranton, Pennsylvania'
    },
    picture: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgBcidMFs1JKoYHIIP_CwhwAJdJ_9QHt_sng&usqp=CAU',
    pronouns: 'He/Him/His',
    purpose_statement: 'The most sacred thing I do is care and provide for my workers, my family. I give them money. I give them food. Not directly, but through the money. I heal them.',
    purposes: ['Teammates', 'Culture', 'Lifestyle'],
    title: 'Regional Manager',
    teams: ['team2'],
    workforce_name: 'Dunder Mifflin'
  },
  {
   demo: true,
    _id: 'char2',
    adjectives: ['Adaptable', 'Passionate', 'Pro-active', 'Resourceful', 'Diligent'],
    collabs: [
      {
        title: 'Mentoring'
      },
      {
        title: 'Venting'
      },
      {
        title: 'Work reviews'
      }
    ],
    communities: ['comm1'],
    contact_preferences: {
      primary: {
        name: 'In-person'
      },
      meetings: {
        name: 'In-person'
      },
      casual: {
        name: 'In-person'
      },
      urgent: {
        name: 'Phone call'
      }
    },
    created: new Date('March 24, 2005 00:00:00'),
    domains: [
      {
        title: 'Management',
        capabilities: [
          {
            skill_level: 2,
            title: 'Create incentive programs',
            tools: ['Currencies', 'Doomsday device']
          },
          {
            skill_level: 5,
            title: 'Serve as interpreter and enforcer of rules',
            tools: ['Force', 'Megaphone']
          },
          {
            skill_level: 4,
            title: 'Step up to lead when the manager is not fit',
            tools: []
          }
        ]
      },
      {
        title: 'Sales',
        capabilities: [
          {
            skill_level: 3,
            title: 'Overseeing and reporting on productivity in the sales department',
            tools: ['Excel', 'Spying']
          },
          {
            skill_level: 5,
            title: 'Maintaining the top sales record throughout the company',
            tools: ['Charm', 'Salesforce']
          }
        ]
      },
      {
        title: 'Safety officer',
        capabilities: [
          {
            skill_level: 1,
            title: 'Conduct safety simulations',
            tools: ['Fire', 'Fear']
          },
          {
            skill_level: 1,
            title: 'Supplement safety consultations',
            tools: []
          },
          {
            skill_level: 1,
            title: 'Protecting office staff from physical threats',
            tools: ['Assorted weaponry']
          }
        ]
      },
    ],
    faqs: [
      {
        question: 'Where does your confidence stem from?',
        answer: "Whenever I’m about to do something, I think, ‘Would an idiot do that?’ And if they would, I do not do that thing."
      },
      {
        question: "Define your ideal workday",
        answer: "My perfect Valentine’s Day? I’m at home. Three cell phones in front of me. Fielding desperate calls from people who want to buy one of the 50 restaurant reservations I made over six months ago."
      }
    ],
    fname: 'Dwight',
    independent: true,
    impact: 'Vanquish customer resistance & external threats through physically imposing alpha-male traits and insatiable, merciless, jackhammer-like techniques',
    interests: [
      {
        title: '🥋 Martial arts'
      },
      {
        title: '✌️ Meditation'
      },
      {
        title: '🏓 Ping pong'
      },
      {
        title: '🌱 Gardening'
      },
      {
        title: '🌍 Environmentalism'
      }
    ],
    lname: 'Schrute',
    location: {
      formatted: 'Scranton, Pennsylvania'
    },
    picture: 'https://cdn.pastemagazine.com/www/articles/2020/05/20/the-office-dwight-schrute-main.jpg',
    pronouns: 'He/Him/His',
    purpose_statement: '',
    purposes: ['Mission', 'Challenge', 'Mastery'],
    title: 'Assistant to the Regional Manager',
    teams: ['team1'],
    workforce_name: 'Dunder Mifflin'
  },
  {
   demo: true,
    _id: 'char3',
    adjectives: ['Diplomatic', 'Patient', 'Creative', 'Sincere', 'Optimistic'],
    collabs: [
      {
        title: 'Brainstorming'
      },
      {
        title: 'Happy hour'
      },
      {
        title: 'Making intros'
      },
      {
        title: 'Just chatting'
      }
    ],
    communities: ['comm2', 'comm3'],
    community_names: ['party planning committee'],
    contact_preferences: {
      primary: {
        name: 'Phone call'
      },
      meetings: {
        name: 'In-person'
      },
      casual: {
        name: 'Video call'
      },
      urgent: {
        name: 'Phone call'
      }
    },
    created: new Date('March 24, 2005 00:00:00'),
    domains: [
      {
        title: 'Design',
        capabilities: [
          {
            skill_level: 4,
            title: 'Graphic design',
            tools: ['Adobe Creative Suite']
          },
          {
            skill_level: 4,
            title: 'Animation',
            tools: ['Adobe Creative Suite']
          },
          {
            skill_level: 4,
            title: 'Advertisement',
            tools: ['Sketch']
          },
        ]
      },
      {
        title: 'Sales',
        capabilities: [
          {
            skill_level: 2,
            title: 'Making client calls',
            tools: []
          },
          {
            skill_level: 3,
            title: 'In-person sales meetings',
            tools: ['Salesforce']
          },
        ]
      },
      {
        title: 'Office Administrator',
        capabilities: [
          {
            skill_level: 3,
            title: 'Supply management',
            tools: ['Budgets']
          },
          {
            skill_level: 4,
            title: 'Managing branch building lease',
            tools: []
          },
        ]
      },
    ],
    faqs: [
      {
        question: 'Describe how you prefer to be recognized',
        answer: 'Award ceromonies are a good way to show praise'
      },
      {
        question: 'Define your ideal workday',
        answer: 'Pranks, conference meetings, copier fixes and rooftop dinners'
      }
    ],
    fname: 'Pam',
    independent: true,
    impact: 'My sales make a small impact on the company, but improving morale in-office is where I make a splash',
    interests: [
      {
        title: '🎨 Art'
      },
      {
        title: '✒️ Design'
      },
      {
        title: '🏐 Volleyball'
      },
      {
        title: '🏛 Museums & galleries'
      },
      {
        title: '🌆 Exploring new cities'
      }
    ],
    lname: 'Beesly',
    location: {
      formatted: 'Scranton, Pennsylvania'
    },
    picture: 'https://www.lifeandstylemag.com/wp-content/uploads/2019/02/Jenna-Fischer-Best-Quotes-The-Office-Promo.jpg?resize=1260%2C709&quality=86&strip=all',
    pronouns: 'She/Her/Hers',
    purpose_statement: 'I am here to advance my professional capabilties amongst my colleagues and family',
    purposes: ['Teammates', 'Lifestyle', 'Autonomy'],
    title: 'Sales Representative',
    teams: ['team1'],
    workforce_name: 'Dunder Mifflin'
  },
  {
   demo: true,
    _id: 'char4',
    adjectives: ['Easygoing', 'Frank', 'Practical'],
    collabs: [
      {
        title: 'Happy hour'
      },
      {
        title: 'Venting'
      }
    ],
    communities: ['comm1'],
    contact_preferences: {
      primary: {
        name: 'Phone call'
      },
      meetings: {
        name: 'In-person'
      },
      casual: {
        name: 'Email'
      },
      urgent: {
        name: 'Phone call'
      }
    },
    created: new Date('March 24, 2005 00:00:00'),
    domains: [
      {
        title: 'Sales',
        capabilities: [
          {
            skill_level: 3,
            title: 'Client retention',
            tools: []
          }
        ]
      },
    ],
    faqs: [
      {
        question: 'Describe how you work with others',
        answer: 'Quietly and with minimal interaction'
      }
    ],
    fname: 'Stanley',
    independent: true,
    impact: 'I make sales so that the sales department makes enough money to avoid down-sizing',
    interests: [
      {
        title: '🕺 Clubs'
      },
      {
        title: '📺 Film & TV'
      },
      {
        title: '🏝 Beach bum'
      },
      {
        title: '❤️ Family'
      },
    ],
    lname: 'Hudson',
    location: {
      formatted: 'Scranton, Pennsylvania'
    },
    picture: 'https://wsbt.com/resources/media/a635a1b6-4cc4-425b-b686-67fa860f9ed8-jumbo16x9_stanleyhudsonNBC.PNG?1550090897058',
    pronouns: 'He/Him/His',
    purpose_statement: "I'm here for the money.",
    purposes: ['Lifestyle'],
    title: 'Salesman',
    teams: ['team1'],
    workforce_name: 'Dunder Mifflin'
  },
  {
    demo: true,
    _id: 'char5',
    adjectives: ['Calm', 'Diplomatic', 'Patient', 'Diligent', 'Straightforward'],
    collabs: [
      {
        title: 'Venting'
      },
      {
        title: 'Career advice'
      },
      {
        title: 'Just chatting'
      },
    ],
    communities: ['comm2'],
    contact_preferences: {
      primary: {
        name: 'Phone call'
      },
      meetings: {
        name: 'In-person'
      },
      casual: {
        name: 'In-person'
      },
      urgent: {
        name: 'Phone call'
      }
    },
    created: new Date('March 24, 2005 00:00:00'),
    domains: [
      {
        title: 'Human Resources',
        capabilities: [
          {
            skill_level: 2,
            title: 'Mediate conflict within the workplace',
            tools: []
          },
          {
            skill_level: 1,
            title: 'Handle onboarding of new employees',
            tools: []
          },
          {
            skill_level: 2,
            title: 'Exit interviews with former employees',
            tools: []
          },
          {
            skill_level: 2,
            title: 'General tech support such as forgot my password and setting up online accounts',
            tools: []
          }
        ]
      }
    ],
    faqs: [
      {
        question: "Why didn't you invite the boy scouts to their fundraiser?",
        answer: "I didn't think it was appropriate to invite children, since it's uh, you know, there's gambling and alcohol, it's in our dangerous warehouse, it's a school night, and you know, Hooters is catering, and is that - is that enough? Should I keep going?"
      },
      {
        question: 'Any tips for working at home?',
        answer: "Ok, um, one thing that you're gonna want to look out for is carpal tunnel syndrome. It's recommended that you take a ten minute break from typing every hour. For your circulation, you're gonna want to get up out of your chairs and uh, and move around about ten minutes every hour."
      }
    ],
    fname: 'Toby',
    independent: true,
    impact: 'Represent corporate in ensuring that all personel feel comfortable at work and follow the guidlines in my binders',
    interests: [
      {
        title: '📝 Writing'
      },
      {
        title: '👟 Running'
      },
      {
        title: '🏝 Beach bum'
      }
    ],
    lname: 'Flenderson',
    location: {
      formatted: 'Scranton, Pennsylvania'
    },
    picture: 'https://www.looper.com/img/gallery/toby-from-the-office-has-some-dangerous-traits-of-this-type-of-person/intro-1617723093.jpg',
    pronouns: 'He/Him/His',
    purpose_statement: "I'd like to think I'm here to help these people with my calming presence, but honestly I'm not sure if that is true for everyone.",
    purposes: ['Challenge', 'Culture'],
    title: 'Human relations representative',
    teams: ['team3'],
    workforce_name: 'Dunder Mifflin'
  },
  {
    demo: true,
    _id: 'char6',
    adjectives: ['Careful', 'Imaginative', 'Inventive', 'Humorous'],
    collabs: [
      {
        title: 'Brainstorming'
      },
      {
        title: 'Just chatting'
      },
    ],
    communities: [],
    contact_preferences: {
      primary: {
        name: 'Text'
      },
      meetings: {
        name: 'In-person'
      },
      casual: {
        name: 'In-person'
      },
      urgent: {
        name: 'Phone call'
      }
    },
    created: new Date('March 24, 2005 00:00:00'),
    domains: [
      {
        title: 'I really should have written this down',
        capabilities: [
          {
            skill_level: 5,
            title: 'Identity theft',
            tools: []
          },
          {
            skill_level: 5,
            title: 'Eluding the authorities',
            tools: []
          },
          {
            skill_level: 1,
            title: 'Acronyms',
            tools: []
          }
        ]
      }
    ],
    faqs: [
      {
        question: 'What experience do you have of working in a team?',
        answer: "I’ve Been Involved In A Number Of Cults, Both As A Leader And A Follower. You Have More Fun As A Follower. But You Make More Money As A Leader."
      },
      {
        question: "What do you enjoy doing in your free time",
        answer: "Hang Out By The Quarry And Throw Things Down There."
      },
      {
        question: 'What would you do if you won the lottery?',
        answer: "I Already Won The Lottery, I Was Born In The U-S Of A, Baby."
      },
    ],
    fname: 'Creed',
    independent: true,
    impact: "I Stopped Caring A Long Time Ago.",
    interests: [
      {
        title: '♟️ Chess'
      },
      {
        title: '🏊 Scuba'
      },
      {
        title: '🎊 Festivals'
      }
    ],
    lname: 'Bratton',
    location: {
      formatted: 'Scranton, Pennsylvania'
    },
    picture: 'https://cdn3.whatculture.com/images/2019/02/04b01e2e1c68bf2c-600x338.png',
    pronouns: 'He/Him/His',
    purpose_statement: "The Only Difference Between Me And A Homeless Man Is This Job. I Will Do Whatever It Takes To Survive Like I Did When I Was A Homeless Man.",
    purposes: ['Lifestyle', 'Autonomy'],
    title: 'Quabity Ashurance',
    teams: ['team4'],
    workforce_name: 'Dunder Mifflin'
  },
  {
    demo: true,
    _id: 'char7',
    adjectives: ['Emotional', 'Energetic', 'Sociable', 'Optimistic'],
    collabs: [
      {
        title: 'Making intros'
      },
      {
        title: 'Just chatting'
      },
      {
        title: 'Coffee chats'
      }
    ],
    communities: ['comm3'],
    community_names: ['party planning committee'],
    contact_preferences: {
      primary: {
        name: 'Text'
      },
      meetings: {
        name: 'Text'
      },
      casual: {
        name: 'Phone call'
      },
      urgent: {
        name: 'Phone call'
      }
    },
    created: new Date('March 24, 2005 00:00:00'),
    domains: [
      {
        title: 'Customer service',
        capabilities: [
          {
            skill_level: 5,
            title: 'Answering the phones when they ring',
            tools: []
          },
          {
            skill_level: 5,
            title: 'Gathering information on performance of sales reps, mostly using spreadsheets. I can help with excel',
            tools: ['Excel']
          },
          {
            skill_level: 1,
            title: 'Managing crisis situations with customers',
            tools: []
          }
        ]
      }
    ],
    faqs: [
      {
        question: 'Do you have any management experience?',
        answer: "Well, I manage my own department and I’ve been doing that for several years now. And God, I’ve learned a lot of life lessons along the way. It's just me, but I am not easy to manage"
      },
      {
        question: "What do you do when conflict arises",
        answer: "I would just like freak out and get really drunk and then tell someone I was pregnant."
      }
    ],
    fname: 'Kelly',
    independent: true,
    impact: "I solve customers problems and make them happier by saying just the right things at the right times",
    interests: [
      {
        title: '💄 Make-up'
      },
      {
        title: '🎤 Karaoke'
      },
      {
        title: '📺 Film & TV'
      },
      {
        title: '🛁 Spa weekends'
      }
    ],
    lname: 'Kapoor',
    location: {
      formatted: 'Scranton, Pennsylvania'
    },
    picture: 'https://images2.fanpop.com/images/photos/6500000/Ryan-and-Kelly-the-office-6533409-641-359.jpg',
    pronouns: 'She/Her/Hers',
    purpose_statement: "Fall in love, have babies, spend every second together. But don’t tell Ryan that, OK? Just tell him I’m like up for anything, I mean I’m not a slut, but who knows?",
    purposes: ['Lifestyle', 'Teammates'],
    title: 'Customer relations',
    teams: ['team5'],
    workforce_name: 'Dunder Mifflin'
  },
  {
    demo: true,
    _id: 'char8',
    adjectives: ['Intellectual', 'Helpful', 'Self-confident', 'Intuitive'],
    collabs: [
      {
        title: 'Mentoring'
      },
      {
        title: 'Work reviews'
      },
      {
        title: 'Making intros'
      }
    ],
    communities: ['comm1'],
    contact_preferences: {
      primary: {
        name: 'Phone call'
      },
      meetings: {
        name: 'In person'
      },
      casual: {
        name: 'Email'
      },
      urgent: {
        name: 'Phone call'
      }
    },
    created: new Date('March 24, 2005 00:00:00'),
    domains: [
      {
        title: 'Accounting',
        capabilities: [
          {
            skill_level: 5,
            title: 'Addition',
            tools: ['Calculator']
          },
          {
            skill_level: 5,
            title: 'Subtraction',
            tools: ['Calculator']
          },
          {
            skill_level: 1,
            title: 'Percents',
            tools: ['Calculator']
          }
        ]
      }
    ],
    faqs: [
      {
        question: 'What is your most ambitious goal?',
        answer: "I want to eat a pig in a blanket, in a blanket."
      },
      {
        question: "How do you relax in your freetime?",
        answer: "You know what's really funny and relaxing? This bird in the park that can’t fly right. I’d pay to see him but I don’t have to, ‘cause the park is free"
      }
    ],
    fname: 'Kevin',
    independent: true,
    impact: "I do the numbers",
    interests: [
      {
        title: '🌮 Foodie'
      },
      {
        title: '🎲 Board games'
      },
      {
        title: '🐢 Turtles'
      },
      {
        title: '🏀 Basketball'
      }
    ],
    lname: 'Malone',
    location: {
      formatted: 'Scranton, Pennsylvania'
    },
    picture: 'https://openpsychometrics.org/tests/characters/test-resources/pics/TO/9.jpg',
    pronouns: 'He/Him/His',
    purpose_statement: "I like knowing that there’s going to be a break. Most days I just sit and wait for the break.",
    purposes: ['Mastery', 'Teammates'],
    title: 'Accountant',
    teams: ['team6'],
    workforce_name: 'Dunder Mifflin'
  },
  {
    demo: true,
    _id: 'char9',
    adjectives: ['Compassionate', 'Modest', 'Practical', 'Reserved'],
    collabs: [
      {
        title: 'Venting'
      },
      {
        title: 'Coffee chats'
      },
      {
        title: 'Career advice'
      }
    ],
    communities: ['comm3'],
    community_names: ['party planning committee'],
    contact_preferences: {
      primary: {
        name: 'Phone call'
      },
      meetings: {
        name: 'In person'
      },
      casual: {
        name: 'Email'
      },
      urgent: {
        name: 'Phone call'
      }
    },
    created: new Date('March 24, 2005 00:00:00'),
    domains: [
      {
        title: 'Accounting',
        capabilities: [
          {
            skill_level: 5,
            title: "Correcting Kevnin's mistakes",
            tools: []
          },
          {
            skill_level: 5,
            title: 'Submitting quartely reports to corporate',
            tools: []
          },
          {
            skill_level: 1,
            title: 'Balancing company expenses',
            tools: ['Calculator']
          }
        ]
      }
    ],
    faqs: [
      {
        question: 'Given the office drama which you partake in, how do you sleep at night?',
        answer: "I have a nice comforter, a few cozy pillows, I usually read a chapter of a book, and it's lights out by 8:30. That's how I sleep at night."
      },
      {
        question: "What do you think of jazz?",
        answer: "Jazz is stupid! I mean, just play the right notes!"
      }
    ],
    fname: 'Angela',
    independent: true,
    impact: "My accounting work and the corrections I make within the accounting department keep this branch and the company afloat.",
    interests: [
      {
        title: '🐱 Cats'
      },
      {
        title: '🐱 Cats'
      },
      {
        title: '🐱 Cats'
      }
    ],
    lname: 'Martin',
    location: {
      formatted: 'Scranton, Pennsylvania'
    },
    picture: 'https://tvline.com/wp-content/uploads/2020/08/the-office-angela.jpg?w=619',
    pronouns: 'She/Her/Hers',
    purpose_statement: "I am here to further my professional career and provide for my furry children",
    purposes: ['Mastery', 'Autonomy'],
    title: 'Accountant',
    teams: ['team6'],
    workforce_name: 'Dunder Mifflin'
  },
]
