import React from 'react';
import {
  Typography,
  Dialog,
  Box,
  Button,
  Grid,
  DialogActions
} from '@material-ui/core';
import moIcon from '../../assets/icons/Mo-logo-wht.png'

function giveElements(parent) {
  return {
    header: 'Meet me in your Slack DM\'s',
    body: 'We will finish setting up your profile there',
    button: 'Close'
  }
}

function deepLinkSlack(workforce) {
  let teamId = workforce.slack && workforce.slack.team_id ? `&team=${workforce.slack.team_id}` : ''
  window.open(`https://slack.com/app_redirect?app=A01L0FR3L6Q${teamId}`, '_blank')
}

function UserOnboardCelebration(props) {
  let {parent} = props

  let elements = giveElements(parent)

  return (
    <div>
      <Dialog open={true}
       onClose={() => parent.setState({modalMessagedInSlack: false})}
       aria-labelledby="form-dialog-title" fullWidth>
       <Box px={3} py={3}>
         <Box py={2} px={2} mb={4} style={{ backgroundImage: 'linear-gradient(270deg, #00C7B4 10%, #00D9AE 85%)', boxShadow: '0 0 10px 3px rgba(0,0,0,0.06)', borderRadius: 12 }}>
           <Grid container direction="row" alignItems="center">
             <Box px={1} mr={1}>
               <img src={moIcon} alt="Mo:" style={{ width: 32, height: 32 }} />
             </Box>
             <Typography variant="h6" style={{ color: '#006954' }}>
              {elements.header}
             </Typography>
           </Grid>
        </Box>
        <Box py={1} px={2}>
          <Typography variant="body1" style={{ color: '#726B83' }}>{elements.body}</Typography>
        </Box>
       </Box>
       <DialogActions>
         <Button color="primary" variant="contained"
         onClick={() => deepLinkSlack(parent.state.workforce)}>
           Take me there!
         </Button>
         <Button color="primary" variant="contained"
         onClick={() => parent.setState({modalMessagedInSlack: false})}>
           {elements.button}
         </Button>
       </DialogActions>
      </Dialog>
    </div>
  );

}

export default UserOnboardCelebration;
