import React from 'react'
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function ClearChip(props) {
  let { children, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'transparent',
      color: '#4300FF',
      height: 24,
      borderRadius: 4,
      fontSize: 18,
      fontWeight: 600,
      fontFamily: 'lato',
      letterSpacing: 0.1,
      padding: '0 2px 0 2px'
    }
  }));

  const classes = useStyles();

  return (
    <Chip { ...passThroughProps } size="small" className={ classes.root }>
      {children}
    </Chip>
  )
}
export default ClearChip
