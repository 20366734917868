import React from 'react'
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MediumAvatar from '../general/avatars/MediumAvatar'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import {giveFirstName, giveLastName, giveTitle} from '../../utility/profile'
import {imageSrc} from '../../utility/images'
import {clickProfile, clickSlackProfile} from '../../utility/tabs'

function ProfilePreview(props) {
  const {parent, user, isSlackUser, styleVariant, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    white: {
      background: '#FFF',
      borderRadius: 8,
      width: '100%',
      '&:hover': {
        background: '#EFEFF1',
        cursor: 'pointer'
      },
    },
    grey: {
      background: '#EFEFF1',
      borderRadius: 8,
      width: '100%',
      '&:hover': {
        background: '#E6E4EC',
        cursor: 'pointer'
      },
    },
  }));
  const classes = useStyles();
  let variant = styleVariant ? styleVariant : 'white'

  let fname = isSlackUser ? user.profile.first_name : giveFirstName(user)
  let lname = isSlackUser ? user.profile.last_name : giveLastName(user)
  let title = isSlackUser ? user.profile.title : giveTitle(user)
  let image = isSlackUser ? user.profile.image_72 : imageSrc(user)

  let fullName = `${fname} ${lname}`
  if( (!fname && !lname) || fullName.length < 3) {
    fullName = '[Nameless user]'
  }

  const onClick = () => {
    if(isSlackUser) {
      clickSlackProfile(parent, user)
    } else {
      clickProfile(parent, user)
    }
  }

  return (
    <Box py={1} px={1} className={classes[variant]}
      onClick={() => onClick()}  { ...passThroughProps }
    >
      <Grid container direction="row" justify="space-between" alignItems="center">
        <MediumAvatar src={image} />
        <Box pl={2} style={{ width: 'calc(100% - 70px)' }}>
          <Typography variant="subtitle1" style={{ letterSpacing: -0.1, weight: 700, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'  }}>
             {fullName} <span style={{ fontWeight: 500, color: '#726B83' }}>{user._id === parent.state.user._id ? ' | you' : ''}</span>
          </Typography>
          <Typography variant="body2" style={{ color: '#726B83', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {title}
          </Typography>
        </Box>
        <ChevronRightRoundedIcon fontSize="small" style={{ color: '#141217' }} />
      </Grid>
    </Box>
  )
}
export default ProfilePreview
