import React, { Component } from 'react';
import Loading from "../components/general/Loading";
import { getByAuth0 } from '../api/user'
import { withAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import { Redirect } from "react-router-dom"
import queryString from "query-string"

class NewWorkforce extends Component  {
  constructor(props) {
    super();
    this.state = {
      companyName: "",
      verify: false,  // when toggled, we check if each input is filled out
      error: "",    // error message from validation
      giveSlackOrEmail: true
    };
  }

  componentDidMount() {
    const { user, getAccessTokenSilently } = this.props.auth0;
    const values = queryString.parse(this.props.location.search)

    if(values.access_token) {
      this.setState({accessToken: values.access_token})
      if(values.user_slack_token) {
        this.setState({user_slack_token: values.user_slack_token})
      }
      if(values.name) {
        this.setState({slackName: values.name})
      }
      if(values.teamId) {
        this.setState({teamId: values.teamId})
      }
      if(values.logo) {
        this.setState({slackLogo: values.logo})
      }
      if(values.user_slack_token) {
        this.setState({user_slack_token: values.user_slack_token})
      }
    } else {
      this.setState({noSlackToken: true})
    }

    getAccessTokenSilently({ audience: 'https://joinhelm.com/api' }).then(token => {
      getByAuth0(user.sub, token).then(my_user => {
        this.setState({ user: my_user, token: token })
      })
    })
  }

  render() {
    const { user } = this.props.auth0
    const { toDash, accessToken, slackName, slackLogo, noSlackToken, teamId,
       user_slack_token } = this.state

    if (!user) {
      return <Loading/>
    }

    if(accessToken) {
      return <Redirect to={`/launch?access_token=${accessToken}${user_slack_token ? `&user_slack_token=${user_slack_token}` : ''}${slackName ? `&name=${slackName}` : ''}${teamId ? `&teamId=${teamId}` : ''}${slackLogo ? `&logo=${slackLogo}` : ''}`} />
    } else if(toDash) {
      return <Redirect to={`/`} />
    } else if(noSlackToken) {
      return <Redirect to={`/launch`} />
    } else {
      return <Loading />
    }

  }
}

export default withAuth0(withAuthenticationRequired(NewWorkforce, {
  onRedirecting: () => <Loading />,
}));
