
export const days_of_week_long = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const days_of_week_short = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
export const shortMonths = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec']
export const DAY_IN_MS = 86400000

export function giveShortDate(date, ignoreYear) {
  let d = new Date(date)
  return `${shortMonths[d.getMonth()]} ${d.getDate()}${ignoreYear ? '' : `, ${d.getFullYear()}`}`
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export function giveCountdownXS(now, subject) {
  var diff = Math.floor(subject.getTime() - now.getTime());
  var day = 1000 * 60 * 60 * 24;

  let weeksDiff = diff / (day * 7)
  let weeks = 0
  if(weeksDiff > 0) {
    weeks = Math.floor(weeksDiff)
  } else if(weeksDiff < 0) {
    weeks = Math.ceil(weeksDiff)
  }

  let remainingDiff = 0
  if(diff > 0) {
    remainingDiff = Math.floor(diff - (weeks * (7 * day)))
  } else {
    remainingDiff = Math.ceil(diff + ((weeks * -1) * (7 * day)))
  }

  let days = 0
  if(remainingDiff >= 0) {
    days = Math.floor(remainingDiff / day)
  } else {
    days = Math.ceil(remainingDiff / day)
  }

  if(days === 0 && weeks === 0) {
    return `Sent today`
  } else {
    return `Sent ${Math.abs(weeks) === 0 ? '' : `${Math.abs(weeks)}w & `} ${Math.abs(days) === 0 ? '' : `${Math.abs(days)}d`} ago`
  }
}

export function giveContextDate(date, giveTime) {
  let context = false // if today or yesterday, replace

  let d = new Date(date)
  let now = new Date()
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0, 0);
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0, 0);

  if(d.getTime() === now.getTime()) {
    context = 'Today'
  } else if(d.getTime() >= (now.getTime() - DAY_IN_MS) ) {
    context = "Yesterday"
  }

  if(context) {
    return `${context} ${giveTime ? `at ${formatAMPM(date)}` : ''}`
  } else {
    return `${days_of_week_long[d.getDay()]}, ${months[d.getMonth()]} ${d.getDate()} ${giveTime ? `at ${formatAMPM(date)}`:''}`
  }
}

export const roundDownDate = (date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date
}
