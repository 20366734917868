import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ButtonPrimary from '../../components/general/buttons/ButtonPrimary'
import HiSquareChip from '../../components/general/HiSquareChip'
import ProfileElement from '../../components/profile/ProfileElement'
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {startEditGroup} from '../../components/modals/EditGroup'
import GroupElement from '../../components/groups/GroupElement'

// Custom styling
const useStyles = makeStyles((theme) => ({
  editButton: {
    visibility: 'visible',
    height: 22,
    color: '#141217',
    backgroundColor: '#FFF',
    border: '1px solid #E6E4EC',
    padding: '0 10px 0 10px',
    borderRadius: 12,
    fontSize: 12,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#EFEFF1',
    },
  },
}));



// Main function
function Group(props) {
  const { parent } = props
  let {teamId, selectedGroup} = parent.state

  let isTeam = teamId ? true : false
  let leader = false
  if(selectedGroup.leads && selectedGroup.leads.length) {
    selectedGroup.users.forEach((user, i) => {
      if(!leader && selectedGroup.leads.includes(user._id)) {
        leader = user
      }
    });
  }

  const classes = useStyles()

  return (
    <>
      <Box>
        {/* Intro */}
        <Box>
          <Grid container direction="row" justify="space-between">
            <Box style={{ width: 'calc(100% - 200px)' }}>
              <Typography variant="h2">
                <i>{selectedGroup.name}</i>
              </Typography>
              <Typography variant="body1" style={{ color: '#726B83' }}>
                {selectedGroup.description}
              </Typography>
            </Box>
            <Box>
              <ButtonPrimary
                // onClick= join or leave team
                styleVariant="grey"
                // styleVariant="purple" for join team (IF not a member)
              >
                Leave {isTeam ? 'team' : 'community'}
              </ButtonPrimary>
            </Box>
          </Grid>
          <Grid container direction="row" justify="space-between">
            <Box style={{ width: 'calc(100% - 100px)' }}>
              {selectedGroup.domains && selectedGroup.domains.length ? (
                <Grid container direction="row" alignItems="center">
                  {selectedGroup.domains.map((domain, i) => {
                    return (
                      <Box my={0.5} pr={0.5} key={i}>
                        <HiSquareChip styleVariant="purple"
                          label={domain}
                        />
                      </Box>
                    )
                  })}

                  <Box m={0.5} />

                  {/*<Typography variant="body1" style={{ color: '#726B83' }}>
                    Design, code, and ship powerful software to our users
                  </Typography>*/}
                </Grid>
              ) : null}
            </Box>
            <Box>
              <Button
                className={classes.editButton}
                onClick={() => startEditGroup(parent, selectedGroup, isTeam ? 'team' : 'community')}
                startIcon={<EditRoundedIcon style={{ fontSize: 12 }} />}
              >
                Edit
              </Button>
            </Box>
          </Grid>
        </Box>

        <Box m={3} />

          {parent.state.fullTeams.length ? (
            <Box p={2} my={2} style={{ background: '#EFEFF1', borderRadius: 12 }}>
              <Typography variant="h6">
                Sub-teams
              </Typography>
              {parent.state.fullTeams.map((team) => {
                if(team.parent && team.parent === teamId) {
                  return (
                    <Box my={1}>
                      <GroupElement parent={parent} group={team} type="team" />
                    </Box>
                  )
                } else return null

              })}
            </Box>
            ) : null
          }

        {/* Lead(s) */}
        {leader ? (
          <Box py={3}>
            <Typography variant="h6">
              Lead
            </Typography>

            <Box my={1}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12} md={12}>
                  <ProfileElement parent={parent} user={leader} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : null}

        {/* Members */}
        <Box py={3}>
          <Typography variant="h6">
            Members
          </Typography>

          {/* BRAD: map all group members here */}
          <Box my={1}>
            <Grid container direction="row" spacing={1}>
              {selectedGroup.users.map((user) => {
                if(!leader || user._id !== leader._id) {
                  return (
                    <Grid item xs={12} md={6}>
                      <ProfileElement parent={parent} user={user} />
                    </Grid>
                  )
                } else return null
              })}
            </Grid>
          </Box>
        </Box>

        <Box my={10}/>
      </Box>
    </>
  )
};
export default Group;
