import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function HiButton(props) {
  const { children, sizeVariant, colorVariant, ...passThroughProps } = props

  // Classes
  const useStyles = makeStyles((theme) => ({
    // Sizes
    small: {
      borderRadius: 8,
      fontSize: 12,
      padding: '4px 12px 4px 12px'
    },
    large: {
      borderRadius: 12,
      fontSize: 14,
    },
    // Colors
    grey: {
      backgroundColor: '#EFEFF1',
      color: '#141217',
      '&:hover': {
        backgroundColor: '#E6E4EC'
      }
    },
    black: {
      color: '#FFF',
      backgroundColor: '#141217',

      '&:hover': {
        backgroundColor: '#312E3B',
      },
    },
    purple: {
      backgroundColor: 'rgba(67,0,255,1)',
      color: '#FFF',
      '&:hover': {
        backgroundColor: 'rgba(67,0,255,0.7)'
      },
      '&:focus': {
        backgroundColor: 'rgba(67,0,255,.6)'
      }
    },
    green: {
      backgroundColor: 'rgba(0,217,174,1)',
      color: '#000',
      '&:hover': {
        backgroundColor: 'rgba(0,217,174,0.7)',
      }
    },
    yellow: {
      backgroundColor: 'rgba(255, 217, 0, 0.4)',
      color: '#8B7600',
      '&:hover': {
        backgroundColor: 'rgba(255, 217, 0, 0.8)'
      }
    }
  }));

  let color = colorVariant ? colorVariant : 'grey'
  let size = sizeVariant ? sizeVariant : 'large'
  const classes = useStyles();

  return (
    <Button { ...passThroughProps } variant="contained" className={`${classes[size]} ${classes[color]}`}>
      {children}
    </Button>
  )
}
export default HiButton
