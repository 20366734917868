import React from 'react';
import MessagedInSlack from "../components/modals/MessagedInSlack";
import ShareProfile from "../components/modals/ShareProfile";
import SlackPermissionsReset from "../components/modals/SlackPermissionsReset";
import UpdateProfile from "../pages/tabs/UpdateProfile";
import DemoConnect from "../components/modals/DemoConnect";
import DemoPhoebeIntros from "../components/modals/DemoPhoebeIntros";
import SlackInvite from "../components/modals/SlackInvite";
import MentorMatch from "../components/modals/MentorMatch";
import CreateGroup from "../components/modals/CreateGroup";
import EditGroup from "../components/modals/EditGroup";
import TourIntro from "../components/modals/TourIntro";
import NewHireIntroDemo from "../components/modals/NewHireIntroDemo";
import MentorMatchDemo from "../components/modals/MentorMatchDemo";
import PeopleSearchPrompt from "../components/modals/PeopleSearchPrompt";
import ContactPreview from "../components/modals/ContactPreview";

export function giveModals(parent) {
  if(parent.state.modalMessagedInSlack) {
    return <MessagedInSlack parent={parent}  />
  } else if(parent.state.sharingProfile) {
    return <ShareProfile parent={parent} />
  } else if(parent.state.slackPermissionsReset) {
    return <SlackPermissionsReset parent={parent} />
  } else if(parent.state.editProfile) {
    return <UpdateProfile parent={parent} />
  } else if(parent.state.demoConnect) {
    return <DemoConnect parent={parent} />
  } else if(parent.state.demoPhoebeIntros) {
    return <DemoPhoebeIntros parent={parent} />
  } else if(parent.state.slackInvite) {
    return <SlackInvite parent={parent} users={parent.state.slackUsers} />
  } else if(parent.state.mentorMatch) {
    return <MentorMatch parent={parent} />
  } else if(parent.state.createGroup) {
    return <CreateGroup parent={parent} />
  } else if(parent.state.editGroup) {
    return <EditGroup parent={parent} />
  } else if(parent.state.tourIntro) {
    return <TourIntro parent={parent} />
  } else if(parent.state.tourNewHireIntro_2) {
    return <NewHireIntroDemo parent={parent} />
  } else if(parent.state.tourMentorMatch_3) {
    return <MentorMatchDemo parent={parent} />
  } else if(parent.state.tourPeopleSearch_4) {
    return <PeopleSearchPrompt parent={parent} />
  } else if(parent.state.contactPreview) {
    return <ContactPreview parent={parent} />
  } else return null
}
