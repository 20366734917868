import React from 'react';
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';

import {giveWorkforceName} from '../../utility/workforce'



// Main function
function Connections(props) {
  const { parent, teammate } = props
  let {workforce} = parent.state
  let user = teammate ? teammate : parent.state.user

  return (
    <>
      {/* Analytics */}
      <Box my={2}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Typography variant="h4">
            <i>Latest news</i>
          </Typography>
        </Grid>

        <Box m={1} />

        {/* Analytics */}
        <Box p={2} style={{ background: 'linear-gradient(315deg, #00FFCC 0%, #4300FF 77.1%)', borderRadius: 12, color: '#FFF' }}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Box>
              <Typography variant="subtitle2">
                Analytics
              </Typography>
            </Box>
            {/* Add a "connect" with more teammates button here */}
          </Grid>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="h3" style={{ color: '#FFF' }}>
              42
            </Typography>
            <Typography variant="body2" align="right" style={{ color: '#FFF' }}>
              <b>connections made</b><br/>
              in last 30 days
            </Typography>
          </Grid>

          <Box m={1} />

          <Box p={1.5} style={{ backdropFilter: 'blur(20px)', background: 'rgba(255,255,255,0.7)', borderRadius: 8 }}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Typography variant="h6" style={{ color: '#4300FF' }}>
                10
              </Typography>
              <Typography variant="body2" align="right" style={{ color: '#8053FF' }}>
                <b>total people</b> at {giveWorkforceName(workforce, user)}
              </Typography>
            </Grid>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Typography variant="h6" style={{ color: '#4300FF' }}>
                6
              </Typography>
              <Typography variant="body2" align="right" style={{ color: '#8053FF' }}>
                <b>actively connecting</b>
              </Typography>
            </Grid>
          </Box>
        </Box>

        <Box m={2} />
      </Box>
    </>
  )
};
export default Connections;
