import React from "react";

import {
  Typography,
  IconButton,
  Grid,
  Box,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Divider,
  Dialog,
  DialogContent,
  DialogActions
  } from '@material-ui/core';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import PlacesAutocomplete from 'react-places-autocomplete';
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import ButtonBlack from '../../components/general/buttons/ButtonBlack'
import ButtonText from '../../components/general/buttons/ButtonText'
import ClearChip from '../../components/general/chips/ClearChip'
import YellowChip from '../../components/general/chips/YellowChip'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { imageUpload } from '../../utility/images'
import { capitalizeFirstLetter } from '../../utility/profile'
import { giveToolImage } from '../../utility/user'
import { adjectives, contactSituations, contactMethods, defaultContactPreferences,
        templateFAQs, contactMethodsObj, motivators, levels, collabs } from '../../utility/constants'
import { updateUser } from '../../api/user';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxA: {
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 240,
      display: 'inline',
    },
  },
  boxB: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - 240px)`,
    },
  },
  buttonTab: {
    background: 'transparent',
    textAlign: 'left',
    justifyContent: 'left',
    borderRadius: 12,
    boxShadow: 'none',
    '&:hover': {
      background: '#EFEFF1',
      boxShadow: 'none',
    },
  },
  levelDot: {
    height: 12,
    width: 12,
    borderRadius: 6,
    background: '#4300FF',
    opacity: '100%',
    '&:hover': {
      opacity: '60%',
      cursor: 'pointer'
    },
  },
  locationDropdown: {
    position: 'absolute',
    display: 'inline-block',
    background: '#EFEFF1',
    marginTop: 60,
    width: 200,
    zIndex: 100,
    borderRadius: 12,
  }
}));

export function toggleProfileDetails(parent, isOpen, element) {
  if(isOpen) {
    console.log(1)
    let {user} = parent.state
    parent.setState({
      editProfile: element,
      editProfileFname: user.fname, editProfileLname: user.lname,
      editProfileTitle: user.title, editProfilePronouns: user.pronouns,
      editProfileOneLiner: user.impact,
      editProfileDomains: user.domains ? user.domains : [],
      editProfileDirection: user.purpose_statement,
      editProfileMotivators: user.purposes ? user.purposes : [],
      editProfileTimezone: user.log_settings.timezone.id,
      editProfileAdjectives: user.adjectives ? user.adjectives : [],
      editProfileContactPreferences: user.contact_preferences ? user.contact_preferences : defaultContactPreferences,
      editProfileFaqs: user.faqs ? user.faqs : [],
      editProfileCollabs: user.collabs ? user.collabs : [],
      editProfileWorkforceName: user.workforce_name ? user.workforce_name : '',
      editProfileLocation: user.location && user.location.formatted ? user.location.formatted : ''
    })

    let interestStrings = []
    if(user.interests) {
      user.interests.forEach((interest, i) => {
        interestStrings.push(interest.title)
      });
    }
    parent.setState({editProfileInterests: interestStrings})
  } else {
    console.log(2)
    parent.setState({editProfile: false})
  }
};

function changeUserDetails(parent) {
  let {editProfileFname, editProfileLname, editProfileTitle,
  editProfileOneLiner, editProfileDirection, editProfileDomains,
  editProfileMotivators, editProfilePronouns, editProfileInterests, editProfileAdjectives,
  editProfileContactPreferences, editProfileFaqs, editProfileCollabs, editProfileWorkforceName,
  editProfileLocation} = parent.state
  let user = {}

  if(editProfileFname) {
    user.fname = editProfileFname
  }
  if(editProfileLname) {
    user.lname = editProfileLname
  }
  if(editProfileTitle) {
    user.title = editProfileTitle
  }
  if(editProfilePronouns) {
    user.pronouns = editProfilePronouns
  }
  if(editProfileOneLiner) {
    user.impact = editProfileOneLiner
  }
  if(editProfileDirection) {
    user.purpose_statement = editProfileDirection
  }
  if(editProfileWorkforceName) {
    user.workforce_name = editProfileWorkforceName
  }
  if(editProfileLocation) {
    user.location = {
      formatted: editProfileLocation
    }
  }
  user.domains = editProfileDomains
  user.purposes = editProfileMotivators
  user.adjectives = editProfileAdjectives
  user.contact_preferences = editProfileContactPreferences
  user.faqs = editProfileFaqs
  user.collabs = editProfileCollabs

  let interestObjs = []
  editProfileInterests.forEach((interest, i) => {
    interestObjs.push({
      title: interest
    })
  });
  user.interests = interestObjs
  console.log(user)
  update(parent, user)
}

function update(parent, user) {
  updateUser(parent.state.user._id, parent.state.token, user).then((res) => {
    if (!res.error) {
      parent.setState({ user: res })
      let foundIndex = -1
      parent.state.profiles.forEach((p, i) => {
        if(p._id === res._id) {
          foundIndex = i
        }
      })
      if(foundIndex !== -1) {
        parent.state.profiles.splice(foundIndex, 1, res)
      }
      console.log(foundIndex)
      toggleProfileDetails(parent, false)
    }
  })
}

function removeDomain(parent, index) {
  parent.state.editProfileDomains.splice(index, 1)
  parent.forceUpdate()
}

function addNewDomain(parent, givenVal) {
  let value = givenVal ? givenVal : parent.state.newDomain
  if(value && value.length) {
    parent.state.editProfileDomains.push({
      title: value,
      lead: parent.state.newDomainLead ? true : false
    })
    parent.setState({newDomain: '', newDomainLead: false})
    parent.forceUpdate()
  }
}

function toggleMotivator(parent, motivator) {
  let index = parent.state.editProfileMotivators.indexOf(motivator)
  if(index === -1) {
    parent.state.editProfileMotivators.push(motivator)
  } else {
    parent.state.editProfileMotivators.splice(index, 1)
  }
  parent.forceUpdate()
}

function toggleAddCapability(parent, domainIndex, adding) {
  if(adding) {
    parent.state.addingDomains.push(domainIndex)
    parent.setState({ [`domain${domainIndex}CapLevel`]: 3 })
    parent.forceUpdate()
  } else {
    let foundIndex = parent.state.addingDomains.indexOf(domainIndex)
    if(foundIndex !== -1) {
      parent.state.addingDomains.splice(foundIndex, 1)
      parent.forceUpdate()
    }
  }
}

function addCapability(parent, domainIndex, isOnboarding) {
  if(parent.state[`domain${domainIndex}CapTitle`]) {
    let cap = {
      title: parent.state[`domain${domainIndex}CapTitle`],
      skill_level: parent.state[`domain${domainIndex}CapLevel`],
      tools: parent.state[`domain${domainIndex}Tools`] ? parent.state[`domain${domainIndex}Tools`] : []
    }

    if(parent.state.editProfileDomains[domainIndex].capabilities) {
      parent.state.editProfileDomains[domainIndex].capabilities.push(cap)
    } else {
      parent.state.editProfileDomains[domainIndex].capabilities = [cap]
    }
    parent.setState({ [`domain${domainIndex}CapTitle`]: '', [`domain${domainIndex}CapLevel`]: 3, [`domain${domainIndex}Tools`]: [] })
    toggleAddCapability(parent, domainIndex, false)
    if(isOnboarding) {
      let user = {
        domains: parent.state.editProfileDomains
      }
      update(parent, user)
    }
  }
}

function addNewTool(parent, domainIndex, givenVal) {
  let value = givenVal ? givenVal : parent.state[`domain${domainIndex}NewTool`]
  if(value && value.length) {
    if(parent.state[`domain${domainIndex}Tools`]) {
      parent.state[`domain${domainIndex}Tools`].push(value)
    } else {
      parent.setState({ [`domain${domainIndex}Tools`]: [ value ] })
    }
    parent.setState({ [`domain${domainIndex}NewTool`]: '' })
  }
}

function removeCapability(parent, domainIndex, capabilitiesIndex) {
  let domains = parent.state.editProfileDomains
  let domain = parent.state.editProfileDomains[domainIndex]
  domain.capabilities.splice(capabilitiesIndex, 1)
  domains.splice(domainIndex, 1, domain)
  parent.setState({editProfileDomains: domains})

}

function removeFAQ(parent, index) {
  parent.state[`editProfileFaqs`].splice(index, 1)
  parent.forceUpdate()
}

function toggleInterest(parent, interest) {
  let index = parent.state.editProfileInterests.indexOf(interest)
  if(index === -1) {
    parent.state.editProfileInterests.push(interest)
  } else {
    parent.state.editProfileInterests.splice(index, 1)
  }
  parent.forceUpdate()
}

function toggleAdjective(parent, adjective) {
  let index = parent.state.editProfileAdjectives.indexOf(adjective)
  if(index === -1) {
    if(parent.state.editProfileAdjectives.length < 5) {
      parent.state.editProfileAdjectives.push(adjective)
    }
  } else {
    parent.state.editProfileAdjectives.splice(index, 1)
  }
  parent.forceUpdate()
}

function setContact(parent, situation, method) {
  let prefs = parent.state.editProfileContactPreferences
  prefs[situation.value].name = method
  prefs[situation.value].link = undefined
  parent.setState({ editProfileContactPreferences: prefs })
  parent.forceUpdate()
}

function addQuestion(parent) {
  let q = parent.state.editProfileQuestionText
  let a = parent.state.editProfileQuestionAnswer
  if(q && a) {
    parent.state.editProfileFaqs.push({
      question: q,
      answer: a
    })
    parent.setState({ editProfileQuestionText: undefined, editProfileQuestionAnswer: undefined })
    parent.forceUpdate()
  }
}

function setContactLink(parent, situation, value) {
  let prefs = parent.state.editProfileContactPreferences
  prefs[situation.value].link = value
  parent.setState({ editProfileContactPreferences: prefs })
}

function toggleCollab(parent, collab) {
  let foundIndex = -1
  parent.state.editProfileCollabs.forEach((c, i) => {
    if(c.title === collab.title) {
      foundIndex = i
    }
  });

  if(foundIndex > -1) {
    parent.state.editProfileCollabs.splice(foundIndex, 1)
  } else {
    parent.state.editProfileCollabs.push(collab)
  }
  parent.forceUpdate()
}

function hasCollab(parent, collab) {
  let foundIndex = -1
  parent.state.editProfileCollabs.forEach((c, i) => {
    if(c.title === collab.title) {
      foundIndex = i
    }
  });

  if(foundIndex > -1) {
    return true
  } else {
    return false
  }
}

const handleLocationChange = (parent, address) => {
  parent.setState({ editProfileLocation: address });
};

const handleLocationSelect = (parent, address) => {
  console.log(address)
  parent.setState({ editProfileLocation: address });
};

export function contactInput(parent) {
  let {editProfileContactPreferences} = parent.state
  if(editProfileContactPreferences) {
    return (
      <>
        {contactSituations.map((sit, i) => {
          return (
            <Box my={1} p={2} style={{ background: '#EFEFF1', borderRadius: 12 }}>
              <Grid container direction="row" alignItems="center" justify="space-between">
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" style={{ color: '#141217' }}>{sit.text}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    value={editProfileContactPreferences[sit.value].name}
                    onChange={(event) => console.log(event.target.value)}
                    label="Contact method"
                    fullWidth
                  >
                  {contactMethods.map((method, j) => {
                    return (
                      <MenuItem onClick={(event) => setContact(parent, sit, method.name)}
                      value={method.name}>{method.name}</MenuItem>
                    )
                  })}
                  </Select>
                  {contactMethodsObj[editProfileContactPreferences[sit.value].name] && contactMethodsObj[editProfileContactPreferences[sit.value].name].link ? (
                    <TextField
                      variant="outlined"
                      margin="dense"
                      label={`${editProfileContactPreferences[sit.value].name} link`}
                      placeholder="Optional: allow coworkers to contact you"
                      fullWidth
                      onChange={(event) => setContactLink(parent, sit, event.target.value)}
                      value={editProfileContactPreferences[sit.value].link}
                    />
                  ) : null}
                  {contactMethodsObj[editProfileContactPreferences[sit.value].name] && contactMethodsObj[editProfileContactPreferences[sit.value].name].email ? (
                    <TextField
                      variant="outlined"
                      margin="dense"
                      label={`Your email`}
                      placeholder="Optional: allow coworkers to contact you"
                      fullWidth
                      onChange={(event) => setContactLink(parent, sit, event.target.value)}
                      value={editProfileContactPreferences[sit.value].link}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          )
        })}
      </>
    )
  }
}

export function domainInput(parent, classes) {
  let {resources_domains, editProfileDomains, addingDomains, resources_tools,
      editProfile, newDomain} = parent.state
  let isOnboarding = !editProfile
  let showDomains = editProfile !== 'domains'
  return (
    <>
      {isOnboarding ? null : (
      <Grid container direction="row" alignItems="center" justify="space-between">
        <Box style={{ width: 'calc(100%)', zIndex: 'modal' }}>
          <div onKeyDown={(e) => e.keyCode === 13 ? addNewDomain(parent) : false}>
            <ReactSearchAutocomplete
              items={resources_domains}
              fuseOptions={{ keys: ["text"] }}
              resultStringKeyName="text"
              onSearch={(val) => parent.setState({newDomain: val})}
              onSelect={(val) => addNewDomain(parent, val.text)}
              inputSearchString={newDomain}
              placeholder="Development, Finance, Marketing ..."
              styling={{
                zIndex: 100
              }}
            />
          </div>
          <Box m={0.5}>
            <Typography variant="caption" style={{color: '#726B83' }}>
              <b>Press ↵ Enter</b> or click result to add domain
            </Typography>
          </Box>
        </Box>

      </Grid>
    )}
      <Box py={2}>

        <Typography variant="subtitle2" gutterBottom style={{ color: '#726B83' }}>My domains</Typography>
        {editProfileDomains.length ? (
          editProfileDomains.map((domain, i) => {
            return (
              <Box p={2} mb={1} style={{ background: 'rgba(67,0,255,0.08)', borderRadius: 12 }}>
                <Grid container direction="row" justify="space-between">
                  <Typography variant="subtitle1" style={{ color: '#4300FF' }}>{domain.title}</Typography>
                  <IconButton onClick={() => removeDomain(parent, i)} size="small">
                    <DeleteForeverRoundedIcon fontSize="small" style={{ color: '#726B83' }} />
                  </IconButton>
                </Grid>
                <Box m={1} />

                {showDomains ? (
                  <>
                    {/* Existing capabilities */}
                    {domain.capabilities ? (
                      domain.capabilities.map((cap, j) => {
                        return (
                          <Box my={2} p={2} style={{ background: '#FFF', borderRadius: 12 }}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                              <Typography variant="subtitle1" style={{ color: '#4300FF' }}>
                                {cap.title}
                              </Typography>
                              <Box my={1}>
                                <Grid container direction="row" alignItems="center">
                                  <Typography variant="caption" textAlign="right" style={{ color: '#4300FF'}}>
                                    {levels[cap.skill_level - 1]}
                                  </Typography>
                                  <Box m={1} />
                                  {levels.map((level, j) => {
                                    return (
                                      <>
                                        {cap.skill_level-1 >= j ? (
                                          <Box style={{ opacity: '100%', cursor: 'default' }} m={0.2} className={ classes.levelDot }
                                          />
                                        ) : (
                                          <Box style={{ opacity: '10%', cursor: 'default' }} m={0.2} className={ classes.levelDot }
                                          />
                                        )}
                                      </>
                                    )
                                  })}
                                </Grid>
                              </Box>
                            </Grid>

                            <Grid container direction="row" alignItems="center" justify="space-between">
                              {cap.tools && cap.tools.length ? (
                                <Box>
                                  <Grid container direction="row" alignItems="center">
                                    <Typography variant="caption">with</Typography>
                                    {cap.tools.map((tool, k) => {
                                      return (
                                        <Box m={0.2}>
                                          <ClearChip
                                            icon={giveToolImage(parent, tool)}
                                            label={tool}
                                            deleteIcon={<DeleteForeverRoundedIcon />}
                                          />
                                        </Box>
                                      )
                                    })}
                                  </Grid>
                                </Box>
                              ) : (
                                <Box>
                                </Box>
                              )}
                              <IconButton onClick={() => removeCapability(parent, i, j)} size="small">
                                <DeleteForeverRoundedIcon fontSize="small" style={{ color: '#726B83' }} />
                              </IconButton>
                            </Grid>
                          </Box>
                        )
                      })
                    ) : null}

                    {/* Adding Capabilities */}
                    {addingDomains.includes(i) ? (
                      <Box p={2} style={{ backgroundColor: '#FFF', borderRadius: 12, }}>
                        <Grid>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            multiline
                            autoFocus
                            minRows={2}
                            label={`${domain.title} capability`}
                            placeholder="Mapping out user flows"
                            fullWidth
                            onChange={(event) => parent.setState({ [`domain${i}CapTitle`]: event.target.value })}
                            value={parent.state[`domain${i}CapTitle`]}
                          />

                          <Box m={2} />

                          {/* Skill level */}
                          <Box p={2} style={{ background: 'rgba(67,0,255,0.1)', borderRadius: 8 }}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                              <Typography variant="subtitle2" style={{ color: '#4300FF' }}>
                                Rate your skill level out of 5
                              </Typography>
                              <Box my={1}>
                                <Grid container direction="row" alignItems="center">
                                  <Typography variant="caption" textAlign="right" style={{ color: '#4300FF'}}>
                                    {levels[parent.state[`domain${i}CapLevel`]-1]}
                                  </Typography>
                                  <Box m={1} />
                                  {levels.map((level, j) => {
                                    return (
                                      <>
                                        {parent.state[`domain${i}CapLevel`]-1 >= j ? (
                                          <Box style={{ opacity: '100%' }} m={0.2} className={ classes.levelDot }
                                            onClick={() => parent.setState({ [`domain${i}CapLevel`]: j+1 })}
                                          />
                                        ) : (
                                          <Box style={{ opacity: '10%' }} m={0.2} className={ classes.levelDot }
                                            onClick={() => parent.setState({ [`domain${i}CapLevel`]: j+1 })}
                                          />
                                        )}
                                      </>
                                    )
                                  })}
                                </Grid>
                              </Box>
                            </Grid>
                          </Box>

                          <Box m={2} />

                          {/* Tools */}
                          <Typography variant="subtitle1" gutterBottom style={{ color: '#4300FF' }}>What tools do you use for this capability?</Typography>

                          <Grid container direction="row" alignItems="center" justify="space-between">
                            <Box style={{ width: 'calc(100% - 150px)', zIndex: 'modal' }}>
                              <div onKeyDown={(e) => e.keyCode === 13 ? addNewTool(parent, i) : false}>
                                <ReactSearchAutocomplete
                                  items={resources_tools}
                                  fuseOptions={{ keys: ["text"] }}
                                  resultStringKeyName="text"
                                  onSearch={(val) => parent.setState({ [`domain${i}NewTool`]: val})}
                                  onSelect={(val) => addNewTool(parent, i, val.text)}
                                  inputSearchString={parent.state[`domain${i}NewTool`]}
                                  placeholder="Javascript, Airtable, maybe Excel?"
                                />
                              </div>
                            </Box>
                            <ButtonPurple variant="contained" onClick={() => addNewTool(parent, i)}>Add tool</ButtonPurple>
                          </Grid>
                          {parent.state[`domain${i}Tools`] && parent.state[`domain${i}Tools`].length ? (
                            <>
                              <Typography variant="subtitle2" style={{color: '#726B83'}}>Tools</Typography>
                              {parent.state[`domain${i}Tools`].map((tool, k) => {
                                return (
                                  <>
                                    <Box>
                                      <Box m={0.2}>
                                        <ClearChip
                                          icon={giveToolImage(parent, tool)}
                                          label={tool}
                                          deleteIcon={<DeleteForeverRoundedIcon />}
                                        />
                                      </Box>
                                    </Box>
                                  </>
                                )
                              })}
                            </>
                          ) : null}

                        </Grid>

                        <Box my={3}>
                          <Divider style={{ background: '#EFEFF1', height: 3 }} />
                        </Box>


                        <Grid container direction="row" alignItems="center" justify="flex-end">
                          <ButtonText size="small" onClick={() => toggleAddCapability(parent, i, false)}>Cancel</ButtonText>
                          <Box m={0.2} />
                          <ButtonPurple size="small" onClick={() => addCapability(parent, i, isOnboarding)}>Add ability</ButtonPurple>
                        </Grid>
                      </Box>
                    ) : null}

                    {!addingDomains.includes(i) ? (
                      <ButtonPurple onClick={() => toggleAddCapability(parent, i, true)} startIcon={<AddRoundedIcon />} size="small">
                        Add an ability
                      </ButtonPurple>
                    ) : null}
                  </>
                ) : null}

              </Box>
            )
          })
        ) : (
          <Typography variant="body1" style={{ color: '#726B83' }}>No domains</Typography>
        )}
      </Box>
    </>
  )
}

export function faqInput(parent) {
  let {editProfileQuestionText, editProfileQuestionAnswer, editProfileFaqs} = parent.state
  return (
    <>
      <Typography variant="subtitle1">Choose 1-3 questions that speak to the way you work.</Typography>

      <Box m={2} />


      <Typography variant="subtitle2">Select a prompt or add your own</Typography>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Box style={{ width: 'calc(100% - 150px)' }}>
          <Select
            value={editProfileQuestionText}
            label="FAQs"
            fullWidth
          >
            {templateFAQs.map((question, i) => {
              return (
                <MenuItem onClick={(event) => parent.setState({editProfileQuestionText: question})}
                value={question}>{question}</MenuItem>
              )
            })}
          </Select>
        </Box>
        <ButtonBlack onClick={() => parent.setState({ editProfileQuestionText: '' })}>Add your own</ButtonBlack>
      </Grid>
      {editProfileQuestionText === undefined ? null : (
        <Box my={3} p={3} style={{ background: '#EFEFF1', borderRadius: 12 }}>
          <TextField
            required autoFocus
            variant="outlined"
            margin="dense"
            label="Prompt"
            placeholder="Enter your prompt ..."
            fullWidth
            onChange={(event) => parent.setState({ editProfileQuestionText: event.target.value })}
            value={editProfileQuestionText}
          />
          <Box m={1} />
          <TextField
            required
            variant="outlined"
            margin="dense"
            label="Answer"
            multiline
            minRows={2}
            size="small"
            placeholder="Enter your answer here ..."
            fullWidth
            onChange={(event) => parent.setState({ editProfileQuestionAnswer: event.target.value })}
            value={editProfileQuestionAnswer}
          />
          <Box my={3}>
            <Divider style={{ backgroundColor: '#FFF', height: 3 }} />
          </Box>
          <Grid container direction="row" justify="flex-end">
            <ButtonText onClick={() => parent.setState({editProfileQuestionText: undefined, editProfileQuestionAnswer: undefined})}>Cancel</ButtonText>
            <Box m={0.2} />
            <ButtonPurple onClick={() => addQuestion(parent)}>Add question</ButtonPurple>
          </Grid>
        </Box>
      )}
      {editProfileFaqs.map((faq, i) => {
        return (
          <>
            <Box my={2} p={3} style={{ background: '#EFEFF1', borderRadius: 12, boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.04)' }}>
              <Grid container direction="row" justify="space-between">
                <Typography variant="subtitle1" gutterBottom style={{ color: '#0093C0' }}>
                  {faq.question}
                </Typography>
                <IconButton onClick={() => removeFAQ(parent, i)} size="small">
                  <DeleteForeverRoundedIcon fontSize="small" style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Typography variant="body2" style={{ color: '#726B83' }}>
                {faq.answer}
              </Typography>
            </Box>
          </>
        )
      })}
    </>
  )
}

function UpdateProfile(props) {
  const {parent} = props
  const classes = useStyles()
  let {myProfile, editProfile, editProfileFname, editProfileLname, editProfileTitle,
  editProfileOneLiner, editProfileDirection,
  editProfileMotivators, editProfilePronouns, resources_interests,
  resources_interestCategories, editProfileInterests, editProfileAdjectives,
  editProfileWorkforceName, user, editProfileLocation} = parent.state

  let modalTitle = editProfile
  if(editProfile === 'character') {
    modalTitle = 'attributes'
  }


  if(myProfile) {
    return (
      <Dialog open={true} maxWidth="sm" onClose={() => toggleProfileDetails(parent, false)}>

        <DialogContent style={{ backgroundColor: '#FFF' }}>
          <Typography variant="h4">Edit {modalTitle}</Typography>
          {/* Basics */}
          {editProfile === 'basics' ? (
            <Box py={2}>
              <Typography variant="h5"><i>Identity</i></Typography>
              <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
                A few things to make it easy to identify you
              </Typography>
              <Box m={2} />
              <Grid container direction="row">
                <Box width={120}>
                  {imageUpload(parent, true, false)}
                </Box>
                <Box pl={3} style={{ width: 'calc(100% - 120px)' }}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={6}>

                      {/* First name */}
                      <TextField
                        required
                        variant="outlined"
                        margin="dense"
                        label="First name"
                        fullWidth
                        onChange={(event) => parent.setState({ editProfileFname: event.target.value })}
                        value={editProfileFname}
                      />
                    </Grid>
                    <Grid item xs={6}>

                      {/* Last name */}
                      <TextField
                        required
                        variant="outlined"
                        margin="dense"
                        label="Last name"
                        fullWidth
                        onChange={(event) => parent.setState({ editProfileLname: event.target.value })}
                        value={editProfileLname}
                      />
                    </Grid>
                    <Grid item xs={8}>

                    {/* Location */}
                    <PlacesAutocomplete
                      value={editProfileLocation}
                      onChange={(e) => handleLocationChange(parent, e)}
                      onSelect={(e) => handleLocationSelect(parent, e)}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <Grid container direction="column">
                          <TextField
                            variant="outlined"
                            margin="dense"
                            label="Location"
                            fullWidth
                            {...getInputProps({
                              placeholder: 'The city you primarily work in',
                              className: 'location-search-input',
                            })}
                          />
                          <div className={classes.locationDropdown}>
                            {loading &&
                              <div style={{ backgroundColor: 'transparent', padding: 10, borderRadius: 8, }}>
                                Loading...
                              </div>
                            }
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                  backgroundColor: 'rgba(67,0,255, 0.2)',
                                  color: '#4300FF',
                                  padding: 10,
                                  borderRadius: 8,
                                  cursor: 'pointer'
                                }
                                : {
                                  backgroundColor: 'transparent',
                                  padding: 10,
                                  borderRadius: 8,
                                  cursor: 'pointer'
                                };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </Grid>
                      )}
                    </PlacesAutocomplete>

                    </Grid>
                    <Grid item xs={4}>
                      {/* Pronouns */}
                      <TextField
                        required
                        variant="outlined"
                        margin="dense"
                        label="Pronouns"
                        fullWidth
                        onChange={(event) => parent.setState({ editProfilePronouns: event.target.value })}
                        value={editProfilePronouns}
                      />
                    </Grid>

                    {/* Title */}
                    <TextField
                      required
                      variant="outlined"
                      margin="dense"
                      label="Title"
                      fullWidth
                      onChange={(event) => parent.setState({ editProfileTitle: event.target.value })}
                      value={editProfileTitle}
                    />

                    {/* Workforce name */}
                    {user.independent ? (
                      <TextField
                        required
                        variant="outlined"
                        margin="dense"
                        label="Company name"
                        fullWidth
                        onChange={(event) => parent.setState({ editProfileWorkforceName: event.target.value })}
                        value={editProfileWorkforceName}
                      />
                    ) : null}

                  </Grid>
                </Box>
              </Grid>
            </Box>
          ) : null}

          {/* Contact preferences */}
          {editProfile === 'contact preferences' ? (
            <>
              <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
                Set your preferred means of communication
              </Typography>
              <Box m={2} />
              {contactInput(parent)}
            </>
          ) : null}

          {/* Collabs */}
          {editProfile === 'collabs' ? (
            <>
              <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
                Select the collaborations you are open to
              </Typography>
              <Box m={2} />
              <Grid container direction="row" alignItems="center">
                {collabs.map((collab, i) => {
                  return (
                    <Box onClick={() => toggleCollab(parent, collab)}
                      px={1} py={0.5} mr={0.5} mb={0.5} style={{ borderRadius: 12, cursor: 'pointer',
                        background: hasCollab(parent, collab) ? '#0093C0' : '#EFEFF1',
                        color: hasCollab(parent, collab) ? '#FFF' : '#141217'
                      }}
                    >
                      <Typography variant="subtitle2">
                        {capitalizeFirstLetter(collab.title)}
                      </Typography>
                    </Box>
                  )
                })}
              </Grid>
            </>
          ) : null}

          {/* Domains */}
          {editProfile === 'domains' ? (
            <Box width={500}>
              {domainInput(parent, classes)}
            </Box>
          ) : null}

          {/* Attributes */}
          {editProfile === 'character' ? (
            <>
              <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
                Select up to 5 words to best describe yourself at work
              </Typography>
              <Box m={2} />
              <Grid container direction="row" alignItems="center">
                {adjectives.map((adj, i) => {
                  return (
                    <Box onClick={() => toggleAdjective(parent, adj)}
                      px={1} py={0.5} mr={0.5} mb={0.5} style={{ borderRadius: 12, cursor: 'pointer',
                        background: editProfileAdjectives.includes(adj) ? '#0093C0' : '#EFEFF1',
                        color: editProfileAdjectives.includes(adj) ? '#FFF' : '#141217'
                      }}
                    >
                      <Typography variant="subtitle2">
                        {capitalizeFirstLetter(adj)}
                      </Typography>
                    </Box>
                  )
                })}
              </Grid>
            </>
          ) : null}

          {/* Interests */}
          {editProfile === 'interests' ? (
            <>
              <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
                Select up to 5 interests you have outside of work
              </Typography>
              <Box m={2} />
              {resources_interestCategories.map((category, i) => {
                return (
                  <>
                    <Typography variant="subtitle1" gutterBottom>{category.text}</Typography>
                    <Grid container direction="row" alignItems="center">
                      {resources_interests.map((interest, j) => {
                        if(interest.category === category._id) {
                          return (
                            <Box mb={0.5} mr={0.5}>
                              <YellowChip onClick={() => toggleInterest(parent, interest.text)}
                                style={{ background: editProfileInterests.includes(interest.text) ? 'rgba(255, 217, 0, 0.6)' : '#EFEFF1' }}
                                label={interest.text}
                              />
                            </Box>
                          )
                        } else return null
                      })}
                    </Grid>
                    <Box m={2} />
                  </>
                )
              })}
            </>
          ) : null}

          {/* Domains */}
          {editProfile === 'role' ? (
            <>
              <Typography variant="h5"><i>One-liner</i></Typography>
              <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
                Describe the impact of your work in one sentence
              </Typography>
              <Box m={2} />
              <TextField
                required
                variant="outlined"
                margin="dense"
                label="One-liner"
                multiline
                minRows={2}
                placeholder="Type your one-liner here"
                fullWidth
                onChange={(event) => parent.setState({ editProfileOneLiner: event.target.value })}
                value={editProfileOneLiner}
              />
              {domainInput(parent, classes)}
            </>
          ) : null}

          {/* Purpose */}
          {editProfile === 'purpose' ? (
            <Box width={500}>
              <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
                Define why you're working here
              </Typography>
              <Box m={2} />

              <TextField
                required
                variant="outlined"
                margin="dense"
                multiline
                minRows={2}
                label="Intention"
                placeholder="Type your statement ..."
                fullWidth
                onChange={(event) => parent.setState({ editProfileDirection: event.target.value })}
                value={editProfileDirection}
              />

              <Box m={2} />

              {/* Motivators */}
              <Typography variant="subtitle2" gutterBottom style={{ color: '#726B83' }}>Motivators</Typography>
              <Box p={2} style={{ background: 'rgba(67,0,255,0.1)', borderRadius: 8 }}>
                {motivators.map((motivator, i) => {
                  return (
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Typography variant="body1" style={{ color: '#4300FF' }}>{motivator}</Typography>
                      <Checkbox
                        checked={editProfileMotivators.includes(motivator)}
                        size="small"
                        color="primary"
                        onClick={() => toggleMotivator(parent, motivator)}
                      />
                    </Grid>
                  )
                })}
              </Box>
            </Box>
          ) : null}

          {/* FAQs */}
          {editProfile === 'FAQs' ? faqInput(parent, classes) : null}
        </DialogContent>

        <DialogActions>
          <Box pr={1}>
            <ButtonText onClick={() => toggleProfileDetails(parent, false)} size="small" style={{ color: '#141217' }}>
              Cancel
            </ButtonText>
          </Box>
          <ButtonPurple onClick={() => changeUserDetails(parent)} startIcon={<CheckCircleRoundedIcon fontSize="small" />}>
            Save
          </ButtonPurple>
        </DialogActions>
      </Dialog>
    );
  } else return null
};

export default UpdateProfile;
