import React, { Component } from 'react';
import { Redirect } from "react-router-dom"
import Loading from "../components/general/Loading";
import MainNav from '../components/nav/MainNav'
import SearchResults from './tabs/SearchResults'
import SearchSuggestions from './tabs/SearchSuggestions'
import {
  Box
} from '@material-ui/core';
import Cookies from 'universal-cookie';
import queryString from "query-string"
import { withAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {initMainDash} from '../utility/init'
import {giveTabContent, tabsWithNav} from '../utility/tabs'
import {giveModals} from '../utility/modals'

const giveBody = (parent) => {
  return (
    <>
      {(parent.state.workforce && parent.state.workforce._id) || (parent.state.user && parent.state.isDemo) ? (
        <>
          <Box style={{ height: 120 }} />
          {tabsWithNav.includes(parent.state.tab) ? (
            <>
              <MainNav parent={parent} user={parent.state.user}>
                {parent.state.overlay ? (
                  <SearchResults parent={parent} />
                ) : (
                  <>{parent.state.suggestionOverlay ? (
                    <SearchSuggestions parent={parent} />
                  ) : (
                    <>{giveTabContent(parent)}</>
                  )}</>
                )}
              </MainNav>
            </>
          ) : (
            <>{giveTabContent(parent)}</>
          )}

          {giveModals(parent)}
        </>
      ) : <Loading />}
    </>
  )
}

class App extends Component  {
  constructor(props) {
    super();
    this.state = {
      profiles: [],
      tab: 'profile',
      timeline: [],
      teammateTimeline: [],
      responsesByDay: [],
      emails: [],
      fullLink: {},
      slackUsers: [],
      resources_domains: [],
      resources_tools: [],
      resources_interests: [],
      resources_interestCategories: [],
      addingDomains: [],
      profile: {},
      onboardingCollabs: [],
      onboardingDomains: [],
      onboardingAdjectives: [],
      onboardingInterests: [],
      onboardingMotivators: [],
      editProfileDomains: [],
      editProfileFaqs: [],
      profileProgress: {
        steps_needed: [],
        percent: 0
      },
      workforceOnboardingStep: 1,
      inviteEmails: [],
      needsOnboarding: true,
      wikiSearchResults: [],
      selectedTeams: [],
      fullTeams: [],
      selectedCommunities: [],
      userSlackIds: [],
      userTeams: [],
      mentorMatchGoalsSelected: [],
      workforceDomains: [],
      userDomains: [],
      mentorMatchDomains: [],
      groupDomains: [],
      slackChannels: [],
      publicChannels: [],
      privateChannels: [],
      groupUsers: [],
      groupLeads: [],
      selectedGroup: {},
      editGroupDomains: [],
      editGroupUsers: [],
      editGroupLeads: [],
      editGroupRemovedUsers: [],
      myProfile: {},
      user: {},
      notificationMessages: []
    };
  }

  componentDidMount() {
    initMainDash(this)
  }

  render() {

    if(this.state.refresh) {
      return <Redirect to={`/`} />
    } else if(this.state.settingsSlackRedirect) {
      return <Redirect to={`/settings?slack_access_token=${this.state.settingsSlackRedirect}${this.state.stillNeedSlackId ? '&stillNeedSlackId=true' : ''}`} />
    } else if(this.state.createWorkforce) {
      return <Redirect to={`/newWorkforce`} />
    } else if(this.state.toNewUserFlow) {
      return <Redirect to={`/newUser`} />
    } else if(this.state.toNewUserFlowNoSlack) {
      return <Redirect to={`/newUser?notUsingSlack=true`} />
    } else if(this.state.urlRedirectNewWorkforce) {
      console.log(this.state.urlRedirectNewWorkforce.substring(80))
      return <Redirect to={this.state.urlRedirectNewWorkforce} />
    } else if (this.state.toLaunchSequence) {
      return <Redirect to={`/launch?slackProfile=true`} />
    } else {
      const cookies = new Cookies();
      const values = queryString.parse(this.props.location.search)
      if(cookies.get('demo') || values.d) {
        return giveBody(this)
      } else {
        return withAuth0(withAuthenticationRequired(giveBody(this), {
          onRedirecting: () => <Loading />,
        }))
      }
    }

  }
}

export default App
