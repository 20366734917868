import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { withStyles } from '@material-ui/core/styles';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core'

const MiniButton = withStyles((theme) => ({
  root: {
    color: '#2E00B2',
    elevation: 'none',
    backgroundColor: '#EFEFF1',
    '&:hover': {
      backgroundColor: 'rgba(208,206,214,0.5)',
    },
  },
}))(Button);

function ShareProfile(props) {
  let {parent} = props

  return (
    <>
      <Dialog fullwidth open={true} onClose={() => toggleShare(parent)}>
        <DialogTitle>
        <IconButton onClick={() => toggleShare(parent)}>
          <CloseRoundedIcon />
        </IconButton>
          <Grid container direction="row" justify="space-between">
            <Box>
              <Typography variant="h4">
                Share your profile
              </Typography>
              <Typography variant="body2">
                Privately share it with anyone on your team, within your company, or external shareholders
              </Typography>
            </Box>
            <Box>
            </Box>
          </Grid>
        </DialogTitle>
        <DialogContent>

          {/*<Grid container direction="row" alignItems="center" justify="space-between">
            <Box style={{ width: 'calc(100% - 100px)'}}>
              <TextField
                onChange={(event) => parent.setState({ email: event.target.value, emailError: undefined })}
                value={parent.state.email}
                label="Emails"
                size="small"
                placeholder="Enter emails here..."
                variant="outlined"
                fullWidth
              />
            </Box>
            <ButtonBlack variant="contained" size="small" onClick={() => addEmail(parent)}>Add email</ButtonBlack>
          </Grid>
          {parent.state.emails.length ? (
            <>
              <Box py={2} px={2} my={1} style={{ background: 'rgba(67,0,255,0.06)', borderRadius: 12 }}>
                <Grid container direction="row" justify="space-between">
                  <Typography variant="h6">Emails added</Typography>
                  <Button
                    onClick={() => sendEmails(parent)}
                    startIcon={ <SendRoundedIcon /> }
                    variant="contained"
                    size="small"
                    color="primary"
                  >
                    Send brief
                  </Button>
                </Grid>
                <Box my={0.5}>
                  <Grid container direction="row">
                    {parent.state.emails.map((email, i) => {
                      return (
                        <div key={i}>
                          <Box mb={0.25} mr={0.25}>
                            <ChipLight onDelete={() => removeEmail(parent, email)} label={email} />
                          </Box>
                        </div>
                      )
                    })}
                  </Grid>
                </Box>
              </Box>
            </>
          ) : null}

          {parent.state.sentEmails && !parent.state.emails.length ? (
            <Typography variant="subtitle2" color="primary">Emails sent!</Typography>
          ) : null}
          <Typography variant="subtitle2" color="error">{parent.state.emailError}</Typography>*/}

          <Box py={2} px={2} my={1} style={{ background: '#EFEFF1', borderRadius: 12 }}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Box style={{ width: 'calc(100% - 120px)'}}>
                <Typography variant="h6">Shareable Link</Typography>
                <Typography variant="body2"><b>Warning:</b> anyone that possesses this link will see your profile data</Typography>
              </Box>
              <CopyToClipboard text={`https://app.joinhelm.com/profile/${parent.state.user.fname}-${parent.state.user.lname}/${parent.state.workforce._id}/${parent.state.fullLink.code }`}
                onCopy={() => parent.setState({ copied: true})}>
                <MiniButton disableElevation variant="contained" size="small" startIcon={ <FileCopyRoundedIcon fontSize="small" /> }>{parent.state.copied ? 'Copied!' : 'Copy link'}</MiniButton>
              </CopyToClipboard>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export function toggleShare(parent) {
  parent.setState({sharingProfile: !parent.state.sharingProfile})
}

export default ShareProfile;
