import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: '#6833FF',
    borderRadius: 12,
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#4300FF',
    },
  },
}));

function ButtonPurple(props) {
  const { children, ...passThroughProps } = props
  const classes = useStyles();

  return (
    <Button variant="contained" { ...passThroughProps } className={ classes.root }>
      {children}
    </Button>
  )
}
export default ButtonPurple
