import React from 'react'
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function SquareChip(props) {
  let { children, styleVariant, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: 8,
      height: 26,
      fontSize: 15,
      fontWeight: 700,
      fontFamily: 'lato',
      letterSpacing: 0.3,
      padding: 1
    },
    grey: {
      backgroundColor: '#EFEFF1',
      color: '#141217',
      '&:hover': {
        backgroundColor: '#E6E4EC'
      }
    },
    blue: {
      backgroundColor: 'rgba(0,195,255,0.1)',
      color: '#0093C0',
    },
    purple: {
      backgroundColor: 'rgba(67,0,255,0.1)',
      color: '#4300FF',
      '&:hover': {
        backgroundColor: 'rgba(67,0,255,0.2)'
      },
      '&:focus': {
        backgroundColor: 'rgba(67,0,255,0.2)'
      }
    },
    green: {
      backgroundColor: 'rgba(0,217,174,0.2)',
      color: '#009779',
      '&:hover': {
        backgroundColor: 'rgba(0,217,174,0.4)',
      }
    },
    yellow: {
      backgroundColor: 'rgba(255, 217, 0, 0.4)',
      color: '#8B7600',
      '&:hover': {
        backgroundColor: 'rgba(255, 217, 0, 0.8)'
      }
    }
  }));

  let variant = styleVariant ? styleVariant : 'grey'
  const classes = useStyles();

  return (
    <Chip { ...passThroughProps } className={`${classes.root} ${classes[variant]}`}>
      {children}
    </Chip>
  )
}
export default SquareChip
