export const handleResources = async (parent, resources) => {
  try {
    let queries = ""
    resources.forEach((r, i) => {
      queries += `${i === 0 ? '?' : '&'}${r}=true`
    })

    //TODO
    const response = await fetch(`https://joinhelm.com/api/resources${queries}`, {
    // const response = await fetch(`http://localhost:8080/api/resources${queries}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    });

    const responseData = await response.json();
    if(responseData.ok && responseData.data) {
      resources.forEach((r) => {
        if(responseData.data[r]) {
          parent.setState({ [`resources_${r}`]: responseData.data[r] })
        }
      })

      return responseData.data
    } else {
      return false
    }

  } catch (error) {
    console.log(error)
    return false
  }
};
