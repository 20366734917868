import React from "react";
import { v4 as uuidv4 } from 'uuid';
import {
  Typography,
  Grid,
  Box,
  TextField,
  Container,
  NativeSelect,
  FormControl,
  InputLabel
  } from '@material-ui/core';
import PlacesAutocomplete from 'react-places-autocomplete';
import ButtonBlack from "../../components/general/buttons/ButtonBlack";
import ButtonPurple from "../../components/general/buttons/ButtonPurple";
import ButtonToggle from "../../components/general/buttons/ButtonToggle";
import TeamPreview from '../../components/groups/TeamPreview'
import DropDownInput from '../../components/general/DropDownInput'
import { imageUpload } from '../../utility/images'
import { updateUser } from '../../api/user';
import { sendUserToCustomerIO } from '../../api/customer-io';
import { updateWorkforce } from '../../api/workforce'
import { office_teams } from '../../utility/demo/office-teams'
import { handleTeamsAndCommunities } from '../../utility/init'

import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  locationDropdown: {
    position: 'absolute',
    display: 'inline-block',
    background: '#EFEFF1',
    marginTop: 60,
    width: 200,
    zIndex: 100,
    borderRadius: 12,
  }
}));

function next(parent, step) {
  let {onboardingFname, onboardingLname, onboardingWorkforceName,
    onboardingTitle, selectedTeams, newHire, existingHire, startDate, workforce,
    introChannel, publicChannels
  } = parent.state

  if(step === 1) {
    let hasStartDate = false
    if(newHire) {
      hasStartDate = true
    } else if(existingHire && startDate) {
      hasStartDate = true
    }
    if(!onboardingFname || !onboardingLname || !onboardingWorkforceName || !onboardingTitle) {
      parent.setState({onboardingError: 'Please complete all fields'})
    } else if(!hasStartDate) {
      parent.setState({onboardingError: 'Please provide a previous start date'})
    } else {
      changeUserDetails(parent)
    }
  } else if(step === 2) {
    if(selectedTeams.length) {
      let updatedUser = {
        teams: selectedTeams
      }

      update(parent, updatedUser)
      if( workforce && (!workforce.slack || (!workforce.slack.intro_channel && !workforce.slack.intro_channel_skipped)) ) {
        parent.setState({ onboardingStep: 3, onboardingError: '' })
      } else {
        parent.setState({ refresh: true })
      }
    } else {
      parent.setState({onboardingError: 'Select at least one team'})
    }
  } else if(step === 3) {
    let updatedWorkforce = {
      slack: workforce && workforce.slack ? workforce.slack : {}
    }
    if(introChannel) {
      let channelID = null
      publicChannels.forEach((channel, i) => {
        if(channel.name === introChannel) {
          channelID = channel.id
        }
      });

      if(channelID) {
        updatedWorkforce.slack.intro_channel = channelID
      } else {
        updatedWorkforce.slack.intro_channel_skipped = true
      }

    } else {
      updatedWorkforce.slack.intro_channel_skipped = true
    }

    updateWorkforce(parent.state.workforce._id, parent.state.token, updatedWorkforce, parent.state.user.permission_token)
    .then((res) => {
      console.log(res)
      // parent.setState({ refresh: true })
    })

  }
}

function changeUserDetails(parent) {
  let {onboardingFname, onboardingLname, onboardingLocation, onboardingPronouns,
      onboardingWorkforceName, onboardingTitle, newHire, startDate
      } = parent.state
  let user = {
    log_settings: parent.state.user.log_settings ? parent.state.user.log_settings : {},
    privacy: parent.state.user.privacy ? parent.state.user.privacy : {},
    location: {},
    fname: onboardingFname,
    lname: onboardingLname,
    title: onboardingTitle,
    workforce_name: onboardingWorkforceName,
    start_date: {}
  }
  if(onboardingLocation) {
    user.location.formatted = onboardingLocation
  }
  if(onboardingPronouns) {
    user.pronouns = onboardingPronouns
  }
  if(newHire) {
    user.start_date.new_hire = true
    user.needs_new_hire_intros = true
    user.start_date.full_date = new Date()
  } else {
    user.start_date.full_date = new Date(startDate)
  }

  let customer = parent.state.user
  customer.fname = onboardingFname
  customer.lname = onboardingLname
  console.log(user)
  sendUserToCustomerIO(customer)
  update(parent, user)
  parent.setState({ onboardingStep: 2, onboardingError: '' })
}

function update(parent, user, refresh) {
  updateUser(parent.state.user._id, parent.state.token, user).then((res) => {
    console.log(res)

    if(!res.error) {
      if(refresh) {
        parent.setState({refresh: true})
      } else {
        parent.setState({ user: res })
        let foundIndex = -1
        parent.state.profiles.forEach((p, i) => {
          if(p._id === res._id) {
            foundIndex = i
          }
        })
        if(foundIndex !== -1) {
          parent.state.profiles.splice(foundIndex, 1, res)
        }
        handleTeamsAndCommunities(parent, res)
        parent.forceUpdate()
      }
    }
  })
}

const handleLocationChange = (parent, address) => {
  parent.setState({ onboardingLocation: address });
};

const handleLocationSelect = (parent, address) => {
  console.log(address)
  parent.setState({ onboardingLocation: address });
};

const toggleTeam = (parent, teamId) => {
  let index = parent.state.selectedTeams.indexOf(teamId)
  console.log(index)

  if(index === -1) {
    parent.state.selectedTeams.push(teamId)
  } else {
    parent.state.selectedTeams.splice(index, 1)
  }
  parent.forceUpdate()
}

const userInTeam = (parent, teamId) => {
  let index = parent.state.selectedTeams.indexOf(teamId)

  if(index === -1) {
    return false
  } else {
    return true
  }
}

function addTeam(parent) {
  const teamId = uuidv4()
  let newTeam = {
    team_id: teamId,
    name: parent.state.newTeamName
  }
  let updatedWorkforce = {
    teams: parent.state.workforce.teams ? parent.state.workforce.teams : []
  }

  // ensure no duplicates
  let unique = true
  updatedWorkforce.teams.forEach((team, i) => {
    if(team.name === newTeam.name) {
      unique = false
    }
  });

  if(unique) {
    updatedWorkforce.teams.push(newTeam)
    updateWorkforce(parent.state.workforce._id, parent.state.token, updatedWorkforce, parent.state.user.permission_token)
    .then((res) => {
      parent.setState({ workforce: res, newTeamName: '' })
      parent.state.selectedTeams.push(teamId)

      let fullTeam = newTeam

      // ensure no duplicates
      let unique = true
      parent.state.fullTeams.forEach((team, i) => {
        if(team.name === newTeam.name) {
          unique = false
        }
      });
      if(unique) {
        parent.state.fullTeams.push(fullTeam)
      }

      parent.forceUpdate()
    })
  }
}


function UpdateProfile(props) {
  const {parent} = props
  const classes = useStyles()

  let {myProfile, onboardingFname, onboardingLname,
    onboardingPronouns, onboardingError, onboardingStep, onboardingWorkforceName,
    onboardingTitle, onboardingLocation, newTeamName, fullTeams} = parent.state

  if(myProfile) {
    if(onboardingStep === 1) {
      return (
        <div>
          <Container style={{ maxWidth: 680 }}>
            <Typography variant="h2" gutterBottom>
              First, introduce yourself.
            </Typography>
            <Typography variant="body1" style={{ color: '#726B83' }}>
              This will set up your <b>Workplace ID</b> and help your teammates find you.
            </Typography>
            <Typography variant="body2" color="error">{onboardingError}</Typography>

            <Box m={3} />

            {/* Basics */}
            <Box p={1} style={{ background: 'linear-gradient(95.82deg, #4300FF -6.73%, #00D9AE 86.16%)', borderRadius: 20 }}>
              <Box p={3} style={{ background: '#FFF', borderRadius: 12 }}>
                <Grid container direction="row">
                  <Box width={120}>
                    <Typography variant="subtitle1" gutterBottom style={{ color: '#4300FF' }}>
                      Portrait
                    </Typography>
                    {imageUpload(parent, true, false)}
                  </Box>
                  <Box pl={3} style={{ width: 'calc(100% - 120px)' }}>
                    <Typography variant="subtitle1" gutterBottom style={{ color: '#4300FF' }}>
                      Name
                    </Typography>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={6}>

                        {/* First name */}
                        <TextField
                          required
                          variant="outlined"
                          margin="dense"
                          label="First name"
                          fullWidth
                          onChange={(event) => parent.setState({ onboardingFname: event.target.value })}
                          value={onboardingFname}
                        />
                      </Grid>
                      <Grid item xs={6}>

                        {/* Last name */}
                        <TextField
                          required
                          variant="outlined"
                          margin="dense"
                          label="Last name"
                          fullWidth
                          onChange={(event) => parent.setState({ onboardingLname: event.target.value })}
                          value={onboardingLname}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {/* Pronouns */}
                        <TextField
                          variant="outlined"
                          margin="dense"
                          label="Pronouns"
                          fullWidth
                          onChange={(event) => parent.setState({ onboardingPronouns: event.target.value })}
                          value={onboardingPronouns}
                        />
                      </Grid>
                    </Grid>
                    <Box m={3} />
                    <Typography variant="subtitle1" gutterBottom style={{ color: '#4300FF' }}>
                      Workplace
                    </Typography>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={12}>

                        {/* Location */}
                        <PlacesAutocomplete
                          value={onboardingLocation}
                          onChange={(e) => handleLocationChange(parent, e)}
                          onSelect={(e) => handleLocationSelect(parent, e)}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <Grid container direction="column">
                              <TextField
                                variant="outlined"
                                margin="dense"
                                label="Location"
                                fullWidth
                                {...getInputProps({
                                  placeholder: 'The city you work in',
                                  className: 'location-search-input',
                                })}
                              />
                              <div className={classes.locationDropdown}>
                                {loading &&
                                  <div style={{ backgroundColor: 'transparent', padding: 10, borderRadius: 8, }}>
                                    Loading...
                                  </div>
                                }
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                      backgroundColor: 'rgba(67,0,255, 0.2)',
                                      color: '#4300FF',
                                      padding: 10,
                                      borderRadius: 8,
                                      cursor: 'pointer'
                                    }
                                    : {
                                      backgroundColor: 'transparent',
                                      padding: 10,
                                      borderRadius: 8,
                                      cursor: 'pointer'
                                    };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </Grid>
                          )}
                        </PlacesAutocomplete>
                      </Grid>
                    </Grid>
                    <Box m={3} />
                    <Typography variant="subtitle1" gutterBottom style={{ color: '#4300FF' }}>
                      Position
                    </Typography>

                    {/* Workforce name */}
                    <TextField
                      required
                      variant="outlined"
                      margin="dense"
                      label="Company name"
                      fullWidth
                      onChange={(event) => parent.setState({ onboardingWorkforceName: event.target.value })}
                      value={onboardingWorkforceName}
                    />

                    {/* Job title */}
                    <TextField
                      required
                      variant="outlined"
                      margin="dense"
                      label="Job title"
                      fullWidth
                      onChange={(event) => parent.setState({ onboardingTitle: event.target.value })}
                      value={onboardingTitle}
                    />

                    {/* Start date */}
                    <Box my={3} px={0.5}>
                      <Grid container direction="row" spacing={1} justify="space-between" alignItems="center">
                        <Typography variant="subtitle2" gutterBottom style={{ color: '#726B83' }}>
                          Are you a new hire?
                        </Typography>
                        <Box>
                          <Grid container direction="row" spacing={1}>
                            <ButtonToggle onClick={() => parent.setState({ newHire: true, existingHire: false })}
                              style={{
                                background: parent.state.newHire ? '#4300FF' : '',
                                color: parent.state.newHire ? '#FFF' : ''

                             }}
                            >
                              Yes
                            </ButtonToggle>
                            <Box m={0.5} />
                            <ButtonToggle onClick={() => parent.setState({ existingHire: true, newHire: false })}
                              style={{
                                background: parent.state.existingHire ? '#4300FF' : '',
                                color: parent.state.existingHire ? '#FFF' : ''
                              }}
                            >
                              No
                            </ButtonToggle>
                          </Grid>
                        </Box>
                      </Grid>
                      {parent.state.existingHire ? (
                        <Box py={1}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              variant="inline"
                              openTo="year"
                              fullWidth
                              views={["year", "month"]}
                              label="Start date"
                              helperText="Start from year selection"
                              value={parent.state.startDate}
                              onChange={(val) => parent.setState({ startDate: val })}
                            />
                          </MuiPickersUtilsProvider>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </Grid>
                <Box pt={2}>
                  <Grid container direction="row" justify="flex-end">
                    <ButtonBlack variant="contained" onClick={() => next(parent, 1)} color="primary">
                      Next
                    </ButtonBlack>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box m={3} />

          </Container>
        </div>
      );
    } else if(onboardingStep === 2) {
      return (
        <div>
          <Container style={{ maxWidth: 680 }}>

            {/* Independent profile */}
            {myProfile.independent ? (
              <>
                <Typography variant="h2" gutterBottom>
                  Pick a demo team to see how Helm works.
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>These teams (from The Office) will demonstrate <b>how the people wiki works</b> before you invite your teammates.</Typography>
                <Typography variant="body2" color="error">{onboardingError}</Typography>

                <Box m={3} />

                <Box p={1} style={{ background: 'linear-gradient(95.82deg, #4300FF -6.73%, #00D9AE 86.16%)', borderRadius: 20 }}>
                  <Box p={3} style={{ background: '#FFF', borderRadius: 12 }}>
                    {office_teams.map((team) => {
                      return (
                        <Box my={1}>
                          <TeamPreview parent={parent} team={team} clickAction="add" disableClick={true}
                          onClick={() => toggleTeam(parent, team.team_id)}
                            style={{ background: userInTeam(parent, team.team_id) ? 'rgba(67, 0, 255, 0.1)' : '' }}
                          />
                        </Box>
                      )
                    })}
                    <Box pt={2}>
                      <Grid container direction="row" justify="flex-end">
                        <ButtonBlack onClick={() => next(parent, 2)}>Next</ButtonBlack>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                {/* Workforce profile */}
                <Typography variant="h2" gutterBottom>
                  Pick the team(s) you're on.
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>We'll connect you with your teammates once you create your profile.</Typography>
                <Typography variant="body2" color="error">{onboardingError}</Typography>

                <Box m={3} />

                <Box p={1} style={{ background: 'linear-gradient(95.82deg, #4300FF -6.73%, #00D9AE 86.16%)', borderRadius: 20 }}>
                  <Box p={3} style={{ background: '#FFF', borderRadius: 12 }}>
                    <Grid container direction="row" justify="space-between">
                      <Box style={{ width: 'calc(100% - 100px)' }}>
                        <TextField
                          required
                          variant="outlined"
                          margin="dense"
                          label="Team name"
                          minRows={2}
                          size="small"
                          placeholder="Enter a new team here"
                          fullWidth
                          onChange={(event) => parent.setState({ newTeamName: event.target.value })}
                          value={newTeamName}
                        />
                      </Box>
                      <ButtonPurple onClick={() => addTeam(parent)}>Add team</ButtonPurple>
                    </Grid>
                    {fullTeams.length ? (
                      <>
                        <Typography variant="h4" gutterBottom>
                          Select from existing teams
                        </Typography>
                        {fullTeams.map((team) => {
                          console.log('team')
                          console.log(team)
                          return (
                            <Box my={1}>
                              <TeamPreview parent={parent} team={team} clickAction="add" disableClick={true}
                                onClick={() => toggleTeam(parent, team.team_id)}
                                style={{ background: userInTeam(parent, team.team_id) ? 'rgba(67, 0, 255, 0.1)' : '' }}
                              />
                            </Box>
                          )
                        })}
                      </>
                    ) : (
                      <Typography variant="h3" gutterBottom>
                        No teams, create a team
                      </Typography>
                    )}
                    <Box pt={2}>
                      <Grid container direction="row" justify="flex-end">
                        <ButtonBlack onClick={() => next(parent, 2)}>Next</ButtonBlack>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </>

            )}


          </Container>
        </div>
      );
    } else if(onboardingStep === 3) {
      return (
        <div>
          <Container style={{ maxWidth: 680 }}>
            <Typography variant="h2" gutterBottom>
              Select which channel we should send new hire intros to
            </Typography>
            <Typography variant="body1" style={{ color: '#726B83' }}>
              We recommend a channel such as #general where all their coworkers can view their intro
            </Typography>

            <Box style={{ width: '100%' }}>
              <Typography variant="subtitle1" style={{ fontWeight: 900 }} gutterBottom>
                Connect a Slack channel
              </Typography>

              <FormControl fullWidth>
                <InputLabel htmlFor="select-Slack-channel">Channel</InputLabel>
                <NativeSelect
                  id="select-Slack-channel"
                  value={parent.state.introChannel ? parent.state.introChannel : 'None'}
                  onChange={(e) => parent.setState({ introChannel: e.target.value })}
                  input={<DropDownInput />}
                >
                  <option aria-label="None" value="None">-</option>
                  {parent.state.publicChannels.map((channel, i) => {
                    return <option key={i} value={channel.name}>#{channel.name}</option>
                  })}
                </NativeSelect>
              </FormControl>

              <Box pt={2}>
                <Grid container direction="row" justify="flex-end">
                  <ButtonBlack variant="contained" onClick={() => next(parent, 3)} color="primary">
                    Next
                  </ButtonBlack>
                </Grid>
              </Box>

            </Box>
          </Container>
        </div>
      )
    }
  } else return null
};

export default UpdateProfile;
