import React, {Component} from 'react';
import Loading from "../components/general/Loading";
import { Redirect } from "react-router-dom"
import { withAuth0, withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { getWorkforces, getUsers } from "../api/admin";
import { getByAuth0 } from '../api/user';
import { giveShortDate } from '../utility/time';
import {handleResources} from '../api/resources'
import {AppBar} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';

const HELM_WORKFORCE = '5faec6d1937019bc4226f3ed'

const giveUserInfo = (workforce) => {
  let users = []
  workforce.profiles.forEach((user) => {
    users.push(user)
  })
  return users
}

function WorkforceRow(props) {
  const [open, setOpen] = React.useState(false);
  const {workforce} = props

  let numInviteLinksPending = 0
  if(workforce.invite_links && workforce.invite_links) {
    let userSlackIds = []
    workforce.profiles.forEach((wp) => {
      if(wp.slack_id) {
        userSlackIds.push(wp.slack_id)
      }
    })

    workforce.invite_links.forEach((invite) => {
      if(invite.user_slack_id) {
        if(!userSlackIds.includes(invite.user_slack_id)) {
          numInviteLinksPending++
        }
      }
    })
  }

  let workforceUsers = giveUserInfo(workforce)

  return (
    <React.Fragment>
      <TableRow style={{backgroundColor: open ? '#b5d1ff' : ''}}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {workforce._id === '5faec6d1937019bc4226f3ed' ? '*****' : ''}{workforce.name}
          <span style={{color: 'gray'}}> ({workforce._id})</span>
        </TableCell>
        <TableCell align="right">{giveShortDate(new Date(workforce.created))}</TableCell>
        <TableCell align="right">{workforce.users ? workforce.users.length : 0}</TableCell>
        <TableCell align="right">{numInviteLinksPending}</TableCell>
        <TableCell align="right">{workforce.teams ? workforce.teams.length : 0}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} style={{backgroundColor: '#d1e2ff'}} timeout="auto" unmountOnExit>
            <Box margin={1}>

              <Typography variant="h6" gutterBottom component="div">
                Users
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workforce.profiles ? (
                    workforceUsers.map((user) => (
                      <TableRow key={user._id}>
                        <TableCell>
                          {user.fname} {user.lname}
                        </TableCell>
                        <TableCell>
                          {user.email}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : null}
                </TableBody>
              </Table>

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function UserRow(props) {
  const [open] = React.useState(false);
  const {user} = props

  return (
    <React.Fragment>
      <TableRow style={{backgroundColor: open ? '#b5d1ff' : ''}}>
        <TableCell component="th" scope="row">
          {user.fname} {user.lname}
          <span style={{color: 'gray'}}> ({user._id})</span>
        </TableCell>
        <TableCell align="right">{giveShortDate(new Date(user.created))}</TableCell>
        <TableCell align="right">{user.email}</TableCell>
        <TableCell align="right">{user.independent ? 'True' : 'False'}</TableCell>
        <TableCell align="right">Coming soon!</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

class AdminDash extends Component {

  constructor() {
    super()
    this.state = {
      workforces: [],
      workforceUsers: [],
      workforceCheckpoints: [],
      workforceRounds: [],
      inactiveCheckpoints: [],
      numWorkforces: 20,
      numUsers: 20,
      tab: 'users',
      users: []
    }
  }

  componentDidMount() {
    const { match } = this.props
    let tool = match.params.tool
    if(tool) {
      this.setState({tool: tool})
    }

    const { user, getAccessTokenSilently } = this.props.auth0;

    getAccessTokenSilently({ audience: 'https://joinhelm.com/api' }).then(token => {
      getByAuth0(user.sub, token).then(my_user => {
        handleResources(this, ['storylinesRef', 'storylineQuestions'])

        // if no user, logout
        if(!my_user || typeof my_user !== 'object') {
          //logout
          const { logout } = useAuth0()
          logout({ returnTo: 'https://joinhelm.com' })
        } else {

          // only allow helm users in
          if(my_user.workforce._id === HELM_WORKFORCE) {
            this.setState({user: my_user, token: token, valid: true})
          } else {
            this.setState({valid: false})
          }

          getWorkforces(this.state.token, process.env.REACT_APP_HELM_SUPERADMIN_TOKEN)
          .then((workforces) => {
            workforces.sort((a,b) => {
              let aDate = new Date(a.created)
              let bDate = new Date(b.created)
              if(aDate.getTime() > bDate.getTime()) {
                return -1
              } else return 1
            })
            let filtered = []
            let ourNames = ['Helm', 'Novo']
            workforces.forEach((workforce, i) => {
              if(!ourNames.includes(workforce.name)) {
                filtered.push(workforce)
              }
            });

            this.setState({workforces: filtered})
          })

          getUsers(this.state.token, process.env.REACT_APP_HELM_SUPERADMIN_TOKEN)
          .then((users) => {
            users.sort((a,b) => {
              let aDate = new Date(a.created)
              let bDate = new Date(b.created)
              if(aDate.getTime() > bDate.getTime()) {
                return -1
              } else return 1
            })
            let filtered = []
            users.forEach((user, i) => {
              if(user.email.slice(-13) !== '@joinhelm.com') {
                filtered.push(user)
              }
            });

            this.setState({ users: filtered })
          })
        }

      })
    })
  }


  render() {

    if(this.state.valid === false) {
      return <Redirect to='/' />
    } else {

      return (
        <>
          {this.state.user && this.state.valid ? (
            <>
              <AppBar position="static">
                <Toolbar>
                  <Typography variant="h6">
                    Admin dash v2
                  </Typography>
                </Toolbar>
              </AppBar>

              <div style={{width: '80%', margin: 'auto', marginTop: 50}}>
                <button onClick={() => this.setState({tab: 'users'})}
                style={{border: this.state.tab === 'users' ? '2px solid black' : '2px solid transparent'}}
                >View users</button>
                <button onClick={() => this.setState({tab: 'workforces'})}
                style={{border: this.state.tab === 'workforces' ? '2px solid black' : '2px solid transparent'}}
                >View workforces</button>

                {this.state.tab === 'users' ? (
                  <>
                    <Typography variant="h5">
                      Users
                    </Typography>
                    <Typography variant="h5">
                      # users: {this.state.users.length}
                    </Typography>
                    <p>Filtering out users with @joinhelm.com emails</p>

                    <Box margin={2}></Box>

                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              User
                              <span style={{color: 'gray'}}> (user id)</span>
                            </TableCell>
                            <TableCell align="right">Joined</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Is independent?</TableCell>
                            <TableCell align="right">Profile progress</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.users.map((user, i) => (
                            <>
                              {this.state.numUsers > i ? (
                                <UserRow key={user._id} user={user} />
                              ) : null}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <button onClick={() => this.setState({numUsers: this.state.numUsers+20})}>20 more users</button>
                  </>
                ) : null}

                {this.state.tab === 'workforces' ? (
                  <>
                    <Typography variant="h5">
                      Workforces
                    </Typography>
                    <Typography variant="h5">
                      # workforces: {this.state.workforces.length}
                    </Typography>

                    <Box margin={2}></Box>

                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>
                              Workforce
                              <span style={{color: 'gray'}}> (workforce id)</span>
                            </TableCell>
                            <TableCell align="right">Joined</TableCell>
                            <TableCell align="right"># users</TableCell>
                            <TableCell align="right"># invites outstanding</TableCell>
                            <TableCell align="right"># teams</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.workforces.map((workforce, i) => (
                            <>
                              {this.state.numWorkforces > i ? (
                                <WorkforceRow key={workforce._id} workforce={workforce} />
                              ) : null}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <button onClick={() => this.setState({numWorkforces: this.state.numWorkforces+20})}>20 more workforces</button>
                  </>
                ) : null}
              </div>

            </>
          ) : <Loading />}
        </>
      )

    }
  }
}
export default withAuth0(withAuthenticationRequired(AdminDash, {
  onRedirecting: () => <Loading />,
}));
