import React, { Component } from 'react';
import Loading from "../components/general/Loading";
import { getByAuth0, updateUser } from '../api/user';
import { withAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import queryString from "query-string"
import Cookies from 'universal-cookie';
import {PERSONAL_SLACK_LINK} from '../api/slack'
import { Redirect } from "react-router-dom"

const cookies = new Cookies();

class MemberOnboard extends Component  {
  constructor(props) {
    super();
    this.state = {
    };
  }

  componentDidMount() {
    const { user, getAccessTokenSilently } = this.props.auth0;
    const values = queryString.parse(this.props.location.search)
    getAccessTokenSilently({ audience: 'https://joinhelm.com/api' }).then(token => {
      getByAuth0(user.sub, token).then(my_user => {

        if(!values.notUsingSlack) {
          let cookieSlackId = cookies.get('user_slack_id')
          if(!my_user.slack_id && (!cookieSlackId || cookieSlackId === 'undefined')) {
            window.location.href = PERSONAL_SLACK_LINK
          }
        }

        // check for cookies
        let updatedUser = {
          pictures: {},
          site_logging: {}
        }
        if(my_user.site_logging) {
          updatedUser.site_logging = my_user.site_logging
        }
        if(my_user.pictures) {
          updatedUser.pictures = my_user.pictures
        }
        updatedUser.site_logging.been_in_user_flow = true
        updatedUser.site_logging.met_mo = true
        if(!values.notUsingSlack) {
          updatedUser.needs_mo_intro = true
        }

        if(!my_user.fname) {
          let fname = cookies.get('fname')
          if(fname) {
            updatedUser.fname = fname
          }
        }
        if(!my_user.lname) {
          let lname = cookies.get('lname')
          if(lname) {
            updatedUser.lname = lname
          }
        }

        let title = cookies.get('title')
        if(title) {
          updatedUser.title = title
        }

        let image_small_url = cookies.get('image_small_url')
        if(image_small_url) {
          updatedUser.pictures.image_small_url = image_small_url
        }

        let image_large_url = cookies.get('image_large_url')
        if(image_large_url) {
          updatedUser.pictures.image_large_url = image_large_url
        }

        let user_slack_id = cookies.get('user_slack_id')
        if(user_slack_id && user_slack_id !== 'undefined') {
          updatedUser.slack_id = user_slack_id
        }

        console.log(updatedUser)
        updateUser(my_user._id, token, updatedUser).then((resUser) => {
          if(resUser && !resUser.error) {
            cookies.remove('fname')
            cookies.remove('lname')
            cookies.remove('title')
            cookies.remove('image_small_url')
            cookies.remove('image_large_url')
            cookies.remove('user_slack_id')
          }
          if(values.notUsingSlack) {
            this.setState({toApp: true})
          } else {
            this.setState({toAppModal: 'MessagedInSlack'})
          }
        })

      })
    })
  }

  render() {
    const { user } = this.props.auth0

    if (!user) {
      return <Loading/>
    }

    if(this.state.toAppModal) {
      return <Redirect to={`/?modal=${this.state.toAppModal}`} />
    } else if(this.state.toApp) {
      return <Redirect to={`/`} />
    }

    return (
      <Loading />
    );
  }
}

export default withAuth0(withAuthenticationRequired(MemberOnboard, {
  onRedirecting: () => <Loading />,
}));
