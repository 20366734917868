import React from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import peopleSearch from '../../assets/people-search-preview.png'

function PeopleSearchPrompt(props) {
  let {parent} = props

  const end = () => {
    parent.setState({ tourPeopleSearch_4: false, tourEnded: true })
  }
  const giveDialog = () => {
    return (
      <>
        <DialogTitle style={{ background: '#FFF' }}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="overline" style={{ color: '#726B83' }}>
              WIKI TOUR
            </Typography>
            <IconButton size="small" onClick={() => end()}>
              <CloseRoundedIcon style={{ color: '#726B83' }} />
            </IconButton>
          </Grid>
          <Box>
            <Typography variant="h4" gutterBottom>
              Answers all your people questions.<br/>
              <span style={{ color: '#4300FF' }}>Stay connected <i>from anywhere.</i></span>
            </Typography>
            <Typography variant="body1" style={{ color: '#726B83' }}>
              <b>Find who you need, wherever they are.</b> Helm's people search uses the rich data in profiles (+ some wiki knowlege) to show you exactly what you're looking for with a simple search.
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent style={{ background: '#FFF' }}>
          <Box p={2} mb={1.5} style={{ background: '#4300FF', color: '#FFF',  borderRadius: 12 }}>
            <Typography variant="h6" gutterBottom>
              People Search
            </Typography>
            <Typography variant="body2" style={{ color: 'rgba(255,255,255,0.7)' }}>
              <b>Plus, it lives in Slack.</b> Every question answered is a connection made.
            </Typography>
            <Box px={8} pt={3}>
              <img src={peopleSearch} alt="" style={{ width: '100%' }} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Box pl={2}>
              <Typography variant="caption" style={{ color: '#726B83' }}>
                Step 4 of 4
              </Typography>
            </Box>
            <ButtonPurple onClick={() => end()}>
              Explore
            </ButtonPurple>
          </Grid>
        </DialogActions>
      </>
    )
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={true} onClose={() => end()}>
        {giveDialog()}
      </Dialog>
    </>
  )
}

export default PeopleSearchPrompt;
