import React from 'react'
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#000',
    backgroundColor: 'rgba(0,0,0, 0.06)',
    fontWeight: '600',
  },
}));

function ButtonGrey(props) {
  const { children, ...passThroughProps } = props
  const classes = useStyles();

  return (
    <Chip { ...passThroughProps } className={ classes.root }>
      {children}
    </Chip>
  )
}
export default ButtonGrey
