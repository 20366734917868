import React from 'react'
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function YellowChip(props) {
  let { children, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'rgba(255, 217, 0, 0.4)',
      color: '#8B7600',
      height: 28,
      fontWeight: 500,
      padding: '0 5px 0 5px'
    }
  }));

  const classes = useStyles();

  return (
    <Chip { ...passThroughProps } className={ classes.root }>
      {children}
    </Chip>
  )
}
export default YellowChip
