import React from "react";
import Fuse from 'fuse.js'
import {
  Box
} from '@material-ui/core';
import Loading from "../../components/general/Loading";
import ProfileSearchResult from '../../components/profile/ProfileSearchResult'

export const handleSearch = (parent, val, profiles) => {
  if(val && val.length) {
    parent.setState({wikiSearchText: val, overlay: 'search', suggestionOverlay: false})

    const options = {
      includeScore: true,
      includeMatches: true,
      threshold: 0.2,
      keys: [
        {name: 'full_name', weight: 2},
        {name: 'title', weight: 2},
        {name: 'domains.title', weight: 2},
        'collabs.title', 'domains.capabilities.title',
      'domains.capabilities.tools', 'faqs.answer', 'interests.title',
      'purpose_statement', 'impact', 'community_names'],
      ignoreLocation: true
    }
    const fuse = new Fuse(profiles, options)
    const results = fuse.search(val)
    console.log('results')
    console.log(results)

    if(results && results.length) {
      parent.setState({ wikiSearchResults: results, noSearchResults: false })
    } else {
      parent.setState({ wikiSearchResults: [], noSearchResults: true })
    }

  } else {
    parent.setState({wikiSearchText: '', overlay: '', noSearchResults: false, suggestionOverlay: true})
  }
}

const giveSearchResults = (parent, results) => {
  return (
    <>
      {results.map((result, i) => {
        return (
          <div key={i}>
            <ProfileSearchResult parent={parent} user={result.item} result={result} />
            <Box my={0.5}/>
          </div>
        )
      })}
    </>
  )
}

function SearchResults(props) {
  const {parent} = props
  let {wikiSearchResults, noSearchResults} = parent.state

  return (
    <>
      {noSearchResults ? (
        <p>No results</p>
      ) : (
        <>
          {wikiSearchResults.length ? (
            <>
              {giveSearchResults(parent, wikiSearchResults)}
            </>
          ) : (
            <Loading />
          )}
        </>
      )}
    </>
  )
};

export default SearchResults;
