import React from 'react'
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function BlueSquareChip(props) {
  let { children, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'rgba(0,195,255,0.1)',
      color: '#0093C0',
      borderRadius: 8,
      height: 26,
      fontSize: 15,
      fontWeight: 700,
      fontFamily: 'lato',
      letterSpacing: 0.3,
      padding: 1
    }
  }));

  const classes = useStyles();

  return (
    <Chip { ...passThroughProps } className={ classes.root }>
      {children}
    </Chip>
  )
}
export default BlueSquareChip
