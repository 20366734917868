import React from 'react'
import {
  Box,
  Grid,
  Typography,
  Chip,
  ButtonBase
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExtraSmallAvatar from '../../components/general/avatars/ExtraSmallAvatar'
import PurpleSquareChip from '../../components/general/chips/PurpleSquareChip'
import { AvatarGroup } from '@material-ui/lab'
// import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {imageSrc} from '../../utility/images'
// import {startEditGroup} from '../modals/EditGroup'

export function clickIntoGroup(parent, group, id) {
  let idVar = group.team_id ? 'teamId' : 'communityId'
  let clearVar = group.team_id ? 'communityId' : 'teamId'
  parent.setState({ tab: 'group', [idVar]: id, selectedGroup: group, [clearVar]: undefined })
}
//
// function editGroupButton(parent, group, type, id, classes) {
//   return (
//     <div>
//       <Button
//         className={parent.state[`show${id}`] ? classes.editButtonShow : classes.editButtonHide}
//         onClick={() => startEditGroup(parent, group, type)}
//         startIcon={<EditRoundedIcon style={{ fontSize: 12 }} />}
//       >
//         Edit
//       </Button>
//     </div>
//   )
// }

function toggleEditButton(parent, teamID, value) {
  parent.setState({ [`show${teamID}`] : value })
}


// Main function
function GroupElement(props) {
  const { parent, group, type, ...passThroughProps } = props
  const id = group.team_id ? group.team_id : group.community_id
  let leader = false
  if(group.leads && group.leads.length) {
    group.users.forEach((user, i) => {
      if(!leader && group.leads.includes(user._id)) {
        leader = user
      }
    });
  }

  // Custom styling
  const useStyles = makeStyles((theme) => ({
    root: {
      background: '#FFF',
      color: '#4300FF',
      borderRadius: 12,
      width: '100%',
      '&:hover': {
        background: 'rgba(255,255,255,0.6)',
        cursor: 'pointer'
      },
    },
    avatar: {
      border: 'none',
      maxHeight: 22,
      maxWidth: 22,
      fontSize: 12,
      color: '#FFF',
      background: '#141217'
    },
    editButtonHide: {
      visibility: 'hidden',
      height: 22,
    },
    editButtonShow: {
      visibility: 'visible',
      height: 22,
      color: '#141217',
      backgroundColor: '#FFF',
      border: '1px solid #E6E4EC',
      padding: '0 10px 0 10px',
      borderRadius: 12,
      fontSize: 12,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: '#EFEFF1',
      },
    },
    teamButton: {
      padding: '10px 15px 10px 15px',
      width: '100%',
      color: '#726B83',
      backgroundColor: '#EFEFF1',
      borderRadius: 12,
      textAlign: 'left',
      '&:hover': {
        backgroundColor: '#E6E4EC',
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box py={2} px={2} className={classes.root} { ...passThroughProps }
      onMouseEnter={() => toggleEditButton(parent, id, true)}
      onMouseLeave={() => toggleEditButton(parent, id, false)}
      onClick={() => clickIntoGroup(parent, group, id)}
    >
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            {group.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container direction="row" justify="flex-end">
            {/* BRAD: put the lead in the chip */}
            {leader ? (
              <>
                <Chip
                  avatar={<ExtraSmallAvatar src={imageSrc(leader)} />}
                  label={leader.fname}
                  style={{
                    background: '#D0CED6',
                    color: '#141217',
                    height: 22,
                    fontWeight: 700,
                    fontFamily: 'lato'
                  }}
                />
                <Box m={1} />
              </>
            ) : null}
            {group.users && (group.users.length > 1 || (!leader && group.users.length)) ? (
              <AvatarGroup spacing={-1} style={{ height: 22 }}
                classes={{ avatar: classes.avatar }}
              >
                {group.users.map((user) => {
                  if(!leader || user._id !== leader._id) {
                    return (
                      <ExtraSmallAvatar src={imageSrc(user)} />
                    )
                  } else return null
                })}
              </AvatarGroup>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Box m={2} />
      {group.domains ? (
        <Box>
          <Grid container direction="row">
            {group.domains.map((domain) => {
              return (
                <Box mr={0.5} my={0.5}>
                  <PurpleSquareChip label={domain} />
                </Box>
              )
            })}
          </Grid>
        </Box>
      ) : null}

      {group.team_id ? (
        <>
          <Box m={2} />
          {parent.state.fullTeams.map((fullTeam) => {
            if(fullTeam.parent === group.team_id) {
              return (
                <Box mb={0.5}>
                  <ButtonBase className={classes.teamButton}>
                    <Grid container direction="row" justify="space-between">
                      <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
                        {fullTeam.name}
                      </Typography>
                    </Grid>
                  </ButtonBase>
                </Box>
              )
            } else return null
          })}
        </>
      ) : null}
    </Box>
  )
}
export default GroupElement
