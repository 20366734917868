import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// boxShadow:'0 0 5px 0 rgba(29, 27, 35, .20)'

// B7FFF1

// Create a theme instance.
const theme = responsiveFontSizes(createMuiTheme({
  palette: {
    primary: {
      light: '#6833FF',
      main: '#4300FF',
      dark: '#2E00B2',
    },
    secondary: {
      light: '#00FFCC',
      main: '#00D9AE',
      dark: '#009779',
    },
    error: {
      light: '#EE4373',
      main: '#EA1551',
      dark: '#A30E38',
    },
    warning: {
      light: '#FFBB33',
      main: '#FFAA00',
      dark: '#B27600',
    },
    success: {
      light: '#6FE34B',
      main: '#4CDC1E',
      dark: '#359A15',
    },
    white: {
      main: '#FFFFFF'
    },
    black: {
      main: '#000000'
    },
    faded: {
      main: 'rgba(208,206,214, 0.4)'
    },
    type: 'light',
    contrastThreshold: 3,
    tonalOffset: 0.2,
    grey: {
      50: '#EFEFF1',
      100: '#D0CED6',
      200: '#B1ADBA',
      300: '#918C9E',
      400: '#726B83',
      500: '#585269',
      600: '#454052',
      700: '#312E3B',
      800: '#1D1B23',
      900: '#0A090C',
      A100: '#DDDBE2',
      A200: '#C0BDC8',
      A400: '#27242F',
      A700: '#4E495E',
    },

    // Remember to change with light and dark theme
    background: {
      default: '#FFFFFF',
      paper: '#EFEFF1',
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      smd: 820,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  typography: {
    h1: {
      fontFamily: [
        'Vollkorn'
      ].join(','),
      fontWeight: 800,
      lineHeight: 1.1,
      letterSpacing: -1.5,
      fontSize: 68
    },
    h2: {
      fontFamily: [
        'Vollkorn'
      ].join(','),
      fontWeight: 800,
      lineHeight: 1.1,
      letterSpacing: -1,
      fontSize: 54
    },
    h3: {
      fontFamily: [
        'Lato'
      ].join(','),
      fontWeight: 900,
      lineHeight: 1.2,
      letterSpacing: -.75,
      fontSize: 44
    },
    h4: {
      fontFamily: [
        'Lato'
      ].join(','),
      fontWeight: 900,
      lineHeight: 1.2,
      letterSpacing: -.75,
      fontSize: 32
    },
    h5: {
      fontFamily: [
        'Lato'
      ].join(','),
      fontWeight: 900,
      lineHeight: 1.2,
      letterSpacing: -.5,
      fontSize: 26
    },
    h6: {
      fontFamily: [
        'Lato'
      ].join(','),
      fontWeight: 900,
      lineHeight: 1.2,
      letterSpacing: -.5,
      fontSize: 22,
    },
    subtitle1: {
      fontFamily: [
        'Lato',
      ].join(','),
      fontWeight: 700,
      fontSize: 19,
      lineHeight: 1.3,
    },
    subtitle2: {
      fontFamily: [
        'Lato',
      ].join(','),
      fontWeight: 700,
      fontSize: 15,
      lineHeight: 1.3,
    },
    body1: {
      fontFamily: [
        'Lato',
      ].join(','),
      fontWeight: 500,
      lineHeight: 1.3,
      fontSize: 19,
    },
    body2: {
      fontFamily: [
        'Lato',
      ].join(','),
      fontWeight: 500,
      lineHeight: 1.3,
      fontSize: 15,
    },
    caption: {
      fontFamily: [
        'Lato',
      ].join(','),
      fontWeight: 500,
      lineHeight: 1.1,
      fontSize: 12,
      letterSpacing: 0.3
    },
    button: {
      fontFamily: [
        'Lato',
      ].join(','),
      fontWeight: 600,
      fontSize: 15,
      textTransform: 'none'
    },
    overline: {
      fontFamily: [
        'Lato',
      ].join(','),
      fontWeight: 900,
      letterSpacing: 1.5,
      fontSize: 12,
      lineHeight: 1.2
    },
  },

  shape: {
    borderRadius: 20
  }
}));

export default theme;
