import {roundDownDate, giveContextDate} from './time'

export function giveResponsesByDay(responses) {
  let parsed = {} // store score by date
  if(responses && responses.length) {
    responses.forEach((r) => {
      if(r.answers && r.answers.length && r.updated) {
        let date = roundDownDate(new Date(r.updated))
        parsed[date] ? parsed[date].push(r) : parsed[date] = [r]
      }
    })
  }

  let ordered = []
  Object.keys(parsed).forEach((date) => {
    let day = {
      responses: parsed[date],
      dayScore: giveDayScore(parsed[date]),
      updated: new Date(date)
    }
    ordered.push(day)
  })

  return ordered
}

function giveDayScore(responseArray) {
  let sum = 0
  let count = 0

  if(responseArray && responseArray.length) {
    responseArray.forEach((r) => {
      if(r.answers) {
        r.answers.forEach((answer) => {
          if(answer.question_id === 'q_0' && (answer.value || answer.value === 0)) {
            sum += answer.value
            count++
          }
        })
      }
    })
  }

  let adjustedSum = sum * 10;
  let score = count ? round(adjustedSum/count, 0) : 0
  return score
}

export function round(value, decimals, giveString) {
  let num = Number(Math.round(value+'e'+decimals)+'e-'+decimals);
  // If there is no decimal, or the decimal is less than 2 digits, toFixed
  if(giveString) {
    if (String(num).split(".").length < 1 || String(num).split(".")[0].length<=1 ){
      num = num.toFixed(1);
    }
  }
  return parseInt(num)
}

export function giveRecentScores(responseDays, index) {
  let stats = {
    current: 0,
    change: 0,
    ups: [],
    downs: [],
    updated: '...'
  }
  if(responseDays && responseDays.length) {
    stats.current = responseDays[index].dayScore
    stats.updated = giveContextDate(responseDays[index].updated)
    if(index) { //if not the first element
      stats.change = responseDays[index].dayScore - responseDays[index-1].dayScore
    }
  }

  return stats
}
