import React from 'react'
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function ExtraSmallAvatar(props) {
  let { children, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      border: '2px solid #141217',
      height: 22,
      width: 22,
    },
  }));

  const classes = useStyles();

  return (
    <Avatar { ...passThroughProps } className={ classes.root }>
      {children}
    </Avatar>
  )
}
export default ExtraSmallAvatar
