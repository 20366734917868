import { v4 as uuidv4 } from 'uuid';
const axios = require('axios');
const base64 = require('base-64');

export function sendUserToCustomerIO(user) {
  console.log(user)
  return axios.put(`https://track.customer.io/api/v1/customers/${user._id}`, {
    id: user._id,
    email: user.email,
    created_at: Math.floor(new Date().getTime() / 1000), // js date to unix time stamp
    first_name: user.fname,
    last_name: user.lname
  }, {
    headers: {
      'Authorization': `Basic ${base64.encode('58cc8430776f95d5458c:f528cbce4d65cf824eac')}`
    }
  })
  .then(response => {
      console.log(response)
      return response
  }).catch(error => {
      console.log(error);
      return null
  });
}

export function sendDemoUserToCustomerIO(email) {
  let id = 'demo-user-' + uuidv4()
  return axios.put(`https://track.customer.io/api/v1/customers/${id}`, {
    id: id,
    email: email,
    created_at: Math.floor(new Date().getTime() / 1000), // js date to unix time stamp
  }, {
    headers: {
      'Authorization': `Basic ${base64.encode('58cc8430776f95d5458c:f528cbce4d65cf824eac')}`
    }
  })
  .then(response => {
      console.log(response)
      return response
  }).catch(error => {
      console.log(error);
      return null
  });
}
