import React from "react";
import { v4 as uuidv4 } from 'uuid';
import {
  Typography,
  Container,
  Checkbox,
  Avatar,
  Paper,
  Button,
  IconButton,
  Grid,
  Box,
  Dialog,
  DialogContent,
  Slide,
  AppBar,
  Toolbar
} from '@material-ui/core';

import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import Loading from "../general/Loading";
import { makeStyles } from '@material-ui/core/styles';

import {createInviteLink} from '../../api/workforce'
import {sendSlackInviteDM} from '../../api/slack'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    backgroundColor: '#FFFFFF',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function inviteSlackUser(parent, slackUser) {
  let {workforce, token, user} = parent.state

  let inviteLink = uuidv4();
  let fullLink = {
    code: inviteLink,
    created: Date.now(),
    createdBy: parent.state.user._id,
    full_name: slackUser.real_name,
    display_name: slackUser.profile.display_name,
    image_small_url: slackUser.profile.image_72,
    image_large_url: slackUser.profile.image_512,
    profile: {
      fname: slackUser.profile.first_name ? slackUser.profile.first_name : undefined,
      lname: slackUser.profile.last_name ? slackUser.profile.last_name : undefined,
      title: slackUser.profile.title ? slackUser.profile.title : undefined
    },
    delivery: 'slack',
    user_slack_id: slackUser.id,
    teams: [],
    leadingTeams: []
  }
  let invitedBy = `${parent.state.user.fname ? parent.state.user.fname : ''} ${parent.state.user.lname ? parent.state.user.lname : ''}`
  createInviteLink(workforce._id, token, fullLink, user.permission_token)
  .then((response) => {
    if(response && !response.error) {
      sendSlackInviteDM(workforce.slack.access_token, workforce._id, fullLink.code, workforce.name, invitedBy, token, slackUser.id)
      .then((res2) => {
        console.log(res2)
        parent.state.notificationMessages.push({text: "Invite sent succesfully"})
        parent.forceUpdate()
      })
    }
  })
}

function SlackInvite(props) {

  let {parent} = props
  let users = parent.state.slackUsers

  const classes = useStyles();

  const handleClose = () => {
    parent.setState({slackInvite: false})
  };

  const submit = () => {
    users.forEach((user, i) => {
      if(user.checked) {
        inviteSlackUser(parent, user)
      }
    });
  }

  /*const toggleInviteAllUsers = () => {
    let desiredValueForAllUsers = !parent.state.inviteAllUsers
    let users = parent.state.slackUsers
    users.forEach((u) => {
      u.checked = desiredValueForAllUsers
    })
    parent.setState({slackUsers: users, inviteAllUsers: !parent.state.inviteAllUsers})
    parent.forceUpdate()
  }*/

  const toggleUserSelected = (i) => {
    let newUsers = parent.state.slackUsers
    newUsers[i].checked = !newUsers[i].checked
    let numChecked = 0
    newUsers.forEach((u) => {
      if(u.checked) {
        numChecked++
      }
    })

    if(numChecked === newUsers.length) {
      parent.setState({inviteAllUsers: true})
    } else if(numChecked === 0) {
      parent.setState({inviteAllUsers: false})
    }

    parent.setState({slackUsers: newUsers})
  }

  const slackUserPreviousInvites = (slackId) => {
    let results = []
    if(parent.state.workforce && parent.state.workforce.invite_links) {
      parent.state.workforce.invite_links.forEach((link) => {
        if(link.user_slack_id === slackId && link.delivery === 'slack') {
          results.push(link)
        }
      })
    }
    return results
  }

  return (
    <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
       <Container maxWidth="md">
         <Box my={3}>
           <Grid container direction="row" alignItems="center" justify="space-between">
             <Typography variant="h2">Invite People to your workforce</Typography>
             {parent.state.notificationMessages ? parent.state.notificationMessages.map((m, i) => {
               return (
                 <p key={i}>{m.text}</p>
               )
             }) : null}
             <Box>
               <Grid container direction="row" alignItems="center">
                {/* TODO SLACK: sending invites */}
                 <Button onClick={() => submit()} variant="contained" color="primary" startIcon={ <SendIcon /> }>
                   Send
                 </Button>
               </Grid>
             </Box>
           </Grid>
         </Box>
         {/*<Box my={3}>
           <Grid container direction="row" alignItems="center">
             <Box width={60}>
             <Switch
               checked={parent.state.inviteAllUsers}
               onChange={() => toggleInviteAllUsers()}
               name="checked"
               color="primary"
             />
             </Box>
             <Box style={{ width: 'calc(100% - 60px)' }}>
               <Grid container direction="row" alignItems="center">
                 <Typography variant="subtitle1" style={{ color: '#726B83'}}>
                   Add everyone
                 </Typography>
               </Grid>
             </Box>
           </Grid>
         </Box>*/}

         <Paper variant="outlined" style={{ borderWidth: '6px', }}>
           <Box style={{ maxHeight: 'calc(100vh - 400px)', overflow: 'auto' }} px={3} py={2}>

            {users.length ? (
              <>
                {users.map((u, i) => {
                  let previousInvites = slackUserPreviousInvites(u.id)
                  let invitesOlderThanOneDay = 0
                  previousInvites.forEach((pi) => {
                    if(pi.created) {
                      let now = new Date()
                      let sentDate = new Date(pi.created)
                      if(now.getTime() < sentDate.getTime() + 86400000) {
                        invitesOlderThanOneDay++
                      }
                    }
                  })

                  if(!parent.state[`voidAlreadySent${u.id}`] && (previousInvites.length && invitesOlderThanOneDay === previousInvites.length)) {
                    let slackUsers = parent.state.slackUsers
                    if(slackUsers[i].checked) {
                      slackUsers[i].checked = false
                      parent.setState({ slackUsers: slackUsers })
                    }
                  }

                  if(u.id !== parent.state.user.slack_id && !parent.state.userSlackIds.includes(u.id)) {
                    return (
                      <Box mb={1} py={1} key={i}>
                        <Grid container direction="row" alignItems="center" justify="space-between">
                          <Box>
                            <Grid container direction="row" alignItems="center">
                              {!parent.state[`voidAlreadySent${u.id}`] && (previousInvites.length && invitesOlderThanOneDay === previousInvites.length) ? (
                                <Box width={60}>
                                  <p>You have already invited {u.profile && u.profile.display_name ? u.profile.display_name : 'this person'} today</p>
                                  <button onClick={() => parent.setState({ [`voidAlreadySent${u.id}`]: true })}>Invite anyway</button>
                                </Box>
                              ) : (
                                <Box width={60}>
                                  <Checkbox checked={u.checked} size="small" onClick={() => toggleUserSelected(i)} />
                                </Box>
                              )}
                              <Box style={{ width: 'calc(100% - 60px)' }}>
                                <Grid container direction="row" alignItems="center">
                                  <Box mr={3}>
                                    <Avatar size="lg" src={u.profile.image_72}>
                                    </Avatar>
                                  </Box>
                                  <Typography variant="h6">
                                    {u.real_name}
                                  </Typography>
                                </Grid>
                              </Box>
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )
                  } else return null
                })}
              </>
            ) : (<Loading />)}

           </Box>
         </Paper>
       </Container>
      </DialogContent>
    </Dialog>
  )
};

export default SlackInvite;
