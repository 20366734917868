import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { updateUser } from '../api/user';
import { updateWorkforce } from '../api/workforce'


import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import {
  Grid,
  Box,
  Button,
  Typography
} from '@material-ui/core';
import ButtonBrick from '../components/general/buttons/ButtonBrick'

const axios = require("axios")

function update(parent, user) {
  updateUser(parent.state.user._id, parent.state.token, user).then((res) => {
    if (!res.error) {
      parent.setState({user: res, myProfile: res})
    }
  })
}

function onChange(e, parent) {
  parent.setState({ file: e.target.files[0] })
}

export function imageSrc(userOrUrl, givenAsUrl, needsLarge) {
  if(givenAsUrl) {
    if(userOrUrl.substring(0, 4) === 'http') {
      return userOrUrl
    } else {
      return `https://joinhelm.com/api/getImage?image=${userOrUrl}`
    }
  } else if(userOrUrl && userOrUrl.pictures && userOrUrl.pictures.image_large_url && needsLarge) {
    return userOrUrl.pictures.image_large_url
  } else if(userOrUrl && userOrUrl.pictures && userOrUrl.pictures.image_small_url) {
    return userOrUrl.pictures.image_small_url
  } else if(userOrUrl && userOrUrl.picture) {

    if(userOrUrl.picture.substring(0, 4) === 'http') {
      return userOrUrl.picture
    } else {
      return `https://joinhelm.com/api/getImage?image=${userOrUrl.picture}`
    }

  } else {
     return "/static/images/avatar/1.jpg"
   }
}

const clearUserImages = (parent) => {
  updateUser(parent.state.user._id, parent.state.token, {
    picture: "", pictures: {}
  }).then((res) => {
    if (!res.error) {
      parent.setState({user: res, myProfile: res})
    }
  })
}

function removeButton(parent, isUser) {
  if(isUser) {
    if (parent.state.user && parent.state.user.picture) {
      return (
        <ButtonBrick
          onClick={() => clearUserImages(parent)}
          type="button" startIcon={<DeleteForeverIcon />}
        >
          Remove
        </ButtonBrick>
      )
    } else return null
  } else {
    if (parent.state.logo) {
      return (
        <ButtonBrick
          onClick={() => parent.setState({logo: "", uploading: false})}
          type="button" startIcon={<DeleteForeverIcon />}
        >
          Remove
        </ButtonBrick>
      )
    } else return null
  }
}

function changeButton(parent, isUser) {
  if(isUser) {
    if (parent.state.user && parent.state.user.picture) {
      return (
        <form onChange={(e) => onFormSubmit(e, parent, isUser)}>
          {parent.state.uploading ? (
            <div className="jumbotron text-center">
              loading...
            </div>
          ) : (
            <>
              <input
                type="file"
                accept="image/*"
                name="myImage"
                style={{ display: 'none' }}
                id="image-file-button"
                onChange={(e) => onChange(e, parent)}
              />
              <label htmlFor="image-file-button">
                <Button
                  variant="raised" component="span"
                  style={{
                    height: 120,
                    width: 120,
                    backgroundImage: `url(${imageSrc(parent.state.user)})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    border: '4px solid #141217'
                  }}
                >
                  <ImageRoundedIcon style={{ color: '#FFF' }} />
                </Button>
              </label>
            </>
          )}
        </form>
      )
    } else return null
  } else return null
}

function onFormSubmit(e, parent, isUser, companyInSettings) {
  setTimeout(() => {
    e.preventDefault()
    parent.setState({ uploading: true })
    const end = parent.state.file.name.substring(parent.state.file.name.lastIndexOf(".")).toLowerCase()

    if (parent.state.file.size > 5000000) {
      parent.setState({ imageError: "Image too large. Max size of 5 MB" })
    } else if (end !== ".png" && end !== ".jpg" && end !== ".jpeg") {
      parent.setState({ imageError: "Invalid file type" })
    } else {
      const id = uuidv4()
      const imageID = `IMAGE-${id}${end}`

      const formData = new FormData()
      formData.append("myImage", parent.state.file)
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      }
      axios
        .post(`https://joinhelm.com/api/upload?id=${id}`, formData, config)
        .then(response => {
          if (response.status === 200) {
            if(isUser) {
              const user = {
                picture: imageID
              }
              parent.setState({ uploading: false })
              update(parent, user)
            } else if(companyInSettings) {
              const workforce = {
                logo: imageID
              }
              parent.setState({ uploading: false })
              updateWorkforce(parent.state.workforce._id, parent.state.token, workforce, parent.state.user.permission_token)
              .then((res) => {
                if (!res.error) {
                  window.location.reload()
                }
              })
            } else {
              parent.setState({ logo: imageID })
            }
          }
        })
    }
  }, 100)
}

export const imageUpload = (parent, isUser, companyInSettings) => {
  let {imageError, user, uploading, logo} = parent.state

  return (
    <>
      <Typography color="error">{imageError}</Typography>
      {isUser ? (
        <>
          {user.picture || (user.pictures && user.pictures.image_small_url) ? (
            <Grid container direction="column" alignItems="center">
              <Box>
                {changeButton(parent, isUser)}
              </Box>
              <Box mt={1}>
                {removeButton(parent, isUser)}
              </Box>
            </Grid>
          ) : (
            <form onChange={(e) => onFormSubmit(e, parent, isUser)}>
              {uploading ? (
                <div className="jumbotron text-center">
                  loading...
                </div>
              ) : (
                <>
                  <p>Profile picture</p>
                  <input
                    type="file"
                    accept="image/*"
                    name="myImage"
                    onChange={(e) => onChange(e, parent)}
                  />
                </>
              )}
            </form>
          )}
        </>
      ) : (
        <>
          {logo ? (
            <>
              <Grid container direction="row">
                <img
                  src={imageSrc(logo, true)}
                  className="_profile-image-lg"
                  alt="profile"
                  style={{width: 100, borderRadius: '10px'}}
                />
                <Box mx={1}>
                  {removeButton(parent, isUser)}
                </Box>
              </Grid>
            </>
          ) : (
            <form onChange={(e) => onFormSubmit(e, parent, isUser, companyInSettings)}>
              {uploading ? (
                <div className="jumbotron text-center">
                  loading...
                </div>
              ) : (
                <input
                  type="file"
                  accept="image/*"
                  name="myImage"
                  onChange={(e) => onChange(e, parent)}
                />
              )}
            </form>
          )}
        </>
      )}
    </>
  )
}
