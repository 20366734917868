import React from 'react'
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function SquareAvatar(props) {
  let { children, styleVariant, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      border: '4px solid #141217',
      boxShadow: '0 0 8px 4px rgba(0,0,0,0.06)',
      borderRadius: 12
    },
    large: {
      height: 100,
      width: 100,
    },
    medium: {
      height: 80,
      width: 80,
    },
    small: {
      height: 30,
      width: 30,
    },
    xSmall: {
      height: 20,
      width: 20,
      borderWidth: 1,
      borderRadius: 4
    }
  }));

  const classes = useStyles();
  let variant = styleVariant ? styleVariant : 'medium'

  return (
    <Avatar { ...passThroughProps } className={`${classes.root} ${classes[variant]}`}>
      {children}
    </Avatar>
  )
}
export default SquareAvatar
