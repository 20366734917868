import React from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core'
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

function NewHireIntroDemo(props) {
  let {parent} = props

  const next = () => {
    parent.setState({ tourNewHireIntro_2: false, tourClickFindMentor: true })
  }
  const giveDialog = () => {
    return (
      <>
        <DialogTitle style={{ background: '#FFF' }}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="overline" style={{ color: '#726B83' }}>
              WIKI TOUR
            </Typography>
            <IconButton size="small" onClick={() => next()}>
              <CloseRoundedIcon style={{ color: '#726B83' }} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ background: '#FFF' }}>
          <Box>
            <Typography variant="h4" gutterBottom>
              Here's how Helm gives new hires<br/>
              <span style={{ color: '#4300FF' }}>a warm welcome, in Slack.</span>
            </Typography>
            <Typography variant="body1" style={{ color: '#726B83' }}>
              <b>It's instantaneous.</b> As soon as you set up your profile, Helm is making introductions that make sense.
            </Typography>
          </Box>
          <Typography variant="subtitle1" style={{ color: '#141217' }}>
            See it in action:
          </Typography>
          <Box my={2}>
            <div style={{position: 'relative', paddingBottom: '62.5%', height: 0}}><iframe title="New Hire Demo" src="https://www.loom.com/embed/400d608dacf34f3a8c8e6f6492c324aa" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: 8, boxShadow: '0 0 5px 10px rgba(0,0,0,0.06)'}}></iframe></div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Box pl={2}>
              <Typography variant="caption" style={{ color: '#726B83' }}>
                Step 2 of 4
              </Typography>
            </Box>
            <ButtonPurple onClick={() => next()}>
              Next
            </ButtonPurple>
          </Grid>
        </DialogActions>
      </>
    )
  }

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={true} onClose={() => next()}>
        {giveDialog()}
      </Dialog>
    </>
  )
}

export default NewHireIntroDemo;
