import React from "react";
import {
  Typography,
  Button,
  Grid,
  Box,
  TextField,
  Container
  } from '@material-ui/core';
import ButtonBlock from "../../components/general/buttons/ButtonBlock";
import { updateUser } from '../../api/user';
import {WORKFORCE_SLACK_LINK} from '../../api/slack'
import { createWorkforce } from '../../api/workforce';

function createWorkforceAndUpdate(parent, launch) {
  let name = parent.state.workforceOnboardingName
  let {user, token} = parent.state
  if(name && name.length) {
    let workforce = {
      name: name,
      users: [
        {
          _id: user._id,
          auth0Id: user.auth0Id,
          joined_date: Date.now,
          role: 'Admin'
        }
      ],
      regularIndex: {
        toggle: false
      },
      slack: {
        declined: true
      }
    }
    console.log(workforce)
    createWorkforce(workforce, token).then((newWorkforce) => {
      console.log(newWorkforce)
      if(newWorkforce._id) { //if succesful, create user workforce
        let updatedUser = {
          workforce: {
            _id: newWorkforce._id,
            name: newWorkforce.name
          },
          independent: false
        }
        updateUser(user._id, token, updatedUser).then((newUser) => {
          console.log(newUser)
          if(launch) {
            parent.setState({toHome: true})
          } else {
            window.location.reload()
          }
        })
      } else {
        parent.setState({workforceOnboardingError: 'Error in creating workforce'})
      }
    })
  } else {
    parent.setState({workforceOnboardingError: 'Provide the name of your company'})
  }
}

export function workforceOnboardingData(parent, launch) {
  return (
    <Box py={2}>
      <Box m={4} />
      <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
        What is the name of your company?
      </Typography>
      <Box m={2} />

      <TextField
        required
        variant="outlined"
        margin="dense"
        label="Company name"
        multiline
        minRows={2}
        placeholder="xyz inc."
        fullWidth
        onChange={(event) => parent.setState({ workforceOnboardingName: event.target.value })}
        value={parent.state.workforceOnboardingName}
      />

      <Button variant="contained" onClick={() => createWorkforceAndUpdate(parent, launch)} color="primary">
        Finish
      </Button>
    </Box>
  )
}

function UpdateProfile(props) {
  const {parent, launch} = props

  let {workforceOnboardingError, workforceOnboardingStep, user} = parent.state

  if(user) {
    return (
      <div>
        <Container maxWidth="sm">
          <Typography variant="body2" color="error">{workforceOnboardingError}</Typography>
          {launch ? null : (
            <button onClick={() => parent.setState({ tab: 'profile' })}>back</button>
          )}
          {workforceOnboardingStep === 1 ? (
            <>
              <div>
                <Container style={{ maxWidth: '680px' }}>
                  <Box py={10}>
                    <Box pb={4}>
                      <Typography variant="h4" gutterButtom>
                        Let's set up your workforce
                      </Typography>
                      <Box my={1} />
                      <Typography variant="body1" style={{ color: '#726B83' }}>
                        Do you want to use Slack?
                      </Typography>
                    </Box>

                    <Box p={3} style={{ backgroundImage: 'linear-gradient(270deg, #00C7B4 10%, #00D9AE 85%)', boxShadow: '0 0 10px 3px rgba(0,0,0,0.06)', borderRadius: 20 }}>
                      <Box>
                        <Grid container direction="row" alignItems="center" justify="space-between">
                          <Box pl={1} pr={2} style={{ width: 'calc(100% - 240px)' }}>
                            <Typography variant="h6" style={{ color: '#000' }}>
                              Yes, let's use Slack
                            </Typography>
                            <Typography variant="body2" style={{ color: '#006954' }}>
                              This allows us to easily onboard and communicate with your team
                            </Typography>
                          </Box>
                          <ButtonBlock
                            href={WORKFORCE_SLACK_LINK}
                            style={{ width: 240 }}
                          >
                            Connect my Slack
                          </ButtonBlock>
                        </Grid>
                        <Box m={4} />
                        <Grid container direction="row" alignItems="center" justify="space-between">
                          <Box pl={1} pr={2} style={{ width: 'calc(100% - 240px)' }}>
                            <Typography variant="h6" style={{ color: '#000' }}>
                              No, not right now
                            </Typography>
                            <Typography variant="body2" style={{ color: '#006954' }}>
                              You can always connect at a later date
                            </Typography>
                          </Box>
                          <ButtonBlock
                            onClick={() => parent.setState({workforceOnboardingStep: 2})}
                            style={{ width: 240 }}
                          >
                            Proceed without Slack
                          </ButtonBlock>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Container>
              </div>
            </>
          ) : null}

          {workforceOnboardingStep === 2 ? (
            <>
              {workforceOnboardingData(parent, launch)}
            </>
          ) : null}

        </Container>
      </div>
    );
  } else return null
};

export default UpdateProfile;
