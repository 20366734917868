import queryString from "query-string"
import Cookies from 'universal-cookie';
import { useAuth0 } from "@auth0/auth0-react";

import { getByAuth0, updateUser, createUser, getIndependentUser } from '../api/user';
import { getWorkforce, createWorkforce, addWorkforceUser, checkInvite, updateWorkforce} from '../api/workforce'
import {handleResources} from '../api/resources'

import {createPermissionTokenAndUpdate, giveUsersTeams} from '../utility/user'
import {defaultContactPreferences} from '../utility/constants'
import {giveResponsesByDay, giveRecentScores} from './score'
import {clickProfile, handleTeammateResponses} from './tabs'
import {giveOnboardingInfo, giveRandomUserPicture} from './profile'
import {office_profiles} from './demo/office-profiles'

const base64 = require('base-64');
const cookies = new Cookies();

export function initMainDash(parent) {
  let demo = cookies.get('demo')
  let startedTour = cookies.get('startedTour')
  const values = queryString.parse(parent.props.location.search)
  if(demo || values.d) {
    handleData(office_profiles[0], null, parent, true)
    if(!startedTour) {
      parent.setState({ tourIntro: true })
      // cookies.set('startedTour', 'true', { path: '/' });
    }
  } else {
    if(!parent.props.auth0 || !parent.props.auth0.user) {
      parent.setState({settingsSlackRedirect: true})
    } else {
      const { user, getAccessTokenSilently } = parent.props.auth0;
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if(isChrome) {
      if(window.location.pathname === '/settings') { // to fix bug where auth0 redirects to settings url but app page is served
        window.location.reload()
      } else if(window.location.pathname.substring(0, 6) === '/?code') {
        window.location.href = window.location.pathname
      }
    } else {
      if(window.location.pathname === '/settings') { // to fix bug where auth0 redirects to settings url but app page is served
        parent.setState({settingsRedirect: window.location.pathname})
      } else if(window.location.pathname.substring(0, 6) === '/?code') {
        parent.setState({codeRedirect: window.location.pathname})
      }
    }

    getAccessTokenSilently({ audience: 'https://joinhelm.com/api' }).then(token => {
      getByAuth0(user.sub, token).then(my_user => {

        // logout if error in auth0 user object
        if(!my_user || typeof my_user !== 'object') {

          // account for demo workforce
          let demo = cookies.get('demo')
          if(demo) {
            handleData(office_profiles[0], token, parent, true)
          } else {
            const { logout } = useAuth0()
            logout({ returnTo: 'https://joinhelm.com' })
          }
        } else {
          cookies.remove('demo')

          //check if is a user, if not then create user
          if(my_user.error === "User not found.") {

            let toBeCreated = {
              auth0Id: user.sub,
              email: user.email,
              pictures: {},
              log_settings: {
                beginning_time: '09:00',
                end_time: '17:00',
                timezone: {
                  id: 'EST',
                  correction: 5
                }
              },
              onboarding: {
                skipped: []
              }
            }

            // check for extra inputs from google / slack
            if(user.given_name) {
              toBeCreated.fname = user.given_name
            }
            if(user.family_name) {
              toBeCreated.lname = user.family_name
            }
            toBeCreated.picture = giveRandomUserPicture()

            createUser(toBeCreated).then((newUser) => {
              parent.setState({user: newUser, token: token})
              handleData(newUser, token, parent)
            })
          } else {
            parent.setState({ user: my_user, token: token })
            handleData(my_user, token, parent)
          }
        }
      })
    })
    }
  }
}

export async function handleData(user, token, parent, isDemo) {
  const resources = await handleResources(parent, ['questionsRef', 'questions', 'conversations', 'conversationsRef', 'domains', 'tools', 'interests', 'interestCategories', 'demoProfiles', 'demoTeams', 'demoCommunities'])
  parent.setState({allResources: resources, isDemo: isDemo})
  const values = queryString.parse(parent.props.location.search)

  if(!user.workforce && !isDemo) {//If no workforce, serve choice to join or create
    console.log(3)
    invitedUserOrNewWorkforce(parent, user, token)
  } else {

    if(isDemo) {
      handleIndependentUser(parent, user, token, values, resources)
    } else {
      handleWorkforceUser(parent, user, token, values, resources)
      giveOnboardingInfo(user, parent)
    }

    if(!user.contact_preferences) {
      parent.setState({ editProfileContactPreferences: defaultContactPreferences })
    }
    if(user.domains) {
      parent.setState({editProfileDomains: user.domains})
    }

  }
}

function handleWorkforceUser(parent, user, token, values, resources) {
  //todo create real permissions
  if(!user.permission_token) {
    createPermissionTokenAndUpdate(user, token, 'Admin')
  }
  console.log(user)
  // Call 2: get general workforce info
  getWorkforce(user.workforce._id, token, user.permission_token).then((workforce) => {
    console.log(workforce)
    if(workforce) {
      if(!workforce.slack) {
        workforce.slack = {}
      }
      parent.setState({workforce: workforce})
      if(workforce.slackUsers) {
        parent.setState({slackUsers: workforce.slackUsers})
      }
      if(workforce.slackChannels) {
        parent.setState({slackChannels: workforce.slackChannels})
      }
      if(workforce.publicChannels) {
        parent.setState({publicChannels: workforce.publicChannels})
      }
      if(workforce.privateChannels) {
        parent.setState({privateChannels: workforce.privateChannels})
      }

      // handle slack tokens
      // if access token is provided in query string, save and redirect
      if(values.slack_access_token) {
        console.log(1)
        handleSlackAccessToken(parent, token, values, user, workforce)
      } else if(values.slack_user_id && !user.slack_id) { // if slack user data such as names/images are in query, save and redirect
        console.log(2)
        handleSlackUserData(parent, values, user, token)
      }

      // send new users to new user flow
      sendNewUsersToNewUserFLow(parent, user)

      // send non-onboarded users to onboarding
      sendUsersToOnboard(parent, user, workforce)

      // send to slack permissions if we dont have their slack id
      if(workforce.slack && workforce.slack.access_token && !user.slack_id) {
        parent.setState({getPersonalSlack: true})
      }

      // check billing status and redirect as needed
      billingRedirect(parent, workforce)

      // Call(s) 3: get basic profiles of each user
      getWorkforceProfiles(parent, workforce, token, user, values)

      handleModalQueryValues(parent, values, user, token, workforce)
    }

  })
}

export function handleTeamsAndCommunities(parent, user, resources) {
  console.log(user.teams)
  let all_users = resources.demoProfiles
  let fullTeams = resources.demoTeams
  let fullCommunities = resources.demoCommunities
  console.log(fullTeams)
  console.log(fullCommunities)
  fullTeams.forEach((team, i) => {
    team.users = []
  });
  fullCommunities.forEach((comm, i) => {
    comm.users = []
  });
  console.log('HERE 2')

  console.log(user.teams)
  parent.setState({ profiles: [] })
  all_users.forEach((profile, i) => {
    profile.full_name = `${profile.fname} ${profile.lname}`
    parent.state.profiles.push(profile)

    // put users into workforce teams
    if(profile.teams) {
      profile.teams.forEach((teamId, i) => {
        fullTeams.forEach((fullTeam, j) => {
          if(fullTeam.team_id === teamId) {
            fullTeams[j].users.push(profile)
          }
        });
      });
    }

    // put users into workforce communities
    if(profile.communities) {
      profile.communities.forEach((communityId, i) => {
        fullCommunities.forEach((fullCommunity, j) => {
          if(fullCommunity.community_id === communityId) {
            fullCommunities[j].users.push(profile)
          }
        });
      });
    }
  });
  console.log(user.teams)

  let userTeams = giveUsersTeams(user, fullTeams, true)

  parent.setState({ fullTeams, fullCommunities, userTeams })
  parent.forceUpdate()
}

function handleIndependentUser(parent, user, token, values, resources) {
  let defaultUser = resources.demoProfiles[38]
  parent.setState({myProfile: defaultUser, profile: defaultUser, user: defaultUser})

  handleTeamsAndCommunities(parent, defaultUser, resources)
}

function handleModalQueryValues(parent, values, user, token, workforce) {
  if(values.slackPermissionsReset) {
    resetSlackPermissions(parent)
  }
}

function resetSlackPermissions(parent) {
  let newWorkforce = {
    slack: {}
  }
  updateWorkforce(parent.state.workforce._id, parent.state.token, newWorkforce, parent.state.user.permission_token)
  .then((res) => {
    if(!res.error) {
      window.history.pushState( {} , '', `/` );
      parent.setState({slackPermissionsReset: true})
    }
  })
}

function handleTeammateTab(parent, values, profiles) {
  if(values.teammate) {
    let profile = null
    profiles.forEach((p, i) => {
      if(p._id === values.teammate) {
        profile = p
      }
    });


    if(profile) {
      clickProfile(parent, profile)
    }
  }
}

function billingRedirect(parent, workforce) {
  // if past free trial
  if(workforce.users && workforce.users.length > 5) {
    // check if they have active subscription
    let hasActiveSubscription = false
    if(workforce.stripe_subscriptions) {
      workforce.stripe_subscriptions.forEach((sub) => {
        if(sub.current_period_end) {
          let ends = new Date(sub.current_period_end)
          if(new Date().getTime() < ends.getTime()) {
            hasActiveSubscription = true
          }
        }
      })
    }

    // check if they are exempt
    let exempt = workforce.billing && workforce.billing.excluded

    if(!hasActiveSubscription && !exempt) {
      // if they dont have an active subscription, force redirect
      parent.setState({forceBilling: true})
    }

  }
}

function handleSlackUserData(parent, values, user, token) {
  let userWithSlackId = user

  if(values.fname) {
    userWithSlackId.fname = values.fname
  }
  if(values.lname) {
    userWithSlackId.lname = values.lname
  }
  if(values.image_small_url) {
    let pictures = {}
    if(userWithSlackId.pictures) {
      pictures = userWithSlackId.pictures
    }
    pictures.image_small_url = base64.decode(values.image_small_url);
    userWithSlackId.pictures = pictures
  }
  if(values.image_large_url) {
    let pictures = {}
    if(userWithSlackId.pictures) {
      pictures = userWithSlackId.pictures
    }
    pictures.image_large_url = base64.decode(values.image_large_url);
    userWithSlackId.pictures = pictures
  }

  userWithSlackId.slack_id = values.slack_user_id
  updateUser(user._id, token, userWithSlackId)
  .then((res) => {
    if(!user.workforce) {
      parent.setState({toLaunchSequence: true})
    } else {
      parent.setState({refresh: true})
    }
  })
}

function handleSlackAccessToken(parent, token, values, user, workforce) {
  if(values.slack_access_token) {
    console.log(values)
    if(!workforce) {
      let workforce = {
        name: values.workforce_name,
        users: [
          {
            _id: user._id,
            auth0Id: user.auth0Id,
            joined_date: Date.now,
            role: 'Admin'
          }
        ],
        slack: {
          access_token: values.slack_access_token
        }
      }
      if(values.workforce_image) {
        workforce.logo = values.workforce_image
      }

      createWorkforce(workforce, token).then((newWorkforce) => {
        console.log('newWorkforce')
        console.log(newWorkforce)
        if(newWorkforce._id) { //if succesful, put workforce into user
          let updatedUser = {
            workforce: {
              _id: newWorkforce._id,
              name: newWorkforce.name
            },
            independent: false,
            teams: [],
            communities: [],
            slack_id: values.user_slack_id,
            slack_token: values.user_slack_token,
          }
          updateUser(user._id, token, updatedUser).then((newUser) => {
            console.log('newUser')
            console.log(newUser)
            window.location.href = '/'
          })
        }
      })

    } else {
      // let newWorkforce = {
      //   slack: {}
      // }
      // if(workforce.slack) {
      //   newWorkforce.slack = workforce.slack
      // }
      // newWorkforce.slack.access_token = values.slack_access_token

      // updateWorkforce(workforce._id, token, newWorkforce, user.permission_token)
      // .then((res) => {
      //   console.log(res)
      //   console.log('refresh')
      //   // this.setState({refresh: true})
      // })
    }
  }
}

function sendNewUsersToNewUserFLow(parent, user) {
  // this redirect sends them to page which captures slack data and cookie data
  // implement url param that allows for no slack data to be captured
  if(!user.site_logging || !user.site_logging.been_in_user_flow) {
    if(!user.title && !user.location) {
      if(!parent.state.workforce.slack.access_token) {
        parent.setState({toNewUserFlowNoSlack: true})
      } else {
        parent.setState({toNewUserFlow: true})
      }
      console.log('t3')
    }
  }
}

function sendUsersToOnboard(parent, user, workforce) {
  console.log('check 0')
  if( !user.fname || !user.lname ) {
    parent.setState({
      tab: 'onboarding',
      onboardingStep: 1
    })
  } else if( !user.teams || !user.teams.length ) {
    console.log('check 2')
    parent.setState({
      tab: 'onboarding',
      onboardingStep: 2
    })
  } else if(workforce && (!workforce.slack || (!workforce.slack.intro_channel && !workforce.slack.intro_channel_skipped)) ) {
    parent.setState({
      tab: 'onboarding',
      onboardingStep: 3
    })
  } else {
    parent.setState({
      needsOnboarding: false
    })
  }
}

export function getWorkforceProfiles(parent, workforce, token, user, values) {
  let profiles = []
  let length = workforce.users.length
  let requestCount = 0

  let fullTeams = workforce.teams ? workforce.teams : []
  let fullCommunities = workforce.communities ? workforce.communities : []
  fullTeams.forEach((team, i) => {
    team.users = []
  });
  fullCommunities.forEach((comm, i) => {
    comm.users = []
  });


  workforce.users.forEach((u, i) => {
    getByAuth0(u.auth0Id, token)
    .then((uProfile) => {
      requestCount++

      if(!uProfile.error) {
        uProfile.role = u.role

        // set this user
        if(uProfile._id === user._id) {
          parent.setState({myProfile: uProfile, role: u.role})
        }

        uProfile.full_name = `${uProfile.fname} ${uProfile.lname}`

        parent.state.profiles.push(uProfile)
        profiles.push(uProfile)

        // put users into workforce teams
        if(uProfile.teams) {
          uProfile.teams.forEach((teamId, i) => {
            fullTeams.forEach((fullTeam, j) => {
              if(fullTeam.team_id === teamId) {
                fullTeams[j].users.push(uProfile)
              }
            });
          });
        }

        // put users into workforce communities
        if(uProfile.communities) {
          uProfile.communities.forEach((communityId, i) => {
            fullCommunities.forEach((fullCommunity, j) => {
              if(fullCommunity.community_id === communityId) {
                fullCommunities[j].users.push(uProfile)
              }
            });
          });
        }

        // store slack id
        if(uProfile.slack_id) {
          parent.state.userSlackIds.push(uProfile.slack_id)
        }
      }

      if(requestCount === length) {
        handleTeammateTab(parent, values, profiles)
      }
    })
  })

  let userTeams = giveUsersTeams(user, fullTeams, true)

  parent.setState({ fullTeams, fullCommunities, userTeams })
  setTimeout(() => parent.forceUpdate(), 100) // band-aid, find real problem
}

export function handleDataResponses(parent, originalWorkforceResponses, user, resources, values) {
  let userResponses = []
  originalWorkforceResponses.responses.forEach((response) => {
    if(response.user_id === user._id) {
      userResponses.push(response)
    }
  })

  let parsedResponses = giveResponsesByDay(userResponses)
  console.log(parsedResponses)
  let timeline = createTimeline(parsedResponses)

  // handle teammate responses
  if(values.teammate) {
    handleTeammateResponses(parent, values.teammate, originalWorkforceResponses.responses)
  }

  parent.setState({userResponses: userResponses,
    workforceResponses: originalWorkforceResponses.responses,
    timeline: timeline, responsesByDay: parsedResponses })
}

export function createTimeline(parsedResponses) {
  console.log(parsedResponses)
  let timeline = []
  parsedResponses.forEach((response, i) => {
    let item = {
      stats: giveRecentScores(parsedResponses, i),
      date: new Date(response.updated),
      answers: []
    }

    // put all day's answers into one array
    response.responses.forEach((r, i) => {
      if(r.answers) {
        item.answers = item.answers.concat(r.answers)
      }
    });

    timeline.push(item)
  })
  timeline.reverse()
  console.log(timeline)

  return timeline
}

function invitedUserOrNewWorkforce(parent, user, token) {
  let workforceId = cookies.get('workforce')
  cookies.remove('workforce')
  let code = cookies.get('code')
  cookies.remove('code')

  if(!workforceId || !code) {
    console.log('createWorkforce')
    parent.setState({createWorkforce: true})
  } else {
    checkInvite(workforceId, code)
    .then((inviteRes) => {
      console.log(inviteRes)
      if(inviteRes.code) {

        let userIntoWorkforce = {
          _id: user._id,
          auth0Id: user.auth0Id,
          joined_date: Date.now,
          role: 'Member'
        }
        let slackRole = cookies.get('role')

        if(inviteRes.role) {
          userIntoWorkforce.role = inviteRes.role
        } else if(slackRole) {
          userIntoWorkforce.role = slackRole
        }
        addWorkforceUser(workforceId, token, userIntoWorkforce)
        .then((workforce) => {
          let userWithWorkforce = {
            workforce: {
              _id: workforceId,
              name: workforce.name
            }
          }
          updateUser(user._id, token, userWithWorkforce).then((newUser) => {
            console.log(newUser)
            if(newUser.workforce && newUser.workforce._id) { //if succesfully added workforce
              parent.setState({toNewUserFlowNoSlack: true})
              console.log('t4')
            }
          })

        })

      }
    })
  }
}

export async function independentInit(parent, id) {
  const resources = await handleResources(parent, ['questionsRef', 'questions', 'conversations', 'conversationsRef', 'domains', 'tools', 'interests', 'interestCategories', 'demoProfiles', 'demoTeams', 'demoCommunities'])
  parent.setState({allResources: resources})
  getIndependentUser(id)
  .then((res) => {
    console.log(res)
    parent.setState({ profile: res })
  })
}
