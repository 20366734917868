import React from 'react'
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#009779',
    fontWeight: '600',
    fontSize: 20,
    fontFamily: 'lato',
    width: '100%',
    paddingRight: 30,
    paddingLeft: 30,
    height: 50,
    borderRadius: 12,
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.9)',
      boxShadow: '0 0 10px 5px rgba(0,0,0,0.06)'
    },
  },
}));

function ButtonBlock(props) {
  const { children, ...passThroughProps } = props
  const classes = useStyles();

  return (
    <ButtonBase { ...passThroughProps } disableRipple className={ classes.root }>
      {children}
    </ButtonBase>
  )
}
export default ButtonBlock
