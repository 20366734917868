import React, {Component} from 'react';
import Loading from "../components/general/Loading";
import SecondaryNav from "../components/nav/SecondaryNav"
import ButtonBlack from "../components/general/buttons/ButtonBlack"
import {
  Box,
  Typography,
  Container,
  Paper,
  Avatar,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Button
} from '@material-ui/core';
import { Redirect } from "react-router-dom"
import { withAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getByAuth0 } from '../api/user';
import { getWorkforce, updateWorkforceUser, updateWorkforce } from '../api/workforce'
import { updateUser } from '../api/user';
import {giveCountdownXS, giveShortDate} from '../utility/time'
import {roles} from '../utility/permissions'
import {imageUpload, imageSrc} from '../utility/images'
import 'whatwg-fetch'
import queryString from "query-string"
import {getSlackUsers} from '../api/slack'
import { PERSONAL_SLACK_LINK, addToSlackButton } from '../api/slack'

const base64 = require('base-64');

export const ENERGY_INDEX_INTERVALS = ['Daily', 'Weekly']

function handleUpdateWorkforce(parent, updatedWorkforce) {
  updateWorkforce(parent.state.workforce._id, parent.state.token, updatedWorkforce, parent.state.user.permission_token)
  .then((res) => {
    if(res && !res.error) {
      parent.setState({workforce: res})
      parent.forceUpdate()
    }
  })
}

export function callSlackUsers(parent, slackToken) {
  getSlackUsers(slackToken)
  .then((res) => {
    console.log(res)
    if(res && res.ok) {
      console.log('2')
      let validUsers = []
      res.members.forEach((m) => {
        if(!m.deleted && !m.is_bot && m.id !== 'USLACKBOT') {
          m.checked = true
          validUsers.push(m)
          parent.setState({ [`user${m.id}role`]: 'Member' })
        }
      })
      console.log(`Number of valid users: ${validUsers.length}`)
      if(validUsers.length) {
        parent.setState({slackUsers: validUsers})
      } else {
        parent.setState({slackConnectionError: 'Your workforce cannot connect to your slack at this moment. Please try the \'Add to Slack\' button again.'})
      }
    } else {
      console.log(res)
      parent.setState({slackConnectionError: 'Your workforce cannot connect to your slack at this moment. Please try the \'Add to Slack\' button again.'})
    }
  })
}

export function pendingInvitesToState(parent, invite_links, email_invites, profiles) {
  let count = 0
  if(invite_links && invite_links.length) {
    invite_links.forEach((link) => {
      let isPending = true
      if(link.delivery !=='slack') {
        isPending = false
      } else {
        profiles.forEach((p) => {
          if(p.slack_id && p.slack_id === link.user_slack_id) {
            isPending = false
          }
        })
      }
      if(isPending) {
        count++
        parent.state.pendingInvites.push(link)
        parent.forceUpdate()
      }
    })
  }
  if(email_invites && email_invites.length) {
    let userEmails = []
    profiles.forEach((p) => {
      if(p.email) {
        userEmails.push(p.email)
      }
    })
    email_invites.forEach((invite) => {
      let isPending = true
      if(userEmails.includes(invite.email)) {
        isPending = false
      }
      if(isPending) {
        count++
        parent.state.pendingInvites.push(invite)
        parent.forceUpdate()
      }
    })
  }
  return count
}

class Settings extends Component {

  constructor() {
    super()
    this.state = {
      profiles: [],
      uploading: false,
      slackUsers: [],
      inviteAllUsers: true,
      notificationMessages: [],
      pendingInvites: [],
      userSlackIds :[],
      inviteEmails: [],
      inviteUserRole: 'Member',
      channels: [],
      allChannelsChecked: false,
      pendingTeams: [],
      loadingStep2: true,
      newWorkforceName: ''
    }
  }

  onChange(e) {
    this.setState({ file: e.target.files[0] })
  }

  saveSlackToken(slackToken, token, workforce, permissionToken, sendToPersonal, user) {
    let newWorkforce = {
      slack: {}
    }
    if(workforce.slack) {
      newWorkforce.slack = workforce.slack
    }
    newWorkforce.slack.access_token = slackToken

    updateWorkforce(workforce._id, token, newWorkforce, permissionToken)
    .then((res) => {
      if(sendToPersonal && !user.slack_id) {
        window.location.href = PERSONAL_SLACK_LINK
      } else {
        this.setState({redirectToHome: true})
      }
    })
  }

  componentDidMount() {
    const { user, getAccessTokenSilently } = this.props.auth0;
    const values = queryString.parse(this.props.location.search)

    if(values.origin) {
      this.setState({origin: values.origin})
    }
    if(values.focusRequests) {
      this.setState({focusRequests: true})
    }

    getAccessTokenSilently({ audience: 'https://joinhelm.com/api' }).then(token => {
      getByAuth0(user.sub, token).then(my_user => {
        this.setState({ user: my_user, token: token, fname: my_user.fname, lname: my_user.lname, title: my_user.title, remote: my_user.remote })

        getWorkforce(my_user.workforce._id, token, my_user.permission_token).then((workforce) => {
          this.setState({workforce: workforce})

          if(workforce.logo) {
            this.setState({logo: workforce.logo})
          }

          if(values.slack_access_token) {
            const sendToPersonal = values.stillNeedSlackId
            this.saveSlackToken(values.slack_access_token, token, workforce, my_user.permission_token, sendToPersonal, my_user)
          }

          if(workforce.slack && workforce.slack.access_token) {
            if(values.slackInvite) {
              this.setState({inviteSlackUsers: true})
              callSlackUsers(this, workforce.slack.access_token)
              if(!workforce.slack.suggested_slack_invite) {
                let updatedWorkforce = workforce
                updatedWorkforce.slack.suggested_slack_invite = true
                updateWorkforce(workforce._id, token, updatedWorkforce, my_user.permission_token)
              }
            }
          }

          // get workforce profiles
          let profiles = []
          let errorCount = 0
          workforce.users.forEach((u, i) => {
            getByAuth0(u.auth0Id, token)
            .then((uProfile) => {
              if(!uProfile.error) {
                uProfile.role = u.role
                this.state.profiles.push(uProfile)
                profiles.push(uProfile)
                if(uProfile.slack_id) {
                  this.state.userSlackIds.push(uProfile.slack_id)
                }
                this.forceUpdate()
                // set this user
                if(uProfile._id === my_user._id) {
                  this.setState({myProfile: uProfile, role: u.role})
                }
              } else errorCount++

              if(((profiles.length + errorCount) === workforce.users.length) && workforce.invite_links) {
                pendingInvitesToState(this, workforce.invite_links, workforce.email_invites, profiles)
              }

            })
          })

        })

      })
    })

  }

  render() {

    function updateUserPermissions(parent, userId, role, currentRole) {

      let allow = true
      if(currentRole === 'Admin') { // ensure there is always one admin
        let adminCount = 0;
        parent.state.profiles.forEach((p) => {
          if(p.role === 'Admin') {
            adminCount++;
          }
        })
        if(adminCount === 1) {
          allow = false
        }
      }
      if(allow) {
        let user = {
          role: role
        }
        updateWorkforceUser(parent.state.workforce._id, userId, user, parent.state.token, parent.state.user.permission_token)
        .then((workforce) => {

          window.location.reload()

        })
      } else {
        parent.setState({error: 'There must always be 1 admin'})
      }
    }

    function giveOnClick(parent, userId, r, currentRole, hasPermission) {
      if(hasPermission) {
        updateUserPermissions(parent, userId, r, currentRole)
      } else {
        parent.setState({error: 'Cannot change superior\'s permissions'})
      }
    }

    function givePermissionChoices(parent, myRole, currentRole, userId) {
      return roles.map((r, i) => {
        return (
          <MenuItem value={r} onClick={() => giveOnClick(parent, userId, r, currentRole, roles.indexOf(myRole) >= i)}>{r}</MenuItem>
        )
      })
    }

    function disconnectSlack(parent) {
      let newWorkforce = {
        slack: {
          access_token: undefined,
          suggested_slack_invite: false,
          declined: true
        }
      }
      updateWorkforce(parent.state.workforce._id, parent.state.token, newWorkforce, parent.state.user.permission_token)
      .then((res) => {
        if(!res.error) {
          window.location.reload()
        }
      })
    }

    function giveSlackButton(parent) {
      let give = false
      if(parent.state.slackConnectionError) {
        give = true
      } else if(parent.state.workforce) {
        if(!parent.state.workforce.slack || !parent.state.workforce.slack.access_token) {
          give = true
        }
      }
      if(give) {
        return (
          addToSlackButton()
        )
      } else if(parent.state.role === "Admin") {
        return (
          <Paper elevation={0}>
            <Box px={2} py={2}>
              <Typography variant="overline">Slack</Typography>
              <Box mt={2}>
                <ButtonBlack variant="contained" size="small" onClick={() => disconnectSlack(parent)}>
                  Disconnect Slack
                </ButtonBlack>
              </Box>
            </Box>
          </Paper>
        )}
    }

    function givePendingInvites(parent) {
      let pendingInvites = parent.state.pendingInvites
      pendingInvites.reverse()
      return pendingInvites.map((invite, i) => {
        if(invite.image_small_url) {
          let decode = false
          if(invite.image_small_url.length % 4 === 0) {
            decode = true
          }
          return (
            <Box mb={1} py={1} key={i} style={{ color: '#726B83' }}>
              <Grid container direction="row" alignItems="center" justify="space-between">
                <Box>
                  <Grid container direction="row" alignItems="center">
                    <Box mr={3}>
                      <Avatar size="lg" src={decode ? base64.decode(invite.image_small_url) : invite.image_small_url}>
                      </Avatar>
                    </Box>
                    <Typography variant="h6">
                      {invite.full_name}
                    </Typography>
                  </Grid>
                </Box>
                <Typography variant="body1">
                  {invite.created ? giveCountdownXS(new Date(), new Date(invite.created)) : null}
                </Typography>
              </Grid>
            </Box>
          )
        } else {
          return (
            <Box mb={1} py={1} key={i} style={{ color: '#726B83' }}>
              <Grid container direction="row" alignItems="center" justify="space-between">
                <Box>
                  <Grid container direction="row" alignItems="center">
                    <Box mr={3}>
                      <Avatar size="lg" src={invite.image_small_url}>
                      </Avatar>
                    </Box>
                    <Typography variant="h6">
                      {invite.email}
                    </Typography>
                  </Grid>
                </Box>
                <Typography variant="body1">
                  {invite.sent ? giveCountdownXS(new Date(), new Date(invite.sent)) : null}
                </Typography>
              </Grid>
            </Box>
          )
        }
      })
    }

    function resolvePermissionRequest(parent, request, granted) {
      parent.setState({loading: true})
      let requests = parent.state.workforce.permission_requests
      let index = -1
      requests.forEach((r, i) => {
        if(r._id === request._id) {
          index = i
        }
      })
      if(index !== -1) {
        requests[index].resolved = true
        let newWorkforce = {
          permission_requests: requests
        }
        updateWorkforce(parent.state.workforce._id, parent.state.token, newWorkforce, parent.state.user.permission_token)
        .then((res) => {
          if(!res.error) {
            if(granted) {
              updateUserPermissions(parent, request.user_id, 'Manager', 'granting')
            } else {
              parent.setState({workforce: res, loading: false})
            }
          }
        })
      }
    }

    function givePermissionRequests(isFocused, parent) {
      let focused = parent.state.focusRequests
      let permissionRequests = parent.state.workforce && parent.state.workforce.permission_requests ? parent.state.workforce.permission_requests : null
      let show = false
      permissionRequests.forEach((pr) => {
        if(!pr.resolved) {
          show = true
        }
      })
      if(show && parent.state.myProfile && parent.state.myProfile.role === 'Admin' && permissionRequests) {
        if((isFocused && focused) || (!isFocused && !focused)) {
          return (
            <>
              <Box my={5} />
              <Typography variant="subtitle1">Permission requests</Typography>
              {permissionRequests.map((pr, i) => {
                if(!pr.resolved) {
                  let userName = null
                  let teamName = null
                  parent.state.profiles.forEach((p) => {
                    if(p._id === pr.user_id) {
                      userName = `${p.fname} ${p.lname}`
                    }
                  })
                  parent.state.workforce.teams.forEach((t) => {
                    if(t._id === pr.team_id) {
                      teamName = t.name
                    }
                  })
                  return (
                    <>
                      {userName && teamName ? (
                        <div key={i}>
                          <p>{userName} requested manager access to {teamName} team on {giveShortDate(pr.date)}</p>
                          <button onClick={() => resolvePermissionRequest(parent, pr, true)}>Grant</button>
                          <button onClick={() => resolvePermissionRequest(parent, pr, false)}>Deny</button>
                        </div>
                      ) : null}
                    </>
                  )
                } else return null
              })}
            </>
          )
        }
      }
    }

    function saveNewWorkforceName(parent) {
      if(parent.state.newWorkforceName) {
        let newWorkforce = parent.state.workforce
        newWorkforce.name = parent.state.newWorkforceName
        updateWorkforce(newWorkforce._id, parent.state.token, newWorkforce, parent.state.user.permission_token)
        .then((res) => {
          parent.setState({workforce: res})
        })
      }

      parent.setState({ editingWorkforceName: false, newWorkforceName: '' })
    }

    function removeUserFromWorkforce(parent) {
      let user = parent.state.removingUser
      if(user) {

        // remove user from workforce
        let removeIndex = -1
        console.log(parent.state.workforce.users)
        parent.state.workforce.users.forEach((u, i) => {
          if(u._id === user._id) {
            removeIndex = i
          }
        })
        if(removeIndex !== -1) {
          console.log(removeIndex)
          let updatedUsers = parent.state.workforce.users
          updatedUsers.splice(removeIndex, 1)
          let updatedWorkforce = {
            users: updatedUsers
          }
          console.log(updatedWorkforce)
          handleUpdateWorkforce(parent, updatedWorkforce)
          parent.setState({ removingUser: false })
        }

        // remove workforce from user
        console.log(user)
        let updatedUser = {
          workforce: {}
        }
        console.log(updatedUser)
        updateUser(user._id, parent.state.token, updatedUser)

      }
    }

    let {workforce, myProfile, redirectToHome, loading} = this.state

    if(redirectToHome) {
      return <Redirect to="/" />
    }

    return (
      <>
        <SecondaryNav profile={myProfile ? myProfile : null} />
        {workforce && !loading ? (
          <>
            <Container maxWidth="md">
              <Box py={5} />
              <Box py={4} px={2}>

                {/*company profile*/}
                <Typography variant="h2">Workforce Settings</Typography>
                <Typography color="error" variant="subtitle">{this.state.error}</Typography>

                {givePermissionRequests(true, this)}

                <Box my={5} />

                <Typography variant="subtitle1">
                  {this.state.slackConnectionError}
                </Typography>
                <Grid container direction="row">

                <Paper elevation={0}>
                  <Box px={3} py={3}>
                    <Typography variant="overline">Identity</Typography>
                    <Box my={1} />
                    {this.state.editingWorkforceName ? (
                      <TextField
                        onChange={(event) => this.setState({ newWorkforceName: event.target.value })}
                        value={this.state.newWorkforceName}
                        placeholder={this.state.workforce.name}
                        fullWidth
                      />
                    ) : (
                      <Typography variant="h6" style={{textDecoration: 'underline'}}>{this.state.workforce.name}</Typography>
                    )}
                    <Box my={1} />
                    {this.state.editingWorkforceName ? (
                      <>
                        <Button size="small" onClick={() => this.setState({editingWorkforceName: false, newWorkforceName: ''})}>Cancel</Button>
                        <Button variant="contained" size="small" color="primary" onClick={() => saveNewWorkforceName(this)}>Save</Button>
                      </>
                    ) : (
                      <Button variant="contained" size="small" color="primary" onClick={() => this.setState({editingWorkforceName: true})}>Edit</Button>
                    )}
                    <Box mt={2}>
                      {imageUpload(this, false, true)}
                    </Box>
                  </Box>
                </Paper>

                  <Box mx={2} />

                  <Paper elevation={0}>
                    <Box px={3} py={3}>
                      <Typography variant="overline">Subscription</Typography>
                      <Box my={1} />
                      <Button variant="contained" color="primary" href={'/billing'}>Manage billing</Button>
                    </Box>
                  </Paper>

                  <Box mx={2} />

                  {giveSlackButton(this)}

                </Grid>

                {/*Pending invites*/}
                {this.state.pendingInvites.length ? (
                  <>
                    <Box my={3} />

                    <Paper elevation={0}>
                      <Box px={3} py={3}>
                        <Typography variant="h4">Pending invites</Typography>

                        <Box my={4} />

                        {givePendingInvites(this)}
                      </Box>
                    </Paper>
                  </>
                ) : null}

                <Box my={3} />

                {/*permissions*/}
                <Paper elevation={0}>
                  <Box px={3} py={3}>
                    <Typography variant="h4">Permissions</Typography>

                    <Box my={4} />

                    {this.state.profiles.map((p, i) => {
                      return (
                        <div key={i}>
                          <Box my={2}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                              <Box>
                                <Grid container direction="row" alignItems="center">
                                  <Avatar
                                    src={imageSrc(p)}
                                    className="_profile-image-lg"
                                    alt="profile"
                                  />
                                  <Box mx={2} />
                                  <Typography variant="h6">
                                    {p.fname} {p.lname}
                                  </Typography>
                                </Grid>
                              </Box>
                              <Box width={150}>
                                <FormControl fullWidth variant="outlined" size="small">
                                  <InputLabel>Status</InputLabel>
                                  <Select
                                    value={p.role}
                                    onChange={(event) => updateUserPermissions(this, p._id, event.target.value, p.role)}
                                    label="Status"
                                  >
                                    {givePermissionChoices(this, this.state.role, p.role, p._id)}
                                  </Select>
                                </FormControl>
                              </Box>
                              {this.state.role === 'Admin' ? (
                                <ButtonBlack variant="contained" size="small" onClick={() => this.setState({ removingUser: p })}>
                                  Remove user
                                </ButtonBlack>
                              ) : null}
                            </Grid>
                          </Box>
                        </div>
                      )
                })}
                  </Box>
                </Paper>
              </Box>

              {givePermissionRequests(false, this)}

            </Container>

            {this.state.removingUser ? (
              <Dialog open={this.state.removingUser} onClose={() => this.setState({removingUser: false})} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle>
                  <Typography variant="h4">Are you sure you want to remove {this.state.removingUser.fname} {this.state.removingUser.fname}</Typography>
                  <Typography variant="h5">This action is permanent</Typography>
                </DialogTitle>
                <DialogActions>
                  <Button onClick={() => this.setState({removingUser: false})} color="primary">
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={() => removeUserFromWorkforce(this)} color="primary">
                    Remove user
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}
          </>
        ) : <Loading />}
      </>
    )

  }
}
export default withAuth0(withAuthenticationRequired(Settings, {
  onRedirecting: () => <Loading />,
}));
