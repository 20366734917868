import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#141217',
    backgroundColor: '#EFEFF1',
    borderRadius: 8,
    fontSize: 14,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#E6E4EC',
    },
  },
}));

function ButtonToggle(props) {
  const { children, ...passThroughProps } = props
  const classes = useStyles();

  return (
    <Button variant="contained" size="small" { ...passThroughProps } className={ classes.root }>
      {children}
    </Button>
  )
}
export default ButtonToggle
