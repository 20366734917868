import React from 'react';

import {
  Typography,
  Box,
  Container
} from '@material-ui/core'

export const giveTos = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h1">
        Terms of Service
      </Typography>

      <Box my={3} />

      <Typography variant="body1">
        These Terms of Service (the “Terms”) govern your use of the Helm website, as well as any other websites, subdomains, or services owned or controlled by Helm (collectively, the “Helm Services”) provided by Velificor Inc., a Delaware corporation (d/b/a Helm). To access the Helm Services, users must at all times agree to and abide by these Terms. By accessing the Helm Services or by the Parties signing or accepting the present agreement, you agree to the Terms on behalf of yourself as a User and/or, as the case may be, on behalf of the organization that you represent (“Customer”), unless you have a superseding written agreement with Helm. If you are accessing the Helm Services on behalf of the organization that you represent, you represent and warrant that you have the authority to agree to the Terms on its behalf. If you do not agree with the Terms, do not use or access the Helm Services. The terms of each Order Form are incorporated herein such that the Terms and each Order Form should be construed as a single agreement. In these Terms, references to “Company,” “we,” “us,” and “our” refer to Velificor, Inc.; “you,” “your,” and “ours” refer to the organization identified as the Customer in the applicable Order Form; and references to the “Service” refer to the online software as a service (“SaaS”) and supporting services subscribed to by you and made available by us, as described in the Order Form. We and you are each sometimes referred to herein as a “party.”
      </Typography>

      <Box my={1} />

      <Typography variant="subtitle1">
        1. SaaS Services and Support
      </Typography>
      <Typography variant="body1">
        <b>1.1</b> Subject to the agreement of these Terms, Company will use commercially reasonable efforts to provide Customer the Services.
        <br />
        <b>1.2</b> Subject to the Terms hereof, Company will provide Customer with reasonable technical support services in accordance with the Terms set forth in Exhibit A.
        <br />
        <b>1.3</b> With respect to each Order Form, you will have registered with the Service and will identify an administrative contact, including username and password, for your account. You are required to maintain and promptly update all information provided by you during your registration process, and any other information you provide to us, so that it remains true, accurate, up-to-date and complete at all times. You represent and warrant that all such information provided by you is true, accurate and complete at the time it is provided.
        <br />
        <b>1.4</b> For purposes of these Terms, “Authorized User” means each of your employees, contractors and other individuals whom you identify to us by name as being authorized to use the Service on your behalf. A unique username and password will be provided for each Authorized User to enable such Authorized User to access the Service on your behalf in accordance with these Terms. We reserve the right to change or update such usernames and passwords in our sole discretion from time to time. Each such username and password may not be shared and may only be used to access the Service during one (1) concurrent login session. You (a) will provide us with information and other assistance as reasonably necessary to enable us to establish such usernames and passwords; (b) will verify all requests for such usernames and passwords; (c) are responsible for maintaining the confidentiality of all such usernames and passwords; (d) are solely responsible for all activities that occur under such usernames and for Authorized Users’ compliance with these Terms and any Order Forms; and (e) will notify us promptly of any actual or suspected unauthorized use of your account or such usernames or passwords, or any other breach or suspected breach of these Terms.
        <br />
        <b>1.5</b> The Service may allow you to connect to or otherwise interact with one or more third-party service providers (for example, a workplace communication tool) for purposes permitted by the Service. Such access may be implemented, suspended or terminated by us from time to time in our sole discretion. It is your sole responsibility to enter into and maintain any agreement between you and any such third party for the provision of their services to you or otherwise, and we are not hereby made a party to such agreement. To the extent you or your representatives or Authorized Users use the Service to transmit any Customer Content (defined below) to any such third party, you direct and authorize us to provide such Customer Content to such third party. To the extent you or your representatives or Authorized Users use the Service to connect or otherwise interact with any such third party, or have identified or designated any such third party as your third-party service provider, you authorize us to allow such third party to access your Customer Content as necessary for us to provide the Service to you. You acknowledge and agree that such third parties are not our agents, that we are not responsible for their services, compliance, actions or omissions or for their maintenance or treatment of your Customer Content, that we will not be liable for and specifically disclaim liability for any damage or loss caused thereby, that access to such third party via the Service does not imply any endorsement by us, and that any of your Customer Content submitted to such third parties via the Service will be governed by your agreement (if any) with such third party. We are not responsible for any disclosure, modification or deletion of Customer Content resulting from access by such third party.
        <br />
        <b>1.6</b> Access to the Service is purchased on a subscription basis by a written order form describing the Service to be provided and signed by both parties, or in another manner agreed by the parties (in each case, an “Order Form”). Subject to these Terms, we hereby grant you a non-exclusive, non-sublicensable, non-transferable (except as provided herein) license, during the Term (defined below), to access and use the Service solely for your internal business purposes and in accordance with any Service documentation or product feature descriptions made available to you by us in tangible or electronic format (collectively “Documentation”).
      </Typography>

      <Box my={1} />

      <Typography variant="subtitle1">
        2. Restrictions and Responsibility
      </Typography>
      <Typography variant="body1">
        <b>2.1</b> Customer will not, directly or indirectly: reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or data related to the Services (“Software”); modify, translate, or create derivative works based on the Services or any Software (except to the extent expressly permitted by Company or authorized within the Services); use the Services or any Software for timesharing or service bureau purposes or otherwise for the benefit of a third; or remove any proprietary notices or labels.
        <br />
        <b>2.2</b> Customer represents, covenants, and warrants that Customer will use the Services only in compliance with Company’s standard published policies then in effect (the “Policy”) and all applicable laws and regulations. Although Company has no obligation to monitor Customer’s use of the Services, Company may do so and may prohibit any use of the Services it believes may be (or alleged to be) in violation of the foregoing.
        <br />
        <b>2.3</b> As between you and us, you are the owner of the content, information, and other data uploaded by you to the Service or Software, or otherwise transmitted by you or your representatives or third-party service providers in connection with your use of the Service (collectively, “Customer Content”). You hereby grant us a non-exclusive, worldwide, royalty-free and fully paid license, during the Term, to use the Customer Content as necessary for the purpose of providing the Service to you. You shall be responsible for the accuracy, quality and legality of Customer Content, the means by which you acquired Customer Content, your use of Customer Content with the Service and your interoperation of any Customer Content with the Service.
        <br />
        <b>2.4</b> Customer shall be responsible for obtaining and maintaining any equipment and ancillary services needed to connect to, access or otherwise use the Services, including, without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively, “Equipment”).  Customer shall also be responsible for maintaining the security of the Equipment, Customer account, passwords (including but not limited to administrative and user passwords) and files, and for all uses of Customer account or the Equipment with or without Customer’s knowledge or consent.
      </Typography>

      <Box my={1} />

      <Typography variant="subtitle1">
        3. Confidentiality; Proprietary Rights
      </Typography>
      <Typography variant="body1">
        <b>3.1</b> Each party (the “Receiving Party”) understands that the other party (the “Disclosing Party”) has disclosed or may disclose business, technical or financial information relating to the Disclosing Party’s business (hereinafter referred to as “Proprietary Information” of the Disclosing Party).  Proprietary Information of Company includes non-public information regarding features, functionality, and performance of the Service.  Proprietary Information of Customer includes non-public data provided by Customer to Company to enable the provision of the Services (“Customer Data”). The Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to any third person any such Proprietary Information.  The Disclosing Party agrees that the foregoing shall not apply with respect to any information after three (3) years following the disclosure thereof or any information that the Receiving Party can document (a) is or becomes generally available to the public, or (b) was in its possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully disclosed to it without restriction by a third party, or (d) was independently developed without use of any Proprietary Information of the Disclosing Party or (e) is required to be disclosed by law.
        <br />
        <b>3.2</b> Customer shall own all right, title and interest in and to the Customer Data. Company shall own and retain all right, title and interest in and to (a) the Services and Software, all improvements, enhancements or modifications thereto, (b) any suggestions, ideas, enhancement requests, feedback, recommendations provided by Customer, and (c) all intellectual property rights related to any of the foregoing.
        <br />
        <b>3.3</b> Notwithstanding anything to the contrary, Company shall have the right to collect and analyze data, feedback, recommendations, and other information relating to the provision, use and performance of various aspects of the Services and related systems and technologies (including, without limitation, information concerning Customer Data and data derived therefrom), and  Company will be free (during and after the term hereof) to (i) use such information and data to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other Company offerings, and (ii) disclose such data solely in aggregate or other de-identified form in connection with its business. No rights or licenses are granted except as expressly set forth herein.
      </Typography>

      <Box my={1} />

      <Typography variant="subtitle1">
        4. Payment of Fees
      </Typography>
      <Typography variant="body1">
        <b>4.1</b> Customer will pay Company the then applicable fees described in the Order Form for the Services in accordance with the terms therein (the “Fees”).  Fees are nonrefundable except as expressly provided herein. Any Fees per Authorized User will apply with respect to the initial number of Authorized Users identified in the Order Form, plus any additional actual Authorized Users (on a prorated basis based on when such additional Authorized Users are added). If Customer’s use of the Services exceeds the Service Capacity set forth on the Order Form or otherwise requires the payment of additional fees (per the Terms of this agreement), Customer shall be billed for such usage and Customer agrees to pay the additional fees in the manner provided herein.  Company reserves the right to change the Fees or applicable charges and to institute new charges and Fees at the end of the Initial Service Term or thencurrent renewal term, upon thirty (30) days prior notice to Customer (which may be sent by email). If Customer believes that Company has billed Customer incorrectly, Customer must contact Company no later than 60 days after the closing date on the first billing statement in which the error or problem appeared, in order to receive an adjustment or credit.  Inquiries should be directed to Company’s customer support department.
        <br />
        <b>4.2</b> If you pay your Fees by credit card, you hereby authorize us and our third-party payment service providers to collect all due and payable Fees using the credit card or other payment method you provide to us, and you must keep the payment method and other billing information you provide to us current at all times; any change in such method or information will not affect charges we submit via the prior payment method and information before we reasonably could act on the change. All invoices, fees and any other applicable charges are due and payable to us within thirty (30) days after the date of our applicable invoice. Unpaid amounts are subject to a finance charge of 1.5% per month on any outstanding balance, or the maximum permitted by law, whichever is lower, plus all expenses of collection and may result in immediate termination of Service. All Fees and other amounts are payable in United States Dollars (“USD”).
        <br />
        <b>4.3</b> Fees do not include any taxes, levies, duties, export or import fees, or other governmental assessments of any nature, including but not limited to value-added sales, use or withholding taxes, imposed or assessed by any jurisdiction (collectively, “Taxes”). You are responsible for the payment of all applicable Taxes (other than Taxes assessable against us based on our income, property, franchise, or employment) associated with your subscription to the Service. You agree to cooperate with us and provide us with timely and accurate information as may be required for the calculation and withholding of applicable Taxes. If we have a legal obligation to collect and remit Taxes for which you are responsible, we will invoice you and you will pay us that amount unless you provide us with a valid tax exemption certificate authorized by the appropriate taxing authority.
      </Typography>

      <Box my={1} />

      <Typography variant="subtitle1">
        5. Term and Termination
      </Typography>
      <Typography variant="body1">
        <b>5.1</b> Subject to earlier termination as provided below, these Terms are for the Initial Service Term as specified in the Order Form, and shall be automatically renewed for additional periods of the same duration as the Initial Service Term (collectively, the “Term”), unless either party requests termination at least thirty (30) days prior to the end of the then-current term.
        <br />
        <b>5.2</b> If Customer registers for a free trial or a freemium subscription for the Services, Company will make such Services available to Customer on a trial or freemium basis free of charge until the earlier of (i) the end of the free trial period applicable to Customer; (ii) the start date of any paid subscription purchased by Customer for such Services; or (iii) termination of the trial or the freemium subscription at any time by Company or Customer, in its sole discretion.
        <br />
        <b>5.3</b> In addition to any other remedies it may have, either party may also terminate these Terms upon thirty (30) days’ notice (or without notice in the case of nonpayment), if the other party materially breaches any of the terms or conditions of these Terms.  Customer will pay in full for the Services up to and including the last day on which the Services are provided. Upon any termination, Company will make all Customer Data available to Customer for electronic retrieval for a period of thirty (30) days, but thereafter Company may, but is not obligated to, delete stored Customer Data. Upon the termination of these Terms, Customer will immediately cease use of the Services and will destroy or return any Software that Customer possesses.  All sections of these Terms which by their nature should survive termination will survive termination, including, without limitation, accrued rights to payment, confidentiality obligations, warranty disclaimers, and limitations of liability.
      </Typography>

      <Box my={1} />

      <Typography variant="subtitle1">
        6. Warranty and Disclaimer
      </Typography>
      <Typography variant="body1">
        Company shall use reasonable efforts consistent with prevailing industry standards to maintain the Services in a manner which minimizes errors and interruptions in the Services.  Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Company or by third-party providers, or because of other causes beyond Company’s reasonable control, but Company shall use reasonable efforts to provide advance notice in writing or by e-mail of any scheduled service disruption.  However, Company does not warrant that the Services will be uninterrupted or error free; nor does it make any warranty as to the results that may be obtained from use of the Services.  EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, THE SERVICES ARE PROVIDED “AS IS” AND COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
      </Typography>

      <Box my={1} />

      <Typography variant="subtitle1">
        7. Limitation of Liability
      </Typography>
      <Typography variant="body1">
        NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY OF A PERSON, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THESE TERMS OF SERVICE RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND COMPANY’S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY CUSTOMER TO COMPANY FOR THE SERVICES UNDER THESE TERMS IN THE 12 MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </Typography>

      <Box my={1} />

      <Typography variant="subtitle1">
        8. Miscellaneous
      </Typography>
      <Typography variant="body1">
        <b>8.1</b> Public Announcements. You grant us the right to use your name, logo, trademarks and/or trade names in press releases, webpages, product brochures and financial reports indicating that you are a customer of ours. All other public statements or releases will require the mutual consent of the parties.
        <br />
        <b>8.2</b> Force Majeure. Neither party will be liable for any delay or failure to perform its obligations hereunder resulting from any cause beyond such party’s reasonable control, including pandemic, weather, fire, floods, pandemic, labor disputes, riots or civil disturbances, acts of government, and acts of war or terrorism, provided that, in every case, the delay or failure to perform is beyond the control and without the fault or negligence of the party claiming excusable delay and that such party cures the breach as soon as possible after the occurrence of the unforeseen event.
        <br />
        <b>8.3</b> If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect and enforceable.  These Terms are not assignable, transferable or sublicensable by Customer except with Company’s prior written consent.  Company may transfer and assign any of its rights and obligations under these Terms without consent.  These Terms are the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms, and that all waivers and modifications must be in a writing signed by both parties, except as otherwise provided herein.  No agency, partnership, joint venture, or employment is created as a result of these Terms and Customer does not have any authority of any kind to bind Company in any respect whatsoever.  In any action or proceeding to enforce rights under these Terms, the prevailing party will be entitled to recover costs and attorneys’ fees.  All notices under these Terms will be in writing and will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; the day after it is sent, if sent for next day delivery by recognized overnight delivery service; and upon receipt, if sent by certified or registered mail, return receipt requested.  These Terms shall be governed by the laws of the State of Ohio without regard to its conflict of laws provisions.  Customer agrees to provide reasonable access to human resources staff or users for questions, feedback, or testimonials regarding the Services, and Customer otherwise agrees to reasonably cooperate with Company to serve as a reference account upon request.
      </Typography>

      <Box my={1} />

      <Typography variant="subtitle1">
        Exhibit A
      </Typography>
      <Typography variant="body1">
        <b>Support Terms</b>
        <br />
        Company will provide Technical Support to Customer via both telephone and electronic mail on weekdays during the hours of 9:00 am through 5:00 pm Eastern time, with the exclusion of Federal Holidays.
        Customer may initiate a ticket any time by emailing support@joinhelm.com.
        Helm will use commercially reasonable efforts to respond to all Helpdesk tickets within one (1) business day.
      </Typography>

      <Box my={10} />
    </Container>
  )
}
