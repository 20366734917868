import {imageSrc} from './images'
import {office_profiles} from './demo/office-profiles'

export function giveNumberWorkforceUsers(parent) {
  let profiles = parent.state.profiles
  let slackUsers = parent.state.slackUsers
  if(profiles) {
    if(parent.state.user.independent) {
      return office_profiles.length + 1
    } else if(!slackUsers || !slackUsers.length) {
      return profiles.length
    } else {

      let existingSlackIds = []
      profiles.forEach((profile, i) => {
        if(profile.slack_id) {
          existingSlackIds.push(profile.slack_id)
        }
      });

      let count = profiles.length
      slackUsers.forEach((user, i) => {
        if(!existingSlackIds.includes(user.id)) {
          count++
        }
      });
      return count

    }
  } else return 0
}

export function getWorkforceDomains(profiles, giveFullDomain) {
  let domains = []

  profiles.forEach((profile, i) => {
    if(profile.domains) {
      profile.domains.forEach((domain, i) => {
        if(!domains.includes(domain.title)) {
          domains.push(giveFullDomain ? domain : domain.title)
        }
      });
    }
  });
  console.log(domains)
  return domains
}

export function giveWorkforceName(workforce, user) {
  if(workforce && workforce.name) {
    return workforce.name
  } else if (user.workforce_name) {
    return user.workforce_name
  } else return ''
}

export function giveWorkforceLogo(workforce) {
  if(workforce && workforce.logo) {
    return imageSrc(workforce.logo, true)
  } else {
    return 'https://www.google.com/imgres?imgurl=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2Fthumb%2F7%2F7e%2FSphere_wireframe_10deg_6r.svg%2F640px-Sphere_wireframe_10deg_6r.svg.png&imgrefurl=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FSphere&tbnid=Lz3Ddgu9-IIedM&vet=12ahUKEwivxrX2i8v2AhUCBs0KHWi4AYUQMygAegUIARDcAQ..i&docid=Zw6FdQRlsR1WAM&w=640&h=640&q=sphere&ved=2ahUKEwivxrX2i8v2AhUCBs0KHWi4AYUQMygAegUIARDcAQ'
  }
}
