import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#B1ADBA',
    backgroundColor: 'transparent',
    width: '100%',
    fontSize: 14,
    padding: '4px 8px 4px 8px',
    border: '1px solid #312E3B',
    borderRadius: 8,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#312E3B',
      color: '#D0CED6',
      boxShadow: 'none',
    },
  },
}));

function ButtonBrick(props) {
  const { children, ...passThroughProps } = props
  const classes = useStyles();

  return (
    <Button variant="contained" { ...passThroughProps } className={ classes.root }>
      {children}
    </Button>
  )
}
export default ButtonBrick
