import React, {Component} from 'react';
import Cookies from 'universal-cookie';
import { Redirect } from "react-router-dom"
import * as EmailValidator from 'email-validator';
import {
  TextField,
  Typography,
  Container,
  Box,
  Grid,
  Link,
  Slide,
  Fade,
  Hidden
} from '@material-ui/core';
import {sendDemoUserToCustomerIO} from "../api/customer-io"
import ButtonPurple from '../components/general/buttons/ButtonPurple'
import ButtonWhite from '../components/general/buttons/ButtonWhite'
import helmWht from '../assets/Helm-wordmark-wt.png'
import peopleWiki from '../assets/people-wiki-preview.png'

class Demo extends Component {

  constructor() {
    super()
    this.state = {
      email: '',
      step: 1
    }
  }

  render() {
    let {toProfile, toDemo, step, email, error} = this.state


    if (toProfile) {
      return <Redirect to={`/`} />
    }

    if (toDemo) {
      return <Redirect to={`/?d=1`} />
    }

    const giveCookie = () => {
      const cookies = new Cookies();
      cookies.set('demo', 'true', { path: '/' });
      this.setState({toDemo: true})
    }

    const submitEmail = () => {
      if(EmailValidator.validate(email)) {
        sendDemoUserToCustomerIO(email)
        this.setState({step: 2})
      } else {
        this.setState({error: 'Provide a valid email'})
      }

    }

    return (
      <div style={{ backgroundColor: '#270093', minHeight: '100vh' }}>
        <Container maxWidth="lg">
          <Box pt={3} pb={1} width="100%">
            <Grid container direction="row" justify="space-between">
              <img src={helmWht} alt="Helm" style={{ height: 50 }} />
            </Grid>
          </Box>
        </Container>
        {step === 1 ? (
          <>
            <Container maxWidth="sm">
                <Box my={10} />
                <Box p={3} style={{ background: '#FFF', borderRadius: 12 }}>
                  <Typography variant="h5">Enter your email to demo the <Link href="https://joinhelm.com/" target="_blank" rel="noopener noreferrer" style={{ color: '#4300FF'}}>People Wiki.</Link></Typography>
                  <Typography color="error">{error}</Typography>
                  <TextField
                    margin="dense"
                    label={`Email`}
                    color="primary"
                    placeholder="Type your email to enter ..."
                    fullWidth
                    type="email"
                    onChange={(event) => this.setState({ email: event.target.value })}
                    value={email}
                  />
                  <Box m={2} />
                  <ButtonPurple onClick={() => submitEmail()}

                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        submitEmail()
                      }
                    }}
                  >
                    Get started
                  </ButtonPurple>
                </Box>
            </Container>
          </>
        ) : (
          <>
            <Container maxWidth="lg">
              <Box px={3} style={{ color: '#FFF' }}>
                <Grid container direction="row" spacing={10} alignItems="center">
                  <Slide direction="right" in="true" timeout={800}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography variant="h6" component="h6" gutterBottom style={{ color: '#00FFCC'}}>
                        What is Helm:
                      </Typography>
                      <Typography variant="h1">
                        People Wiki
                      </Typography>
                      <Typography variant="h6" style={{ color: '#00FFCC', fontWeight: 500, opacity: 0.6 }}>
                        <i>noun /ˈpēpəl ˈwikē/</i>
                      </Typography>
                      <Box m={3} />
                      <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 900 }}>
                        Profiles that make connections. It plugs in everywhere your people work online.  <span style={{ color: '#00FFCC' }}>It showcases who your people are.</span>
                      </Typography>
                      <Box my={5}>
                        <Grid container direction="row">
                          <ButtonWhite
                            onClick={() => giveCookie()}
                          >
                            Take a tour
                          </ButtonWhite>
                          <Box m={0.5} />
                          <ButtonPurple
                            onClick={() => this.setState({toProfile: true})}
                          >
                            Skip tour
                          </ButtonPurple>
                        </Grid>
                      </Box>
                      {/* JOBS TO BE DONE */}
                      <Box p={1} my={1} style={{ background: '#4300FF', borderRadius: 12, width: '100%' }}>
                        <Grid container direction="row" alignItems="center">
                          <Box style={{ background: '#FFF', borderRadius: 8, width: 60, height: 60 }}>
                            <Grid container direction="column" justify="center" style={{ width: '100%', height: '100%' }}>
                              <Typography variant="h4" align="center">
                                <span aria-label="handshake" role="img">🤝</span>
                              </Typography>
                            </Grid>
                          </Box>
                          <Box pl={2} style={{ width: 'calc(100% - 60px)' }}>
                            <Typography variant="subtitle1" style={{ fontWeight: 900 }}>
                              New hire intros
                            </Typography>
                            <Typography variant="body1">
                              Instantly connect them to leads, peers, and mentors
                            </Typography>
                          </Box>
                        </Grid>
                      </Box>
                      <Box p={1} my={1} style={{ background: '#4300FF', borderRadius: 12, width: '100%' }}>
                        <Grid container direction="row" alignItems="center">
                          <Box style={{ background: '#FFF', borderRadius: 8, width: 60, height: 60 }}>
                            <Grid container direction="column" justify="center" style={{ width: '100%', height: '100%' }}>
                              <Typography variant="h4" align="center">
                                <span aria-label="mentor" role="img">🧭</span>
                              </Typography>
                            </Grid>
                          </Box>
                          <Box pl={2} style={{ width: 'calc(100% - 60px)' }}>
                            <Typography variant="subtitle1" style={{ fontWeight: 900 }}>
                              Mentor matching
                            </Typography>
                            <Typography variant="body1">
                              Connect expertise with teammates seeking to grow
                            </Typography>
                          </Box>
                        </Grid>
                      </Box>
                      <Box p={1} my={1} style={{ background: '#4300FF', borderRadius: 12, width: '100%' }}>
                        <Grid container direction="row" alignItems="center">
                          <Box style={{ background: '#FFF', borderRadius: 8, width: 60, height: 60 }}>
                            <Grid container direction="column" justify="center" style={{ width: '100%', height: '100%' }}>
                              <Typography variant="h4" align="center">
                                <span  aria-label="compass" role="img">🔍</span>
                              </Typography>
                            </Grid>
                          </Box>
                          <Box pl={2} style={{ width: 'calc(100% - 60px)' }}>
                            <Typography variant="subtitle1" style={{ fontWeight: 900 }}>
                              People search
                            </Typography>
                            <Typography variant="body1">
                              Easily find who you need and how to reach them
                            </Typography>
                          </Box>
                        </Grid>
                      </Box>

                    </Grid>
                  </Slide>
                  <Fade direction="in" in="true" timeout={2000}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Hidden smDown>
                        <img alt="the people wiki" src={peopleWiki} style={{ width: '100%', paddingLeft: 50, paddingRight: 0 }} />
                      </Hidden>
                      <Hidden mdUp>
                        <img alt="the people wiki" src={peopleWiki} style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }} />
                      </Hidden>
                    </Grid>
                  </Fade>
                </Grid>
              </Box>
            </Container>
          </>
        )}

      </div>
    )
  }
}
export default Demo;
