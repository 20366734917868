import React from 'react'
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SquareAvatar from '../general/avatars/SquareAvatar'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import {giveFirstName, giveLastName, giveTitle} from '../../utility/profile'
import {imageSrc} from '../../utility/images'
import {clickProfile, clickSlackProfile} from '../../utility/tabs'

function ProfilePreview(props) {
  const {parent, user, isSlackUser, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      background: '#EFEFF1',
      borderRadius: 12,
      width: '100%',
      '&:hover': {
        background: '#E6E4EC',
        cursor: 'pointer'
      },
    },
  }));
  const classes = useStyles();

  let fname = isSlackUser ? user.profile.first_name : giveFirstName(user)
  let lname = isSlackUser ? user.profile.last_name : giveLastName(user)
  let title = isSlackUser ? user.profile.title : giveTitle(user)
  let image = isSlackUser ? user.profile.image_72 : imageSrc(user)

  let fullName = `${fname} ${lname}`
  if( (!fname && !lname) || fullName.length < 3) {
    fullName = '[Nameless user]'
  }

  const onClick = () => {
    if(isSlackUser) {
      clickSlackProfile(parent, user)
    } else {
      clickProfile(parent, user)
    }
  }

  return (
    <Box p={2} className={classes.root}
      onClick={() => onClick()}  { ...passThroughProps }
    >
      <Grid container direction="row" justify="space-between" alignItems="center">
        <SquareAvatar src={image} />
        <Box pl={2} style={{ width: 'calc(100% - 160px)' }}>
          <Typography variant="h6" style={{ letterSpacing: -0.1, weight: 700, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'  }}>
            {fullName} <span style={{ fontWeight: 500, color: '#726B83' }}>{user._id === parent.state.user._id ? ' | you' : ''}</span>
          </Typography>
          <Typography variant="body1" style={{ color: '#726B83', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'  }}>
            {title}
          </Typography>
        </Box>
        <ChevronRightRoundedIcon style={{ color: '#141217', fontSize: 36 }} />
      </Grid>
    </Box>
  )
}
export default ProfilePreview
