export const getByAuth0 = async (auth0Id, token) => {
  try {
    const response = await fetch(`https://joinhelm.com/api/userByAuth0/${auth0Id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();

    return responseData
  } catch (error) {
    console.log(error)
  }
};

export const createUser = async (user) => {
  try {
    const response = await fetch(`https://joinhelm.com/api/signup`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(user)
    });

    const responseData = await response.json();

    return responseData
  } catch (error) {
    console.log(error)
  }
};

export const updateUser = (userId, token, user) => {
  return fetch(`https://joinhelm.com/api/user/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(user)
  })
    .then(response => {
      return response.json()
    })
    .catch(err => console.log(err))
}

export const createPermissionToken = async (permission_token, token) => {
  try {
    const response = await fetch(`https://joinhelm.com/api/permissionToken`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(permission_token)
    });

    const responseData = await response.json();

    return responseData
  } catch (error) {
    console.log(error)
  }
};

export const getIndependentUser = async (userId) => {
  console.log('trying')
  try {
    const response = await fetch(`https://joinhelm.com/api/userIndependent/${userId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    });

    const responseData = await response.json();
    responseData.independent = true
    return responseData
  } catch (error) {
    console.log(error)
  }
};
