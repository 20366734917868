import React from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import peopleSearch from '../../assets/people-search-preview.png'

function ContactPreview(props) {
  let {parent} = props

  const close = () => {
    parent.setState({ contactPreview: false })
  }
  const giveDialog = () => {
    return (
      <>
        <DialogTitle style={{ background: '#FFF' }}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="overline" style={{ color: '#726B83' }}>
              PREVIEW
            </Typography>
            <IconButton size="small" onClick={() => close()}>
              <CloseRoundedIcon style={{ color: '#726B83' }} />
            </IconButton>
          </Grid>
          <Box>
            <Typography variant="h4" gutterBottom>
              Make it easy to connect<br/>
              <span style={{ color: '#4300FF' }}>th.</span>
            </Typography>
            <Typography variant="body1" style={{ color: '#726B83' }}>
              <b>Find who you need, wherever they are.</b> Helm's people search uses the rich data in profiles (+ some wiki knowlege) to show you exactly what you're looking for with a simple search.
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent style={{ background: '#FFF' }}>
          <Box p={2} mb={1.5} style={{ background: '#4300FF', color: '#FFF',  borderRadius: 12 }}>
            <Typography variant="h6" gutterBottom>
              Contact preferences
            </Typography>
            <Typography variant="body2" style={{ color: 'rgba(255,255,255,0.7)' }}>
              <b>Plus, it lives in Slack.</b> Every question answered is a connection made.
            </Typography>
            <Box px={8} pt={3}>
              <img src={peopleSearch} alt="" style={{ width: '100%' }} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonPurple onClick={() => close()}>
            Done
          </ButtonPurple>
        </DialogActions>
      </>
    )
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={true} onClose={() => close()}>
        {giveDialog()}
      </Dialog>
    </>
  )
}

export default ContactPreview;
