import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';
import HiSquareChip from '../../components/general/HiSquareChip'
import domainIcon from '../../assets/icons/domain-purple.png'
import collabIcon from '../../assets/icons/collabs-green.png'
import interestIcon from '../../assets/icons/interests-yellow.png'
import {getWorkforceDomains} from '../../utility/workforce'
import {handleSearch} from './SearchResults'

function SearchSuggestions(props) {
  const {parent} = props
  let workforceDomains = getWorkforceDomains(parent.state.profiles)

  return (
    <>
      {/* Title */}
      <Box>
        <Grid container direction="row" justify="space-between">
          <Box>
            <Grid container direction="row" alignItems="center">
              <ExploreRoundedIcon style={{ fontSize: 18 }}/>
              <Box m={0.5} />
              <Typography variant="subtitle2" style={{ color: '#141217' }}>
                Guides: <span style={{ fontWeight: 500, color: '#726B83' }}>Search something like...</span>
              </Typography>
            </Grid>
          </Box>
          {parent.state.tab === 'search' ? (
            <>
              <Box p={2} />
            </>
          ):(
            <IconButton onClick={() => parent.setState({suggestionOverlay: false, })}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>
      </Box>
      {/* Collab search */}
      <Box py={2}>
        <Typography variant="h5" style={{ verticalAlign: 'middle', color: '#D0CED6' }}>
          Find someone to <span style={{ color: '#009779', paddingLeft: 8 }}><img src={collabIcon} alt="Collab" style={{ width: 23 }} /> Collab</span> with
        </Typography>
        <Box py={1}>
          <Grid container direction="row">
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                styleVariant="green"
                onClick={() => handleSearch(parent, 'New hire mentoring', parent.state.profiles)}
                label="New hire mentoring"
              />
            </Box>
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                styleVariant="green"
                onClick={() => handleSearch(parent, 'Grab coffee', parent.state.profiles)}
                label="Grab coffee"
              />
            </Box>
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                styleVariant="green"
                onClick={() => handleSearch(parent, 'Grab lunch', parent.state.profiles)}
                label="Grab lunch"
              />
            </Box>
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                styleVariant="green"
                onClick={() => handleSearch(parent, 'Take a walk', parent.state.profiles)}
                label="Take a walk"
              />
            </Box>
          </Grid>
        </Box>
      </Box>
      {/* Domain search */}
      <Box py={2}>
        <Typography variant="h5" style={{ verticalAlign: 'middle', color: '#D0CED6' }}>
          Filter by <span style={{ color: '#4300FF', paddingLeft: 8 }}><img src={domainIcon} alt="Domain" style={{ width: 23 }} /> Domain</span>
        </Typography>
        <Box py={1}>
          <Grid container direction="row">
            {workforceDomains.length ? (
              <>
                {workforceDomains.map((domain, i) => {
                  let limit = 5
                  if (domain && i <= limit) {
                    return (
                      <>
                        <Box mr={0.5} mb={0.5} key={i}>
                          <HiSquareChip clickable
                            styleVariant="purple"
                            onClick={() => handleSearch(parent, domain, parent.state.profiles)}
                            label={domain}
                          />
                        </Box>
                      </>
                    )
                  } else return null
                })}
              </>
            ) : null}
          </Grid>
        </Box>
      </Box>
      {/* Interest search */}
      <Box py={2}>
        <Typography variant="h5" style={{ verticalAlign: 'middle', color: '#D0CED6' }}>
          Explore people with common <span style={{ color: '#8B7600', paddingLeft: 8 }}><img src={interestIcon} alt="Interests" style={{ width: 23 }} /> Interests</span>
        </Typography>
        <Box py={1}>
          <Grid container direction="row">
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                styleVariant="yellow"
                onClick={() => handleSearch(parent, '☕️ Cafe-hopping', parent.state.profiles)}
                label="☕️ Cafe-hopping"
              />
            </Box>
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                styleVariant="yellow"
                onClick={() => handleSearch(parent, '📷 Photography', parent.state.profiles)}
                label="📷 Photography"
              />
            </Box>
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                styleVariant="yellow"
                onClick={() => handleSearch(parent, '⛷ Skiing', parent.state.profiles)}
                label="⛷ Skiing"
              />
            </Box>
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                styleVariant="yellow"
                onClick={() => handleSearch(parent, '🥘 Cooking', parent.state.profiles)}
                label="🥘 Cooking"
              />
            </Box>
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                styleVariant="yellow"
                onClick={() => handleSearch(parent, '🎒 Backpacking', parent.state.profiles)}
                label="🎒 Backpacking"
              />
            </Box>
          </Grid>
        </Box>
      </Box>
      {/* Interest search */}
      <Box py={2}>
        <Typography variant="h5" style={{ verticalAlign: 'middle', color: '#D0CED6' }}>
          Answer a <span style={{ color: '#141217', paddingLeft: 2 }}>FAQ</span>
        </Typography>
        <Box py={1}>
          <Grid container direction="row">
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                onClick={() => handleSearch(parent, 'Forgot my password', parent.state.profiles)}
                label="Forgot my password"
              />
            </Box>
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                onClick={() => handleSearch(parent, 'Joseph Cannon', parent.state.profiles)}
                label="Who do I report to"
              />
            </Box>
            <Box mr={0.5} mb={0.5}>
              <HiSquareChip clickable
                onClick={() => handleSearch(parent, 'Product team', parent.state.profiles)}
                label="See my teammates"
              />
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  )
};

export default SearchSuggestions;
