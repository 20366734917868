import React from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import {capitalizeFirstLetter} from '../../utility/profile'
import {collabs, adjectives, defaultDomains} from '../../utility/constants'
import {contactInput} from '../../pages/tabs/UpdateProfile'
import {updateUser} from '../../api/user'

import PurpleSquareChip from '../../components/general/chips/PurpleSquareChip'
import YellowSquareChip from '../../components/general/chips/YellowSquareChip'
import BlueSquareChip from '../../components/general/chips/BlueSquareChip'
import GreenSquareChip from '../../components/general/chips/GreenSquareChip'
import ButtonBlack from '../../components/general/buttons/ButtonBlack'
import ButtonText from '../../components/general/buttons/ButtonText'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import {
  Box,
  Grid,
  Typography,
  TextField,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogActions
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';


const longCategories = [1]
const longCutoffs = [20]

const ProfileStrength = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:'rgba(20,18,23,0.1)' ,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#00D9AE',
  },
}))(LinearProgress);


function toggleCollab(parent, collab) {
  let foundIndex = -1
  parent.state.onboardingCollabs.forEach((c, i) => {
    if(c.title === collab.title) {
      foundIndex = i
    }
  });

  if(foundIndex > -1) {
    parent.state.onboardingCollabs.splice(foundIndex, 1)
  } else {
    parent.state.onboardingCollabs.push(collab)
  }
  parent.forceUpdate()
}

function addNewDomain(parent, givenVal) {
  let value = givenVal ? givenVal : parent.state.newDomain
  if(value) {
    parent.state.onboardingDomains.push({
      title: value,
      capabilties: []
    })
    parent.setState({newDomain: ''})
    parent.forceUpdate()
  }
}
function removeDomain(parent, index) {
  parent.state.onboardingDomains.splice(index, 1)
  parent.forceUpdate()
}

function toggleAdjective(parent, adjective) {
  let index = parent.state.onboardingAdjectives.indexOf(adjective)
  if(index === -1) {
    if(parent.state.onboardingAdjectives.length < 5) {
      parent.state.onboardingAdjectives.push(adjective)
    }
  } else {
    parent.state.onboardingAdjectives.splice(index, 1)
  }
  parent.forceUpdate()
}

function toggleInterest(parent, interest) {
  let index = parent.state.onboardingInterests.indexOf(interest)
  if(index === -1) {
    parent.state.onboardingInterests.push(interest)
  } else {
    parent.state.onboardingInterests.splice(index, 1)
  }
  parent.forceUpdate()
}

function toggleDefaultDomain(parent, domainTitle) {
  let index = -1
  parent.state.onboardingDomains.forEach((domain, i) => {
    if(domain.title === domainTitle) {
      index = i
    }
  });

  if(index === -1) {
    parent.state.onboardingDomains.push({
      title: domainTitle,
      capabilties: []
    })
  } else {
    parent.state.onboardingDomains.splice(index, 1)
  }
  parent.forceUpdate()
}

function hasDomain(parent, domainTitle) {
  let index = -1
  parent.state.onboardingDomains.forEach((domain, i) => {
    if(domain.title === domainTitle) {
      index = i
    }
  });

  if(index === -1) {
    return false
  } else {
    return true
  }
}

function giveInterestSeeMore(parent, index) {
  if(longCategories.includes(index)) {
    if(parent.state[`showInterests${index}`]) {
      return (
        <ButtonText onClick={() => toggleInterestShowMore(parent, index, false)}>Show less</ButtonText>
      )
    } else {
      return (
        <ButtonText onClick={() => toggleInterestShowMore(parent, index, true)}>Show more</ButtonText>
      )
    }
  }
}

function toggleInterestShowMore(parent, index, value) {
  parent.setState({ [`showInterests${index}`]: value })
}

function onboardSubmit(parent, step, skipping) {
  let {onboardingTitle, onboardingWorkforceName, user, editProfileContactPreferences,
      onboardingCollabs, onboardingDomains, onboardingAdjectives,
      onboardingInterests} = parent.state
  let updatedUser = {}

  if(step === 'position') {
    if(onboardingTitle && onboardingTitle.length && (onboardingWorkforceName || user.workforce)) {
      updatedUser.title = onboardingTitle
      if(onboardingWorkforceName && onboardingWorkforceName.length) {
        updatedUser.workforce_name = onboardingWorkforceName
      }
      update(parent, updatedUser)
    } else {
      parent.setState({onboardingError: 'Please fill out all fields'})
    }
  } else if(step === 'contact') {
    if(skipping) {
      updatedUser.onboarding = user.onboarding
      updatedUser.onboarding.skipped.push('contact')
      update(parent, updatedUser)
    } else {
      updatedUser.contact_preferences = editProfileContactPreferences
      update(parent, updatedUser)
    }
  } else if(step === 'collabs') {
    if(skipping) {
      updatedUser.onboarding = user.onboarding
      updatedUser.onboarding.skipped.push('collabs')
      update(parent, updatedUser)
    } else {
      updatedUser.collabs = onboardingCollabs
      update(parent, updatedUser)
    }
  } else if(step === 'domains') {
    if(skipping) {
      updatedUser.onboarding = user.onboarding
      updatedUser.onboarding.skipped.push('domains', 'abilities')
      update(parent, updatedUser)
    } else if(!onboardingDomains.length) {
      parent.setState({onboardingError: 'Please give at least one domain'})
    } else {
      updatedUser.domains = onboardingDomains
      update(parent, updatedUser)
      parent.setState({editProfileDomains: onboardingDomains})
    }
  } else if(step === 'character') {
    if(skipping) {
      updatedUser.onboarding = user.onboarding
      updatedUser.onboarding.skipped.push('character')
      update(parent, updatedUser)
    } else {
      updatedUser.adjectives = onboardingAdjectives
      update(parent, updatedUser)
    }
  } else if(step === 'interests') {
    if(skipping) {
      updatedUser.onboarding = user.onboarding
      updatedUser.onboarding.skipped.push('interests')
      update(parent, updatedUser)
    } else {
      let interestObjs = []
      onboardingInterests.forEach((interest, i) => {
        interestObjs.push({
          title: interest
        })
      });

      updatedUser.interests = interestObjs
      update(parent, updatedUser)
      parent.setState({ onboardingCelebration: true })
    }
  }
}

function update(parent, user) {
  console.log(user)
  updateUser(parent.state.user._id, parent.state.token, user).then((res) => {
    if (!res.error) {
      console.log(res)
      parent.setState({ user: res, onboardingError: '' })

      let foundIndex = -1
      parent.state.profiles.forEach((p, i) => {
        if(p._id === res._id) {
          foundIndex = i
        }
      })
      if(foundIndex !== -1) {
        parent.state.profiles.splice(foundIndex, 1, res)
      }

      parent.forceUpdate()

    }
  })
}

export function onboardingDiv(parent, user, info, classes) {
  let {onboardingTitle, onboardingWorkforceName, onboardingError, onboardingCollabs,
      resources_domains, newDomain, onboardingDomains, onboardingAdjectives,
      resources_interests, resources_interestCategories, onboardingInterests,
      } = parent.state

  if(info.step === 'position') {
    return (
      <Box p={3} style={{ border: '3px solid #EFEFF1' , borderRadius: 20 }}>

        <Grid container direction="row" alignItems="center" justify="space-between">
          <Typography variant="h4" style={{ color: '#141217' }}>Position</Typography>
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Profile strength: <span style={{ color: '#009779' }}>{parent.state.profileProgress.percent}%</span>
            </Typography>

            <ProfileStrength
              value={parent.state.profileProgress.percent} variant="determinate"
            />
          </Box>
        </Grid>

        <Box m={4} />

        {/* Title */}
        <Typography variant="subtitle1">What's your title?</Typography>
        <TextField
          required
          variant="outlined"
          margin="dense"
          label="Title"
          fullWidth
          onChange={(event) => parent.setState({ onboardingTitle: event.target.value })}
          value={onboardingTitle}
        />

        <Box m={2} />

        {/* Workforce name */}
        {user.independent ? (
          <>
            <Typography variant="subtitle1">Where do you work?</Typography>
            <TextField
              required
              variant="outlined"
              margin="dense"
              label="Company name"
              fullWidth
              onChange={(event) => parent.setState({ onboardingWorkforceName: event.target.value })}
              value={onboardingWorkforceName}
            />
          </>
        ) : null}

        <Box m={1}>
          <Typography variant="body1" color="error">{onboardingError}</Typography>
        </Box>
        <Grid container direction="row" justify="flex-end">
          <ButtonBlack color="primary" variant="contained" onClick={() => onboardSubmit(parent, 'position')}>Next</ButtonBlack>
        </Grid>
      </Box>
    )
  } else if(info.step === 'contact') {
    return (
      <Box p={1} style={{ background: 'linear-gradient(95.82deg, #4300FF -6.73%, #00D9AE 86.16%)', borderRadius: 20 }}>
        <Box p={3} style={{ background: '#FFF', borderRadius: 12 }}>
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Typography variant="subtitle1" style={{ color: '#4300FF' }}>Contact</Typography>
            <Box>
              <Typography variant="subtitle2" gutterBottom style={{ color: '#726B83' }}>
                Step 1 of 5
              </Typography>
            </Box>
          </Grid>

          <Box m={1} />

          {/* Contact */}
          <Typography variant="h4">How should people reach you?</Typography>
          <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
            <b>Define how you communicate best.</b> This will take the guesswork out of teamwork
          </Typography>

          {contactInput(parent)}
          <Box p={2}>
            <Typography variant="body1" color="error">{onboardingError}</Typography>
          </Box>
          <Grid container direction="row" alignItems="center" justify="flex-end">
            <ButtonText onClick={() => onboardSubmit(parent, 'contact', true)}>Skip</ButtonText>
            <Box m={0.5} />
            <ButtonBlack onClick={() => onboardSubmit(parent, 'contact')}>Next</ButtonBlack>
          </Grid>
        </Box>
      </Box>
    )
  } else if(info.step === 'collabs') {
    return (
      <Box p={1} style={{ background: 'linear-gradient(95.82deg, #4300FF -6.73%, #00D9AE 86.16%)', borderRadius: 20 }}>
        <Box p={3} style={{ background: '#FFF', borderRadius: 12 }}>
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Typography variant="subtitle1" style={{ color: '#4300FF' }}>Collabs</Typography>
            <Box>
              <Typography variant="subtitle2" gutterBottom style={{ color: '#726B83' }}>
                Step 2 of 5
              </Typography>
            </Box>
          </Grid>

          <Box m={1} />

          {/* Collabs */}
          <Typography variant="h4">Which collabs are you open to?</Typography>
          <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
            <b>Select top 3 collabs.</b> This will help your teammates meet you where you connect best.
          </Typography>

          <Box m={4} />

          <Grid container direction="row" alignItems="center">
            {collabs.map((collab, i) => {
              return (
                <Box mr={0.5} mb={0.5}>
                  <GreenSquareChip clickable
                    onClick={() => toggleCollab(parent, collab)}
                    label={capitalizeFirstLetter(collab.title)}
                    style={{
                      background: onboardingCollabs.includes(collab) ? 'rgba(0,217,174,0.2)' : '#EFEFF1',
                      color: onboardingCollabs.includes(collab) ? '#009779' : '#726B83',
                    }}
                  />
                </Box>
              )
            })}
          </Grid>
          <Box p={2}>
            <Typography variant="body1" color="error">{onboardingError}</Typography>
          </Box>
          <Grid container direction="row" alignItems="center" justify="flex-end">
            <ButtonText onClick={() => onboardSubmit(parent, 'collabs', true)}>Skip</ButtonText>
            <Box m={0.5} />
            <ButtonBlack onClick={() => onboardSubmit(parent, 'collabs')}>Next</ButtonBlack>
          </Grid>
        </Box>
      </Box>
    )
  } else if(info.step === 'domains') {
    return (
      <Box p={1} style={{ background: 'linear-gradient(95.82deg, #4300FF -6.73%, #00D9AE 86.16%)', borderRadius: 20 }}>
        <Box p={3} style={{ background: '#FFF', borderRadius: 12 }}>
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Typography variant="subtitle1" style={{ color: '#4300FF' }}>Domains</Typography>
            <Box>
              <Typography variant="subtitle2" gutterBottom style={{ color: '#726B83' }}>
                Step 3 of 5
              </Typography>
            </Box>
          </Grid>

          <Box m={1} />

          {/* Domains */}
          <Typography variant="h4">What kind of work do you do?</Typography>
          <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
            <b>Select your primary domain(s).</b> This will help you and your team find relevant collaborators and mentors.
          </Typography>

          <Box m={4} />

          <Grid container direction="row" alignItems="center" justify="space-between">
            <Box style={{ width: '100%' }}>
              <div onKeyDown={(e) => e.keyCode === 13 ? addNewDomain(parent) : false}>
                <ReactSearchAutocomplete
                  items={resources_domains}
                  fuseOptions={{ keys: ["text"] }}
                  resultStringKeyName="text"
                  onSearch={(val) => parent.setState({newDomain: val})}
                  onSelect={(val) => addNewDomain(parent, val.text)}
                  inputSearchString={newDomain}
                  placeholder="Development, Finance, Marketing ..."
                  styling={{
                    zIndex: 100
                  }}
                />
              </div>
            </Box>
          </Grid>

          <Box m={2} />

          <Grid container direction="row">
            {defaultDomains.map((domain, i) => {
              return (
                <Box mr={0.5} mb={0.5}>
                  <PurpleSquareChip clickable
                    onClick={() => toggleDefaultDomain(parent, domain)}
                    label={capitalizeFirstLetter(domain)}
                    style={{
                      background: hasDomain(parent, domain) ? 'rgba(67,0,255,0.1)' : '#EFEFF1',
                      color: hasDomain(parent, domain) ? '#4300FF' : '#726B83',
                    }}
                  />
                </Box>
              )
            })}
          </Grid>

          <Box py={2}>
            <Typography variant="subtitle2" style={{ color: '#726B83' }} gutterBottom>
              Your domains:
            </Typography>
            <Grid container direction="row">
              {onboardingDomains.map((domain, i) => {
                return (
                  <Box mr={0.5} mb={0.5}>
                    <PurpleSquareChip
                      deleteIcon={<DeleteForeverRoundedIcon style={{ fontSize: 14 }} />}
                      onDelete={() => removeDomain(parent, i)}
                      label={domain.title}
                    />
                  </Box>
                )}
              )}
            </Grid>
          </Box>

          <Box p={2}>
            <Typography variant="body1" color="error">{onboardingError}</Typography>
          </Box>
          <Grid container direction="row" alignItems="center" justify="flex-end">
            <ButtonText onClick={() => onboardSubmit(parent, 'domains', true)}>Skip</ButtonText>
            <Box m={0.5} />
            <ButtonBlack onClick={() => onboardSubmit(parent, 'domains')}>Next</ButtonBlack>
          </Grid>
        </Box>
      </Box>
    )
  } else if(info.step === 'character') {
    return (
      <Box p={1} style={{ background: 'linear-gradient(95.82deg, #4300FF -6.73%, #00D9AE 86.16%)', borderRadius: 20 }}>
        <Box p={3} style={{ background: '#FFF', borderRadius: 12 }}>
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Typography variant="subtitle1" style={{ color: '#4300FF' }}>Domains</Typography>
            <Box>
              <Typography variant="subtitle2" gutterBottom style={{ color: '#726B83' }}>
                Step 4 of 5
              </Typography>
            </Box>
          </Grid>

          <Box m={1} />

          {/* Attributes */}
          <Typography variant="h4">What's your style at work?</Typography>
          <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
            <b>Select up to 5 attributes.</b> This will outline your unique persona and help your teammates work with you.
          </Typography>

          <Box m={4} />

          <Grid container direction="row" alignItems="center">
            {adjectives.map((adj, i) => {
              return (
                <Box mr={0.5} mb={0.5}>
                  <BlueSquareChip clickable
                    onClick={() => toggleAdjective(parent, adj)}
                    label={capitalizeFirstLetter(adj)}
                    style={{
                      background: onboardingAdjectives.includes(adj) ? 'rgba(0,195,255,0.1)' : '#EFEFF1',
                      color: onboardingAdjectives.includes(adj) ? '#0093C0' : '#726B83',
                    }}
                  />
                </Box>
              )
            })}
          </Grid>
          <Box m={1}>
            <Typography variant="body1" color="error">{onboardingError}</Typography>
          </Box>
          <Grid container direction="row" alignItems="center" justify="flex-end">
            <ButtonText onClick={() => onboardSubmit(parent, 'character', true)}>Skip</ButtonText>
            <Box m={0.5} />
            <ButtonBlack onClick={() => onboardSubmit(parent, 'character')}>Next</ButtonBlack>
          </Grid>
        </Box>
      </Box>
    )
  } else if(info.step === 'interests') {
    return (
      <Box p={1} style={{ background: 'linear-gradient(95.82deg, #4300FF -6.73%, #00D9AE 86.16%)', borderRadius: 20 }}>
        <Box p={3} style={{ background: '#FFF', borderRadius: 12 }}>
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Typography variant="subtitle1" style={{ color: '#4300FF' }}>Domains</Typography>
            <Box>
              <Typography variant="subtitle2" gutterBottom style={{ color: '#726B83' }}>
                Step 5 of 5
              </Typography>
            </Box>
          </Grid>

          <Box m={1} />

          {/* Interests */}
          <Typography variant="h4">What do you do for fun?</Typography>
          <Typography variant="body1" gutterBottom style={{ color: '#726B83' }}>
            <b>Select up to 5 attributes.</b> This will outline your unique persona and help your teammates work with you.
          </Typography>

          <Box m={4} />

          {resources_interestCategories.map((category, i) => {
            return (
              <>
                <Typography variant="subtitle1" gutterBottom>{category.text}</Typography>
                <Grid container direction="row" alignItems="center">
                  {resources_interests.map((interest, j) => {
                    if(interest.category === category._id) {
                      if(!longCategories.includes(i) || parent.state[`showInterests${i}`] || j<longCutoffs[0]) {
                        return (
                          <Box mb={0.5} mr={0.5}>
                            <YellowSquareChip clickable
                              onClick={() => toggleInterest(parent, interest.text)}
                              style={{
                                background: onboardingInterests.includes(interest.text) ? 'rgba(255, 217, 0, 0.6)' : '#EFEFF1',
                                color: onboardingInterests.includes(interest.text) ? '#8B7600' : '#726B83'
                              }}
                              label={interest.text}
                            />
                          </Box>
                        )
                      } else return null
                    } else return null
                  })}
                </Grid>
                {giveInterestSeeMore(parent, i)}
                <Box m={2} />
              </>
            )
          })}
          <Box m={1}>
            <Typography variant="body1" color="error">{onboardingError}</Typography>
          </Box>
          <Grid container direction="row" alignItems="center" justify="flex-end">
            <ButtonText onClick={() => onboardSubmit(parent, 'interests', true)}>Skip</ButtonText>
            <Box m={0.5} />
            <ButtonBlack onClick={() => onboardSubmit(parent, 'interests')}>Next</ButtonBlack>
          </Grid>
        </Box>
      </Box>
    )
  } else if(parent.state.onboardingCelebration) {
    return (
      <Dialog open={true} maxWidth="sm">
        <DialogContent>
          <Grid container direction="row" alignItems="center" >
            <div style={{ position: 'relative', paddingTop: 5 }}>
              <iframe src="https://giphy.com/embed/IwAZ6dvvvaTtdI8SD5" title="Office Gif" style={{ borderRadius: 12 }} width="240" height="200" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
            </div>
            <Typography variant="h4" style={{ color: '#141217' }} gutterBottom>Congrats! Your profile is LIVE.</Typography>
          </Grid>
          <Typography variant="body1" style={{ color: '#726B83' }}>
            <b>Now you can explore how it works.</b> Find the right people to talk to. Get to know the person you’re meeting with. Build community
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonText onClick={() => parent.setState({ onboardingCelebration: false })}>
            Skip
          </ButtonText>
          <ButtonBlack onClick={() => parent.setState({ onboardingCelebration: false, demoConnect: 1 })}>Try it</ButtonBlack>
        </DialogActions>
      </Dialog>
    )
  }
}
