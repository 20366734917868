import React from 'react';
import { updateUser, createPermissionToken } from '../api/user';
import XXSmallAvatar from '../components/general/avatars/XXSmallAvatar';

const base64 = require('base-64');
const utf8 = require('utf8');

export const createPermissionTokenAndUpdate = (user, token, role) => {
  if(!user.independent) {
    let text = user._id + '@' + user.workforce._id;
    let bytes = utf8.encode(text);
    let encoded = base64.encode(bytes);

    let permission_token = {
      value: encoded,
      permissions: {
        user_id: user._id,
        workforce_id: user.workforce._id,
        role: role,
        teams: user.teams
      }
    }
    console.log(permission_token)
    createPermissionToken(permission_token, token)
    .then((resToken) => {
      if(resToken && !resToken.error) {
        let userWithPermissionToken = {
          permission_token: encoded
        }
        updateUser(user._id, token, userWithPermissionToken).then((newUser) => {
          if(!newUser.error) {
            console.log(newUser)
            window.location.reload()
          }
        })
      }
    })
  }
}

export function getUserDomains(profile) {
  let domains = []

  if(profile.domains) {
    profile.domains.forEach((domain, i) => {
      domains.push(domain.title)
    });
  }
  return domains
}

export function giveUsersTeams(user, teams, giveFullTeams) {
  let usersTeams = []
  console.log(teams)
  console.log(user.teams)
  if(teams && teams.length && user && user.teams) {
    teams.forEach((t) => {
      if(user.teams.includes(t.team_id.toString())) {
        if(giveFullTeams) {
          usersTeams.push(t)
        } else {
          usersTeams.push(t._id)
        }
      }
    })
  }
  console.log('userTeams')
  console.log(usersTeams)
  return usersTeams
}

export function giveUsersCommunities(user_id, communities, giveFullCommunities) {
  let usersCommunities = []
  if(communities && communities.length) {
    communities.forEach((c) => {
      if(c.users) {
        let userInCommunity = false
        c.users.forEach((u) => {
          if(u._id === user_id) {
            userInCommunity = true
          }
        })
        if(userInCommunity) {
          if(giveFullCommunities) {
            usersCommunities.push(c)
          } else {
            usersCommunities.push(c._id)
          }
        }
      }
    })
  }
  return usersCommunities
}

export function giveToolImage(parent, toolText, urlOnly) {
  let url = false
  if(parent.state.resources_tools) {
    parent.state.resources_tools.forEach((tool, i) => {
      if(tool.text === toolText) {
        url = tool.image_url
      }
    });
  }

  if(url) {
    return <XXSmallAvatar src={url} style={{ borderRadius: 4 }} />
  } else return null
}
