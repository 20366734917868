import React from 'react';

import {giveNumberWorkforceUsers, giveWorkforceName, giveWorkforceLogo} from '../../utility/workforce'
import {inviteSlackUser} from '../../utility/invites'
import {listTeammates, giveProfileActions} from './Profile'

import MainNav from '../../components/nav/MainNav'
import BlackChip from '../../components/general/chips/BlackChip'
import ExtraSmallAvatar from '../../components/general/avatars/ExtraSmallAvatar'
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import {
  Box,
  Grid,
  Container,
  Avatar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  boxA: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - 300px)`,
    },
  },
  boxB: {
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 300,
      display: 'inline',
    },
  },
  bioBox: {
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 140px)',
      paddingLeft: 30,
    },
  },
  box1A: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 200,
      paddingRight: 30,
    },
  },
  box1B: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 200px)',
    },
  },
  box2A: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 300,
    },
  },
  box2B: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 300px)',
      paddingleft: 30,
    },
  },
  box3A: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 440,
    },
  },
  box3B: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 440px)',
      paddingleft: 30,
    },
  },
  avatarGroup: {
    root: {
      maxHeight: 22
    },
    avatar: {
      border: '2px solid #FFF',
    },
  },
}));

function inviteGhostUser(parent, slackUser) {
  inviteSlackUser(parent, slackUser)
}

function GhostProfile(props) {
  let {parent, teammate} = props
  const classes = useStyles()

  let workforce = parent.state.workforce

  let fname = teammate.profile.first_name
  let lname = teammate.profile.last_name
  let image = teammate.profile.image_192 ? teammate.profile.image_192 : teammate.profile.image_72

  return (
    <>
      <MainNav parent={parent} user={parent.state.user} />
      <Container style={{ maxWidth: 1000 }}>
        <Grid container direction="row">
          <Box pr={4} className={classes.boxA}>
            {/* Intro */}
            <Grid container direction="row">
              <Avatar
                style={{
                  width: 140, height: 140,
                  border: '6px solid #141217',
                  borderRadius: 20,
                  boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.1)'
                }}
                src={image}
              />
              <Box className={classes.bioBox}>
                <Typography variant="h2">
                  <i>{fname} {lname}</i>
                </Typography>
                <Typography variant="h6" style={{ color: '#4300FF', fontWeight: 600, fontFamily: 'Lato' }}>

                </Typography>
                <Box my={2.5} />
                <Grid container direction="row">
                  <Box mr={0.5} mb={0.5}>
                    <BlackChip
                      icon={<ExtraSmallAvatar src={giveWorkforceLogo(workforce)} />}
                      label={giveWorkforceName(workforce)}
                    />
                  </Box>
                   <Box mr={0.5} mb={0.5}>
                     <BlackChip
                       icon={<PeopleAltRoundedIcon style={{ color: '#FFF', fontSize: 16 }} />}
                       label={`${giveNumberWorkforceUsers(parent)-1} ${giveNumberWorkforceUsers(parent)-1 === 1 ? 'teammate' : 'teammates'}`}
                     />
                   </Box>
                </Grid>
              </Box>
            </Grid>
            <Box my={4} />
            <Box p={3} style={{ background: '#EFEFF1', borderRadius: 20 }}>
              <Typography variant="h6" style={{ color: '#141217' }}>
                <i>Invite {fname} to join Helm</i>
              </Typography>
              <Typography variant="body1" style={{ color: '#726B83' }}>
                {fname} hasn't built their profile on Helm yet. Click the button below to send them an invite in Slack!
              </Typography>
              <Box m={2} />
              {parent.state[`ghostInviteSent${teammate.id}`] ? (
                <Typography variant="subtitle2" style={{ color: '#726B83' }}>
                  Invite sent!
                </Typography>
              ) : (
                <ButtonPurple startIcon={<PersonAddRoundedIcon fontSize="small" />} onClick={() => inviteGhostUser(parent, teammate)}>
                  Invite {fname}
                </ButtonPurple>
              )}
            </Box>

            <Box my={10}/>
          </Box>

          {/* RH Column - for suggested actions */}
          <Box className={classes.boxB}>
            <Box style={{ position: 'fixed', width: 300 }}>
              {giveProfileActions(parent)}
              <Box py={3} px={3} mb={2} style={{ backgroundColor: '#EFEFF1', borderRadius: 20 }}>
                <Typography gutterBottom variant="h6">
                  Teammates
                </Typography>
                {listTeammates(parent, {_id: ''})}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Container>
    </>
  )
};

export default GhostProfile;
