import React from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
// import { v4 as uuidv4 } from 'uuid';
import {
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  NativeSelect,
  FormControl,
  InputLabel
} from '@material-ui/core';
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import ButtonToggle from '../../components/general/buttons/ButtonToggle'
import DropDownInput from '../../components/general/DropDownInput'
import RoundAvatar from '../../components/general/avatars/RoundAvatar'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import HiSquareChip from '../../components/general/HiSquareChip'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import {getWorkforceDomains} from '../../utility/workforce'
import {capitalizeFirstLetter} from '../../utility/profile'
// import {updateUser} from '../../api/user'
// import {updateWorkforce} from '../../api/workforce'



export function startCreateGroup(parent, type) {
  parent.setState({ createGroup: type, createGroupStep: 1, groupUsers: [parent.state.user._id],
    groupLeads: [parent.state.user._id],
    workforceDomains: getWorkforceDomains(parent.state.profiles, true),
    })
}

function addNewDomain(parent, givenVal) {
  let value = givenVal ? givenVal : parent.state.newDomain
  if(value) {
    parent.state.groupDomains.push(value)
    parent.setState({newDomain: ''})
    parent.forceUpdate()
  }
}
function removeDomain(parent, index) {
  parent.state.groupDomains.splice(index, 1)
  parent.forceUpdate()
}
function toggleDefaultDomain(parent, domainTitle) {
  let index = -1
  parent.state.groupDomains.forEach((domain, i) => {
    if(domain === domainTitle) {
      index = i
    }
  });
  console.log(index)
  if(index === -1) {
    parent.state.groupDomains.push(domainTitle)
  } else {
    parent.state.groupDomains.splice(index, 1)
  }
  parent.forceUpdate()
}
function hasDomain(parent, domainTitle) {
  let index = -1
  parent.state.groupDomains.forEach((domain, i) => {
    if(domain === domainTitle) {
      index = i
    }
  });

  if(index === -1) {
    return false
  } else {
    return true
  }
}
function toggleUser(parent, userId, adding, isLead) {

  let groupIndex = parent.state.groupUsers.indexOf(userId)
  let leadsIndex = parent.state.groupLeads.indexOf(userId)

  if(adding) {
    if(groupIndex === -1) {
      parent.state.groupUsers.push(userId)
    }

    if(!isLead && leadsIndex !== -1) {
      parent.state.groupLeads.splice(leadsIndex, 1)
    } else if(isLead && leadsIndex === -1) {
      parent.state.groupLeads.push(userId)
    }
  } else {
    if(groupIndex !== -1) {
      parent.state.groupUsers.splice(groupIndex, 1)
    }
    if(leadsIndex !== -1) {
      parent.state.groupLeads.splice(leadsIndex, 1)
    }
  }
  parent.setState({userSearch: ''})
  parent.forceUpdate()
}

// Main function
function CreateGroup(props) {
  const { parent } = props
  let {createGroup, createGroupStep, groupName, resources_domains, groupDomains,
        newDomain, workforceDomains, groupError, publicChannels, groupChannel,
        profiles, userSearch, groupUsers, groupLeads} = parent.state
  let isTeam = createGroup === 'team'
  let teamOrCommunity = isTeam ? 'team' : 'community'

  const close = () => {
    parent.setState({ createGroup: false, groupName: '', groupDomains: [],
      newDomain: '', groupUsers: [], groupLeads: [], userSearch: '' })
  }

  const next = () => {
    if(createGroupStep === 1) {
      if(!groupName) {
        parent.setState({ groupError: 'Provide a name' })
      } else {
        parent.setState({ createGroupStep: 2, groupError: '' })
      }
    } else if(createGroupStep === 2) {
      // const groupId = uuidv4()
      // let group = {
      //   name: groupName,
      //   domains: groupDomains,
      //   members: groupUsers
      // }

      // get slack channel ID
      // let channelID = null
      // if(groupChannel) {
      //   publicChannels.forEach((channel, i) => {
      //     if(channel.name === groupChannel) {
      //       channelID = channel.id
      //     }
      //   });
      // }
      // if(channelID) {
      //   group.slack_channel = channelID
      // }
      //
      // let updatedUser = {}
      // let updatedWorkforce = {}
      //
      // if(isTeam) {
      //   group.team_id = groupId
      //   group.leads = groupLeads
      //
      //   updatedUser.teams = parent.state.user.teams ? parent.state.user.teams : []
      //   updatedUser.teams.push(group.team_id)
      //
      //   updatedWorkforce.teams = parent.state.workforce.teams ? parent.state.workforce.teams : []
      //   updatedWorkforce.teams.push(group)
      //
      // } else {
      //   group.community_id = groupId
      //
      //   updatedUser.communities = parent.state.user.communities ? parent.state.user.communities : []
      //   updatedUser.communities.push(group.community_id)
      //
      //   updatedWorkforce.communities = parent.state.workforce.communities ? parent.state.workforce.communities : []
      //   updatedWorkforce.communities.push(group)
      // }
      // console.log(group)
      // console.log(updatedUser)
      // console.log(updatedWorkforce)
      // updateUser(parent.state.user._id, parent.state.token, updatedUser)
      // .then((res) => {
      //   console.log(res)
      //
      //   if(isTeam) {
      //     updatedWorkforce.teamId = groupId
      //   } else {
      //     updatedWorkforce.communityId = groupId
      //   }
      //   updatedWorkforce.members = groupUsers // triggers user membership on back-end
      //   updateWorkforce(parent.state.workforce._id, parent.state.token, updatedWorkforce, parent.state.user.permission_token)
      //   .then((res2) => {
      //     console.log(res2)
      //     if(!res.error) {
      //       parent.setState({refresh: true})
      //     }
      //   })
      // })

    }
  }

  const giveDialog = () => {

    switch(createGroupStep) {
      case(1):
        return (
          <>
            <DialogTitle style={{ background: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="overline" style={{ color: '#726B83' }}>
                  Create {teamOrCommunity}
                </Typography>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Define your {teamOrCommunity}.
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>
                  Start with a name and link relevant tags
                </Typography>
                <Typography variant="body1" style={{ color: 'red' }}>
                  {groupError}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent style={{ background: '#FFF' }}>
              <Box p={2} mb={1.5} style={{ border: '3px solid #EFEFF1', borderRadius: 20 }}>
                <Typography variant="subtitle1" style={{ fontWeight: 900 }} gutterBottom>
                  Name your {teamOrCommunity}
                </Typography>
                <TextField fullWidth required
                  label={`${capitalizeFirstLetter(teamOrCommunity)} Name`}
                  onChange={(event) => parent.setState({ groupName: event.target.value })}
                  value={groupName}
                />

                <Box m={6} />

                <Box style={{ width: '100%' }}>
                  <Typography variant="subtitle1" style={{ fontWeight: 900 }} gutterBottom>
                    Link relevant tags to your {teamOrCommunity}
                  </Typography>
                    <div onKeyDown={(e) => e.keyCode === 13 ? addNewDomain(parent) : false}>
                      <ReactSearchAutocomplete
                        items={resources_domains}
                        fuseOptions={{ keys: ["text"] }}
                        resultStringKeyName="text"
                        onSearch={(val) => parent.setState({newDomain: val})}
                        onSelect={(val) => addNewDomain(parent, val.text)}
                        inputSearchString={newDomain}
                        placeholder="Development, Finance, Marketing ..."
                        styling={{
                          zIndex: 100
                        }}
                      />
                    </div>
                  <Box m={2} />

                  <h3>Your workforce's domains</h3>
                  <Grid container direction="row">
                    {workforceDomains.map((domain, i) => {
                      return (
                        <Box mr={0.5} mb={0.5}>
                          <HiSquareChip clickable
                            onClick={() => toggleDefaultDomain(parent, domain.title)}
                            label={capitalizeFirstLetter(domain.title)}
                            style={{
                              background: hasDomain(parent, domain) ? 'rgba(67,0,255,0.1)' : '#EFEFF1',
                              color: hasDomain(parent, domain) ? '#4300FF' : '#726B83',
                            }}
                          />
                        </Box>
                      )
                    })}
                  </Grid>

                  {/* Populate with chosen tags */}
                  <Typography variant="subtitle2" style={{ color: '#726B83'}} gutterBottom>
                    Selected tags
                  </Typography>
                  <Grid container direction="row">
                    {groupDomains.map((domain, i) => {
                      return (
                        <Box mr={0.5} mb={0.5}>
                          <HiSquareChip styleVariant="purple"
                            label={domain}
                            deleteIcon={<HighlightOffRoundedIcon style={{ fontSize: 14 }} />}
                            onDelete={() => removeDomain(parent, i)}
                          />
                        </Box>
                      )}
                    )}
                  </Grid>
                </Box>

              </Box>

            </DialogContent>
            <DialogActions>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box pl={2}>
                  <Typography variant="caption" style={{ color: '#726B83' }}>
                    Step 1 of 2
                  </Typography>
                </Box>
                <ButtonPurple onClick={() => next()}>
                  Next
                </ButtonPurple>
              </Grid>
            </DialogActions>
          </>
        )
      case(2):
        return (
          <>
            <DialogTitle style={{ background: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="overline" style={{ color: '#726B83' }}>
                  Create {teamOrCommunity}
                </Typography>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Invite your teammates.
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>
                  Connect your {teamOrCommunity} to a Slack channel and select members
                </Typography>
                <Typography variant="body1" style={{ color: 'red' }}>
                  {groupError}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent style={{ background: '#FFF' }}>

              <Box p={2} mb={1.5} style={{ border: '3px solid #EFEFF1', borderRadius: 20 }}>
                <Box style={{ width: '100%' }}>
                  <Typography variant="subtitle1" style={{ fontWeight: 900 }} gutterBottom>
                    Connect a Slack channel
                  </Typography>

                  <FormControl fullWidth>
                    <InputLabel htmlFor="select-Slack-channel">Channel</InputLabel>
                    <NativeSelect
                      id="select-Slack-channel"
                      value={groupChannel ? groupChannel : 'None'}
                      onChange={(e) => parent.setState({ groupChannel: e.target.value })}
                      input={<DropDownInput />}
                    >
                      <option aria-label="None" value="None">-</option>
                      {publicChannels.map((channel, i) => {
                        return <option key={i} value={channel.name}>#{channel.name}</option>
                      })}
                    </NativeSelect>
                  </FormControl>
                </Box>

                <Box m={6} />

                <Box style={{ width: '100%' }}>
                  <Typography variant="subtitle1" style={{ fontWeight: 900 }} gutterBottom>
                    Select your teammates
                  </Typography>
                  <div
                    onKeyDown={(e) => e.keyCode === 13 ? addNewDomain(parent) : false}
                  >
                    <ReactSearchAutocomplete
                      items={profiles}
                      fuseOptions={{ keys: ["fname","lname"] }}
                      resultStringKeyName="full_name"
                      onSearch={(val) => parent.setState({userSearch: val})}
                      onSelect={(val) => toggleUser(parent, val._id, true)}
                      inputSearchString={userSearch}
                      placeholder="jane smith..."
                      styling={{
                        zIndex: 100
                      }}
                    />
                  </div>
                  <Box m={2} />
                  {/* Search results: people selected for the team */}
                  <Box p={2} style={{ background: '#EFEFF1', borderRadius: 12  }}>
                    <Typography variant="subtitle2" gutterBottom style={{ color: '#726B83' }}>
                      Selected members
                    </Typography>
                    {groupUsers.length ? (
                      <>
                        {groupUsers.map((userId) => {
                          let fullUser = {}
                          profiles.forEach((profile, i) => {
                            if(profile._id === userId) {
                              fullUser = profile
                            }
                          });

                          return (
                            <Box py={0.5}>
                              <Grid container direction="row" alignItems="center" justify="space-between">
                                <Box>
                                  <Grid container direction="row" alignItems="center">
                                    <RoundAvatar src=""/>
                                    <Box m={1} />
                                    <Typography variant="subtitle2" style={{ weight: 700, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'  }}>
                                       {fullUser.fname} {fullUser._id === parent.state.user._id ? (
                                         <span style={{ fontWeight: 500, color: '#726B83' }}> | you</span>
                                       ) : null}
                                    </Typography>
                                  </Grid>
                                </Box>
                                <Box>
                                  <Grid container direction="row" alignItems="center">
                                    {isTeam ? (
                                      <>
                                        <ButtonToggle
                                          style={groupLeads.includes(userId) ? { background: '#141217', color: '#FFF' } : {}}
                                          onClick={() => toggleUser(parent, userId, true, true)}
                                        >
                                          Lead
                                        </ButtonToggle>
                                        <Box m={0.5} />
                                        <ButtonToggle
                                          style={!groupLeads.includes(userId) ? { background: '#141217', color: '#FFF' } : {}}
                                          onClick={() => toggleUser(parent, userId, true)}
                                        >
                                          Member
                                        </ButtonToggle>
                                      </>
                                    ) : null}
                                    <Box ml={0.5}>
                                      <IconButton size="small"
                                      onClick={() => toggleUser(parent, userId, false)}
                                      >
                                        <CloseRoundedIcon fontSize="small"/>
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Box>
                          )
                        })}
                      </>
                    ) : (
                      'No members selected'
                    )}
                  </Box>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box pl={2}>
                  <Typography variant="caption" style={{ color: '#726B83' }}>
                    Step 2 of 2
                  </Typography>
                </Box>
                <ButtonPurple onClick={() => close()}>
                  Done
                </ButtonPurple>
              </Grid>
            </DialogActions>
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <Dialog open={true} maxWidth="sm" fullWidth onClose={() => close(parent, false)}>
        {giveDialog()}
      </Dialog>
    </>
  )
};
export default CreateGroup;
