import React, {Component} from 'react';
import { checkInvite } from '../api/workforce'
import Loading from "../components/general/Loading";
import Cookies from 'universal-cookie';
import { Redirect } from "react-router-dom"


class NewUserInvited extends Component {

  constructor() {
    super()
    this.state = {
      valid: false, // if toggled, redirects user to next step in sign up process
      invalid: false // if toggled, tells user this is an invalid link
    }
  }

  componentDidMount() {
    const { match } = this.props

    let id = match.params.workspaceId
    let code = match.params.code
    checkInvite(id, code)
    .then((res) => {
      if(res.code === code) {
        const cookies = new Cookies();
        cookies.set('workforce', match.params.workspaceId, { path: '/' });
        cookies.set('code', match.params.code, { path: '/' });
        if(res.profile) {
          if(res.profile.fname) {
            cookies.set('fname', res.profile.fname, { path: '/' });
          }
          if(res.profile.lname) {
            cookies.set('lname', res.profile.lname, { path: '/' });
          }
          if(res.profile.title) {
            cookies.set('title', res.profile.title, { path: '/' });
          }
        }
        if(res.image_small_url) {
          cookies.set('image_small_url', res.image_small_url, { path: '/' });
        }
        if(res.image_large_url) {
          cookies.set('image_large_url', res.image_large_url, { path: '/' });
        }
        if(res.role) {
          cookies.set('role', res.role, { path: '/' });
        }
        if(res.team) {
          cookies.set('team', res.team, { path: '/' });
        }
        if(res.user_slack_id) {
          cookies.set('user_slack_id', res.user_slack_id, { path: '/' });
        }
        if(res.teams) {
          cookies.set('teams', JSON.stringify(res.teams), { path: '/' });
        }
        if(res.leadingTeams) {
          cookies.set('leadingTeams', JSON.stringify(res.leadingTeams), { path: '/' });
        }
        if(res.needsTeamSetup) {
          cookies.set('needsTeamSetup', true, { path: '/' });
        }
        this.setState({valid: true})
      } else {
        this.setState({invalid: true})
      }
    })

  }

  render() {

    if (this.state.valid) {
      return <Redirect to={`/`} />
    }

    return (
      <>
        {this.state.invalid ? (
          <>
            <p>Invalid link</p>
          </>
        ) : <Loading />}
      </>
    )
  }
}
export default NewUserInvited;
