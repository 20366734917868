import { v4 as uuidv4 } from 'uuid';
import {createInviteLink} from '../api/workforce'
import {sendSlackInviteDM, getSlackUserChannel} from '../api/slack'

export function inviteSlackUser(parent, slackUser) {
  let inviteLink = uuidv4();
  let fullLink = {
    code: inviteLink,
    created: Date.now(),
    createdBy: parent.state.user._id,
    full_name: slackUser.real_name,
    display_name: slackUser.profile.display_name,
    image_small_url: slackUser.profile.image_72,
    image_large_url: slackUser.profile.image_512,
    profile: {
      fname: slackUser.profile.first_name ? slackUser.profile.first_name : undefined,
      lname: slackUser.profile.last_name ? slackUser.profile.last_name : undefined,
      title: slackUser.profile.title ? slackUser.profile.title : undefined
    },
    delivery: 'slack',
    user_slack_id: slackUser.id,
    teams: [],
    leadingTeams: []
  }

  createInviteLink(parent.state.workforce._id, parent.state.token, fullLink, parent.state.user.permission_token)
  .then((response) => {
    if(response && !response.error) {
      getSlackUserChannel(slackUser.id, parent.state.workforce.slack.access_token)
      .then((channel, ) => {
        if(channel !== 'error') {
          let invitedBy = `${parent.state.user.fname ? parent.state.user.fname : ''} ${parent.state.user.lname ? parent.state.user.lname : ''}`
          sendSlackInviteDM(parent.state.workforce.slack.access_token, channel, parent.state.workforce._id, fullLink.code, parent.state.workforce.name, invitedBy)
          .then((dmRes) => {
            parent.setState({[`ghostInviteSent${slackUser.id}`]: true})
          })
        }
      })
    }
  })
}
