import React from 'react'
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function XXSmallAvatar(props) {
  let { children, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      border: '1px solid #EFEFF1',
      height: 17,
      width: 17,
      margin: '0 2px 0 2px'
    },
  }));

  const classes = useStyles();

  return (
    <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
      <Avatar { ...passThroughProps } className={ classes.root }>
        {children}
      </Avatar>
    </span>
  )
}
export default XXSmallAvatar
