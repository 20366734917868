import React from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Button,
  Grid,
  Checkbox,
  TextField,
  FormControlLabel
} from '@material-ui/core';
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import ButtonBlack from '../../components/general/buttons/ButtonPurple'
import HiSquareChip from '../../components/general/HiSquareChip'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import {mentorMatchGoals} from '../../utility/constants'
import {getWorkforceDomains} from '../../utility/workforce'
import {getUserDomains} from '../../utility/user'
// import {updateUser} from '../../api/user'
import socialLogo from '../../assets/HelmLogo-social.png'

export function beginMentorMatch(parent) {
  parent.setState({ mentorMatch: true, mentorMatchStep: 1 })

  // get workforce domains
  let workforceDomains = getWorkforceDomains(parent.state.profiles)
  let userDomains = getUserDomains(parent.state.user)
  let nonUserDomains = []
  workforceDomains.forEach((domain, i) => {
    console.log(workforceDomains)
    console.log(userDomains)
    if(!userDomains.includes(domain)) {
      nonUserDomains.push(domain)
    }
  });
  parent.setState({ workforceDomains: nonUserDomains, userDomains: userDomains })
}

function MentorMatch(props) {
  let {parent} = props
  let {mentorMatchStep, mentorMatchGoalsSelected, mentorMatchGoalsMessage,
        mentorMatchError, workforceDomains, userDomains, mentorMatchDomains,
        mentorMatchPersonalMessage} = parent.state

  const next = () => {
    if(mentorMatchStep === 1) {
      if(!mentorMatchGoalsSelected.length) {
        parent.setState({ mentorMatchError: 'Select at least one goal' })
      } else {
        parent.setState({ mentorMatchStep: 2, mentorMatchError: '' })
      }
    } else if(mentorMatchStep === 2) {
      if(!mentorMatchDomains.length) {
        parent.setState({ mentorMatchError: 'Select at least one domain' })
      } else {
        parent.setState({ mentorMatchStep: 3, mentorMatchError: '' })
      }
    } else if (mentorMatchStep === 3) {
      parent.setState({ mentorMatchStep: 4})
    }
  }

  // const submit = () => {
  //   let request = {
  //     goals: mentorMatchGoalsSelected,
  //     goals_message: mentorMatchGoalsMessage ? mentorMatchGoalsMessage : '',
  //     domains: mentorMatchDomains,
  //     personal_message: mentorMatchPersonalMessage ? mentorMatchPersonalMessage : '',
  //     start_date: new Date(),
  //     searching: true
  //   }
  //   let updatedUser = {
  //     mentor_match_requests: user.mentor_match_requests ? user.mentor_match_requests : []
  //   }
  //   updatedUser.mentor_match_requests.push(request)
  //   updateUser(user._id, token, updatedUser).then((newUser) => {
  //     parent.setState({user: newUser, mentorMatchStep: 4})
  //
  //     /* TODO SLACK: sending invites */
  //   })
  // }

  const close = () => {
    parent.setState({mentorMatch: false, mentorMatchGoalsSelected: [],
      mentorMatchDomains: []})
  }

  function selectedDomain(parent, domainTitle) {
    let index = -1
    parent.state.mentorMatchDomains.forEach((domain, i) => {
      if(domain === domainTitle) {
        index = i
      }
    });

    if(index === -1) {
      return false
    } else {
      return true
    }
  }

  const toggleGoal = (goalId) => {
    let index = mentorMatchGoalsSelected.indexOf(goalId)
    if(index === -1) {
      parent.state.mentorMatchGoalsSelected.push(goalId)
      parent.forceUpdate()
    } else {
      parent.state.mentorMatchGoalsSelected.splice(index, 1)
      parent.forceUpdate()
    }
  }

  const toggleDomain = (domain) => {
    let index = mentorMatchDomains.indexOf(domain)
    if(index === -1) {
      parent.state.mentorMatchDomains.push(domain)
      parent.forceUpdate()
    } else {
      parent.state.mentorMatchDomains.splice(index, 1)
      parent.forceUpdate()
    }
  }

  const giveBody = () => {
    switch(mentorMatchStep) {
      case(1): {
        return (
          <>
            <DialogTitle style={{ background: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="overline" style={{ color: '#726B83' }}>
                  MENTOR MATCH
                </Typography>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Let's find a mentor!<br/>
                  <span style={{ color: '#4300FF' }}>Start by setting a goal.</span>
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>
                  <b>Helm will find the best match</b> to help you get there.
                </Typography>
                <Typography variant="body2" color="error">{mentorMatchError}</Typography>
              </Box>
            </DialogTitle>

            <DialogContent style={{ background: '#FFF' }}>
              <Box p={2} mb={1.5} style={{ border: '3px solid #EFEFF1', borderRadius: 12 }}>

                <Typography variant="subtitle1">Mentorship goals</Typography>
                <Typography variant="body2" style={{ color: '#726B83' }}>Select any goal(s) that are relevant to you.</Typography>
                <Box py={2}>
                  {mentorMatchGoals.map((goal) => {
                  return (
                    <Box>
                     <FormControlLabel
                        control={
                          <Checkbox
                            checked={mentorMatchGoalsSelected.includes(goal.id)}
                            size="small" onClick={() => toggleGoal(goal.id)}
                            color="primary"
                          />
                        }
                        label={goal.name}
                      />
                    </Box>
                  )
                })}

                  <TextField
                    margin="dense"
                    label={'Other'}
                    placeholder="Type another goal to add ..."
                    fullWidth
                    onChange={(event) => parent.setState({ mentorMatchGoalsMessage: event.target.value })}
                    value={mentorMatchGoalsMessage}
                  />
                </Box>
              </Box>

            </DialogContent>

            <DialogActions>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box pl={2}>
                  <Typography variant="caption" style={{ color: '#726B83' }}>
                    Step 1 of 3
                  </Typography>
                </Box>
                <ButtonPurple onClick={() => next()}>
                  Next
                </ButtonPurple>
              </Grid>
            </DialogActions>

          </>
        )
      }
      case(2): {
        return (
          <>
            <DialogTitle style={{ background: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="overline" style={{ color: '#726B83' }}>
                  MENTOR MATCH
                </Typography>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  What area of expertise would be most helpful to you?
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>
                  <b>Helm will use this as a filter</b> to find the best mentor.
                </Typography>
                <Typography variant="body2" color="error">{mentorMatchError}</Typography>
              </Box>
            </DialogTitle>

            <DialogContent style={{ background: '#FFF' }}>
              <Box p={2} mb={1.5} style={{ border: '3px solid #EFEFF1', borderRadius: 12 }}>
                <Typography variant="subtitle1">Mentorship domain</Typography>
                <Typography variant="body2" style={{ color: '#726B83' }}>Select the domains you want to be mentored in.</Typography>

                <Box m={2} />
                <Typography variant="subtitle2" gutterBottom>Your domains:</Typography>
                <Grid container direction="row">
                  {userDomains.map((domain) => {
                    return (
                      <Box mr={0.5} mb={0.5}>
                        <HiSquareChip clickable
                          onClick={() => toggleDomain(domain)}
                          label={domain}
                          style={{
                            background: selectedDomain(parent, domain) ? 'rgba(67,0,255,0.1)' : '#EFEFF1',
                            color: selectedDomain(parent, domain) ? '#4300FF' : '#726B83',
                          }}
                        />
                      </Box>
                    )
                  })}
                </Grid>

                {workforceDomains.length ? (
                  <>
                    <Box m={2} />
                    <Typography variant="subtitle2" gutterBottom>Workforce domains:</Typography>
                    <Grid container direction="row">
                      {workforceDomains.map((domain) => {
                      if (domain) {
                        return (
                          <>
                            <Box mr={0.5} mb={0.5}>
                              <HiSquareChip clickable
                                onClick={() => toggleDomain(domain)}
                                label={domain}
                                style={{
                                  background: selectedDomain(parent, domain) ? 'rgba(67,0,255,0.1)' : '#EFEFF1',
                                  color: selectedDomain(parent, domain) ? '#4300FF' : '#726B83',
                                }}
                              />
                            </Box>
                          </>
                        )
                      } else return null
                    })}
                    </Grid>
                  </>
                ) : null}

              </Box>

            </DialogContent>

            <DialogActions>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box pl={2}>
                  <Typography variant="caption" style={{ color: '#726B83' }}>
                    Step 2 of 3
                  </Typography>
                </Box>
                <ButtonPurple onClick={() => next()}>
                  Next
                </ButtonPurple>
              </Grid>
            </DialogActions>
          </>
        )
      }
      case(3): {
        return (
          <>
            <DialogTitle style={{ background: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="overline" style={{ color: '#726B83' }}>
                  MENTOR MATCH
                </Typography>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Personalize your ask.
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>
                  <b>Helm will share this message</b> with prospective mentors.
                </Typography>
                <Typography variant="body2" color="error">{mentorMatchError}</Typography>
              </Box>
            </DialogTitle>

            <DialogContent style={{ background: '#FFF' }}>
              <Box p={2} mb={1.5} style={{ border: '3px solid #EFEFF1', borderRadius: 12 }}>
                <Typography variant="subtitle1">Mentorship message</Typography>
                <Typography variant="body2" style={{ color: '#726B83' }}>This is what we'll send out:</Typography>

                <Box m={2} />
                  <Box p={2} mb={1.5} style={{ background: '#EFEFF1', borderRadius: 12 }}>
                    <Grid container direction="row">
                      <Box width={50}>
                        <img src={socialLogo} alt="Helm" style={{ width: 50, height: 50, border: '1px solid #D0CED6', borderRadius: 8 }} />
                      </Box>
                      <Box pl={2} style={{ width: 'calc(100% - 50px)' }}>
                        <Typography variant="subtitle2">
                          Helm
                        </Typography>
                        <Typography variant="body2" style={{ color: '#454052' }}>
                          <span role="img" aria-label="wave">👋</span> Hey there, <b style={{ color: '#4300FF' }}>{parent.state.user.fname}</b> is a new hire on the Product team at Novo. <b>{parent.state.user.fname}'s looking for a mentor to:</b>
                          <br /><br />
                          {mentorMatchGoals.map((goal) => {
                            if(mentorMatchGoalsSelected.includes(goal.id)) {
                              return (
                                <>
                                  <span style={{ padding: 10 }}> •</span>{goal.name}<br/>
                                </>
                              )
                            } else return null
                          })}
                          <br />
                          <b>We thought you’d be a great fit:</b><br />
                          <span style={{ padding: 10 }}> •</span>You both work in <b>UI Design</b><br/>
                          <span style={{ padding: 10 }}> •</span>You’re both into <b><span role="img" aria-label="pan">🍳</span> cooking and <span role="img" aria-label="skiing">⛷</span> skiing</b><br/>
                          <span style={{ padding: 10 }}> •</span>You’re both open to <b>coffee chats</b><br/>
                          <br/>
                          <b>Are you interested in being {parent.state.user.fname}'s mentor?</b>
                        </Typography>
                        <Box mt={2}>
                          <Grid container direction="row">
                            <ButtonBlack size="small" style={{ borderRadius: 8 }}>
                              Yes
                            </ButtonBlack>
                            <Box m={0.5} />
                            <ButtonBlack size="small" style={{ borderRadius: 8, background: '#FFF', color: '#141217', border: '2px solid #726B83' }}>
                              No
                            </ButtonBlack>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>

                 <TextField
                   variant="outlined"
                   margin="dense"
                   label={'Personal note'}
                   placeholder="Type your note here ..."
                   helperText="Optional"
                   minRows={2}
                   fullWidth multiline
                   onChange={(event) => parent.setState({ mentorMatchPersonalMessage: event.target.value })}
                   value={mentorMatchPersonalMessage}
                 />

              </Box>

            </DialogContent>

            <DialogActions>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Box pl={2}>
                  <Typography variant="caption" style={{ color: '#726B83' }}>
                    Step 3 of 3
                  </Typography>
                </Box>
                <ButtonPurple
                  onClick={() => next()}>
                  Submit request
                </ButtonPurple>
              </Grid>


            </DialogActions>
          </>
        )
      }
      case(4): {
        return (
          <>
            <DialogTitle style={{ background: '#FFF' }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="overline" style={{ color: '#726B83' }}>
                  MENTOR MATCH
                </Typography>
                <IconButton size="small" onClick={() => close()}>
                  <CloseRoundedIcon style={{ color: '#726B83' }} />
                </IconButton>
              </Grid>
              <Box>
                <Typography variant="h4" gutterBottom>
                  <span role="img" aria-label="confetti">🎉🎉</span>All done!!
                </Typography>
                <Typography variant="body1" style={{ color: '#726B83' }}>
                  <b>Helm will notify you in Slack</b> once a match is found.
                </Typography>
                <Typography variant="body2" color="error">{mentorMatchError}</Typography>
              </Box>
            </DialogTitle>

            <DialogActions>
              <Button color="primary" variant="contained"
              onClick={() => close()}>
                Done
              </Button>
            </DialogActions>
          </>
        )
      }
      default: return null
    }
  }

  return (
    <div>
      <Dialog open={true}
       onClose={() => close()}
       aria-labelledby="mentor-match" maxWidth="sm" fullWidth>
        {giveBody()}
      </Dialog>
    </div>
  );

}

export default MentorMatch;
