import React from 'react'
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';

import PurpleSquareChip from '../../components/general/chips/PurpleSquareChip'
import SquareAvatar from '../../components/general/avatars/SquareAvatar'
import { giveToolImage } from '../../utility/user'
import {levels} from '../../utility/constants'

function CapabilityBlock(props) {
  const {parent, domain} = props

  return (
    <Box mt={2} p={3} style={{ background: '#FFF', borderRadius: 12, boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.04)' }}>
      <PurpleSquareChip label={domain.title} />

      {domain.capabilities.map((cap, i) => {
        return (
          <>
            <Box m={1.5} />
            <Box>
              <Grid container direction="row" alignItems="center">
                <Typography variant="subtitle1" style={{ color: '#4300FF', fontWeight: 800 }}>
                  {cap.title}
                </Typography>
                {cap.tools && cap.tools.length ? (
                  <>
                    <Box mx={0.5}>
                      <Typography variant="body1" style={{ color: '#726B83' }}>
                        with
                      </Typography>
                    </Box>
                    {cap.tools.map((tool) => {
                      let url = giveToolImage(parent, tool, true)
                      return (
                        <>
                          <Box mr={0.2}>
                            <Grid container direction="row" alignItems="center">
                              { url ? (
                                <Box mr={0.5}>
                                  <SquareAvatar styleVariant="xSmall" style={{ background: '#FFF', border: 'none', boxShadow: 'none' }}>
                                    {url}
                                  </SquareAvatar>
                                </Box>
                                ):null
                              }
                              <Typography variant="subtitle1" style={{ color: '#4300FF' }}>
                                {tool}
                              </Typography>
                            </Grid>
                          </Box>
                        </>
                      )
                    })}
                  </>
                ) : null}
              </Grid>
            </Box>
            <Box px={2} mt={1} style={{ backgroundColor: 'rgba(67,0,255,0.05)', borderRadius: 8  }}>
              <Grid container direction="row" alignItems="center">
                {levels.map((level, j) => {
                  return (
                    <Box m={0.2}
                      style={{
                        height: 12,
                        width: 12,
                        borderRadius: 6,
                        background: '#4300FF',
                        opacity: cap.skill_level-1 >= j ? '100%' : '10%'
                      }}
                    />
                  )
                })}
                <Box m={2} />
                <Typography variant="subtitle2" style={{ color: '#4300FF'}}>
                  {levels[cap.skill_level-1]}
                </Typography>
              </Grid>
            </Box>
          </>
        )
      })}

    </Box>
  )
}
export default CapabilityBlock
