import React from 'react'
import {
  Box,
  Typography
} from '@material-ui/core';

// import BlueSquareChip from '../../components/general/chips/BlueSquareChip'

function FAQBlock(props) {
  const { q } = props

  return (
    <Box mt={2} p={3} style={{ background: '#FFF', borderRadius: 12, boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.04)' }}>
      <Typography variant="subtitle1" gutterBottom style={{ color: '#0093C0' }}>
        {q.question}
      </Typography>
      <Typography variant="body1" style={{ color: '#726B83' }}>
        {q.answer}
      </Typography>
    </Box>
  )
}
export default FAQBlock
