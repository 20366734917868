
export const getWorkforce = async (id, token, permissionToken) => {
  try {
    const response = await fetch(`https://joinhelm.com/api/admin/workforce/${id}?permissionToken=${permissionToken}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();

    return responseData
  } catch (error) {
    console.log(error)
  }
};

export const getWorkforces = async (token, permissionToken) => {
  try {
    const response = await fetch(`https://joinhelm.com/api/admin/workforces?permissionToken=${permissionToken}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();

    return responseData
  } catch (error) {
    console.log(error)
  }
};

export const getUsers = async (token, permissionToken) => {
  try {
    const response = await fetch(`https://joinhelm.com/api/admin/users?permissionToken=${permissionToken}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();

    return responseData
  } catch (error) {
    console.log(error)
  }
};
