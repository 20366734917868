import React from 'react'
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SquareAvatar from '../general/avatars/SquareAvatar'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import {giveFirstName, giveLastName, giveTitle} from '../../utility/profile'
import {imageSrc} from '../../utility/images'
import {clickProfile} from '../../utility/tabs'

const giveMatchKey = (key) => {
  switch(key) {
    case 'collabs.title':
      return 'Collabs';
    case 'title':
      return 'Title';
    case 'domains.title':
      return 'Domains';
    case 'domains.capabilities.title':
      return 'Abilities';
    case 'domains.capabilities.tools':
      return 'Tools';
    case 'faqs.answer':
      return 'FAQs';
    case 'interests.title':
      return 'Interests';
    case 'full_name':
      return 'Name';
    case 'purpose_statement':
      return 'Purpose';
    case 'impact':
      return 'One-liner';
    case 'community_names':
      return 'Community';

    default:
      return ''
  }
}

const indicesIncludes = (indices, index) => {
  let includes = false

  let filtered = []
  indices.forEach((indice, i) => {
    if(indice[1] - indice[0] > 1) {
      filtered.push(indice)
    }
  });


  filtered.forEach((indice) => {
    let start = indice[0]
    let end = indice[1]
    if(index >= start && index <= end) {
      includes = true
    }
  });


  return includes
}

const giveBoldedSearch = (value, indices) => {
  const letters = [...value]
  return (
    <>
      {letters.map((letter, i) => {
        let isBold = indicesIncludes(indices, i)
        return (
          <span style={{ color: isBold ? '#4300FF' : '#726B83' }}>
            {letter}
          </span>
        )
      })}
    </>
  )
}

const giveFoundSearch = (match) => {
  let key = giveMatchKey(match.key)

  return (
    <>
      <b style={{ color: '#141217' }}>{`${key}: `}</b>
      {giveBoldedSearch(match.value, match.indices)}
    </>
  )
}

function ProfileSearchResult(props) {
  const {parent, user, result, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      background: 'transparent',
      borderRadius: 8,
      width: '100%',
      '&:hover': {
        background: 'rgba(0,0,0,0.06)',
        cursor: 'pointer'
      },
    },
  }));
  const classes = useStyles();

  const onClick = () => {
    clickProfile(parent, user)
  }

  let fname = giveFirstName(user)
  let lname = giveLastName(user)
  let title = giveTitle(user)
  let image = imageSrc(user, false, true)

  let fullName = `${fname} ${lname}`
  if( (!fname && !lname) || fullName.length < 3) {
    fullName = '[Nameless user]'
  }

  return (
    <Box py={1} px={1} className={classes.root} onClick={() => onClick()}  { ...passThroughProps }>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <SquareAvatar styleVariant="large" src={image} />
        <Box pl={2} style={{ width: 'calc(100% - 180px)' }}>
          <Typography variant="h5" style={{ letterSpacing: -0.1, weight: 700, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'  }}>
            {fullName} <span style={{ fontWeight: 500, color: '#726B83' }}>{user._id === parent.state.user._id ? ' | you' : ''}</span>
          </Typography>

          {result.matches ? (
            <>
              {result.matches.map((match, i) => {
                return i < 3 ? (
                  <Typography variant="body2" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {giveFoundSearch(match)}
                  </Typography>
                ) : null
              })}
            </>
          ) : title}
        </Box>
        <ChevronRightRoundedIcon style={{ color: '#141217', fontSize: 50 }} />
      </Grid>
    </Box>
  )
}
export default ProfileSearchResult
