import React from 'react';
import Profile from "../pages/tabs/Profile";
import GhostProfile from "../pages/tabs/GhostProfile";
import Onboarding from "../pages/tabs/Onboarding";
import People from "../pages/tabs/People"
import Connections from "../pages/tabs/Connections"
import Group from "../pages/tabs/Group"
import WorkforceOnboarding from "../pages/tabs/WorkforceOnboarding";
import Loading from "../components/general/Loading";
import SearchSuggestions from '../pages/tabs/SearchSuggestions'
import {giveResponsesByDay} from './score'
import {createTimeline} from './init'

export function giveTabContent(parent) {
  switch(parent.state.tab) {
    case 'profile':
      if(parent.state.needsOnboarding && !parent.state.isDemo) {
        return <Loading />
      } else {
        return <Profile parent={parent}/>
      }
    case 'people':
      return <People parent={parent} />
    case 'connections':
      return <Connections parent={parent} />
    case 'group':
      return <Group parent={parent} />
    case 'teammate':
      return <Profile parent={parent} teammate={parent.state.teammate}/>
    case 'search':
      return <SearchSuggestions parent={parent} />
    case 'slackTeammate':
      return <GhostProfile parent={parent} teammate={parent.state.slackTeammate} />
    case 'onboarding':
      return <Onboarding parent={parent} />
    case 'workforceOnboarding':
      return <WorkforceOnboarding parent={parent} />
    default:
      return <Profile parent={parent}/>
  }
}

export const tabsWithNav = ['profile', 'connections', 'search', 'teammate', 'slackTeammate', 'people', 'group']

export function clickProfile(parent, profile) {
  parent.setState({tab: 'teammate', teammate: profile, wikiSearchText: '', overlay: '', suggestionOverlay: false})
  if(profile._id !== parent.state.user._id) { // if clicking to other user
    if(!profile.demo) {
      window.history.pushState( {} , '', `?teammate=${profile._id}` );
      if(parent.state.workforceResponses && parent.state.workforceResponses.length) {
        handleTeammateResponses(parent, profile._id, parent.state.workforceResponses)
      }
    }
  } else { // if clicking to self
    clickToHome(parent)
  }
}

export function clickToHome(parent) {
  window.history.pushState( {} , '', `/` );
  parent.setState({tab: 'profile', teammate: '', teammateResponses: [], suggestionOverlay: false})
}

export function clickSlackProfile(parent, profile) {
  parent.setState({tab: 'slackTeammate', slackTeammate: profile})
}

export function handleTeammateResponses(parent, teammateId, workforceResponses) {
  let responses = []
  workforceResponses.forEach((response, i) => {
    if(response.user_id === teammateId) {
      responses.push(response)
    }
  });

  let parsedResponses = giveResponsesByDay(responses)
  console.log(parsedResponses)
  let timeline = createTimeline(parsedResponses)

  parent.setState({teammateResponses: responses, teammateTimeline: timeline})
}
