import React, { Component } from 'react';
import Loading from "../components/general/Loading";
import ButtonBlock from "../components/general/buttons/ButtonBlock";
import ButtonPurple from "../components/general/buttons/ButtonPurple";
import { getByAuth0, updateUser } from '../api/user';
import { createWorkforce } from '../api/workforce';
import { withAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { giveTos } from '../utility/legal/tos.js'
import CloseIcon from '@material-ui/icons/Close';
import queryString from "query-string"
import Cookies from 'universal-cookie';
import WorkforceOnboarding from "./tabs/WorkforceOnboarding";
import preview from '../assets/profile-preview.png'
import helmLogo from '../assets/Helm-wordmark-wt.png'

import {
  Typography,
  Grid,
  IconButton,
  Box,
  Container,
  Checkbox,
  Link,
  Dialog,
  Slide,
  AppBar,
  Toolbar
} from '@material-ui/core'

import { Redirect } from "react-router-dom"
import {PERSONAL_SLACK_LINK} from '../api/slack'

const cookies = new Cookies();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function createWorkforceAndUpdate(parent, slackName, user, token, slackLogo, access_token, teamId) {
  console.log(access_token)
  let expectedOutcomes = []
  labels.forEach((l, i) => {
    if(parent.state[`checkbox${i}`]) {
      expectedOutcomes.push(l)
    }
  })

  let workforce = {
    name: slackName ? slackName : 'Company',
    users: [
      {
        _id: user._id,
        auth0Id: user.auth0Id,
        joined_date: Date.now,
        role: 'Admin'
      }
    ],
    slack: {
      access_token: access_token,
      team_id: teamId
    },
    expected_outcomes: expectedOutcomes,
    regularIndex: {
      toggle: true,
      interval: 'Daily'
    }
  }
  if(slackLogo) {
    workforce.logo = slackLogo
  }
  console.log(access_token)
  console.log(workforce)
  createWorkforce(workforce, token).then((newWorkforce) => {
    console.log(newWorkforce)
    if(newWorkforce._id) { //if succesful, create user workforce
      let updatedUser = {
        workforce: {
          _id: newWorkforce._id,
          name: newWorkforce.name
        },
        site_logging: {
          met_mo: true
        },
        independent: false
      }
      updateUser(user._id, token, updatedUser).then((newUser) => {
        parent.setState({ toNewUserFlow: true })
      })
    } else {
      parent.setState({error: 'Error in creating workforce'})
    }
  })
}

function createIndependentProfile(parent) {
  let updatedUser = {
    independent: true
  }
  updateUser(parent.state.user._id, parent.state.token, updatedUser).then((newUser) => {
    parent.setState({ toHome: true })
  })
}

const toggleTos = (parent, value) => {
  parent.setState({tosOpen: value})
}

const tosChecked = (parent, value) => {
  parent.setState({tosChecked: value})
  if(value) {
    parent.setState({showError: false})
  }
}

const labels = [
  'Organize team structure',
  'Visualize team performance',
  'Increase transparency',
  'Improve team alignment',
  'Track progress on goals',
  'Optimize team collaboration',
  'Keep teams motivated',
  'Strengthen team culture',
  'Improve work satisfaction'
]

const giveLaunchBody = (parent) => {

  let {notRedirecting, teamFork} = parent.state
  // if(notRedirecting && independentFork) { // independent
    return (
      <>
        <div>
          <Container style={{ maxWidth: 680, color: '#FFF' }}>
            <Box pt={15}>
              <Box pb={4}>
                <img src={helmLogo} alt="Helm" style={{ height: 40, marginLeft: -8 }} />
                <Box m={2} />
                <Typography variant="h2" gutterButtom>
                  The people wiki for work<i style={{ color: '#00FFCC' }}> from anywhere.</i>
                </Typography>
                <Box m={2} />
                <Typography variant="subtitle1" style={{ color: '#FFF' }}>
                  Welcome! This is a place for you to be... you. It show's off what you do, how you work best, and who you are. <b style={{ color: '#00FFCC', fontWeight: 900 }}>Let's get started!</b>
                </Typography>
              </Box>
              <Box my={2} py={1} px={1} style={{ background: '#4300FF', borderRadius: 12 }}>
                <Grid container direction="row" alignItems="center">
                  <Checkbox
                    checked={parent.state.tosChecked}
                    size="small"
                    onChange={(e) => tosChecked(parent, e.target.checked)}
                  />
                  <Box mx={1} />
                  <Typography variant="body1">
                    I agree to the <Link style={{ color: '#00FFCC', cursor: 'pointer', fontWeight: 700 }} onClick={() => toggleTos(parent, true)}>Helm Terms</Link>
                  </Typography>
                </Grid>
              </Box>


              <Box my={4}>
                { parent.state.showError ? (
                    <Typography variant="subtitle2" style={{ color: 'black'}}>
                      Please agree to the Helm Terms before continuing
                    </Typography>
                  ) :
                    <>
                    </>
                }
              </Box>


              {parent.state.tosChecked ? (
                <ButtonPurple
                  onClick={() => createIndependentProfile(parent)}
                >
                  Start
                </ButtonPurple>
              ) : (
                <ButtonPurple
                  onClick={() => parent.setState({showError: true})}
                >
                  Start
                </ButtonPurple>
              )}
            </Box>

            <Box py={6}>
              <img src={preview} alt="Profile preview" style={{ width: '100%' }} />
            </Box>
          </Container>
          <Dialog fullScreen open={parent.state.tosOpen} onClose={() => toggleTos(parent, false)} TransitionComponent={Transition}>
            <AppBar>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => toggleTos(parent, false)} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Box py={15} />
            {giveTos()}
          </Dialog>
        </div>
      </>
    )
  // eslint-disable-next-line
  if(notRedirecting) { // independent
    return (
      <>
        <div>
          <Container style={{ maxWidth: '680px', color: '#FFF' }}>
            <Box pt={15}>
              <Box pb={4}>
                <Typography variant="h2" gutterButtom>
                  Let's build your wiki page <i style={{ color: '#00FFCC' }}>for remote work.</i>
                </Typography>
                <Box m={2} />
                <Typography variant="subtitle1" style={{ color: '#FFF' }}>
                  Welcome! This is a place for you to be... you. It show's off what you do, how you work best, and who you are. <b style={{ color: '#00FFCC' }}>Let's get started!</b>
                </Typography>
              </Box>
              <Box my={2} py={1} px={1} style={{ background: '#4300FF', borderRadius: 12 }}>
                <Grid container direction="row" alignItems="center">
                  <Checkbox
                    checked={parent.state.tosChecked}
                    size="small"
                    onChange={(e) => tosChecked(parent, e.target.checked)}
                  />
                  <Box mx={1} />
                  <Typography variant="body1">
                    I agree to the <Link style={{ color: '#00FFCC', cursor: 'pointer' }} onClick={() => toggleTos(parent, true)}>Helm Terms</Link>
                  </Typography>
                </Grid>
              </Box>


              <Box my={4}>
                { parent.state.showError ? (
                    <Typography variant="subtitle2" style={{ color: 'black'}}>
                      Please agree to the Helm Terms before continuing
                    </Typography>
                  ) :
                    <>
                    </>
                }
              </Box>


              {parent.state.tosChecked ? (
                <ButtonPurple
                  onClick={() => createIndependentProfile(parent)}
                >
                  Start
                </ButtonPurple>
              ) : (
                <ButtonPurple
                  onClick={() => parent.setState({showError: true})}
                >
                  Start
                </ButtonPurple>
              )}
            </Box>

            <Box py={6}>
              <img src={preview} alt="Profile preview" style={{ width: '100%' }} />
            </Box>
          </Container>
          <Dialog fullScreen open={parent.state.tosOpen} onClose={() => toggleTos(parent, false)} TransitionComponent={Transition}>
            <AppBar>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => toggleTos(parent, false)} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Box py={15} />
            {giveTos()}
          </Dialog>
        </div>
      </>
    )
  } else if(notRedirecting && teamFork) { // team
    return <WorkforceOnboarding parent={parent} launch={true} />
  } else if(notRedirecting) { // no choice selected
    return (
      <>
        <div >
          <Container style={{ maxWidth: '680px' }}>
            <Box py={10}>
              <Box pb={4}>
                <Typography variant="h4" gutterButtom>
                  How are planning to use Helm?
                </Typography>
                <Box my={1} />
                <Typography variant="body1" style={{ color: '#726B83' }}>
                  We'll streamline your setup experience accordingly.
                </Typography>
              </Box>

              <Box p={3} style={{ backgroundImage: 'linear-gradient(270deg, #00C7B4 10%, #00D9AE 85%)', boxShadow: '0 0 10px 3px rgba(0,0,0,0.06)', borderRadius: 20 }}>
                <Box>
                  <Grid container direction="row" alignItems="center" justify="space-between">
                    <Box pl={1} pr={2} style={{ width: 'calc(100% - 240px)' }}>
                      <Typography variant="h6" style={{ color: '#000' }}>
                        With my team
                      </Typography>
                      <Typography variant="body2" style={{ color: '#006954' }}>
                        Find who you need. Make new connections. Visualize work on the go.
                      </Typography>
                    </Box>
                    <ButtonBlock
                      onClick={() => parent.setState({teamFork: true})}
                      style={{ width: 240 }}
                    >
                      Try for free
                    </ButtonBlock>
                  </Grid>
                  <Box m={4} />
                  <Grid container direction="row" alignItems="center" justify="space-between">
                    <Box pl={1} pr={2} style={{ width: 'calc(100% - 240px)' }}>
                      <Typography variant="h6" style={{ color: '#000' }}>
                        For myself
                      </Typography>
                      <Typography variant="body2" style={{ color: '#006954' }}>
                        Show off how you work best. Stay connected from anywhere.
                      </Typography>
                    </Box>
                    <ButtonBlock
                      onClick={() => parent.setState({independentFork: true})}
                      style={{ width: 240 }}
                    >
                      Always free
                    </ButtonBlock>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </div>
      </>
    )
  } else {
    return <Loading/>
  }
}

class LaunchSequence extends Component  {
  constructor(props) {
    super();
    this.state = {
      step: 1,
      stepLength: 8,
      tosOpen: false,
      user: {},
      redirecting: true,
      workforceOnboardingStep: 1
    };
  }

  componentDidMount() {
    const { user, getAccessTokenSilently } = this.props.auth0;
    const values = queryString.parse(this.props.location.search)

    if(!values.access_token || !values.name) {
      this.setState({redirecting: false, notRedirecting: true})
    }

    let access_token = cookies.get('access_token')
    let slackName = cookies.get('slackName')
    let slackLogo = cookies.get('slackLogo')
    let teamId = cookies.get('teamId')
    let independentFork = cookies.get('independentFork')
    let teamFork = cookies.get('teamFork')
    if(access_token && access_token !== 'undefined') {
      this.setState({access_token: access_token})
    }
    if(slackName && slackName !== 'undefined') {
      this.setState({slackName: slackName})
    }
    if(slackLogo && slackLogo !== 'undefined') {
      this.setState({slackLogo: slackLogo})
    }
    if(teamId && teamId !== 'undefined') {
      this.setState({teamId: teamId})
    }
    if(independentFork) {
      this.setState({independentFork: true})
    }
    if(teamFork) {
      this.setState({teamFork: true})
    }

    if(values.access_token && values.name) {
      cookies.set('access_token', values.access_token, { path: '/' });
      cookies.set('slackName', values.name, { path: '/' });
      cookies.set('teamId', values.teamId, { path: '/' });
      if(values.logo) {
        cookies.set('slackLogo', values.logo, { path: '/' });
      }
      console.log(values)
      this.setState({getPersonalSlack: values.user_slack_token ? values.user_slack_token : true})
    }

    getAccessTokenSilently({ audience: 'https://joinhelm.com/api' }).then(token => {
      getByAuth0(user.sub, token).then(my_user => {
        this.setState({ user: my_user, token: token })

        if(values.slackProfile) {
          let name = values.name ? values.name : cookies.get('slackName')
          let logo = values.logo ? values.logo : cookies.get('slackLogo')
          let access_token = values.access_token ? values.access_token : cookies.get('access_token')
          let teamId = values.teamId ? values.teamId : cookies.get('teamId')
          createWorkforceAndUpdate(this, name, my_user, token, logo, access_token, teamId)
        }

      })
    })
  }

  render() {
    const { user } = this.props.auth0

    if (!user) {
      return <Loading/>
    }

    if(this.state.getPersonalSlack) {
      let giveExistingToken = typeof this.state.getPersonalSlack === "string"
      console.log(this.state.getPersonalSlack)
      console.log(giveExistingToken)
      if(giveExistingToken) {
        console.log(1)
        window.location.href = `https://joinhelm.com/api/mo/slack_user_oauth?existingToken=${this.state.getPersonalSlack}`
      } else {
        console.log(2)
        window.location.href = PERSONAL_SLACK_LINK
      }
    }

    if(this.state.toNewUserFlow) {
      return <Redirect to={`/newUser`} />
    }

    if(this.state.toHome) {
      return <Redirect to={`/`} />
    }

    if(this.state.notRedirecting && !this.state.redirecting) {
      return (
        <div style={{ height: '100%', background: '#19005E' }}>
          {giveLaunchBody(this)}
        </div>
      );
    } else {
      return <Loading />
    }
  }
}

export default withAuth0(withAuthenticationRequired(LaunchSequence, {
  onRedirecting: () => <Loading />,
}));
