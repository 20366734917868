import React from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions
} from '@material-ui/core'
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

function TourIntro(props) {
  let {parent} = props

  const start = () => {
    parent.setState({ tourIntro: false, tourShowcase_1: true })
  }
  const giveDialog = () => {
    return (
      <>
        <DialogTitle style={{ background: '#FFF' }}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="overline" style={{ color: '#726B83' }}>
              WIKI TOUR
            </Typography>
            <IconButton size="small" onClick={() => start()}>
              <CloseRoundedIcon style={{ color: '#726B83' }} />
            </IconButton>
          </Grid>
          <Box>
            <Typography variant="h4" gutterBottom>
              <span aria-labelledby="Dive" style={{ paddingRight: 8 }}>🤿</span> Let's dive in!<br/>
              <span style={{ color: '#4300FF' }}>Imagine you're a new hire at Novo.</span>
            </Typography>
            <Typography variant="body1" style={{ color: '#726B83' }}>
              <b>Your name is Phoebe.</b> Today is day one at your new job as a Product Designer. Now let's explore Novo's people wiki.
            </Typography>
          </Box>
          <Box m={3} />
        </DialogTitle>
        <DialogActions>
          <ButtonPurple onClick={() => start()}>
            Start
          </ButtonPurple>
        </DialogActions>
      </>
    )
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={true} onClose={() => start()}>
        {giveDialog()}
      </Dialog>
    </>
  )
}

export default TourIntro;
