
export const createWorkforce = async (workforce, token) => {
  try {
    const response = await fetch(`https://joinhelm.com/api/newWorkforce`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(workforce)
    });

    const responseData = await response.json();

    return responseData
  } catch (error) {
    console.log(error)
  }
};

export const getWorkforce = async (id, token, permissionToken) => {
  try {
    const response = await fetch(`https://joinhelm.com/api/workforce/${id}?permissionToken=${permissionToken}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();

    return responseData
  } catch (error) {
    console.log(error)
  }
};

export const updateWorkforce = (workforceId, token, workforce, permissionToken) => {
  if(workforce.invite_links) {
    workforce.invite_links = undefined
  }
  return fetch(`https://joinhelm.com/api/workforce/${workforceId}?permissionToken=${permissionToken}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(workforce)
  })
    .then(response => {
      return response.json()
    })
    .catch(err => console.log(err))
}

export const addWorkforceUser = (workforceId, token, user) => {
  return fetch(`https://joinhelm.com/api/workforce/newUser/${workforceId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(user)
  })
    .then(response => {
      return response.json()
    })
    .catch(err => console.log(err))
}

export const checkInvite = async (id, code) => {
  try {
    const response = await fetch(`https://joinhelm.com/api/workforce/invite/${id}?code=${code}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    });
    const responseData = await response.json();
    return responseData
  } catch (error) {
    console.log(error)
  }
};

export const createInviteLink = (workforceId, token, link, permissionToken) => {
  return fetch(`https://joinhelm.com/api/workforce/invite_links/${workforceId}?permissionToken=${permissionToken}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(link)
  })
    .then(response => {
      return response.json()
    })
    .catch(err => console.log(err))
}

export const updateWorkforceUser = (workforceId, userId, user, token, permissionToken) => {
  return fetch(`https://joinhelm.com/api/workforce/users/${workforceId}?user=${userId}&permissionToken=${permissionToken}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(user)
  })
    .then(response => {
      return response.json()
    })
    .catch(err => console.log(err))
}
