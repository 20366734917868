

export const office_teams = [
  {
    team_id: 'team1',
    name: 'Sales'
  },
  {
    team_id: 'team2',
    name: 'Management'
  },
  {
    team_id: 'team3',
    name: 'Human Resources'
  },
  {
    team_id: 'team4',
    name: 'Quality Assurance'
  },
  {
    team_id: 'team5',
    name: 'Customer service'
  },
  {
    team_id: 'team6',
    name: 'Accounting'
  },
]
