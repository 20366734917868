import React from 'react'
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import XXSmallAvatar from '../../components/general/avatars/XXSmallAvatar'
import { AvatarGroup } from '@material-ui/lab'
import {imageSrc} from '../../utility/images'
import {clickIntoGroup} from './GroupElement'

function toggleCommunity(parent, communityId) {
  let index = parent.state.selectedCommunities.indexOf(communityId)

  if(index === -1) {
    parent.state.selectedCommunities.push(communityId)
  } else {
    parent.state.selectedCommunities.splice(index, 1)
  }
  parent.forceUpdate()
}

function userInCommunity(parent, communityId) {
  if(parent) {
    let index = parent.state.selectedCommunities.indexOf(communityId)
    console.log(`index ${index}`)
    if(index === -1) {
      return false
    } else {
      return true
    }
  }
}

function CommunityPreview(props) {
  const { community, clickAction, parent, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      background: '#EFEFF1',
      borderRadius: 12,
      width: '100%',
      '&:hover': {
        background: '#E6E4EC',
        cursor: 'pointer'
      },
    },
    avatar: {
      border: '2px solid #FFF',
      maxHeight: 17,
      maxWidth: 17,
      fontSize: 12,
      color: '#726B83',
      background: 'transparent'
    },
  }));
  const classes = useStyles();

  const handleClick = (parent) => {
    if(clickAction === 'add') {
      toggleCommunity(parent, community.community_id)
    } else {
      clickIntoGroup(parent, community, community.community_id)
    }
  }

  const giveActionButton = () => {
    if(clickAction === 'add') {
      return <AddCircleRoundedIcon style={{ color: '#726B83' }}  />
    } else {
      return <ChevronRightRoundedIcon style={{ color: '#726B83' }} />
    }
  }

  return (
    <Box onClick={() => handleClick(parent)}
      style={{
        backgroundColor: `${userInCommunity(parent, community.community_id) ? 'rgba(67, 0, 255, 0.1)' : ''}`,
        color: `${userInCommunity(parent, community.community_id) ? '#4300FF' : ''}`
      }}
      py={2} px={2} className={classes.root}
      { ...passThroughProps }>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Box style={{ width: 'calc(100% - 30px)' }}>
          <Typography variant="subtitle1" style={{ letterSpacing: -0.1, fontWeight: 900, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'  }}>
            {community.name}
          </Typography>
          <AvatarGroup spacing={1} style={{ height: 22 }}
            classes={{ avatar: classes.avatar }}
          >
            {community.users.map((user, i) => {
              return (
                <XXSmallAvatar src={imageSrc(user)} />
              )
            })}
          </AvatarGroup>
        </Box>
        {giveActionButton()}
      </Grid>
    </Box>
  )
}
export default CommunityPreview
