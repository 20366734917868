import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function ButtonPurple(props) {
  const { children, styleVariant, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: 12,
      fontSize: 14,
      boxShadow: '0 0 0 0.1em rgba(0,0,0,0.06)',
      '&:hover': {
        boxShadow: '0 0 0 0.2em rgba(0,0,0,0.06)'
      },
    },
    purple: {
      color: '#FFF',
      backgroundColor: '#6833FF',
      '&:hover': {
        backgroundColor: '#4300FF',
      },
    },
    grey: {
      color: '#141217',
      backgroundColor: '#EFEFF1',
      '&:hover': {
        backgroundColor: '#E6E4EC',
      },
    },
  }));
  const classes = useStyles();
  let variant = styleVariant ? styleVariant : 'purple'

  return (
    <Button variant="contained" { ...passThroughProps } className={`${classes.root} ${classes[variant]}`}>
      {children}
    </Button>
  )
}
export default ButtonPurple
