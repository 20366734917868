import React from 'react'
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


function SmallAvatar(props) {
  let { children, ...passThroughProps } = props
  const useStyles = makeStyles((theme) => ({
    root: {
      border: '2px solid #141217',
      boxShadow: '0 0 8px 4px rgba(0,0,0,0.06)',
      height: 32,
      width: 32,
      '&:hover': {
        boxShadow: '0 0 10px 5px rgba(0,0,0,0.12)',
        cursor: 'pointer'
      },
    },
  }));

  const classes = useStyles();

  return (
    <Avatar { ...passThroughProps } className={ classes.root }>
      {children}
    </Avatar>
  )
}
export default SmallAvatar
