import _ from 'lodash'
import {giveContextDate} from './time'

const FEELINGS_QUESTION_ID = 'q_21'
const FOCUSES_QUESTION_ID = 'q_47'

let moments = [
    {
      _id: 'm_0',
      text: 'Idea',
      conversation_id: 'c_13'
    },
    {
      _id: 'm_1',
      text: 'Insight',
      conversation_id: 'c_14'
    },
    {
      _id: 'm_2',
      text: 'Mistake',
      conversation_id: 'c_15'
    },
    {
      _id: 'm_3',
      text: 'Target',
      conversation_id: 'c_16'
    },
    {
      _id: 'm_4',
      text: 'Win',
      conversation_id: 'c_17'
    },
    {
      _id: 'm_5',
      text: 'Tactic',
      conversation_id: 'c_18'
    },
    {
      _id: 'm_6',
      text: 'Challenge',
      conversation_id: 'c_19'
    },
    {
      _id: 'm_7',
      text: 'Gratitude',
      conversation_id: 'c_20'
    },
    {
      _id: 'm_8',
      text: 'Discussion',
      conversation_id: 'c_21'
    },
    {
      _id: 'm_9',
      text: 'Event',
      conversation_id: 'c_22'
    }
]

export function giveFirstName(user) {
  if(user.fname) {
    return user.fname
  } else return ''
}

export function giveLastName(user) {
  if(user.lname) {
    return user.lname
  } else return ''
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function giveTitle(user) {
  if(user.title) {
    return capitalizeFirstLetter(user.title)
  } else return ''
}

export function givePronouns(user) {
  if(user.pronouns) {
    return user.pronouns
  } else return ''
}

export function giveDomains(user) {
  if(user.domains) {
    return user.domains
  } else return []
}

function giveLastResponse(responses) {
  let last = null
  let mostRecentTime = 0

  responses.forEach((response, i) => {
    if(response.updated) {
      let responseTime = new Date(response.updated).getTime()
      if(responseTime > mostRecentTime) {
        last = response
        mostRecentTime = responseTime
      }
    }
  });

  return last
}

function giveLastMomentResponse(responses) {
  let last = null
  let mostRecentTime = 0

  responses.forEach((response, i) => {
    response.answers.forEach((answer, i) => {
      if(answer.moment_title) {
        let responseTime = new Date(response.updated).getTime()
        if(responseTime > mostRecentTime) {
          answer.date = response.updated
          last = answer
          mostRecentTime = responseTime
        }
      }
    });
  });

  return last
}

export function giveMomentAnswers(response) {
  let answers = []

  response.answers.forEach((answer, i) => {
    if(answer.moment_title) {
      answers.push(answer)
    }
  });

  return answers
}

export function giveLastResponseDate(responses) {
  let result = false
  if(responses) {
    let lastResponse = giveLastResponse(responses)
    if(lastResponse) {
      result = giveContextDate(lastResponse.updated)
    }
  }
  return result
}

export function giveRecentMomentAnswer(responses) {
  let result = false
  if(responses) {
    let lastResponse = giveLastMomentResponse(responses)
    if(lastResponse) {
      return lastResponse
    }
  }
  return result
}

export function giveMomentFromConvo(convoId) {
  let moment = null

  if(convoId) {
    moments.forEach((m, i) => {
      if(m.conversation_id === convoId) {
        moment = m
      }
    });
  }

  return moment
}

export function giveRecentFeelings(responses) {
  let last = []
  let mostRecentTime = 0

  if(responses) {
    responses.forEach((response, i) => {
      response.answers.forEach((answer, i) => {
        if(answer.question_id === FEELINGS_QUESTION_ID) {
          let responseTime = new Date(response.updated).getTime()
          if(responseTime > mostRecentTime && answer.attributes && answer.attributes.length) {
            last = answer.attributes
            mostRecentTime = responseTime
          }
        }
      });
    });
  }

  return last
}

export function giveFeelings(response) {
  let result = []

  if(response && response.answers) {
    response.answers.forEach((answer, i) => {
      if(answer.question_id === FEELINGS_QUESTION_ID) {
        if(answer.attributes && answer.attributes.length) {
          result = answer.attributes
        }
      }
    });
  }

  return result
}

export function giveRecentFocuses(responses) {
  let last = []
  let mostRecentTime = 0

  if(responses) {
    responses.forEach((response, i) => {
      response.answers.forEach((answer, i) => {
        if(answer.question_id === FOCUSES_QUESTION_ID) {
          let responseTime = new Date(response.updated).getTime()
          if(responseTime > mostRecentTime && answer.attributes && answer.attributes.length) {
            last = answer.attributes
            mostRecentTime = responseTime
          }
        }
      });
    });
  }

  return last
}

export function giveFocuses(response) {
  let result = []

  if(response && response.answers) {
    response.answers.forEach((answer, i) => {
      if(answer.question_id === FOCUSES_QUESTION_ID) {
        if(answer.attributes && answer.attributes.length) {
          result = answer.attributes
        }
      }
    });
  }

  return result
}

export function listInSentence(array) {
  let result = ""

  let length = array.length
  array.forEach((item, i) => {
    let needsComma = length > 2 && i < length-1
    let needsAnd = length > 1 && i === length-2
    result += `${item}${needsComma?', ':''}${needsAnd?`${needsComma?'':' '}and `:''}`
  });


  return result
}

export function givePurposeStatement(user) {
  let result = ""

  if(user.purpose_statement) {
    result = user.purpose_statement
  }

  return result
}

const onboarding_steps = ['position', 'contact', 'collabs', 'domains', 'character',
'interests', 'purpose', 'abilities', 'faqs']
const optional_steps = ['purpose', 'abilities', 'faqs']
const onboarding_steps_prompt = [
  {
    text: 'Provide your position',
    tab: 'basics'
  },
  {
    text: 'Provide your contact preferences',
    tab: 'contact preferences'
  },
  {
    text: 'Provide the collabs you are open to',
    tab: 'collabs'
  },
  {
    text: 'Provide your domains',
    tab: 'domains'
  },
  {
    text: 'Provide the adjectives that describe you best',
    tab: 'attributes'
  },
  {
    text: 'Provide a few of your interests',
    tab: 'interests'
  },
  {
    text: 'Provide a purpose statement and select your motivators',
    tab: 'purpose'
  },
  {
    text: 'Provide a few abilities within your domains & a one-liner',
    tab: 'role'
  },
  {
    text: 'Answer a few FAQs',
    tab: 'FAQs'
  }
]
export function giveOnboardingInfo(user, parent) {
  let {independent, title, workforce_name, contact_preferences, collabs, adjectives,
      interests, domains, faqs, impact, purpose_statement, purposes} = user
  let info = {
    complete: false,
    steps_skipped: user.onboarding && user.onboarding.skipped ? user.onboarding.skipped : [],
    steps_completed: [],
    steps_needed: []
  }

  if(independent) { // for when independent? determines the conditions
    if( !title || !workforce_name ) { // position
      info.step = onboarding_steps[0]
    } else {
      info.steps_completed.push(onboarding_steps[0])
    }
  } else { // team user
     if( !title ) { // position
       info.step = onboarding_steps[0]
     } else {
       info.steps_completed.push(onboarding_steps[0])
     }
  }

  if( !contact_preferences || !contact_preferences.primary ) { // contact
    if(!info.step && !info.steps_skipped.includes(onboarding_steps[1])) {
      info.step = onboarding_steps[1]
    }
  } else {
    info.steps_completed.push(onboarding_steps[1])
  }

  if( !collabs || !collabs.length ) { // collabs
    console.log('collabs')
    console.log(collabs)
    if(!info.step && !info.steps_skipped.includes(onboarding_steps[2])) {
      info.step = onboarding_steps[2]
    }
  } else {
    info.steps_completed.push(onboarding_steps[2])
  }

  if(!domains || !domains.length) { // domains
    if(!info.step && !info.steps_skipped.includes(onboarding_steps[3])) {
      info.step = onboarding_steps[3]
    }
  } else {
    info.steps_completed.push(onboarding_steps[3])
  }

  if(!adjectives || !adjectives.length) { // character
    if(!info.step && !info.steps_skipped.includes(onboarding_steps[4])) {
      info.step = onboarding_steps[4]
    }
  } else {
    info.steps_completed.push(onboarding_steps[4])
  }

  if(!interests || !interests.length) { // interests
    if(!info.step && !info.steps_skipped.includes(onboarding_steps[5])) {
      info.step = onboarding_steps[5]
    }
  } else {
    info.steps_completed.push(onboarding_steps[5])
  }

  // OPTIONAL: not onboarding but next steps
  if(!purpose_statement || !purpose_statement.length || !purposes || !purposes.length) { // purpose statement
    if(!info.step && !info.steps_skipped.includes(onboarding_steps[6])) {
      info.step = onboarding_steps[6]
    }
  } else {
    info.steps_completed.push(onboarding_steps[6])
  }

  if(!impact || !impact.length || !domains || !domains.length) {
    if(!info.step && !info.steps_skipped.includes(onboarding_steps[7])) {
      info.step = onboarding_steps[7]
    }
  } else { // abilities
    let hasAbility = false
    domains.forEach((domain, i) => {
      console.log(domain)
      if(domain.capabilities && domain.capabilities.length) {
        hasAbility = true
      }
    });

    if(!hasAbility && !info.step && !info.steps_skipped.includes(onboarding_steps[7])) {
      info.step = onboarding_steps[7]
    } else if(hasAbility) {
      info.steps_completed.push(onboarding_steps[7])
    }
  }

  if(!faqs || !faqs.length) { // FAQs
    if(!info.step && !info.steps_skipped.includes(onboarding_steps[8])) {
      info.step = onboarding_steps[8]
    }
  } else {
    info.steps_completed.push(onboarding_steps[8])
  }


  onboarding_steps.forEach((step, i) => {
    if( !info.steps_skipped.includes(step) && !info.steps_completed.includes(step) ) {
      info.steps_needed.push(step)
    }
  });

  let length = info.steps_completed.length
  info.percent = 10 + (length*10)

  if(info.step) {
    let index = onboarding_steps.indexOf(info.step)
    info.prompt = onboarding_steps_prompt[index]
  } else if(info.steps_skipped.length) {
    let neededStep = false
    info.steps_skipped.forEach((step, i) => {
      if(info.steps_completed.indexOf(step) === -1) {
        neededStep = step
      }
    });

    if(neededStep) {
      let index = onboarding_steps.indexOf(neededStep)
      info.prompt = onboarding_steps_prompt[index]
    }
  }
  let mandatorySteps = info.steps_needed.filter(function(x) { return optional_steps.indexOf(x) < 0 })
  console.log(mandatorySteps)
  info.complete = mandatorySteps.length ? false : true

  console.log(info)
  if(parent && !_.isEqual(info, parent.state.profileProgress)) {
    parent.setState({ profileProgress: info })
  }

  return info
}

export function giveRandomUserPicture() {
  let pictures = [
    'IMAGE-8f4c11e7-8156-47f0-b61c-313e7aa8a1d6.jpg', // green
    'IMAGE-064df9da-0c56-49a0-adbd-69d0273117a3.jpg', // purple
    'IMAGE-a1a8371f-16b7-406e-9802-447ba7d5c5aa.png', // pink
    'IMAGE-c5173c4e-813f-415f-94b7-11ecd5f0207c.jpg', // yellow
    'IMAGE-3220171b-9ba9-4f22-820c-79991b1455cb.jpg' // red
  ]

  return pictures[Math.floor(Math.random() * pictures.length)];
}
