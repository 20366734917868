import React from 'react';
import {
  Typography,
  Dialog,
  Box,
  Button,
  Grid,
  DialogActions
} from '@material-ui/core';
import {WORKFORCE_SLACK_LINK} from '../../api/slack'


function SlackPermissionsReset(props) {
  let {parent} = props

  return (
    <div>
      <Dialog open={true}
       onClose={() => parent.setState({slackPermissionsReset: false})}
       aria-labelledby="form-dialog-title" fullWidth>
       <Box px={3} py={3}>
         <Box py={2} px={2} mb={4} style={{ backgroundImage: 'linear-gradient(270deg, #00C7B4 10%, #00D9AE 85%)', boxShadow: '0 0 10px 3px rgba(0,0,0,0.06)', borderRadius: 12 }}>
           <Grid container direction="row" alignItems="center">
             <Typography variant="h6" style={{ color: '#006954' }}>
              We have made some changes!
             </Typography>
           </Grid>
        </Box>
        <Box py={1} px={2}>
          <Typography variant="body1" style={{ color: '#726B83' }}>Please accept new Slack permissions to get the latest functionality.</Typography>
        </Box>
       </Box>
       <DialogActions>
         <Button color="primary" variant="contained"
         href={WORKFORCE_SLACK_LINK}>
           Take me there!
         </Button>
       </DialogActions>
      </Dialog>
    </div>
  );

}

export default SlackPermissionsReset;
