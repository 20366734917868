import React from "react"
import { Route, Switch, withRouter } from "react-router-dom"

import App from "./pages/App"
import NewWorkforce from "./pages/NewWorkforce"
import LaunchSequence from "./pages/LaunchSequence"
import Settings from "./pages/Settings"
import MemberOnboard from "./pages/MemberOnboard"
import NewUserInvited from "./pages/NewUserInvited"
import IndependentProfile from "./pages/IndependentProfile"
import UserFork from "./pages/UserFork"
import Admin from "./pages/Admin"
import Demo from "./pages/Demo"

const MainRouter = () => (
    <Switch>
      <Route exact path="/newWorkforce" component={withRouter(NewWorkforce)} />
      <Route exact path="/launch" component={withRouter(LaunchSequence)} />
      <Route exact path="/settings" component={withRouter(Settings)} />
      <Route exact path="/newUser" component={withRouter(MemberOnboard)} />
      <Route exact path="/joinWorkforce/:workspaceId/:code" component={withRouter(NewUserInvited)} />
      <Route exact path="/profile/:userId" component={withRouter(IndependentProfile)} />
      <Route exact path="/fork" component={withRouter(UserFork)} />
      <Route exact path="/admin" component={withRouter(Admin)} />
      <Route exact path="/demo" component={withRouter(Demo)} />
      <Route exact path="/" component={withRouter(App)} />
    </Switch>
)

export default MainRouter
