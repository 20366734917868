import React from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import ButtonPurple from '../../components/general/buttons/ButtonPurple'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

function NewHireIntroDemo(props) {
  let {parent} = props

  const next = () => {
    parent.setState({ tourMentorMatch_3: false, tourPeopleSearch_4: true })
  }
  const giveDialog = () => {
    return (
      <>
        <DialogTitle style={{ background: '#FFF' }}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Typography variant="overline" style={{ color: '#726B83' }}>
              WIKI TOUR
            </Typography>
            <IconButton size="small" onClick={() => next()}>
              <CloseRoundedIcon style={{ color: '#726B83' }} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ background: '#FFF' }}>
          <Box>
            <Typography variant="h4" gutterBottom>
              Here's how Helm makes it easy<br/>
              <span style={{ color: '#4300FF' }}>for new hires to find a mentor.</span>
            </Typography>
            <Typography variant="body1" style={{ color: '#726B83' }}>
              <b>It's like magic.</b> Helm's AI uses its intel to find the <i>best</i> mentor match based on goals and common ground.
            </Typography>
          </Box>

          <Typography variant="subtitle1" style={{ color: '#141217' }}>
            See it in action:
          </Typography>
          <Box my={2}>
            <div style={{position: 'relative', paddingBottom: '62.5%', height: 0}}><iframe title="Mentor Match Demo" src="https://www.loom.com/embed/7cc97a11b2aa4a359e9e16fbe56ced9f" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: 8, boxShadow: '0 0 5px 10px rgba(0,0,0,0.06)'}}></iframe></div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Box pl={2}>
              <Typography variant="caption" style={{ color: '#726B83' }}>
                Step 3 of 4
              </Typography>
            </Box>
            <ButtonPurple onClick={() => next()}>
              Next
            </ButtonPurple>
          </Grid>
        </DialogActions>
      </>
    )
  }

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={true} onClose={() => next()}>
        {giveDialog()}
      </Dialog>
    </>
  )
}

export default NewHireIntroDemo;
