import React from 'react';
import { withRouter } from "react-router-dom"
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import MainRouter from "./router"
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/general/Loading";
import {StripeProvider} from 'react-stripe-elements';

function App() {
  const { isLoading, error } = useAuth0();

  if (error) {
    return (
      <div>
        <h5>We got an error: {error.message}</h5>
        <p>This is likely a problem on our end, and we apoligize for the inconveniece.
        Please contact a member of the Helm team to help us resolve this.</p>
      </div>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <MainRouter />
        </ThemeProvider>
      </StripeProvider>
  );
}

export default withRouter(App);
